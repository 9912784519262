<template>
  <v-layout align-center justify-center column fill-height>
    <v-flex>
      <v-layout align-center justify-center row fill-height>
        <span class="subheading mr-2">Mensual</span>
        <v-switch
          color="primary"
          v-model="tarifa"
        >
        </v-switch>
        <span class="subheading">Anual</span>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-alert
        :value="true"
        color="black"
        outline
      >
        <span v-if="tarifa">
          El pago de la tarifa anual es en una sola exhibición
        </span>
        <span v-else>
          El pago de la tarifa mensual solo es para contratos de mínimo 6 meses
        </span>
      </v-alert>
    </v-flex>

    <v-layout align-center justify-center row fill-height>
        <v-flex>
          <v-hover v-slot:default="{ hover }">
            <v-card
              min-width="270"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-card-title class="justify-center layout">
                <span class="headline">Free</span>
              </v-card-title>

              <v-card-text>
                <v-layout align-center justify-center column fill-height>

                  <v-flex>
                    <span class="display-3 font-weight-bold">$0</span>
                    <span v-if="tarifa" class="body-1">/ año</span>
                    <span v-else class="body-1">/ mes</span>
                  </v-flex>

                  <v-flex>
                    <span class="body-1">Registro de 5 alumnos máximo</span>
                  </v-flex>

                  <v-flex>
                    <v-btn outline color="orange" :disabled="auth.membresia.tipo_plan.id === 1">Empieza ya</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>

        <v-flex>
          <v-hover v-slot:default="{ hover }">
            <v-card
              min-width="270"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-card-title class="justify-center layout">
                <span class="headline">Premium</span>
              </v-card-title>

              <v-card-text>
                <v-layout align-center justify-center column fill-height>
                  <v-flex>
                    <span v-if="tarifa" class="display-3 font-weight-bold green--text">$24,000</span>
                    <span v-else class="display-3 font-weight-bold green--text">$2,000</span>
                    <span v-if="tarifa" class="body-1">/ año</span>
                    <span v-else class="body-1">/ mes</span>
                  </v-flex>

                  <v-flex>
                    <span class="body-1">+ $100 por alumno registrado</span>
                  </v-flex>

                  <v-flex>
                    <v-btn flat>
                      <v-icon left color="green">la-check</v-icon>Validación de CURP con RENAPO
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <v-btn flat>
                      <v-icon left color="green">la-check</v-icon>Perzonaliza los PDF de títulos
                    </v-btn>
                  </v-flex>

                  <v-flex>
                    <v-btn outline color="orange" :disabled="auth.membresia.tipo_plan.id === 3">Empieza ya</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>

        <v-flex>
          <v-hover v-slot:default="{ hover }">
            <v-card
              min-width="270"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
            >
              <v-card-title class="justify-center layout">
                <span class="headline">Esencial</span>
              </v-card-title>
              
              <v-card-text>
                <v-layout align-center justify-center column fill-height>
                  <v-flex>
                    <span v-if="tarifa" class="display-3 font-weight-bold">$18,000</span>
                    <span v-else class="display-3 font-weight-boldt">$1,500</span>
                    <span v-if="tarifa" class="body-1">/ año</span>
                    <span v-else class="body-1">/ mes</span>
                  </v-flex>

                  <v-flex>
                    <span class="body-1">+ $100 por alumno registrado</span>
                  </v-flex>

                  <v-flex>
                    <v-btn outline color="orange" :disabled="auth.membresia.tipo_plan.id === 2">Empieza ya</v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-hover>
        </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    tarifa: false
  }),

  computed: {
    ...mapGetters({
      auth: 'auth/user'
    })
  },

  watch: {
    
  },

  methods: {
   
  },

  mounted() {
    this.tarifa = this.auth.membresia.modalidad_pago.id === 2 ? true : false
  }
}
</script>