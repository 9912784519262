var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-1 toolbar-fix",
          attrs: { flat: "", dense: "", color: "white" }
        },
        [
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box_outline_blank")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length < _vm.asignaturas.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("indeterminate_check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length === _vm.asignaturas.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.getAsignaturas } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("refresh")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.eliminarAsignaturas } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("delete")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.copiarTexto } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("content_copy")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("span", { attrs: { color: "grey darken-1" } }, [
            _vm._v(
              _vm._s(_vm.asignaturas.total > 1 ? 1 : 0) +
                "-" +
                _vm._s(_vm.asignaturas.total ? _vm.asignaturas.total : 0) +
                " de " +
                _vm._s(_vm.asignaturas.total ? _vm.asignaturas.total : 0)
            )
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.settingsDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("settings")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.asignaturas.data && _vm.asignaturas.data.length > 0
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.asignaturas.data,
              pagination: _vm.pagination,
              "no-data-text": _vm.noDataText,
              "no-results-text": _vm.noResultsText,
              loading: _vm.loading,
              "item-key": "id",
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c("th"),
                        _vm._v(" "),
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              class: [
                                "column sortable",
                                _vm.pagination.descending ? "desc" : "asc",
                                header.value === _vm.pagination.sortBy
                                  ? "active"
                                  : "",
                                "text-xs-left",
                                header.visibility
                                  ? "hidden-print-only"
                                  : "hidden-screen-only"
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.changeSort(header.value)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("arrow_upward")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("v-hover", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return _c(
                                "tr",
                                {
                                  class: "elevation-" + (hover ? 2 : 0),
                                  on: {
                                    click: function($event) {
                                      return _vm.mostrarAsignatura(props.item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          props.selected = !props.selected
                                        }
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": props.selected,
                                          primary: "",
                                          "hide-details": ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[0].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.numero))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[1].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.clave))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[2].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.nombre))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[3].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.tipo))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[4].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.creditos))]
                                  ),
                                  _vm._v(" "),
                                  hover
                                    ? _c(
                                        "div",
                                        { staticClass: "asignatura-fix-1" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "justify-center layout grey lighten-3 asignatura-fix-2"
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        ripple: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.eliminarAsignatura(
                                                            props.item
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey darken-1"
                                                          }
                                                        },
                                                        [_vm._v("delete")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Eliminar")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        : _c("v-container", [
            _c(
              "div",
              { staticClass: "layout column align-center text-center" },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    "lazy-src": _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    src: _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    alt: _vm.busqueda ? _vm.noResultsText : _vm.noDataText,
                    width: "380"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "mb-3 font-weight-light grey--text text--darken-4 title"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.busqueda ? _vm.noResultsText : _vm.noDataText
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.busqueda
                  ? _c(
                      "v-alert",
                      {
                        attrs: {
                          value: true,
                          color: "primary",
                          icon: "info",
                          outline: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n        Intenta usar otras palabras clave o quitar los filtros de búsqueda\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "400px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.settingsDialog,
            callback: function($$v) {
              _vm.settingsDialog = $$v
            },
            expression: "settingsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Configuración")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Columnas a mostrar\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.headers, function(header, index) {
                            return _c(
                              "v-list-tile",
                              { key: index },
                              [
                                _c(
                                  "v-list-tile-action",
                                  [
                                    _c("v-switch", {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: header.visibility,
                                        callback: function($$v) {
                                          _vm.$set(header, "visibility", $$v)
                                        },
                                        expression: "header.visibility"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(header.text))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.settingsDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: { click: _vm.aplicarConfiguracion }
                    },
                    [_vm._v("Aplicar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }