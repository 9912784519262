<template>
  <v-layout row justify-center wrap>

    <v-flex lg4 sm4 xs4>
      <v-select
        @input="getReporte"
        :label="labels.tipo_reporte"
        v-model="tipo"
        :items="tipos"
        dense
        hide-details
      ></v-select>
    </v-flex>

    <v-flex lg4 sm4 xs4>
      <v-select
        @input="getReporte"
        :label="labels.campus"
        v-model="campus_id"
        :items="auth ? auth.campuses : []"
        item-text="nombre"
        item-value="id"
        dense
        :no-data-text="labels.no_datos"
        hide-details
      ></v-select>
    </v-flex>

    <v-flex lg4 sm4 xs4>
      <v-select
        @input="getReporte"
        :label="labels.anio"
        v-model="anio"
        :items="anios"
        dense
        hide-details
      ></v-select>
    </v-flex>

    <v-flex lg4 sm4 xs12>
      <v-card>
        <v-card-title>
          <v-icon
            color="blue"
            class="mr-3"
            size="36"
          >
            la-list
          </v-icon>

          <div class="subheading text-uppercase">
            Capturados
          </div>

          <v-spacer></v-spacer>

          <div class="headline">
            {{ documentos.noFirmados }}
          </div>
        </v-card-title>
      </v-card>
    </v-flex>

    <v-flex lg4 sm4 xs12>
      <v-card>
        <v-card-title>
          <v-icon
            :color="tipo === 'Títulos' ? 'purple' : 'indigo'"
            class="mr-3"
            size="36"
          >
            {{ tipo === 'Títulos' ? 'la-list-ol' : 'la-tasks' }}
          </v-icon>
          
          <div class="subheading text-uppercase">
            {{ tipo === 'Títulos' ? 'Firmados' : 'Pagados' }}
          </div>

          <v-spacer></v-spacer>

          <div class="headline">
            {{ documentos.firmados }}
          </div>
        </v-card-title>
      </v-card>
    </v-flex>

    <v-flex lg4 sm4 xs12>
      <v-card>
        <v-card-title>
          <v-icon
            :color="tipo === 'Títulos' ? 'green' : 'purple'"
            class="mr-3"
            size="36"
          >
            {{ tipo === 'Títulos' ? 'la-list-alt' : 'la-list-ol' }}
          </v-icon>
          
          <div class="subheading text-uppercase">
            {{ tipo === 'Títulos' ? 'Enviados' : 'Firmados' }}
          </div>

          <v-spacer></v-spacer>

          <div class="headline">
            {{ documentos.enviados }}
          </div>
        </v-card-title>
      </v-card>
    </v-flex>

    <v-flex lg12 sm12 xs12>
      <v-card
        class="mx-auto text-xs-center"
        max-width="600"
      >
        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .02)">
            <v-sparkline
              :value="documentos.porMes"
              stroke-linecap="round"
              smooth
              height="100"
              line-width="2"
              padding="24"
              auto-draw
            >
              <template
                slot="label"
                slot-scope="item"
              >
                {{ item.value }}
              </template>
            </v-sparkline>
          </v-sheet>
          <div class="subheading text-uppercase mt-3">Cantidad de {{ tipo }} por mes</div>
        </v-card-text>

      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from '~/mixins/form'

export default {
  mixins: [Form],

  data: () => ({
    documentos: {
      noFirmados: 0,
      firmados: 0,
      enviados: 0,
      porMes: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    },
    campus_id: null,
    anio: new Date().getFullYear(),
    anios: [2019, 2020, 2021, 2022, 2023],
    tipos: ['Títulos', 'Certificados'],
    tipo: 'Títulos',
  }),

  computed: {
    ...mapGetters({
      auth: "auth/user",
      live_mode: "auth/live_mode"
    })
  },

  watch: {
    live_mode(val) {
      this.initial()
    }
  },

  methods: {
    getReporte() {
      if(this.auth && this.campus_id && this.anio) {
        let api_path = null
        if(this.tipo === 'Títulos') {
          api_path = 'titulos.reporte'
        } else if(this.tipo === 'Certificados') {
          api_path = 'lotes_certificados.reporte'
        }
        axios
          .get(api.path(api_path), {
            params: {
              campus_id: this.campus_id,
              anio: this.anio
            }
          })
          .then(res => {
            this.documentos = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      }
    },

    initial() {
      if (this.auth && this.auth.campuses.length > 0) {
        this.campus_id = this.auth.campuses[0].id
      }
      this.getReporte()
    }
  },

  mounted() {
    this.initial()
  }
}
</script>
