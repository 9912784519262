var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _vm.form.logo
            ? _c("v-img", {
                staticClass: "mb-4",
                attrs: {
                  src: _vm.form.logo,
                  "lazy-src": _vm.form.logo,
                  height: "80",
                  contain: "",
                  "aspect-ratio": "1"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "logo"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.archivo_logo,
                  "error-messages": _vm.errors.archivo_logo,
                  rules: [_vm.rules.required("archivo_logo")],
                  disabled: _vm.loadingDialog,
                  "append-icon": "attach_file"
                },
                on: { click: _vm.seleccionarImagenLogo },
                model: {
                  value: _vm.file.name,
                  callback: function($$v) {
                    _vm.$set(_vm.file, "name", $$v)
                  },
                  expression: "file.name"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "archivo_logo",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: ".png" },
            on: { change: _vm.imagenLogoSeleccionada }
          }),
          _vm._v(" "),
          !this.campo || this.campo === "clave"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.clave,
                  "error-messages": _vm.errors.clave,
                  rules: [_vm.rules.required("clave")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.clave,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "clave", $$v)
                  },
                  expression: "form.clave"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "clave_concentradora"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.clave_concentradora,
                  "error-messages": _vm.errors.clave_concentradora,
                  rules: [_vm.rules.required("clave_concentradora")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.clave_concentradora,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "clave_concentradora", $$v)
                  },
                  expression: "form.clave_concentradora"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "rfc"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.rfc,
                  "error-messages": _vm.errors.rfc,
                  rules: [_vm.rules.required("rfc")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.rfc,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rfc", $$v)
                  },
                  expression: "form.rfc"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "razon_social"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.razon_social,
                  "error-messages": _vm.errors.razon_social,
                  rules: [_vm.rules.required("razon_social")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.razon_social,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "razon_social", $$v)
                  },
                  expression: "form.razon_social"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "wsdl_usuario_titulos"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.wsdl_usuario_titulos,
                  "error-messages": _vm.errors.wsdl_usuario_titulos,
                  rules: [_vm.rules.required("wsdl_usuario_titulos")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.configuracion.wsdl_usuario_titulos,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.configuracion,
                      "wsdl_usuario_titulos",
                      $$v
                    )
                  },
                  expression: "form.configuracion.wsdl_usuario_titulos"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "wsdl_constrasena_titulos"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.wsdl_constrasena_titulos,
                  "error-messages": _vm.errors.wsdl_constrasena_titulos,
                  rules: [_vm.rules.required("wsdl_constrasena_titulos")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.configuracion.wsdl_constrasena_titulos,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form.configuracion,
                      "wsdl_constrasena_titulos",
                      $$v
                    )
                  },
                  expression: "form.configuracion.wsdl_constrasena_titulos"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("Cancelar")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("Guardar")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }