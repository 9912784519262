var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-1 toolbar-fix",
          attrs: { flat: "", dense: "", color: "white" }
        },
        [
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box_outline_blank")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length < _vm.titulos.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("indeterminate_check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length === _vm.titulos.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.getTitulos } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("refresh")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 && _vm.loteTitulos.estado_lote_id !== 5
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.eliminarTitulos } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("delete")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.copiarTexto } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("content_copy")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 && _vm.loteTitulos.estado_lote_id === 5
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.cancelarTitulos } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("cancel")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("span", { attrs: { color: "grey darken-1" } }, [
            _vm._v(
              _vm._s(
                _vm.titulos.count > 1
                  ? (_vm.titulos.current_page - 1) * _vm.titulos.per_page + 1
                  : 0
              ) +
                "-" +
                _vm._s(
                  _vm.titulos.count
                    ? (_vm.titulos.current_page - 1) * _vm.titulos.per_page +
                        _vm.titulos.count
                    : 0
                ) +
                " de " +
                _vm._s(_vm.titulos.total ? _vm.titulos.total : 0)
            )
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.titulos.current_page <= 1, icon: "" },
              on: { click: _vm.getTitulosPreviousPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_before")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: _vm.titulos.current_page >= _vm.titulos.last_page,
                icon: ""
              },
              on: { click: _vm.getTitulosNextPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_next")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.settingsDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("settings")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.titulos.data && _vm.titulos.data.length > 0
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.titulos.data,
              pagination: _vm.pagination,
              "no-data-text": _vm.noDataText,
              "no-results-text": _vm.noResultsText,
              loading: _vm.loading,
              "item-key": "id",
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c("th"),
                        _vm._v(" "),
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              class: [
                                "column sortable",
                                _vm.pagination.descending ? "desc" : "asc",
                                header.value === _vm.pagination.sortBy
                                  ? "active"
                                  : "",
                                "text-xs-left",
                                header.visibility
                                  ? "hidden-print-only"
                                  : "hidden-screen-only"
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.changeSort(header.value)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("arrow_upward")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("v-hover", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return _c(
                                "tr",
                                {
                                  class: "elevation-" + (hover ? 2 : 0),
                                  on: {
                                    click: function($event) {
                                      return _vm.mostrarTitulo(props.item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          props.selected = !props.selected
                                        }
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": props.selected,
                                          primary: "",
                                          "hide-details": ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[0].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item.estado_documento.color,
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.estado_documento
                                                  .nombre
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "max-width": "400",
                                                transition:
                                                  "slide-x-transition",
                                                "open-on-hover": "",
                                                "offset-y": ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                right: ""
                                                              }
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "info_outline"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            },
                                            [
                                              _vm._v(" "),
                                              _c(
                                                "v-card",
                                                [
                                                  _c("v-card-text", [
                                                    props.item.estado_documento
                                                      .id === 1
                                                      ? _c(
                                                          "div",
                                                          _vm._l(
                                                            props.item.resultado.split(
                                                              ","
                                                            ),
                                                            function(
                                                              linea,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c("span", [
                                                                    _c(
                                                                      "strong",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            index +
                                                                              1
                                                                          ) +
                                                                            ". "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        linea
                                                                      )
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : props.item
                                                          .estado_documento
                                                          .id === 2
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "Para continuar se requiere firmar el título, una vez firmado no se puede modificar."
                                                          )
                                                        ])
                                                      : props.item
                                                          .estado_documento
                                                          .id === 5
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "Para continuar se requiere enviar el título a la SEP."
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .resultado
                                                            )
                                                          )
                                                        ])
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[1].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.resultado
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              props.item.resultado.split(","),
                                              function(linea, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(linea))
                                                    ])
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[2].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.folio_control))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[3].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.nombre_campus))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[4].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.nombre_carrera))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[5].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item.matricula_profesionista
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[6].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(props.item.nombre_profesionista)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[7].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item
                                            .primer_apellido_profesionista
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[8].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item
                                            .segundo_apellido_profesionista
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[9].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.curp_profesionista
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color:
                                                  props.item.estado_curp.color,
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .curp_profesionista
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "max-width": "400",
                                                    transition:
                                                      "slide-x-transition",
                                                    "open-on-hover": "",
                                                    "offset-y": ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    right: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "info_outline"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card",
                                                    [
                                                      _c("v-card-text", [
                                                        props.item.estado_curp
                                                          .id === 1
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "Es necesario dar clic en el botón de "
                                                              ),
                                                              _c("strong", [
                                                                _vm._v(
                                                                  "Validar CURP's"
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " para verificar la CURP con la base de datos de RENAPO."
                                                              )
                                                            ])
                                                          : props.item
                                                              .estado_curp
                                                              .id === 2
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "La CURP no está registrada ante RENAPO o no coincide con los nombre y/o apellidos del profesionista."
                                                              )
                                                            ])
                                                          : props.item
                                                              .estado_curp
                                                              .id === 3
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "La CURP si está registrada ante RENAPO."
                                                              )
                                                            ])
                                                          : _vm._e()
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[10].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item
                                            .correo_electronico_profesionista
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[11].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          props.item
                                            .numero_telefono_profesionista
                                        )
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  hover
                                    ? _c(
                                        "div",
                                        { staticClass: "titulo-fix-1" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "justify-center layout grey lighten-3 titulo-fix-2"
                                            },
                                            [
                                              _vm.loteTitulos.estado_lote_id ===
                                                5 &&
                                              (props.item.estado_documento
                                                .id === 6 ||
                                                props.item.estado_documento
                                                  .id === 9)
                                                ? _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            icon: "",
                                                            ripple: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.cancelarTitulo(
                                                                props.item
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey darken-1"
                                                              }
                                                            },
                                                            [_vm._v("cancel")]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Cancelar")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.loteTitulos.estado_lote_id ===
                                                2 ||
                                              _vm.loteTitulos.estado_lote_id ===
                                                3 ||
                                              _vm.loteTitulos.estado_lote_id ===
                                                4
                                                ? _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            icon: "",
                                                            ripple: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.eliminarTitulo(
                                                                props.item
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey darken-1"
                                                              }
                                                            },
                                                            [_vm._v("delete")]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Eliminar")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        : _c("v-container", [
            _c(
              "div",
              { staticClass: "layout column align-center text-center" },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    "lazy-src": _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    src: _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    alt: _vm.busqueda ? _vm.noResultsText : _vm.noDataText,
                    width: "380"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "mb-3 font-weight-light grey--text text--darken-4 title"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.busqueda ? _vm.noResultsText : _vm.noDataText
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.busqueda
                  ? _c(
                      "v-alert",
                      {
                        attrs: {
                          value: true,
                          color: "primary",
                          icon: "info",
                          outline: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n        Intenta usar otras palabras clave o quitar los filtros de búsqueda\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "400px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.settingsDialog,
            callback: function($$v) {
              _vm.settingsDialog = $$v
            },
            expression: "settingsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Configuración")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Número de filas por página\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-slider", {
                            attrs: {
                              "thumb-label": "always",
                              step: "10",
                              "thumb-size": 24,
                              min: 10,
                              max: 100,
                              ticks: "",
                              "tick-size": "2"
                            },
                            model: {
                              value: _vm.pagination.rowsPerPage,
                              callback: function($$v) {
                                _vm.$set(_vm.pagination, "rowsPerPage", $$v)
                              },
                              expression: "pagination.rowsPerPage"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Columnas a mostrar\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.headers, function(header, index) {
                            return _c(
                              "v-list-tile",
                              { key: index },
                              [
                                _c(
                                  "v-list-tile-action",
                                  [
                                    _c("v-switch", {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: header.visibility,
                                        callback: function($$v) {
                                          _vm.$set(header, "visibility", $$v)
                                        },
                                        expression: "header.visibility"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(header.text))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.settingsDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: { click: _vm.aplicarConfiguracion }
                    },
                    [_vm._v("Aplicar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "400px" },
          model: {
            value: _vm.cancelarTitulosDialog,
            callback: function($$v) {
              _vm.cancelarTitulosDialog = $$v
            },
            expression: "cancelarTitulosDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Cancelar títulos")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-container",
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.labels.motivo_cancelacion,
                              items: _vm.motivosCancelacion,
                              "item-text": "descripcion",
                              "item-value": "uuid",
                              "error-messages": _vm.errors.motivo_cancelacion,
                              rules: [_vm.rules.required("motivo_cancelacion")],
                              dense: "",
                              "hide-no-data": ""
                            },
                            model: {
                              value: _vm.form.motivo_cancelacion,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "motivo_cancelacion", $$v)
                              },
                              expression: "form.motivo_cancelacion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.cancelarTitulosDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", type: "submit" },
                      on: { click: _vm.cancelar }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("Espera un momento\n        "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }