import formatter from '~/helpers/formatter'

export default {
  data: () => ({
    loading: false,
    valid: true,

    messages: {
      fiel_select_file_cer: 'Selecciona el certificado de la e.firma',
      fiel_select_file_key: 'Selecciona la llave de la e.firma',
      fiel_input_password: 'Ingresa la contraseña de la e.firma',
      fiel_cer_file_invalid: 'El certificado no pertenece a este responsable',
      fiel_not_configured: 'La e.firma no está configurada',
      fiel_pending_sign: 'El responsable aún no ha firmado',
      no_responsables_registrados_campus: 'No hay responsables registrados para este campus',
      lote_certificado_sin_folio_pago: 'En espera de que se capture el folio de pago',
    },

    labels: {
      file_cer: 'Archivo .CER',
      file_key: 'Archivo .KEY',
      next: 'Continuar',
      back: 'Regresar',
      sign: 'Firmar',
      cancel: 'Cancelar',

      name: 'Nombre',
      email: 'Correo electrónico',
      password: 'Contraseña',
      password_confirmation: 'Confirmar contraseña',
      new_password: 'Nueva contraseña',
      new_password_confirmation: 'Confirmar nueva contraseña',
      nombre: 'Nombre',
      primer_apellido: 'Primer apellido',
      segundo_apellido: 'Segundo Apellido',
      curp: 'CURP',
      rfc: 'RFC',
      cargo: 'Cargo',
      abreviatura_titulo: 'Abreviatura de grado académico',
      campuses: 'Campus',
      campuses_asignados: 'Campus Asignados',
      campuses_a_firmar_certificados: 'Campus a firmar certificados',
      campuses_a_firmar_titulos: 'Campus a firmar titulos',
      responsables_asignados: 'Responsables asignados',
      uuid: 'Identificador',
      clave_estatal: 'Clave estatal',
      clave_federal: 'Clave federal',
      clave_pago: 'Clave de pago DGAIR',
      role: 'Rol',
      clave: 'Clave',
      nivel_de_estudio: 'Nivel de estudio',
      campus: 'Campus',
      carrera: 'Carrera',
      modalidad: 'Modalidad',
      tipo_rvoe: 'Tipo de RVOE',
      numero_rvoe: 'Número de RVOE',
      fecha_rvoe: 'Fecha de RVOE',
      clave_plan: 'Clave del plan',
      calificacion_maxima: 'Calif. máxima',
      calificacion_minima: 'Calif. mínima',
      calificacion_minima_aprobatoria: 'Calif. mínima aprobatoria',
      archivo_xlsx: 'Archivo XLSX',
      numero_asignatura: 'Número de asignatura',
      clave_asignatura: 'Clave de asignatura',
      id_asignatura: 'ID de asignatura',
      nombre_asignatura: 'Nombre de asignatura',
      ciclo_escolar: 'Ciclo escolar',
      id_observacion: 'ID de observación',
      observacion: 'Observación',
      calificacion: 'Calificación',
      entidad_federativa: 'Entidad federativa',
      fecha_inicio_carrera: 'Fecha de inicio de la carrera',
      fecha_terminacion_carrera: 'Fecha de terminación de la carrera',
      autorizacion_reconocimiento: 'Autorización o reconocimiento del RVOE',
      curp_profesionista: 'CURP del profesionista',
      matricula_profesionista: 'Matricula del profesionista',
      nombre_profesionista: 'Nombre del profesionista',
      primer_apellido_profesionista: 'Primer apellido del profesionista',
      segundo_apellido_profesionista: 'Segundo apellido del profesionista',
      numero_telefono_profesionista: 'Número de teléfono del profesionista',
      curp_alumno: 'CURP del alumno',
      matricula_alumno: 'Matricula del alumno',
      nombre_alumno: 'Nombre del alumno',
      primer_apellido_alumno: 'Primer apellido del alumno',
      segundo_apellido_alumno: 'Segundo apellido del alumno',
      correo_electronico: 'Correo electrónico',
      correo_electronico_profesionista: 'Correo electrónico del profesionista',
      fecha_de_expedicion: 'Fecha de expedición',
      modalidad_de_titulacion: 'Modalidad de titulación',
      fecha_examen_profesional_o_fecha_exencion_examen_profesional: 'Fecha de (examen/excención de examen) profesional',
      cumplio_servicio_social: 'Cumplió el servicio social',
      fundamento_legal_servicio_social: 'Fundamento legal del servicio social',
      entidad_federativa_expedicion: 'Entidad federativa de expedición',
      institucion_procedencia: 'Institución de procedencia',
      tipo_estudio_antecedente: 'Tipo de estudio del antecedente',
      entidad_federativa_antecedente: 'Entidad federativa del antecedente',
      fecha_inicio_antecedente: 'Fecha de inicio del antecedente',
      fecha_terminacion_antecedente: 'Fecha de terminación del antecedente',
      numero_cedula: 'Número de cedula del antecedente',
      folio: 'Folio',
      folio_control: 'Folio de control',
      estado: 'Estado',
      habilitado: 'Habilitado',
      resultado: 'Resultado',
      motivo_cancelacion: 'Motivo de cancelación',
      fecha_capturado: 'Fecha de captura',
      fecha_firmado: 'Fecha de firma',
      fecha_enviado: 'Fecha de envío',
      usuario_capturado: 'Usuario que capturó',
      usuario_firmado: 'Usuario que firmó',
      usuario_enviado: 'Usuario que envió',
      usuario_pagado: 'Usuario que marcó como pagado',
      fecha_pagado: 'Fecha de pago',
      anio: 'Año',
      tipo_reporte: 'Tipo de reporte',
      no_datos: 'No hay datos',
      clave: 'Clave DGP',
      clave_concentradora: 'Clave concentradora',
      razon_social: 'Razón social',
      version_xml_titulos: 'Versión XML de títulos',
      version_xml_certificados: 'Versión XML de certificados',
      consecutivo_titulos: 'Número consecutivo de títulos',
      consecutivo_certificados: 'Número consecutivo de certificados',
      wsdl_url_titulos: 'URL de Servicio Web de títulos',
      wsdl_usuario_titulos: 'Usuario de Servicio Web de títulos',
      wsdl_constrasena_titulos: 'Contraseña de Servicio Web de títulos',
      tipo_documento: 'Tipo de documento a firmar',
      id_tipo_asignatura: 'ID de tipo de asignatura',
      tipo_asignatura: 'Tipo de asignatura',
      creditos_asignatura: 'Número de créditos de asignatura',
      tipo_certificacion: 'Tipo de certificación',
      tipo_periodo: 'Tipo de periodo',
      genero_alumno: 'Genero del alumno',
      fecha_nacimiento_alumno: 'Fecha de nacimiento del alumno',
      total_asignaturas: 'Total de asignaturas',
      asignaturas_asignadas: 'Asignaturas asignadas',
      promedio_calificaciones: 'Promedio de calificaciones',
      total_creditos: 'Total de créditos',
      creditos_obtenidos: 'Créditos obtenidos',
      numero_ciclos: 'Número de ciclos',
      tipo_periodo: 'Tipo de periodo',
      nombre_lote: 'Nombre de lote',
      cambiar_password: 'Cambiar contraseña',
      folio_pago: 'Folio de pago',
      folio_solicitud: 'Folio de solicitud',
      crear_nuevo_lote: 'Crear un nuevo lote',
      agregar_lote_capturado: 'Agregar a un lote capturado',
      editable: 'Editable',
      notificar_captura_titulos: 'Notificar la captura de nuevos lotes de títulos',
      notificar_firma_titulos: 'Notificar la firma de lotes de títulos',
      notificar_envio_titulos: 'Notificar el envío a la SEP de lotes de títulos',
      notificar_captura_certificados: 'Notificar la captura de lotes de certificados',
      notificar_autorizacion_certificados: 'Notificar la autorización de lotes de certificados',
      notificar_firma_certificados: 'Notificar la firma de lotes de certificados',
      notificar_registro_titulo: 'Notificar el registro de título electrónico',
      consecutivo_documento: 'Consecutivo de lote',
      regla: 'Regla',
      responsable_plan_estudios: "Responsable del plan de estudios",
      numero_certificado: 'Número de certificado',
      fecha_emision: 'Fecha de emisión',
      fecha_expiracion: 'Fecha de expiración',
      tipo_plan: 'Plan actual',
      cantidad_alumnos: 'Alumnos contratados',
      correo_electronico_contacto: 'Correo electrónico de contacto',
      modalidad_pago: 'Modalidad de pago',
      periodo_servicio: 'Periodo de servicio',
      fecha: 'Fecha',
      descripcion: 'Descripción',
      total: 'Total',
      archivo_titulos_autenticados: 'Archivo de títulos autenticados',
      archivo_certificados_registrados: 'Archivo de certificados registrados',
      archivo_firma_autografa: 'Imágen de firma autógrafa',
      archivo_logo: 'Imágen de logotipo',
      estilo: 'Archivo de estilo',
      fondo: 'Imágen de fondo',
      plantilla_titulos: 'Plantilla de títulos',
      autofirma: 'Firmado automático',
    },
    form: {},
    rules: {},
    errors: {}
  }),

  created() {
    for (let key in this.form) {
      if (this.form[key] !== null && typeof this.form[key] === 'object') {
        for (let i in this.form[key]) {
          let key2 = key + '.' + i
          this.errors[key2] = []
          if (!this.labels[key2]) {
            this.labels[key2] = formatter.titlecase(i)
          }
        }
      }
      else {
        this.errors[key] = []
        if (!this.labels[key]) {
          this.labels[key] = formatter.titlecase(key)
        }
      }
    }

    this.rules.required = (field) => ((v) => !!v || 'El campo ' + (this.labels && this.labels[field] && this.labels[field].toLowerCase() + ' ') + 'es requerido')
  },

  methods: {
    handleErrors(errors) {
      if (errors) {
        this.setErrors(errors)
      }
      else {
        this.clearErrors()
      }
    },

    setErrors(errors) {
      for (let key in this.errors) {
        this.errors[key] = errors[key] || []
      }
    },

    clearErrors() {
      for (let key in this.errors) {
        this.errors[key] = []
      }
    }
  }
}
