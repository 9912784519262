import { render, staticRenderFns } from "./UsuarioMostrar.vue?vue&type=template&id=2eb5edd0&"
import script from "./UsuarioMostrar.vue?vue&type=script&lang=js&"
export * from "./UsuarioMostrar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VChip,VContainer,VFlex,VLayout,VSelect,VSwitch,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2eb5edd0')) {
      api.createRecord('2eb5edd0', component.options)
    } else {
      api.reload('2eb5edd0', component.options)
    }
    module.hot.accept("./UsuarioMostrar.vue?vue&type=template&id=2eb5edd0&", function () {
      api.rerender('2eb5edd0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/usuarios/mostrar/UsuarioMostrar.vue"
export default component.exports