var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "nombre"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.name,
                  "error-messages": _vm.errors.name,
                  rules: [_vm.rules.required("name")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "contraseña"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.new_password,
                  "append-icon": _vm.passwordHidden
                    ? "visibility_off"
                    : "visibility",
                  type: _vm.passwordHidden ? "password" : "text",
                  "error-messages": _vm.errors.new_password,
                  rules: [_vm.rules.required("new_password")],
                  disabled: _vm.loading,
                  hint: "Al menos 6 caracteres",
                  autocomplete: "new-password"
                },
                on: {
                  "click:append": function() {
                    return (_vm.passwordHidden = !_vm.passwordHidden)
                  }
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "contraseña"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.new_password_confirmation,
                  type: _vm.passwordHidden ? "password" : "text",
                  "error-messages": _vm.errors.new_password_confirmation,
                  rules: [_vm.rules.required("new_password_confirmation")],
                  disabled: _vm.loading,
                  autocomplete: "new-password"
                },
                model: {
                  value: _vm.form.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password_confirmation", $$v)
                  },
                  expression: "form.password_confirmation"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }