var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.uuid,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCarrera("uuid")
                        }
                      },
                      model: {
                        value: _vm.carrera.uuid,
                        callback: function($$v) {
                          _vm.$set(_vm.carrera, "uuid", $$v)
                        },
                        expression: "carrera.uuid"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.nombre,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCarrera("nombre")
                        }
                      },
                      model: {
                        value: _vm.carrera.nombre,
                        callback: function($$v) {
                          _vm.$set(_vm.carrera, "nombre", $$v)
                        },
                        expression: "carrera.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.nivel_de_estudio,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCarrera("nivel_de_estudio")
                        }
                      },
                      model: {
                        value: _vm.carrera.nivel_de_estudio.nombre,
                        callback: function($$v) {
                          _vm.$set(_vm.carrera.nivel_de_estudio, "nombre", $$v)
                        },
                        expression: "carrera.nivel_de_estudio.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.modalidad,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCarrera("modalidad")
                        }
                      },
                      model: {
                        value: _vm.carrera.modalidad.nombre,
                        callback: function($$v) {
                          _vm.$set(_vm.carrera.modalidad, "nombre", $$v)
                        },
                        expression: "carrera.modalidad.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.clave,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCarrera("clave")
                        }
                      },
                      model: {
                        value: _vm.carrera.clave,
                        callback: function($$v) {
                          _vm.$set(_vm.carrera, "clave", $$v)
                        },
                        expression: "carrera.clave"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }