<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.tipo_documento"
              v-model="reglaFolioControl.tipo_documento.nombre"
              @click:append="editarReglaFolioControl('tipo_documento')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.autorizacion_reconocimiento"
              v-model="reglaFolioControl.autorizacion_reconocimiento.nombre"
              @click:append="editarReglaFolioControl('autorizacion_reconocimiento')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.consecutivo_documento"
              v-model="reglaFolioControl.consecutivo_documento"
              @click:append="editarReglaFolioControl('consecutivo_documento')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.regla"
              v-model="reglaFolioControl.regla"
              @click:append="editarReglaFolioControl('regla')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-autocomplete
              :label="labels.campuses_asignados"
              v-model="reglaFolioControl.campuses"
              :items="campuses"
              item-text="nombre"
              item-value="uuid"
              attach
              chips
              multiple
              @click:append="editarReglaFolioControl('campuses')"
              append-icon="edit"
              outline
              readonly
            ></v-autocomplete>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      campuses: [],
      reglaFolioControl: {
        autorizacion_reconocimiento: {}
      }
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'regla-folio-control' })
      }
    },

    mounted() {
      this.getReglaFolioControl(this.$route.params.id)
      this.getCampuses()
    },

    methods: {
      getReglaFolioControl(id) {
        this.loading = true
        axios
          .get(api.path('folios_control.single', { id: id } ))
          .then(res => {
            this.reglaFolioControl = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      editarReglaFolioControl(campo) {
        this.$router.push({ name: "regla-folio-control-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
