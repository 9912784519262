var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.fecha,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.fecha,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "fecha", $$v)
                        },
                        expression: "facturacion.fecha"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.descripcion,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.descripcion,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "descripcion", $$v)
                        },
                        expression: "facturacion.descripcion"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.periodo_servicio,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.periodo_servicio,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "periodo_servicio", $$v)
                        },
                        expression: "facturacion.periodo_servicio"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.tipo_plan,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.plan,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "plan", $$v)
                        },
                        expression: "facturacion.plan"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.modalidad_pago,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.modalidad,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "modalidad", $$v)
                        },
                        expression: "facturacion.modalidad"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.cantidad_alumnos,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.cantidad_alumnos,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "cantidad_alumnos", $$v)
                        },
                        expression: "facturacion.cantidad_alumnos"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.total,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.facturacion.total,
                        callback: function($$v) {
                          _vm.$set(_vm.facturacion, "total", $$v)
                        },
                        expression: "facturacion.total"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }