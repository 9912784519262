<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-text-field
      :label="labels.email"
      v-model="form.email"
      type="email"
      :error-messages="errors.email"
      :rules="[rules.required('email')]"
      :disabled="loading"
      prepend-icon="la-user"
      ref="campoCorreo"
    >
    </v-text-field>

    <v-text-field
      :label="labels.password"
      v-model="form.password"
      :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
      @click:append="() => (passwordHidden = !passwordHidden)"
      :type="passwordHidden ? 'password' : 'text'"
      :error-messages="errors.password"
      :disabled="loading"
      :rules="[rules.required('password')]"
      prepend-icon="la-lock"
      ref="campoContraseña"
    >
    </v-text-field>

    <!--<div class="text-xs-center">
        <v-btn flat :disabled="loading" :to="{ name: 'forgot', query: {email: form.email} }" color="grey darken-2">
        ¿Olvidaste la contraseña?
      </v-btn>
      </div>-->

    <v-layout row mt-2>
      <!--<v-btn
        flat
        :disabled="loading"
        :to="{ name: 'registro' }"
        color="grey darken-2"
      >
        Crear cuenta
      </v-btn>-->

      <v-spacer></v-spacer>
      
      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading || !valid"
        color="primary"
      >
        Siguiente
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import axios from 'axios'
import { api } from '~/config'
import Form from '~/mixins/form'

export default {
  mixins: [Form],

  data: () => ({
    passwordHidden: true,
    form: {
      email: null,
      password: null
    }
  }),

  created() {
    this.form.email = this.$route.query.email || null
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        axios
          .post(api.path('login'), this.form)
          .then(res => {
            this.$toast.success('Hola. ¡Bienvenido!')
            this.$emit('success', res.data)
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>
