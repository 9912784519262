var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-switch", {
                      attrs: { label: _vm.labels.habilitado },
                      on: { change: _vm.changeEstado },
                      model: {
                        value: _vm.usuario.active,
                        callback: function($$v) {
                          _vm.$set(_vm.usuario, "active", $$v)
                        },
                        expression: "usuario.active"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.name,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarUsuario("nombre")
                        }
                      },
                      model: {
                        value: _vm.usuario.name,
                        callback: function($$v) {
                          _vm.$set(_vm.usuario, "name", $$v)
                        },
                        expression: "usuario.name"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.email,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarUsuario("correo_electronico")
                        }
                      },
                      model: {
                        value: _vm.usuario.email,
                        callback: function($$v) {
                          _vm.$set(_vm.usuario, "email", $$v)
                        },
                        expression: "usuario.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.password,
                        value: "••••••••",
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarUsuario("contraseña")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("v-select", {
                      attrs: {
                        label: _vm.labels.role,
                        items: _vm.roles,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarUsuario("rol")
                        }
                      },
                      model: {
                        value: _vm.usuario.role,
                        callback: function($$v) {
                          _vm.$set(_vm.usuario, "role", $$v)
                        },
                        expression: "usuario.role"
                      }
                    }),
                    _vm._v(" "),
                    _vm.usuario.role === "Administrador" ||
                    _vm.usuario.role === "Escolares"
                      ? _c("v-autocomplete", {
                          attrs: {
                            label: _vm.labels.campuses_asignados,
                            items: _vm.usuario.campuses,
                            "item-text": "nombre",
                            "item-value": "uuid",
                            attach: "",
                            chips: "",
                            multiple: "",
                            "append-icon": "edit",
                            outline: "",
                            readonly: ""
                          },
                          on: {
                            "click:append": function($event) {
                              return _vm.editarUsuario("campuses")
                            }
                          },
                          model: {
                            value: _vm.usuario.campuses,
                            callback: function($$v) {
                              _vm.$set(_vm.usuario, "campuses", $$v)
                            },
                            expression: "usuario.campuses"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.usuario.role === "Firmante"
                      ? _c("v-autocomplete", {
                          attrs: {
                            label: _vm.labels.responsables_asignados,
                            items: _vm.usuario.responsables,
                            "item-text": "nombre",
                            "item-value": "id",
                            attach: "",
                            chips: "",
                            multiple: "",
                            "append-icon": "edit",
                            outline: "",
                            readonly: ""
                          },
                          on: {
                            "click:append": function($event) {
                              return _vm.editarUsuario("responsables")
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-chip",
                                      { staticClass: "grey--text" },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.nombre))
                                        ]),
                                        _vm._v(" \n                "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.primer_apellido))
                                        ]),
                                        _vm._v(" \n                "),
                                        _c("span", [
                                          _vm._v(_vm._s(item.segundo_apellido))
                                        ])
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1758807274
                          ),
                          model: {
                            value: _vm.usuario.responsables,
                            callback: function($$v) {
                              _vm.$set(_vm.usuario, "responsables", $$v)
                            },
                            expression: "usuario.responsables"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }