<template>
  <div>
    <v-tabs
      fixed-tabs
      grow
      v-model="selectedTab"
      :slider-color="sliderColor"
    >
      <v-tab href="#capturado" @click="cambiarANoFirmados">
        <span v-if="selectedTab === 'capturado'" class="blue--text text--darken-2">Capturados</span>
        <span v-else class="grey--text text--darken-1">Capturados</span>
      </v-tab>
      <v-tab href="#pagado" @click="cambiarAPagados">
        <span v-if="selectedTab === 'pagado'" class="indigo--text text--darken-2">Pagados</span>
        <span v-else class="grey--text text--darken-1">Pagados</span>
      </v-tab>
      <v-tab href="#firmado" @click="cambiarAFirmados">
        <span v-if="selectedTab === 'firmado'" class="purple--text text--darken-2">Firmados</span>
        <span v-else class="grey--text text--darken-1">Firmados</span>
      </v-tab>
    </v-tabs>

    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < lotesCertificados.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === lotesCertificados.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getLotesCertificados(selectedTab)"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selectedTab === 'capturado'"
        icon
        @click="eliminarLotesCertificados"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ lotesCertificados.count > 1 ? ((lotesCertificados.current_page - 1) * lotesCertificados.per_page + 1) : 0 }}-{{ lotesCertificados.count ? ((lotesCertificados.current_page - 1) * lotesCertificados.per_page) + lotesCertificados.count : 0 }} de {{ lotesCertificados.total ? lotesCertificados.total : 0 }}</span>

      <v-btn
        :disabled="lotesCertificados.current_page <= 1"
        icon
        @click="getLotesCertificadosPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="lotesCertificados.current_page >= lotesCertificados.last_page"
        icon
        @click="getLotesCertificadosNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" mb-0></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="lotesCertificados.data && lotesCertificados.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="lotesCertificados.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarLoteCertificados(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td v-if="incluyeBusqueda(props.item.id)" :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.id }}</strong></td>
          <td v-else :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.id }}</td>

          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-progress-circular
              v-if="props.item.estado_lote.id === 1"
              indeterminate
              :color="props.item.estado_lote.color"
            ></v-progress-circular>
            <v-chip
              v-else
              small
              :color="props.item.estado_lote.color"
              text-color="white"
            >
              <span>{{ props.item.estado_lote.nombre }}</span>
            </v-chip>
          </td>

          <td v-if="incluyeBusqueda(props.item.nombre)" :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.nombre }}</strong></td>
          <td v-else :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre }}</td>

          <td v-if="incluyeBusqueda(props.item.nombre_campus)" :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.nombre_campus }}</strong></td>
          <td v-else :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.campus.nombre }}</td>

          <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_con_errores_count > 0 ? 'red' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_con_errores_count > 0 ? 'red darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_con_errores_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_capturados_count > 0 ? 'blue' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_capturados_count > 0 ? 'blue darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_capturados_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_pagados_count > 0 ? 'indigo' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_pagados_count > 0 ? 'indigo darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_pagados_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_firmados_count > 0 ? 'purple' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_firmados_count > 0 ? 'purple darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_firmados_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_registrados_count > 0 ? 'green' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_registrados_count > 0 ? 'green darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_registrados_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small :color="props.item.certificados_cancelados_count > 0 ? 'red' : 'blue-grey'" text-color="white">
              <v-avatar :class="props.item.certificados_cancelados_count > 0 ? 'red darken-4' : 'blue-grey darken-4'"><strong>{{ props.item.certificados_cancelados_count }}</strong></v-avatar>
              Certificados
            </v-chip>
          </td>

          <td v-if="incluyeBusqueda(props.item.fecha_capturado)" :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.fecha_capturado }}</strong></td>
          <td v-else :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.fecha_capturado }}</td>

          <td v-if="incluyeBusqueda(props.item.fecha_pagado)" :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.fecha_pagado }}</strong></td>
          <td v-else :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.fecha_pagado }}</td>

          <td v-if="incluyeBusqueda(props.item.fecha_firmado)" :class="[headers[12].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.fecha_firmado }}</strong></td>
          <td v-else :class="[headers[12].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.fecha_firmado }}</td>

          <div v-if="hover" class="lotes-certificados-fix-1">
            <div
              class="justify-center layout grey lighten-3 lotes-certificados-fix-2"
              :class="selectedTab === 'capturado' ? 'lotes-certificados-fix-left-1': 'lotes-certificados-fix-left-2'"
            >
              <v-tooltip bottom>
                <v-btn slot="activator" icon ripple @click.stop="mostrarInformacionLoteCertificados(props.item)">
                  <v-icon color="grey darken-1">visibility</v-icon>
                </v-btn>
                <span>Mostrar lote de certificados</span>
              </v-tooltip>
              <v-tooltip v-if="selectedTab === 'capturado'" bottom>
                <v-btn slot="activator" icon ripple @click.stop="editarCertificados(props.item)">
                  <v-icon color="grey darken-1">edit</v-icon>
                </v-btn>
                <span>Editar certificados</span>
              </v-tooltip>
              <v-tooltip v-if="selectedTab === 'capturado'" bottom>
                <v-btn slot="activator" icon ripple @click.stop="eliminarLoteCertificados(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    selectedTab: 'capturado',
    sliderColor: 'blue darken-2',

    loading: false,
    loadingDialog: false,

    noDataText: "No hay lotes de certificados",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "ID", value: "id", visibility: false },
      { text: "Estado", value: "estado_lote.nombre", visibility: true },
      { text: "Nombre", value: "nombre", visibility: true },
      { text: "Campus", value: "campus.nombre", visibility: true },
      { text: "Con Errores", value: "certificados_con_errores_count", visibility: true },
      { text: "Capturados", value: "certificados_capturados_count", visibility: true },
      { text: "Pagados", value: "certificados_con_pagados_count", visibility: false },
      { text: "Firmados", value: "certificados_con_firmados_count", visibility: false },
      { text: "Registrados", value: "certificados_con_registrados_count", visibility: false },
      { text: "Cancelados", value: "certificados_con_cancelados_count", visibility: false },
      { text: "Fecha de Captura", value: "fecha_capturado", visibility: false },
      { text: "Fecha de Pago", value: "fecha_pagado", visibility: false },
      { text: "Fecha de Firma", value: "fecha_firmado", visibility: false },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: 10,
      sortBy: 'id',
      descending: true
    },

    lotesCertificados: {}
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        this.initial()
      }
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val.trim())
      } else {
        this.$router.replace('#' + this.selectedTab)
      }
      this.getLotesCertificados(this.selectedTab)
    }
  },

  methods: {
    cambiarANoFirmados() {
      this.$router.replace('#capturado')
      this.getLotesCertificados('capturado')
      this.sliderColor = 'blue darken-2'
    },

    cambiarAPagados() {
      this.$router.replace('#pagado')
      this.getLotesCertificados('pagado')
      this.sliderColor = 'indigo darken-2'
    },

    cambiarAFirmados() {
      this.$router.replace('#firmado')
      this.getLotesCertificados('firmado')
      this.sliderColor = 'purple darken-2'
    },

    incluyeBusqueda(val) {
      if (val && this.busqueda) {
        let value = val.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let value_two = this.busqueda.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return value.includes(value_two)
      }
    },

    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.lotesCertificados.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getLotesCertificados(val) {
      this.loading = true
      axios
        .get(api.path("lotes_certificados"), {
          params: {
            estado: val,
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
         .then(res => {
          this.lotesCertificados = res.data
           if (this.selectedTab === 'capturado') {
            this.headers[4].visibility = true
            this.headers[5].visibility = true
            this.headers[6].visibility = false
            this.headers[7].visibility = false
            this.headers[8].visibility = false
            this.headers[9].visibility = false
            this.headers[10].visibility = true
            this.headers[11].visibility = false
            this.headers[12].visibility = false
          } else if (this.selectedTab === 'pagado') {
            this.headers[4].visibility = false
            this.headers[5].visibility = false
            this.headers[6].visibility = true
            this.headers[7].visibility = false
            this.headers[8].visibility = false
            this.headers[9].visibility = false
            this.headers[10].visibility = false
            this.headers[11].visibility = true
            this.headers[12].visibility = false
          } else if (this.selectedTab === 'firmado') {
            this.headers[4].visibility = false
            this.headers[5].visibility = false
            this.headers[6].visibility = false
            this.headers[7].visibility = true
            this.headers[8].visibility = true
            this.headers[9].visibility = true
            this.headers[10].visibility = true
            this.headers[11].visibility = false
            this.headers[12].visibility = false
          }
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    getLotesCertificadosPreviousPage() {
      this.loading = true
      axios
        .get(api.path("lotes_certificados"), {
          params: {
            estado: this.selectedTab,
            page_size: this.pagination.rowsPerPage,
            page: this.lotesCertificados.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.lotesCertificados = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getLotesCertificadosNextPage() {
      this.loading = true
      axios
        .get(api.path("lotes_certificados"), {
          params: {
            estado: this.selectedTab,
            page_size: this.pagination.rowsPerPage,
            page: this.lotesCertificados.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.lotesCertificados = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarLoteCertificados(val) {
      this.$confirm("¿Desea eliminar este lote de certificados?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("lotes_certificados.single", { id: val.id }))
              .then(res => {
                this.$toast.success("Lote de certificados eliminado correctamente.")
                this.selected = []
                this.getLotesCertificados(this.selectedTab)

              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarLotesCertificados() {
      this.$confirm("¿Desea eliminar los lotes de certificados seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("lotes_certificados"), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Lotes de certificados eliminados correctamente.")
                this.selected = []
                this.getLotesCertificados(this.selectedTab)

              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.id + '\t'
        data = data + val.estado_lote.nombre + '\t'
        data = data + val.nombre + '\t'
        data = data + val.campus.nombre + '\t'
        data = data + val.certificados_con_errores_count + '\t'
        data = data + val.certificados_capturados_count + '\t'
        data = data + val.certificados_con_pagados_count + '\t'
        data = data + val.certificados_con_firmados_count + '\t'
        data = data + val.certificados_con_registrados_count + '\t'
        data = data + val.certificados_con_cancelados_count + '\t'
        data = data + val.fecha_capturado + '\t'
        data = data + val.fecha_pagado + '\t'
        data = data + val.fecha_firmado + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success('Datos copiados al portapapeles.')
      }, function (e) {
        me.$toast.success('No se puede copiar los datos al portapapeles.')
      })
    },

    mostrarLoteCertificados(val) {
      if (val.certificados_count > 0) {
        this.$router.push({ name: 'lotes-certificados-certificados', params: { id: val.id } })
      } else {
        this.$toast.info('Capture al menos un certificado.')
      }
    },

    mostrarInformacionLoteCertificados(val) {
      this.$router.push({ name: 'lotes-certificados-mostrar', params: { id: val.id } })
    },

    editarCertificados(val) {
      if (val.estado_lote_id === 2 || val.estado_lote_id === 3) {
        if (localStorage['loteCertificados[' + val.id + ']']) {
          this.$router.push({ name: 'lotes-certificados-certificados-capturar', params: { id: val.id } })
        } else {
          this.getCertificados(val.id)
        }
      } else {
        this.$toast.info('No se pueden editar los certificados en este momento.')
      }
    },

    getCertificados(id) {
      this.loadingDialog = true

      axios
        .get(api.path('lotes_certificados.certificados.captura', { id: id }))
        .then(res => {
          this.certificados = res.data.data
          this.certificados = [...this.certificados, ...[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}]]
          localStorage['loteCertificados[' + id + ']'] = JSON.stringify(this.certificados)
          this.$router.push({ name: 'lotes-certificados-certificados-capturar', params: { id: id } })
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getLotesCertificados(this.selectedTab)
      localStorage.setItem('lotes-certificados.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('lotes-certificados.headers', JSON.stringify(this.headers))
    },

    initial() {
      if (localStorage.hasOwnProperty('lotes-certificados.pagination')) {
        this.pagination = JSON.parse(localStorage.getItem('lotes-certificados.pagination'))
      }

      if (localStorage.hasOwnProperty('lotes-certificados.headers')) {
        this.headers = JSON.parse(localStorage.getItem('lotes-certificados.headers'))
      }

      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
      
    if (tab[0] === '#capturado') {
      this.selectedTab = 'capturado'
      this.sliderColor = 'blue darken-2'
      this.getLotesCertificados('capturado')
    } else if (tab[0] === '#pagado') {
      this.selectedTab = 'pagado'
      this.sliderColor = 'indigo darken-2'
      this.getLotesCertificados('pagado')
    } else if (tab[0] === '#firmado') {
      this.selectedTab = 'firmado'
      this.sliderColor = 'purple darken-2'
      this.getLotesCertificados('firmado')
    } else if (tab[0] === '#buscar') {
        let busqueda = decodeURI(tab[1])
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
      } else {
        if (this.busqueda) {
          this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        } else {
          this.cambiarANoFirmados()
        }
      }
    }
  },

  mounted() {
    this.initial()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .lotes-certificados-fix-1 {
    position: relative !important;
	}

  .lotes-certificados-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    
    top: 1px !important;
    height: 47px !important;
	}

  .lotes-certificados-fix-left-1 {
    left: -156px !important;
	}

  .lotes-certificados-fix-left-2 {
    left: -52px !important;
	}
</style>

