<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < titulos.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === titulos.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getTitulos"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && loteTitulos.estado_lote_id !== 5"
        icon
        @click="eliminarTitulos"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && loteTitulos.estado_lote_id === 5"
        icon
        @click="cancelarTitulos"
      >
        <v-icon color="grey darken-1">cancel</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ titulos.count > 1 ? ((titulos.current_page - 1) * titulos.per_page + 1) : 0 }}-{{ titulos.count ? ((titulos.current_page - 1) * titulos.per_page) + titulos.count : 0 }} de {{ titulos.total ? titulos.total : 0 }}</span>

      <v-btn
        :disabled="titulos.current_page <= 1"
        icon
        @click="getTitulosPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="titulos.current_page >= titulos.last_page"
        icon
        @click="getTitulosNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="titulos.data && titulos.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="titulos.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarTitulo(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>

          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip
              small
              :color="props.item.estado_documento.color"
              text-color="white"
            >
              <span>{{ props.item.estado_documento.nombre }}</span>
              <v-menu max-width="400" transition="slide-x-transition" open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" right>info_outline</v-icon>
                </template>
                  <v-card>
                    <v-card-text>
                        <div v-if="props.item.estado_documento.id === 1">
                          <div v-for="(linea, index) in (props.item.resultado.split(','))" :key="index">
                            <span><strong>{{ index + 1 }}. </strong>{{ linea }}</span>
                          </div>
                        </div>
                        <span v-else-if="props.item.estado_documento.id === 2">Para continuar se requiere firmar el título, una vez firmado no se puede modificar.</span>
                        <span v-else-if="props.item.estado_documento.id === 5">Para continuar se requiere enviar el título a la SEP.</span>
                        <span v-else>{{ props.item.resultado }}</span>
                    </v-card-text>
                  </v-card>
              </v-menu>
            </v-chip>
          </td>

          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <div v-if="props.item.resultado">
              <div v-for="(linea, index) in (props.item.resultado.split(','))" :key="index">
                <span>{{ linea }}</span>
              </div>
            </div>
          </td>
          <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.folio_control }}</td>
          <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_campus }}</td>
          <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_carrera }}</td>
          <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.matricula_profesionista }}</td>
          <td :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_profesionista }}</td>
          <td :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.primer_apellido_profesionista }}</td>
          <td :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.segundo_apellido_profesionista }}</td>
          
          <td :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip
              v-if="props.item.curp_profesionista"
              small
              :color="props.item.estado_curp.color"
              text-color="white"
            >
              <span>{{ props.item.curp_profesionista }}</span>
              <v-menu max-width="400" transition="slide-x-transition" open-on-hover offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" right>info_outline</v-icon>
                </template>
                  <v-card>
                    <v-card-text>
                        <span v-if="props.item.estado_curp.id === 1">Es necesario dar clic en el botón de <strong>Validar CURP's</strong> para verificar la CURP con la base de datos de RENAPO.</span>
                        <span v-else-if="props.item.estado_curp.id === 2">La CURP no está registrada ante RENAPO o no coincide con los nombre y/o apellidos del profesionista.</span>
                        <span v-else-if="props.item.estado_curp.id === 3">La CURP si está registrada ante RENAPO.</span>
                    </v-card-text>
                  </v-card>
              </v-menu>
            </v-chip>
          </td>

          <td :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.correo_electronico_profesionista }}</td>
          <td :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.numero_telefono_profesionista }}</td>
          
          <div v-if="hover" class="titulo-fix-1">
            <div class="justify-center layout grey lighten-3 titulo-fix-2">
              <v-tooltip
                v-if="loteTitulos.estado_lote_id === 5 && (props.item.estado_documento.id === 6 || props.item.estado_documento.id === 9)"
                bottom
              >
                <v-btn slot="activator" icon ripple @click.stop="cancelarTitulo(props.item)">
                  <v-icon color="grey darken-1">cancel</v-icon>
                </v-btn>
                <span>Cancelar</span>
              </v-tooltip>
              <v-tooltip
                v-if="loteTitulos.estado_lote_id === 2 || loteTitulos.estado_lote_id === 3 || loteTitulos.estado_lote_id === 4"
                bottom
              >
                <v-btn slot="activator" icon ripple @click.stop="eliminarTitulo(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="cancelarTitulosDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Cancelar títulos</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-select
                :label="labels.motivo_cancelacion"
                v-model="form.motivo_cancelacion"
                :items="motivosCancelacion"
                item-text="descripcion"
                item-value="uuid"
                :error-messages="errors.motivo_cancelacion"
                :rules="[rules.required('motivo_cancelacion')]"
                dense
                hide-no-data
              ></v-select>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="cancelarTitulosDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" type="submit" @click="cancelar">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from '~/mixins/form'

export default {
  mixins: [Form],

  data: () => ({
    form: {},

    loading: false,

    noDataText: "No hay títulos registrados",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Estado", value: "estado_documento", visibility: true },
      { text: "Resultado", value: "resultado", visibility: false },
      { text: "Folio de Control", value: "folio_control", visibility: false },
      { text: "Campus", value: "nombre_campus", visibility: true },
      { text: "Carrera", value: "nombre_carrera", visibility: true },
      { text: "Matricula", value: "matricula_profesionista", visibility: false },
      { text: "Nombre", value: "nombre_profesionista", visibility: false },
      { text: "Primer Apellido", value: "primer_apellido_profesionista", visibility: false },
      { text: "Segundo Apellido", value: "segundo_apellido_profesionista", visibility: false },
      { text: "CURP", value: "curp_profesionista", visibility: true },
      { text: "Correo Electrónico", value: "correo_electronico_profesionista", visibility: false },
      { text: "Número Teléfono", value: "numero_telefono_profesionista", visibility: false },
    ],

    settingsDialog: false,
    cancelarTitulosDialog: false,
    loadingDialog: false,

    pagination: {
      rowsPerPage: 10,
      sortBy: 'uuid'
    },

    titulos: [],
    loteTitulos: [],
    motivosCancelacion: [],
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      this.$router.push({ name: 'lotes-titulos' })
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val.trim())
      }
      this.getLotesTitulos()
    },

    selected(val) {
      let ids = []

      this.selected.forEach(function(val) {
        ids.push(val.id)
      })

      this.$store.dispatch('admin/guardarTitulosIds', { titulosIds: ids })
    },
  },

  created() {
		this.$store.dispatch('admin/guardarTitulosIds', { titulosIds: null })
  },

  methods: {
    incluyeBusqueda(val) {
      if(val && this.busqueda) {
        let value = val.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let value_two = this.busqueda.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return value.includes(value_two)
      }
    },

    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.titulos.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getLoteTitulos() {
      axios
        .get(api.path("titulos.single", { id: this.$route.params.id }))
        .then(res => {
          this.loteTitulos = res.data
          this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteTitulos.estado_lote })
          if(this.loteTitulos.estado_lote_id === 5 && this.titulos.data.length > 0 && (this.titulos.data[0].resultado === '' || this.titulos.data[0].resultado === null)) {
            this.getResultados(this.$route.params.id)
          }
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
        })
    },
    
    getResultados(id) {
      this.loadingDialog = true
      axios
        .get(api.path('titulos.resultado', { id: id } ))
        .then(res => {
          if (res.data.message) {
            this.getTitulos()
          } else if (res.data.mensaje) {
            if (res.data.mensaje.indexOf("PROCESANDO") || res.data.mensaje.indexOf("EN ESPERA DE PROCESO")) {
              this.$toast.info("Este lote de títulos aún se esta procesando.")
            } else {
              this.$toast.error("Vuelva a intentarlo mas tarde.")
            }
          }
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    getTitulos() {
      this.loading = true
      axios
        .get(api.path("titulos.titulos", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
        .then(res => {
          this.titulos = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    getTitulosPreviousPage() {
      this.loading = true
      axios
        .get(api.path("titulos.titulos", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.titulos.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.titulos = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getTitulosNextPage() {
      this.loading = true
      axios
        .get(api.path("titulos.titulos", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.titulos.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.titulos = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarTitulo(val) {
      this.$confirm("¿Desea eliminar este título?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("titulos.titulos", { id: this.$route.params.id }), {
                params: {
                  ids: val.id
                }
              })
              .then(res => {
                this.$toast.success("Título eliminado correctamente.")
                this.getTitulos()
                this.getLoteTitulos()
              })
              .catch(err => {
                this.$toast.error("No se puede eliminar el título.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarTitulos() {
      this.$confirm("¿Desea eliminar los títulos seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("titulos.titulos", { id: this.$route.params.id }), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Títulos eliminados correctamente.")
                this.selected = []
                this.getTitulos()
                this.getLoteTitulos()
              })
              .catch(err => {
                this.$toast.error("No se pueden eliminar los títulos.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    cancelarTitulo(val) {
      this.cancelarTitulosDialog = true
      this.form.motivo_cancelacion = ''
      this.getCatalogoMotivoCancelacion()
      this.selected.push(val)
    },

    cancelarTitulos() {
      this.cancelarTitulosDialog = true
      this.form.motivo_cancelacion = ''
      this.getCatalogoMotivoCancelacion()
    },

    cancelar() {
      this.cancelarTitulosDialog = false
      this.loadingDialog = true

      let titulosACancelar = []
      let motivo_cancelacion = this.form.motivo_cancelacion

      this.selected.forEach(function(val) {
        titulosACancelar.push({
          folio_control: val.folio_control,
          motivo_cancelacion_id: motivo_cancelacion
        })
      })

      axios
        .post(api.path("titulos.cancelar", { id: this.$route.params.id }), titulosACancelar)
        .then(res => {
          this.$toast.success("Títulos cancelados correctamente.")
          this.selected = []
          this.getTitulos()
        })
        .catch(err => {
          this.$toast.error("No se pueden cancelar los títulos.")
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    getCatalogoMotivoCancelacion() {
      axios
        .get(api.path('catalogos.motivo_cancelacion'))
        .then(res => {
          this.motivosCancelacion = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {})
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.estado_documento.nombre + '\t'
        data = data + val.folio_control + '\t'
        data = data + val.resultado + '\t'
        data = data + val.nombre_campus + '\t'
        data = data + val.nombre_carrera + '\t'
        data = data + val.matricula_profesionista + '\t'
        data = data + val.nombre_profesionista + '\t'
        data = data + val.primer_apellido_profesionista + '\t'
        data = data + val.segundo_apellido_profesionista + '\t'
        data = data + val.estado_curp.nombre + '\t'
        data = data + val.curp_profesionista + '\t'
        data = data + val.correo_electronico_profesionista + '\t'
        data = data + val.numero_telefono_profesionista + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarTitulo(val) {
      if(this.loteTitulos.estado_lote_id === 2 || this.loteTitulos.estado_lote_id === 3) {
        this.$router.push({ name: 'lotes-titulos-titulos-editar', params: { id: this.$route.params.id, id2: val.id } })
      } else {
        this.$router.push({ name: 'lotes-titulos-titulos-mostrar', params: { id: this.$route.params.id, id2: val.id } })
      }
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getTitulos()
      localStorage.setItem('titulo.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('titulo.headers', JSON.stringify(this.headers))
    },
  },

  mounted() {
    if (localStorage.hasOwnProperty('titulo.pagination')) {
      this.pagination = JSON.parse(localStorage.getItem('titulo.pagination'))
    }

    if (localStorage.hasOwnProperty('titulo.headers')) {
      this.headers = JSON.parse(localStorage.getItem('titulo.headers'))
    }

    let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

    if (tab[0] === '#buscar') {
      let busqueda = decodeURI( tab[1] )
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
    } else {
      if (this.busqueda) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      }
    }

    this.getTitulos()
    this.getLoteTitulos()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .titulo-fix-1 {
    position: relative !important;
	}

  .titulo-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>

