<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'curp_alumno'"
        :label="labels.curp_alumno"
        v-model="form.curp_alumno"
        :error-messages="errors.curp_alumno"
        :rules="[rules.required('curp_alumno')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'matricula_alumno'"
        :label="labels.matricula_alumno"
        v-model="form.matricula_alumno"
        :error-messages="errors.matricula_alumno"
        :rules="[rules.required('matricula_alumno')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'nombre_alumno'"
        :label="labels.nombre_alumno"
        v-model="form.nombre_alumno"
        :error-messages="errors.nombre_alumno"
        :rules="[rules.required('nombre_alumno')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'primer_apellido_alumno'"
        :label="labels.primer_apellido_alumno"
        v-model="form.primer_apellido_alumno"
        :error-messages="errors.primer_apellido_alumno"
        :rules="[rules.required('primer_apellido_alumno')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'segundo_apellido_alumno'"
        :label="labels.segundo_apellido_alumno"
        v-model="form.segundo_apellido_alumno"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'genero_alumno'"
        :label="labels.genero_alumno"
        v-model="form.id_genero"
        :items="genero"
        item-text="nombre"
        item-value="uuid"
        :error-messages="errors.genero_alumno"
        :rules="[rules.required('genero_alumno')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-text-field
        v-if="!this.campo || this.campo === 'fecha_nacimiento_alumno'"
        :label="labels.fecha_nacimiento_alumno"
        v-model="form.fecha_nacimiento_alumno"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'tipo_certificacion'"
        :label="labels.tipo_certificacion"
        v-model="form.id_tipo_certificacion"
        :items="tipoCertificacion"
        item-text="nombre"
        item-value="uuid"
        :error-messages="errors.tipo_certificacion"
        :rules="[rules.required('tipo_certificacion')]"
        :disabled="loading"
        dense
      ></v-select>

      <div v-if="!this.campo || this.campo === 'fecha_expedicion'">
        <v-menu
          v-model="menuFechaExpedicion"
          :close-on-content-click="false"
          :nudge-right="40"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="computedFechaExpedicion"
              :label="labels.fecha_de_expedicion"
              :disabled="loading"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fecha_expedicion"
            @input="menuFechaExpedicion = false"
            locale="es-es"
          ></v-date-picker>
        </v-menu>
      </div>
      
      <v-autocomplete
        v-if="!this.campo || this.campo === 'entidad_federativa_expedicion'"
        @keyup.enter="$event.target.blur()"
        :label="labels.entidad_federativa_expedicion"
        v-model="form.nombre_entidad_federativa_expedicion"
        :error-messages="errors.entidad_federativa_expedicion"
        :rules="[rules.required('entidad_federativa_expedicion')]"
        :disabled="loading"
        :items="entidadesFederativas"
        item-text="nombre"
        item-value="nombre"
        dense
        hide-no-data
      ></v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {},
      campo: null,
      genero: [],
      tipoCertificacion: [],
      entidadesFederativas: [],
      menuFechaExpedicion: false,
      fecha_expedicion: null,
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      }),

      computedFechaExpedicion () {
        return this.formatearFecha(this.fecha_expedicion)
      }
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'curp_alumno' ||
          param === 'matricula_alumno' ||
          param === 'nombre_alumno' ||
          param === 'primer_apellido_alumno' ||
          param === 'segundo_apellido_alumno' ||
          param === 'genero_alumno' ||
          param === 'fecha_nacimiento_alumno' ||
          param === 'tipo_certificacion' ||
          param === 'fecha_expedicion' ||
          param === 'entidad_federativa_expedicion'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'genero_alumno') {
        this.getCatalogoGenero()
      }

      if(!this.campo || this.campo === 'tipo_certificacion') {
        this.getCatalogoTipoCertificacion()
      }

      if(!this.campo || this.campo === 'entidad_federativa_expedicion') {
        this.getCatalogoEntidadFederativa()
      }

      this.getCertificado()
    },

    methods: {
      formatearFecha (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${year}-${month}-${day}T00:00:00`
      },

      getCertificado() {
        axios
          .get(api.path('lotes_certificados.certificados.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
            if(this.form.fecha_expedicion) {
              this.fecha_expedicion = this.form.fecha_expedicion.substr(0, 10)
            }
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoGenero() {
        axios
          .get(api.path('catalogos.genero'))
          .then(res => {
            this.genero = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoTipoCertificacion() {
        axios
          .get(api.path('catalogos.tipo_certificacion'))
          .then(res => {
            this.tipoCertificacion = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoEntidadFederativa() {
        axios
          .get(api.path('catalogos.entidad_federativa'))
          .then(res => {
            this.entidadesFederativas = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          if (this.fecha_expedicion) {
            this.form.fecha_expedicion = this.formatearFecha(this.fecha_expedicion)
          }

          axios
            .put(api.path('lotes_certificados.certificados.single', { id: this.$route.params.id, id2: this.$route.params.id2 }), this.form)
            .then(res => {
              if(res.data.errores) {
                this.$toast.error(res.data.errores)
              } else {
                this.$toast.success('Título actualizado correctamente.')
                this.$emit('success', res.data)
              }
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
