var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-1 toolbar-fix",
          attrs: { flat: "", dense: "", color: "white" }
        },
        [
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box_outline_blank")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length < _vm.responsables.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("indeterminate_check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length === _vm.responsables.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.getResponsables } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("refresh")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.eliminarResponsables }
                },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("delete")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.copiarTexto } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("content_copy")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("span", { attrs: { color: "grey darken-1" } }, [
            _vm._v(
              _vm._s(
                _vm.responsables.count > 1
                  ? (_vm.responsables.current_page - 1) *
                      _vm.responsables.per_page +
                      1
                  : 0
              ) +
                "-" +
                _vm._s(
                  _vm.responsables.count
                    ? (_vm.responsables.current_page - 1) *
                        _vm.responsables.per_page +
                        _vm.responsables.count
                    : 0
                ) +
                " de " +
                _vm._s(_vm.responsables.total ? _vm.responsables.total : 0)
            )
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.responsables.current_page <= 1, icon: "" },
              on: { click: _vm.getResponsablesPreviousPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_before")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                disabled:
                  _vm.responsables.current_page >= _vm.responsables.last_page,
                icon: ""
              },
              on: { click: _vm.getResponsablesNextPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_next")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.settingsDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("settings")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.responsables.data && _vm.responsables.data.length > 0
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.responsables.data,
              pagination: _vm.pagination,
              "no-data-text": _vm.noDataText,
              "no-results-text": _vm.noResultsText,
              loading: _vm.loading,
              "item-key": "id",
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c("th"),
                        _vm._v(" "),
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              class: [
                                "column sortable",
                                _vm.pagination.descending ? "desc" : "asc",
                                header.value === _vm.pagination.sortBy
                                  ? "active"
                                  : "",
                                "text-xs-left",
                                header.visibility
                                  ? "hidden-print-only"
                                  : "hidden-screen-only"
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.changeSort(header.value)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("arrow_upward")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("v-hover", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return _c(
                                "tr",
                                {
                                  class: "elevation-" + (hover ? 2 : 0),
                                  on: {
                                    click: function($event) {
                                      return _vm.mostrarResponsable(props.item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          props.selected = !props.selected
                                        }
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": props.selected,
                                          primary: "",
                                          "hide-details": ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[0].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.nombre))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[1].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.primer_apellido))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[2].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(props.item.segundo_apellido)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[3].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.cargo.nombre))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[4].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [_vm._v(_vm._s(props.item.curp))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[5].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(props.item.abreviatura_titulo)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[6].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "open-on-hover": "",
                                            "offset-y": "",
                                            "nudge-bottom": "5",
                                            disabled:
                                              props.item.campuses_titulos
                                                .length === 0
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: "indigo",
                                                            "text-color":
                                                              "white"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            staticClass:
                                                              "indigo darken-4"
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .campuses_titulos
                                                                    .length
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                Campus\n            "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            { attrs: { dense: "" } },
                                            _vm._l(
                                              props.item.campuses_titulos,
                                              function(item, index) {
                                                return _c(
                                                  "v-list-tile",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "v-list-tile-content",
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                item.nombre
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[7].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "open-on-hover": "",
                                            "offset-y": "",
                                            "nudge-bottom": "5",
                                            disabled:
                                              props.item.campuses_certificados
                                                .length === 0
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            color: "indigo",
                                                            "text-color":
                                                              "white"
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          {
                                                            staticClass:
                                                              "indigo darken-4"
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .campuses_certificados
                                                                    .length
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                Campus\n            "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                dense: "",
                                                subheader: ""
                                              }
                                            },
                                            _vm._l(
                                              props.item.campuses_certificados,
                                              function(item, index) {
                                                return _c(
                                                  "v-list-tile",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "v-list-tile-content",
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          {
                                                            domProps: {
                                                              textContent: _vm._s(
                                                                item.nombre
                                                              )
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[8].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.firma
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "green",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [_vm._v("done")])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            Si\n          "
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "red",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("error")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            No\n          "
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[9].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.autofirma
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "green",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [_vm._v("done")])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            Si\n          "
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "red",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("error")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            No\n          "
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[10].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.responsable_plan_estudios
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "green",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [_vm._v("done")])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            Si\n          "
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "red",
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-avatar",
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("error")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(
                                                "\n            No\n          "
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  hover
                                    ? _c(
                                        "div",
                                        { staticClass: "responsable-fix-1" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "justify-center layout grey lighten-3 responsable-fix-2"
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        ripple: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.eliminarResponsable(
                                                            props.item
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey darken-1"
                                                          }
                                                        },
                                                        [_vm._v("delete")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Eliminar")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        : _c("v-container", [
            _c(
              "div",
              { staticClass: "layout column align-center text-center" },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    "lazy-src": _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    src: _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    alt: _vm.busqueda ? _vm.noResultsText : _vm.noDataText,
                    width: "380"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "mb-3 font-weight-light grey--text text--darken-4 title"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.busqueda ? _vm.noResultsText : _vm.noDataText
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.busqueda
                  ? _c(
                      "v-alert",
                      {
                        attrs: {
                          value: true,
                          color: "primary",
                          icon: "info",
                          outline: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n        Intenta usar otras palabras clave o quitar los filtros de búsqueda\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "400px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.settingsDialog,
            callback: function($$v) {
              _vm.settingsDialog = $$v
            },
            expression: "settingsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Configuración")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Número de filas por página\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-slider", {
                            attrs: {
                              "thumb-label": "always",
                              step: "10",
                              "thumb-size": 24,
                              min: 10,
                              max: 100,
                              ticks: "",
                              "tick-size": "2"
                            },
                            model: {
                              value: _vm.pagination.rowsPerPage,
                              callback: function($$v) {
                                _vm.$set(_vm.pagination, "rowsPerPage", $$v)
                              },
                              expression: "pagination.rowsPerPage"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Columnas a mostrar\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.headers, function(header, index) {
                            return _c(
                              "v-list-tile",
                              { key: index },
                              [
                                _c(
                                  "v-list-tile-action",
                                  [
                                    _c("v-switch", {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: header.visibility,
                                        callback: function($$v) {
                                          _vm.$set(header, "visibility", $$v)
                                        },
                                        expression: "header.visibility"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(header.text))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.settingsDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: { click: _vm.aplicarConfiguracion }
                    },
                    [_vm._v("Aplicar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }