<template>
  <div>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
      <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
        <v-autocomplete
          @keyup.enter="$event.target.blur()"
          v-model="form.campus"
          :items="auth.campuses"
          item-text="nombre"
          item-value="id"
          return-object
          :label="labels.campus"
          dense
          hide-no-data
          :error-messages="errors.campus"
          :rules="[rules.required('campus')]"
        ></v-autocomplete>

        <v-text-field
          :label="labels.nombre_lote"
          v-model="form.nombre"
          :error-messages="errors.nombre_lote"
          :rules="[rules.required('nombre_lote')]"
        ></v-text-field>
      </v-container>

      <v-layout justify-end mt-2>
        <v-btn
          flat
          :disabled="loadingDialog"
          @click="$router.go(-1)"
          color="grey darken-2"
        >
          Cancelar
        </v-btn>

        <v-btn
          type="submit"
          :disabled="loadingDialog"
          color="primary"
        >
          Continuar
        </v-btn>
      </v-layout>
    </v-form>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        campus: {}
      },

      loadingDialog: false,
      loteCertificado: null,
    }),

    computed: {
      ...mapGetters({
        auth: 'auth/user',
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      if (this.auth.campuses.length === 1) {
        this.form.campus = this.auth.campuses[0]
      }
    },

    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.loadingDialog = true

          axios
            .post( api.path( 'lotes_certificados' ), {
              nombre: this.form.nombre,
              campus: {
                id: this.form.campus.id
              }
            })
            .then( res => {
              this.loteCertificado = res.data
              localStorage['loteCertificados[' + this.loteCertificado.id + ']'] = JSON.stringify([{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}])
              this.$router.push({ name: "lotes-certificados-certificados-capturar",  params: { id: this.loteCertificado.id } })
            })
            .catch( err => {
              this.handleErrors( err.response.data.errors )
            })
            .then( () => {
              this.loadingDialog = false
            })
        }
      }
    }
  }
</script>
