var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { sm6: "", md4: "", lg4: "" } },
    [
      _c(
        "v-card",
        { attrs: { raised: "" } },
        [
          _c(
            "div",
            { staticClass: "layout column align-center" },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary", flat: "", dense: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "layout column align-center" },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Crea tu cuenta de usuario")
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("v-img", {
                staticClass: "mt-4",
                attrs: {
                  "lazy-src": "/img/logo.png",
                  src: "/img/logo.png",
                  alt: "Logotipo",
                  width: "180"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [_c("registro-form", { on: { success: _vm.success } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }