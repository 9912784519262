<template>
  <div>
    <v-tabs
      fixed-tabs
      grow
      v-model="selectedTab"
      slider-color="primary"
      @change="cambiarPestaña($event)"
    >
      <v-tab href="#certificado" >
        <span>Certificado</span>
      </v-tab>
      <v-tab href="#carrera">
        <span>Carrera</span>
      </v-tab>
      <v-tab href="#alumno">
        <span>Alumno</span>
      </v-tab>
      <v-tab href="#expedicion">
        <span>Expedición</span>
      </v-tab>
      <v-tab href="#asignaturas">
        <span>Asignaturas</span>
      </v-tab>

      <v-tabs-items>
        <v-tab-item value="certificado">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.estado"
                      v-model="certificado.estado_documento.nombre"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.folio_control"
                      v-model="certificado.folio_control"
                      outline
                      readonly
                    ></v-text-field>

                    <v-textarea
                      :label="labels.resultado"
                      v-model="certificado.resultado"
                      outline
                      readonly
                      :rows="resultadoRows"
                    ></v-textarea>
                  </div>
               </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        
        <v-tab-item value="carrera">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.campus"
                      v-model="certificado.nombre_campus"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.entidad_federativa"
                      v-model="certificado.nombre_entidad_federativa"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.nivel_de_estudio"
                      v-model="certificado.nombre_nivel_estudio"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.carrera"
                      v-model="certificado.nombre_carrera"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.numero_rvoe"
                      v-model="certificado.numero_rvoe"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_rvoe"
                      v-model="certificado.fecha_rvoe"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.tipo_periodo"
                      v-model="certificado.nombre_tipo_periodo"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.clave_plan"
                      v-model="certificado.clave_plan"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.calificacion_minima"
                      v-model="certificado.calificacion_minima"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.calificacion_maxima"
                      v-model="certificado.calificacion_maxima"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.calificacion_minima_aprobatoria"
                      v-model="certificado.calificacion_minima_aprobatoria"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="alumno">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.curp_alumno"
                      v-model="certificado.curp_alumno"
                      @click:append="editarCertificado('curp_alumno')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.matricula_alumno"
                      v-model="certificado.matricula_alumno"
                      @click:append="editarCertificado('matricula_alumno')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.nombre_alumno"
                      v-model="certificado.nombre_alumno"
                      @click:append="editarCertificado('nombre_alumno')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.primer_apellido_alumno"
                      v-model="certificado.primer_apellido_alumno"
                      @click:append="editarCertificado('primer_apellido_alumno')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.segundo_apellido_alumno"
                      v-model="certificado.segundo_apellido_alumno"
                      @click:append="editarCertificado('segundo_apellido_alumno')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.genero_alumno"
                      v-model="certificado.genero_alumno"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_nacimiento_alumno"
                      v-model="certificado.fecha_nacimiento_alumno"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="expedicion">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.tipo_certificacion"
                      v-model="certificado.tipo_certificacion"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_de_expedicion"
                      v-model="certificado.fecha_expedicion"
                      @click:append="editarCertificado('fecha_expedicion')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.entidad_federativa_expedicion"
                      v-model="certificado.nombre_entidad_federativa_expedicion"
                      @click:append="editarCertificado('entidad_federativa_expedicion')"
                      :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="asignaturas">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.total_asignaturas"
                      v-model="certificado.total_asignaturas"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.asignaturas_asignadas"
                      v-model="certificado.asignaturas_asignadas"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.promedio_calificaciones"
                      v-model="certificado.promedio_calificaciones"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.total_creditos"
                      v-model="certificado.total_creditos"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.creditos_obtenidos"
                      v-model="certificado.creditos_obtenidos"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.numero_ciclos"
                      v-model="certificado.numero_ciclos"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      selectedTab: 'certificado',
      loading: false,

      estados: [
        'Por certificar',
        'Certificado',
        'Rechazado',
        'Cancelado',
      ],

      certificado: {
        estado_documento: {},
      },

      loteCertificados: [],

      resultadoRows: 1,
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
    
      if (tab[0] === '') {
        this.cambiarPestaña(this.selectedTab)
      } else if (tab[0] === '#certificado') {
        this.selectedTab = 'certificado'
      } else if (tab[0] === '#carrera') {
        this.selectedTab = 'carrera'
      } else if (tab[0] === '#alumno') {
        this.selectedTab = 'alumno'
      } else if (tab[0] === '#expedicion') {
        this.selectedTab = 'expedicion'
      } else if (tab[0] === '#asignaturas') {
        this.selectedTab = 'asignaturas'
      }

      this.getCertificado()
      this.getLoteCertificados()
    },

    methods: {
      getLoteCertificados() {
        axios
          .get(api.path("lotes_certificados.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteCertificados = res.data
            this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteCertificados.estado_lote })
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      getCertificado() {
        axios
          .get(api.path('lotes_certificados.certificados.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ))
          .then(res => {
            this.certificado = res.data
            this.certificado.resultado = this.certificado.resultado.split(',').join('\n')
            this.resultadoRows = this.certificado.resultado.split('\n').length
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      editarCertificado(campo) {
        this.$router.push({ name: "lotes-certificados-certificados-editar", params: { 'campo': campo } })
      },

      cambiarPestaña(tab) {
        this.$router.replace('#' + tab)
      }
    }
  }
</script>
