<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        :label="labels.uuid"
        v-model="form.uuid"
        :error-messages="errors.uuid"
        :rules="[rules.required('uuid')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        :label="labels.nivel_de_estudio"
        v-model="form.nivel_de_estudio.id"
        :error-messages="errors.nivel_de_estudio"
        :rules="[rules.required('nivel_de_estudio')]"
        :disabled="loading"
        :items="nivel_de_estudio"
        item-text="nombre"
        item-value="id"
        dense
      ></v-select>

      <v-select
        :label="labels.modalidad"
        v-model="form.modalidad.id"
        :error-messages="errors.modalidad"
        :rules="[rules.required('modalidad')]"
        :disabled="loading"
        :items="modalidad"
        item-text="nombre"
        item-value="id"
        dense
      ></v-select>

      <v-text-field
        :label="labels.clave"
        v-model="form.clave"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        nivel_de_estudio: {},
        modalidad: {},
      },
      nivel_de_estudio: [],
      modalidad: []
    }),

    computed: mapGetters({
      auth: 'auth/user',
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'carrera' })
      }
    },

    mounted() {
      this.getCatalogoNivelDeEstudio()
      this.getCatalogoModalidad()
    },

    methods: {
      getCatalogoNivelDeEstudio() {
        axios
          .get(api.path('catalogos.nivel_de_estudio'))
          .then(res => {
            this.nivel_de_estudio = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoModalidad() {
        axios
          .get(api.path('catalogos.modalidad'))
          .then(res => {
            this.modalidad = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.post(api.path('carreras'), this.form)
            .then(res => {
              this.$toast.success('Carrera agregado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
