<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < campuses.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === campuses.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getCampuses"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="eliminarCampuses"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ campuses.count > 1 ? ((campuses.current_page - 1) * campuses.per_page + 1) : 0 }}-{{ campuses.count ? ((campuses.current_page - 1) * campuses.per_page) + campuses.count : 0 }} de {{ campuses.total ? campuses.total : 0 }}</span>

      <v-btn
        :disabled="campuses.current_page <= 1"
        icon
        @click="getCampusesPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="campuses.current_page >= campuses.last_page"
        icon
        @click="getCampusesNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="campuses.data && campuses.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="campuses.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarCampus(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.uuid }}</td>
          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre }}</td>
          <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.entidad_federativa.nombre }}</td>
          <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.clave_estatal !== null" small color="green" text-color="white">
              <strong>{{ props.item.clave_estatal }}</strong>
            </v-chip>
            <v-chip v-else small color="orange" text-color="white">
              <strong>Sin clave</strong>
            </v-chip>
          </td>
          <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.clave_federal !== null" small color="green" text-color="white">
              <strong>{{ props.item.clave_federal }}</strong>
            </v-chip>
            <v-chip v-else small color="orange" text-color="white">
              <strong>Sin clave</strong>
            </v-chip>
          </td>
          <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.clave_pago !== null" small color="green" text-color="white">
              <strong>{{ props.item.clave_pago }}</strong>
            </v-chip>
            <v-chip v-else small color="orange" text-color="white">
              <strong>Sin clave</strong>
            </v-chip>
          </td>
          <div v-if="hover" class="campus-fix-1">
            <div class="justify-center layout grey lighten-3 campus-fix-2">
              <v-tooltip bottom>
                <v-btn slot="activator" icon ripple @click.stop="eliminarCampus(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay campus registrados",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "ID", value: "uuid", visibility: true },
      { text: "Nombre", value: "nombre" , visibility: true },
      { text: "Entidad Federativa", value: "entidad_federativa.nombre" , visibility: true },
      { text: "Clave Estatal", value: "clave_estatal", visibility: true },
      { text: "Clave Federal", value: "clave_federal", visibility: true },
      { text: "Clave de Pago DGAIR", value: "clave_pago", visibility: false },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: 10,
      sortBy: 'uuid'
    },

    campuses: {}
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        this.initial()
      }
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val)
      } else {
        this.$router.replace('')
      }
      this.getCampuses()
    }
  },

  methods: {
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.campuses.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getCampuses() {
      this.loading = true
      axios
        .get(api.path("campus"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
        .then(res => {
          this.campuses = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getCampusesPreviousPage() {
      this.loading = true
      axios
        .get(api.path("campus"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.campuses.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.campuses = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getCampusesNextPage() {
      this.loading = true
      axios
        .get(api.path("campus"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.campuses.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.campuses = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarCampus(val) {
      this.$confirm("¿Desea eliminar este campus?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("campus.single", { id: val.id }))
              .then(res => {
                this.$toast.success("Campus eliminado correctamente.")
                this.selected = []
                this.getCampuses()
              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarCampuses() {
      this.$confirm("¿Desea eliminar los campus seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("campus"), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Campus eliminados correctamente.")
                this.selected = []
                this.getCampuses()
              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.uuid + '\t'
        data = data + val.nombre + '\t'
        data = data + val.entidad_federativa.nombre + '\t'
        data = data + val.clave_estatal + '\t'
        data = data + val.clave_federal + '\t'
        data = data + val.clave_pago + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarCampus(val) {
      this.$router.push({ name: 'campus-mostrar', params: { id: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getCampuses()
      localStorage.setItem('campus.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('campus.headers', JSON.stringify(this.headers))
    },

    initial() {
      if (localStorage.hasOwnProperty('campus.pagination')) {
        this.pagination = JSON.parse(localStorage.getItem('campus.pagination'))
      }

      if (localStorage.hasOwnProperty('campus.headers')) {
        this.headers = JSON.parse(localStorage.getItem('campus.headers'))
      }

      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

      if (tab[0] === '#buscar') {
        let busqueda = decodeURI( tab[1] )
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
      } else {
        if (this.busqueda) {
          this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        }
      }
      
      this.getCampuses()
      }
  },

  mounted() {
    this.initial()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .campus-fix-1 {
    position: relative !important;
	}

  .campus-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>

