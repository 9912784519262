<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-text-field
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
    ></v-text-field>

    <v-text-field
        :label="labels.correo_electronico"
        v-model="form.correo_electronico"
        type="email"
        :error-messages="errors.correo_electronico"
        :rules="[rules.required('correo_electronico')]"
        :disabled="loading"
    ></v-text-field>

    <v-layout row mt-2>
      <v-spacer></v-spacer>

      <v-btn
          flat
          :disabled="loading"
          :to="{ name: 'login' }"
          color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
          type="submit"
          :loading="loading"
          :disabled="loading || !valid"
          color="primary"
      >
        Continuar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({

      form: {
        nombre: null,
        correo_electronico: null,
      }
    }),

    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true
          axios
            .post(api.path('usuarios.solicitudes'), {
              nombre: this.form.nombre,
              correo_electronico: this.form.correo_electronico,
            })
            .then(res => {
              this.$toast.success('Solicitud de registro enviada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      },
    }
  }
</script>
