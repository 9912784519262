var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.labels.archivo_xlsx,
                  "error-messages": _vm.errors.name,
                  rules: [_vm.rules.required("archivo_xlsx")],
                  disabled: _vm.loadingDialog,
                  "append-icon": "attach_file"
                },
                on: { click: _vm.seleccionarArchivoXLSX },
                model: {
                  value: _vm.form.file.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form.file, "name", $$v)
                  },
                  expression: "form.file.name"
                }
              }),
              _vm._v(" "),
              _c("input", {
                ref: "archivo_xlsx",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: ".xlsx" },
                on: { change: _vm.archivoXLSXSeleccionado }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "justify-end": "", "mt-2": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    flat: "",
                    disabled: _vm.loadingDialog,
                    color: "grey darken-2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("\n        Cancelar\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.loadingDialog,
                    color: "primary"
                  }
                },
                [_vm._v("\n        Guardar\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { width: "600px" },
          model: {
            value: _vm.errrorsDialog,
            callback: function($$v) {
              _vm.errrorsDialog = $$v
            },
            expression: "errrorsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Resultados del archivo de programas de estudio")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _vm.erroresProgramasDeEstudio.length
                    ? _c("v-data-table", {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.erroresProgramasDeEstudio,
                          "rows-per-page-items": _vm.rowsPerPageItems,
                          "rows-per-page-text": _vm.rowsPerPageText,
                          pagination: _vm.pagination
                        },
                        on: {
                          "update:pagination": function($event) {
                            _vm.pagination = $event
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [_vm._v(_vm._s(props.item.linea))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(props.item.mensajes, function(
                                      mensaje,
                                      index
                                    ) {
                                      return _c("div", { key: index }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(mensaje) +
                                            "\n                "
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2851009299
                        )
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "flat" },
                      on: {
                        click: function($event) {
                          _vm.errrorsDialog = false
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("Espera un momento\n        "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }