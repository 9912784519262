<template>
  <div>
    <v-tabs
      fixed-tabs
      grow
      v-model="selectedTab"
      slider-color="primary"
      @change="cambiarPestaña($event)"
    >
      <v-tab href="#perfil">
        <span>Perfil</span>
      </v-tab>

      <v-tab href="#notificaciones">
        <span>Notificaciones</span>
      </v-tab>

      <v-tabs-items>
        <v-tab-item value="perfil">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    
                    <v-text-field
                      :label="labels.name"
                      v-model="user.name"
                      @click:append="editarPerfil('nombre')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.cambiar_password"
                      value="••••••••"
                      append-icon="edit"
                      @click:append="editarPerfil('contraseña')"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.email"
                      v-model="user.email"
                      outline
                      readonly
                    ></v-text-field>
                    
                    <v-autocomplete
                      :label="labels.campuses_asignados"
                      v-model="user.campuses"
                      :items="user.campuses"
                      item-text="nombre"
                      item-value="id"
                      attach
                      chips
                      multiple
                      outline
                      readonly
                    ></v-autocomplete>

                    <v-autocomplete
                      v-if="user.role === 'Firmante'"
                      :label="labels.responsables_asignados"
                      v-model="user.responsables"
                      :items="user.responsables"
                      item-text="nombre"
                      item-value="id"
                      attach
                      chips
                      multiple
                      outline
                      readonly
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip
                          class="grey--text"
                        >
                          <span>{{ item.nombre }}</span>&nbsp;
                          <span>{{ item.primer_apellido }}</span>&nbsp;
                          <span>{{ item.segundo_apellido }}</span>
                        </v-chip>
                      </template>
                    </v-autocomplete>

                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="notificaciones">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div v-if="user.notificaciones && user.notificaciones.length > 0">
                    <v-card>
                      <v-card-text>
                        <v-switch
                          v-for="(notificacion, i) in user.notificaciones"
                          :key="i"
                          :label="notificacion.nombre"
                          input-value="true"
                        ></v-switch>
                      </v-card-text>
                    </v-card>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      selectedTab: "perfil",

      user: {
        name: null,
        email: null,
      }
    }),

    computed: mapGetters({
      auth: 'auth/user'
    }),

    mounted() {
      let tab = this.$route.fullPath.replace(this.$route.path, "").split("/");

      if (tab[0] === "") {
        this.cambiarPestaña(this.selectedTab);
      } else if (tab[0] === "#perfil") {
        this.selectedTab = "perfil";
      } else if (tab[0] === "#notificaciones") {
        this.selectedTab = "notificaciones";
      }
      
      this.user = Object.assign(this.user, this.auth)
    },

    methods: {
      editarPerfil(campo) {
        this.$router.push({ name: "perfil-editar", params: { 'campo': campo } })
      },

      cambiarPestaña(tab) {
        this.$router.replace("#" + tab);
      }
    }
  }
</script>
