<template>
    <transition name="fade" mode="out-in">
        <router-view></router-view>
    </transition>
</template>

<script>
  export default {

    data: () => ({
    }),

    mounted() {
    },

    methods: {
    }
  }
</script>
