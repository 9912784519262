var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          ref: "toolbar",
          attrs: {
            flat: "",
            dark: "",
            "clipped-left": _vm.$vuetify.breakpoint.mdAndUp,
            fixed: "",
            app: "",
            color: "primary"
          }
        },
        [
          !_vm.barraDeBusqueda &&
          (this.$route.name === "panel" ||
            this.$route.name === "perfil" ||
            this.$route.name === "lotes-titulos" ||
            this.$route.name === "lotes-certificados" ||
            this.$route.name === "certificados" ||
            this.$route.name === "programa" ||
            this.$route.name === "campus" ||
            this.$route.name === "carrera" ||
            this.$route.name === "responsables" ||
            this.$route.name === "usuario" ||
            this.$route.name === "regla-folio-control" ||
            this.$route.name === "plantilla-titulo" ||
            this.$route.name === "configuracion")
            ? _c("v-toolbar-side-icon", {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.navToggle($event)
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.barraDeBusqueda &&
          (this.$route.name === "panel" ||
            this.$route.name === "perfil" ||
            this.$route.name === "lotes-titulos" ||
            this.$route.name === "lotes-certificados" ||
            this.$route.name === "certificados" ||
            this.$route.name === "programa" ||
            this.$route.name === "campus" ||
            this.$route.name === "carrera" ||
            this.$route.name === "responsables" ||
            this.$route.name === "usuario" ||
            this.$route.name === "regla-folio-control" ||
            this.$route.name === "plantilla-titulo" ||
            this.$route.name === "configuracion")
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.barraDeBusqueda = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.$route.name === "perfil-editar" ||
          this.$route.name === "lotes-titulos-subir" ||
          this.$route.name === "lotes-titulos-mostrar" ||
          this.$route.name === "lotes-titulos-editar" ||
          this.$route.name === "lotes-titulos-titulos" ||
          this.$route.name === "lotes-titulos-titulos-agregar" ||
          this.$route.name === "lotes-titulos-titulos-mostrar" ||
          this.$route.name === "lotes-titulos-titulos-editar" ||
          this.$route.name === "lotes-certificados-mostrar" ||
          this.$route.name === "lotes-certificados-editar" ||
          this.$route.name === "lotes-certificados-capturar" ||
          this.$route.name === "lotes-certificados-certificados" ||
          this.$route.name === "lotes-certificados-certificados-capturar" ||
          this.$route.name === "lotes-certificados-certificados-mostrar" ||
          this.$route.name === "lotes-certificados-certificados-editar" ||
          this.$route.name ===
            "lotes-certificados-certificados-calificaciones" ||
          this.$route.name ===
            "lotes-certificados-certificados-calificaciones-capturar" ||
          this.$route.name ===
            "lotes-certificados-certificados-calificaciones-mostrar" ||
          this.$route.name ===
            "lotes-certificados-certificados-calificaciones-editar" ||
          this.$route.name === "programa-subir" ||
          this.$route.name === "programa-mostrar" ||
          this.$route.name === "programa-editar" ||
          this.$route.name === "programa-asignatura" ||
          this.$route.name === "programa-asignatura-agregar" ||
          this.$route.name === "programa-asignatura-mostrar" ||
          this.$route.name === "programa-asignatura-editar" ||
          this.$route.name === "campus-agregar" ||
          this.$route.name === "campus-mostrar" ||
          this.$route.name === "campus-editar" ||
          this.$route.name === "campus-plantilla-titulo" ||
          this.$route.name === "campus-plantilla-titulo-asignar" ||
          this.$route.name === "carrera-agregar" ||
          this.$route.name === "carrera-mostrar" ||
          this.$route.name === "carrera-editar" ||
          this.$route.name === "responsables-agregar" ||
          this.$route.name === "responsables-mostrar" ||
          this.$route.name === "responsables-firma" ||
          this.$route.name === "responsables-editar" ||
          this.$route.name === "usuario-agregar" ||
          this.$route.name === "usuario-solicitud" ||
          this.$route.name === "usuario-solicitud-registrar" ||
          this.$route.name === "usuario-mostrar" ||
          this.$route.name === "usuario-editar" ||
          this.$route.name === "regla-folio-control-agregar" ||
          this.$route.name === "regla-folio-control-editar" ||
          this.$route.name === "regla-folio-control-mostrar" ||
          this.$route.name === "plantilla-titulo-agregar" ||
          this.$route.name === "plantilla-titulo-editar" ||
          this.$route.name === "plantilla-titulo-mostrar" ||
          this.$route.name === "configuracion-editar" ||
          this.$route.name === "configuracion-facturacion" ||
          this.$route.name === "configuracion-facturacion-mostrar" ||
          this.$route.name === "configuracion-plan" ||
          this.$route.name === "acerca"
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("arrow_back")])],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "navbar-brand",
              attrs: { to: { name: this.$route.name } }
            },
            [
              _c("v-toolbar-title", { staticClass: "white--text" }, [
                this.$route.name === "perfil"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Perfil")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "perfil-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Perfil")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "lotes-titulos"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Lotes de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "lotes-titulos-subir"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Subir Lote de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "lotes-titulos-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Lote de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "lotes-titulos-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Lote de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-titulos-titulos"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Lote de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-titulos-titulos-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-titulos-titulos-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-titulos-titulos-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Lotes de Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Lote de Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Lote de Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-capturar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Capturar Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-certificados"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-certificados-capturar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Capturar Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-certificados-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Certificado")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "lotes-certificados-certificados-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Certificado")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name ===
                  "lotes-certificados-certificados-calificaciones"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Calificaciones")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name ===
                  "lotes-certificados-certificados-calificaciones-capturar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Capturar Calificaciones")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name ===
                  "lotes-certificados-certificados-calificaciones-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Calificación")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name ===
                  "lotes-certificados-certificados-calificaciones-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Calificación")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "certificados"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Certificados")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "programa"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Programas de Estudio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Programa de Estudio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Programa de Estudio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-subir"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Subir Programas de Estudio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-asignatura"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Asignaturas")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-asignatura-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Asignatura")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-asignatura-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Asignatura")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "programa-asignatura-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Asignatura")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "campus"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Campus")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "campus-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Campus")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "campus-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Campus")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "campus-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Campus")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "campus-plantilla-titulo"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Plantillas Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "campus-plantilla-titulo-asignar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Plantilla Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "carrera"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Carreras")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "carrera-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Carrera")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "carrera-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Carrera")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "carrera-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Carrera")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "responsables"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Responsables")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "responsables-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Responsable")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "responsables-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Responsable")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "responsables-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Responsable")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "responsables-firma"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Firma de Responsable")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "usuario"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Usuarios")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "usuario-solicitud"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Solicitudes de usuarios")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "usuario-solicitud-registrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Registrar Usuario")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "usuario-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Usuario")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "usuario-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Usuario")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "usuario-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Usuario")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "panel"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Panel")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "regla-folio-control"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Reglas de Folios")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "regla-folio-control-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Regla de Folio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "regla-folio-control-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar Regla de Folio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "regla-folio-control-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Regla de Folio")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "plantilla-titulo"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Plantillas de Títulos")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "plantilla-titulo-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Plantilla de Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "plantilla-titulo-agregar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Agregar plantilla de Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                this.$route.name === "plantilla-titulo-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar plantilla de Título")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "configuracion"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Configuración")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "configuracion-editar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Editar Configuración")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "configuracion-facturacion"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Facturaciones")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "configuracion-facturacion-mostrar"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Mostrar Facturación")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda &&
                this.$route.name === "configuracion-plan"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Cambiar de plan")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.barraDeBusqueda && this.$route.name === "acerca"
                  ? _c("div", [
                      _c("span", { staticClass: "title ml-3 mr-3" }, [
                        _vm._v("Acerca de")
                      ])
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { lg8: "", sm11: "", xs12: "" } },
                [
                  (!_vm.$vuetify.breakpoint.xsOnly || _vm.barraDeBusqueda) &&
                  (this.$route.name === "lotes-titulos" ||
                    this.$route.name === "lotes-certificados" ||
                    this.$route.name === "programa" ||
                    this.$route.name === "campus" ||
                    this.$route.name === "carrera" ||
                    this.$route.name === "responsables" ||
                    this.$route.name === "usuario" ||
                    this.$route.name === "regla-folio-control" ||
                    this.$route.name === "plantilla-titulo")
                    ? _c(
                        "v-text-field",
                        {
                          attrs: {
                            label: "Buscar...",
                            "background-color": _vm.barraDeBusqueda
                              ? "primary"
                              : "primary darken-1",
                            color: "white",
                            type: "search",
                            autocomplete: "off",
                            autocorrect: "off",
                            spellcheck: "false",
                            "hide-details": "",
                            "single-line": "",
                            clearable: "",
                            flat: "",
                            solo: ""
                          },
                          on: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.guardarBusqueda() & $event.target.blur()
                            },
                            "click:clear": _vm.clearBusqueda
                          },
                          model: {
                            value: _vm.modelBusqueda,
                            callback: function($$v) {
                              _vm.modelBusqueda = $$v
                            },
                            expression: "modelBusqueda"
                          }
                        },
                        [
                          !_vm.$vuetify.breakpoint.xsOnly
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { slot: "prepend-inner", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.guardarBusqueda()
                                    }
                                  },
                                  slot: "prepend-inner"
                                },
                                [_c("v-icon", [_vm._v("search")])],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$vuetify.breakpoint.xsOnly &&
                          this.$route.name === "programa"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { slot: "append", icon: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openFilterDialog()
                                    }
                                  },
                                  slot: "append"
                                },
                                [_c("v-icon", [_vm._v("la-caret-down")])],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          !_vm.barraDeBusqueda &&
          (this.$route.name === "lotes-titulos" ||
            this.$route.name === "lotes-certificados" ||
            this.$route.name === "programa" ||
            this.$route.name === "campus" ||
            this.$route.name === "carrera" ||
            this.$route.name === "responsables" ||
            this.$route.name === "usuario" ||
            this.$route.name === "regla-folio-control" ||
            this.$route.name === "plantilla-titulo")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.barraDeBusqueda = true
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("search")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Buscar")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          _vm.barraDeBusqueda &&
          this.$route.name === "programa"
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.openFilterDialog()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("la-caret-down")])],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos" &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.descargarFormatoTitulo },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-download")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar formato")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos" &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "lotes-titulos-titulos-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar título")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos" &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "lotes-titulos-subir" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-upload")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Subir lote de títulos")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          !_vm.barraDeBusqueda &&
          this.$route.name === "lotes-titulos" &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-menu",
                { attrs: { bottom: "", left: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("more_vert")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-tile",
                        { on: { click: _vm.descargarFormatoTitulo } },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Descargar formato de títulos")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile",
                        {
                          attrs: {
                            to: { name: "lotes-titulos-titulos-agregar" }
                          }
                        },
                        [_c("v-list-tile-title", [_vm._v("Agregar título")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile",
                        { attrs: { to: { name: "lotes-titulos-subir" } } },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Subir lote de títulos")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 2 || _vm.estadoLote.id === 3) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Escolares")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.validarCurpsLoteTitulos(
                            _vm.$route.params.id
                          )
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-id-card")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Validar CURP's con RENAPO")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 2 || _vm.estadoLote.id === 3) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Escolares")
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.validarCurpsLoteTitulos(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tValidar CURP's\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 3 || _vm.estadoLote.id === 7) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.firmadoLoteTitulos },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-stamp")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Firmar lote de títulos")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 3 || _vm.estadoLote.id === 7) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: { click: _vm.firmadoLoteTitulos }
                },
                [_vm._v("\n\t\t\tFirmar lote\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.descargarPDFLote(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-pdf")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar PDF de títulos")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.descargarPDFLote(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tDescargar PDF\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote && (_vm.estadoLote.id === 4 || _vm.estadoLote.id === 5)
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.descargarXMLTitulos(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-code")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar XML de títulos")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote && (_vm.estadoLote.id === 4 || _vm.estadoLote.id === 5)
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.descargarXMLTitulos(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tDescargar XML\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 4 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.enviarALaSEP(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-paper-plane")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Enviar a la SEP")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 4 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.enviarALaSEP(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tEnviar a la SEP\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.live_mode &&
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.enviarAAutenticar(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-external-link-alt")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Integración MET")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.auth.live_mode &&
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.enviarAAutenticar(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tIntegración MET\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos-mostrar" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.descargarPDFTitulo(
                            _vm.$route.params.id,
                            _vm.$route.params.id2
                          )
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-pdf")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar título en PDF")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos-mostrar" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.descargarPDFTitulo(
                        _vm.$route.params.id,
                        _vm.$route.params.id2
                      )
                    }
                  }
                },
                [_vm._v("\n\t\t\tDescargar PDF\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos-mostrar" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 5
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.descargarXMLTitulo(
                            _vm.$route.params.id,
                            _vm.$route.params.id2
                          )
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-code")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar título en XML")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-titulos-titulos-mostrar" &&
          _vm.estadoLote && (_vm.estadoLote.id === 4 || _vm.estadoLote.id === 5)
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.descargarXMLTitulo(
                        _vm.$route.params.id,
                        _vm.$route.params.id2
                      )
                    }
                  }
                },
                [_vm._v("\n\t\t\tDescargar XML\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          this.$route.name === "lotes-certificados" &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.capturarCertificados },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Capturar certificados")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 2 || _vm.estadoLote.id === 3) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Escolares")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.validarCurpsLoteCertificados(
                            _vm.$route.params.id
                          )
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-id-card")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Validar CURP's con RENAPO")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 2 || _vm.estadoLote.id === 3) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Escolares")
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.validarCurpsLoteCertificados(
                        _vm.$route.params.id
                      )
                    }
                  }
                },
                [_vm._v("\n\t\t\tValidar CURP's\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 3 &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.pagarLoteCertificados(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("thumb_up")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("Marcar lote de certificados como pagado")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          _vm.estadoLote.id === 3 &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.pagarLoteCertificados(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tMarcar como pagado\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 6 || _vm.estadoLote.id === 7) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.firmarLoteCertificados },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("gavel")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Firmar lote de certificados")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          (_vm.estadoLote.id === 6 || _vm.estadoLote.id === 7) &&
          (_vm.auth.role === "Administrador" || _vm.auth.role === "Firmante")
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: { click: _vm.firmarLoteCertificados }
                },
                [_vm._v("\n\t\t\tFirmar lote\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          _vm.estadoLote.id >= 3 && _vm.estadoLote.id <= 7 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.enviarARegistrar(_vm.$route.params.id)
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-flag-checkered")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Integración MEC")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados" &&
          _vm.estadoLote &&
          _vm.estadoLote.id >= 3 && _vm.estadoLote.id <= 7 &&
          _vm.auth.role !== "Firmante"
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: {
                    click: function($event) {
                      return _vm.enviarARegistrar(_vm.$route.params.id)
                    }
                  }
                },
                [_vm._v("\n\t\t\tIntegración MEC\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados-capturar"
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.enviarCertificados } },
                [_c("v-icon", [_vm._v("la-save")])],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados-capturar"
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: { click: _vm.enviarCertificados }
                },
                [_vm._v("\n\t\t\tGuardar certificados\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados-mostrar"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: {
                          name:
                            "lotes-certificados-certificados-calificaciones",
                          params: {
                            id: this.$route.params.id,
                            id2: this.$route.params.id2
                          }
                        }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-clipboard-list")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Calificaciones")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "lotes-certificados-certificados-mostrar"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outline: "",
                    small: "",
                    to: {
                      name: "lotes-certificados-certificados-calificaciones",
                      params: {
                        id: this.$route.params.id,
                        id2: this.$route.params.id2
                      }
                    }
                  }
                },
                [_vm._v("\n\t\t\tCalificaciones\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly && this.$route.name === "programa"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.descargarFormatoPrograma },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-file-download")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Descargar formato")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly && this.$route.name === "programa"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "programa-subir" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-upload")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Subir programas de estudio")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          !_vm.barraDeBusqueda &&
          this.$route.name === "programa"
            ? _c(
                "v-menu",
                { attrs: { bottom: "", left: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("more_vert")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-tile",
                        { on: { click: _vm.descargarFormatoPrograma } },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Descargar formato de programas de estudio")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile",
                        { attrs: { to: { name: "programa-subir" } } },
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Subir programas de estudio")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "programa-mostrar"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "programa-asignatura" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-clipboard-list")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Asignaturas")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "programa-mostrar"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outline: "",
                    small: "",
                    to: { name: "programa-asignatura" }
                  }
                },
                [_vm._v("\n\t\t\tAsignaturas\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "programa-asignatura"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "programa-asignatura-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar asignatura")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "programa-asignatura"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outline: "",
                    small: "",
                    to: { name: "programa-asignatura-agregar" }
                  }
                },
                [_vm._v("\n\t\t\tAgregar asignatura\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "responsables"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "responsables-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar responsable")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "campus"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "campus-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar campus")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "campus-mostrar"
            ? _c(
                "v-btn",
                {
                  attrs: { outline: "", small: "" },
                  on: { click: _vm.plantillasTitulosCampus }
                },
                [_vm._v("\n\t\t\tPlantillas Títulos\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "campus-plantilla-titulo" &&
          this.auth.membresia.tipo_plan.id === 3
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "campus-plantilla-titulo-asignar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Asignar plantilla")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.$vuetify.breakpoint.xsOnly &&
          this.$route.name === "campus-plantilla-titulo"
            ? _c(
                "v-btn",
                {
                  attrs: {
                    outline: "",
                    small: "",
                    to: { name: "campus-plantilla-titulo-asignar" }
                  }
                },
                [_vm._v("\n\t\t\tAsignar plantilla\n\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "carrera"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "carrera-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar carrera")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "usuario"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "usuario-solicitud" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-bell")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Solicitudes de registro")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "usuario"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "usuario-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar usuario")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "regla-folio-control"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        slot: "activator",
                        icon: "",
                        to: { name: "regla-folio-control-agregar" }
                      },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar regla de folio")])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.barraDeBusqueda && this.$route.name === "plantilla-titulo"
            ? _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { slot: "activator", icon: "" },
                      on: { click: _vm.agregarPlantillaTitulo },
                      slot: "activator"
                    },
                    [_c("v-icon", [_vm._v("la-plus")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Agregar plantilla de título")])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("Espera un momento\n\t\t\t\t"),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("firmado-lote", {
        ref: "firmadoLote",
        on: { success: _vm.successRouterBack }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "500px" },
          model: {
            value: _vm.firmarCertificadosDialog,
            callback: function($$v) {
              _vm.firmarCertificadosDialog = $$v
            },
            expression: "firmarCertificadosDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Firmar lote de certificados")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    { attrs: { "two-line": "", subheader: "" } },
                    _vm._l(_vm.firmantes, function(firmante, index) {
                      return _c(
                        "v-list-tile",
                        { key: index, attrs: { avatar: "" } },
                        [
                          _c(
                            "v-list-tile-avatar",
                            [
                              _c("v-icon", { attrs: { ripple: "" } }, [
                                _vm._v("person")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v(
                                  _vm._s(
                                    firmante.nombre +
                                      " " +
                                      firmante.primer_apellido +
                                      " " +
                                      firmante.segundo_apellido
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("v-list-tile-sub-title", [
                                _vm._v(_vm._s(firmante.curp))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-action",
                            [
                              firmante.firma
                                ? _c(
                                    "div",
                                    [
                                      firmante.firmado
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", disabled: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\tFirmado\n\t\t\t\t\t\t\t\t"
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            [
                                              firmante.permitido
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        color: "primary",
                                                        disabled:
                                                          _vm.loadingDialog
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.firmanteLote(
                                                            firmante.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tFirmar\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        disabled: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\tPendiente\n\t\t\t\t\t\t\t\t\t"
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-tooltip",
                                    { attrs: { bottom: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            icon: "",
                                            ripple: ""
                                          },
                                          slot: "activator"
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "red lighten-1" }
                                            },
                                            [_vm._v("error")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          "e.firma de firmante no configurada"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.firmarCertificadosDialog = false
                        }
                      }
                    },
                    [_vm._v("Volver")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.folioSolicitudAutenticacionDialog,
            callback: function($$v) {
              _vm.folioSolicitudAutenticacionDialog = $$v
            },
            expression: "folioSolicitudAutenticacionDialog"
          }
        },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.stepper,
                callback: function($$v) {
                  _vm.stepper = $$v
                },
                expression: "stepper"
              }
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 1, step: "1" } },
                [_vm._v("Descarga los folios de títulos")]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outline: "" },
                              on: {
                                click: function($event) {
                                  return _vm.descargarFoliosTitulos(
                                    _vm.$route.params.id
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-file-download")
                              ]),
                              _vm._v("Descargar folios\n\t\t\t\t\t\t")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.folioSolicitudAutenticacionDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 2, step: "2" } },
                [_vm._v("Autentica los títulos en el MET")]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outline: "",
                                href: "https://met.sep.gob.mx/met/",
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-external-link-square-alt")
                              ]),
                              _vm._v("Ir al MET\n\t\t\t\t\t\t")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 1
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 3
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-stepper-step", { attrs: { step: "3" } }, [
                _vm._v("Sube los XML de los títulos autenticados")
              ]),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.labels.archivo_titulos_autenticados,
                      "append-icon": "attach_file"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: { click: _vm.marcarAutenticado }
                    },
                    [_vm._v("Subir títulos")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.folioDialog,
            callback: function($$v) {
              _vm.folioDialog = $$v
            },
            expression: "folioDialog"
          }
        },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.stepper,
                callback: function($$v) {
                  _vm.stepper = $$v
                },
                expression: "stepper"
              }
            },
            [
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 1, step: "1" } },
                [_vm._v("Descarga los formatos de pago")]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outline: "" },
                              on: {
                                click: function($event) {
                                  return _vm.descargarFormatoPagosCertificados(
                                    _vm.$route.params.id
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-file-download")
                              ]),
                              _vm._v("Descargar formatos de pago\n\t\t\t\t\t\t")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.folioDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        color: "primary",
                        disabled: _vm.estadoLote && _vm.estadoLote.id === 3
                      },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 2, step: "2" } },
                [_vm._v("Captura el folio de pago")]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.labels.folio_pago,
                      "error-messages": _vm.errors.folio_pago,
                      rules: [_vm.rules.required("folio_pago")],
                      disabled:
                        (_vm.loteCertificados &&
                          _vm.loteCertificados.estado_lote_id !== 6) ||
                        _vm.auth.role === "Firmante"
                    },
                    model: {
                      value: _vm.folioPago,
                      callback: function($$v) {
                        _vm.folioPago = $$v
                      },
                      expression: "folioPago"
                    }
                  }),
                  _vm._v(" "),
                  _vm.loteCertificados &&
                  _vm.loteCertificados.estado_lote_id === 7
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            value: true,
                            color: "info",
                            icon: "info",
                            outline: ""
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "El lote de certificados está en proceso de firma"
                            )
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 1
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _vm.loteCertificados &&
                  _vm.loteCertificados.estado_lote_id === 4
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            color: "primary",
                            disabled:
                              _vm.loteCertificados &&
                              _vm.loteCertificados.estado_lote_id !== 4
                          },
                          on: {
                            click: function($event) {
                              _vm.stepper = 3
                            }
                          }
                        },
                        [_vm._v("Continuar")]
                      )
                    : _vm.auth.role !== "Firmante"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            color: "primary",
                            disabled:
                              (_vm.loteCertificados &&
                                _vm.loteCertificados.estado_lote_id !== 6) ||
                              _vm.auth.role === "Firmante"
                          },
                          on: { click: _vm.saveFolioPago }
                        },
                        [_vm._v("Guardar Folio")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 3, step: "3" } },
                [
                  _vm._v(
                    "Descarga los XML de los certificados y copia el folio de pago"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outline: "" },
                              on: {
                                click: function($event) {
                                  return _vm.descargarXMLCertificados(
                                    _vm.$route.params.id
                                  )
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-file-download")
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v("Descargar certificados")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "" },
                              on: { click: _vm.copiarFolio }
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.folioPago) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("la-copy")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        disabled: _vm.auth.role !== "Administrador"
                      },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 4
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                { attrs: { complete: _vm.stepper > 4, step: "4" } },
                [_vm._v("Registra los certificados en el MEC")]
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outline: "",
                                href: _vm.auth.live_mode
                                  ? "https://mec.sep.gob.mx/mec/"
                                  : "https://mecqa.siged.sep.gob.mx/mec/",
                                target: "_blank"
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-external-link-square-alt")
                              ]),
                              _vm._v("Ir al MEC\n\t\t\t\t\t\t")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 3
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 5
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-stepper-step", { attrs: { step: "5" } }, [
                _vm._v("Marca los certificados como registrados (Opcional)")
              ]),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "layout column align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { outline: "" },
                              on: { click: _vm.marcarRegistrado }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("la-check")
                              ]),
                              _vm._v("Marcar certificados\n\t\t\t\t\t\t")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 4
                        }
                      }
                    },
                    [_vm._v("Regresar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.folioDialog = false
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "500px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.filterDialog,
            callback: function($$v) {
              _vm.filterDialog = $$v
            },
            expression: "filterDialog"
          }
        },
        [
          _vm.$vuetify.breakpoint.xsOnly
            ? _c(
                "v-toolbar",
                { attrs: { dark: "", fixed: "", dense: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c(
                        "v-icon",
                        {
                          on: {
                            click: function($event) {
                              _vm.filterDialog = false
                            }
                          }
                        },
                        [_vm._v("\n\t\t\t\t\tarrow_back\n\t\t\t\t")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "navbar-brand",
                      attrs: { to: { name: this.$route.name } }
                    },
                    [
                      _c("v-toolbar-title", { staticClass: "white--text" }, [
                        _vm._v("\n\t\t\t\t\tFiltro de búsqueda\n\t\t\t\t")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "" },
                          on: { click: _vm.aplicarFiltro }
                        },
                        [_vm._v("Aplicar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Filtro de búsqueda")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  this.$route.name === "programa"
                    ? _c("v-autocomplete", {
                        attrs: {
                          label: _vm.labels.campus,
                          items: _vm.campuses,
                          "item-text": "nombre",
                          "item-value": "nombre",
                          attach: "",
                          chips: "",
                          multiple: "",
                          "deletable-chips": "",
                          dense: "",
                          "hide-no-data": ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return $event.target.blur()
                          }
                        },
                        model: {
                          value: _vm.form.campus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "campus", $$v)
                          },
                          expression: "form.campus"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-autocomplete", {
                        attrs: {
                          label: _vm.labels.carrera,
                          items: _vm.carreras,
                          "item-text": "nombre",
                          "item-value": "nombre",
                          attach: "",
                          chips: "",
                          multiple: "",
                          "deletable-chips": "",
                          dense: "",
                          "hide-no-data": ""
                        },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return $event.target.blur()
                          }
                        },
                        model: {
                          value: _vm.form.carrera,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "carrera", $$v)
                          },
                          expression: "form.carrera"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-select", {
                        attrs: {
                          label: _vm.labels.modalidad,
                          items: _vm.modalidades,
                          "item-text": "nombre",
                          "item-value": "nombre",
                          attach: "",
                          chips: "",
                          multiple: "",
                          "deletable-chips": "",
                          dense: "",
                          "hide-no-data": ""
                        },
                        model: {
                          value: _vm.form.modalidad,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "modalidad", $$v)
                          },
                          expression: "form.modalidad"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-select", {
                        attrs: {
                          label: _vm.labels.tipo_periodo,
                          items: _vm.tipos_periodo,
                          "item-text": "nombre",
                          "item-value": "nombre",
                          attach: "",
                          chips: "",
                          multiple: "",
                          "deletable-chips": "",
                          dense: "",
                          "hide-no-data": ""
                        },
                        model: {
                          value: _vm.form.tipo_periodo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tipo_periodo", $$v)
                          },
                          expression: "form.tipo_periodo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-select", {
                        attrs: {
                          label: _vm.labels.tipo_rvoe,
                          items: ["ESTATAL", "FEDERAL"],
                          attach: "",
                          chips: "",
                          multiple: "",
                          "deletable-chips": "",
                          dense: ""
                        },
                        model: {
                          value: _vm.form.tipo_rvoe,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tipo_rvoe", $$v)
                          },
                          expression: "form.tipo_rvoe"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-text-field", {
                        attrs: { label: _vm.labels.numero_rvoe },
                        model: {
                          value: _vm.form.numero_rvoe,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "numero_rvoe", $$v)
                          },
                          expression: "form.numero_rvoe"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-text-field", {
                        attrs: { label: _vm.labels.fecha_rvoe },
                        model: {
                          value: _vm.form.fecha_rvoe,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "fecha_rvoe", $$v)
                          },
                          expression: "form.fecha_rvoe"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  this.$route.name === "programa"
                    ? _c("v-text-field", {
                        attrs: { label: _vm.labels.clave_plan },
                        model: {
                          value: _vm.form.clave_plan,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "clave_plan", $$v)
                          },
                          expression: "form.clave_plan"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              !_vm.$vuetify.breakpoint.xsOnly
                ? _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary darken-1", flat: "" },
                          on: {
                            click: function($event) {
                              _vm.filterDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary darken-1", flat: "" },
                          on: { click: _vm.aplicarFiltro }
                        },
                        [_vm._v("Aplicar")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("funcion-premium")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }