import { render, staticRenderFns } from "./ResponsableFirma.vue?vue&type=template&id=e93dcf20&"
import script from "./ResponsableFirma.vue?vue&type=script&lang=js&"
export * from "./ResponsableFirma.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e93dcf20')) {
      api.createRecord('e93dcf20', component.options)
    } else {
      api.reload('e93dcf20', component.options)
    }
    module.hot.accept("./ResponsableFirma.vue?vue&type=template&id=e93dcf20&", function () {
      api.rerender('e93dcf20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/responsables/firma/ResponsableFirma.vue"
export default component.exports