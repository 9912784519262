var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "primary" } },
            [_c("v-toolbar-title", [_vm._v("Enviar comentarios")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                ref: "descripcion",
                attrs: {
                  solo: "",
                  flat: "",
                  label: "Describe el problema o comparte tus ideas",
                  "no-resize": "",
                  counter: "500",
                  tabindex: "1"
                },
                model: {
                  value: _vm.descripcion,
                  callback: function($$v) {
                    _vm.descripcion = $$v
                  },
                  expression: "descripcion"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", tabindex: "3" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    flat: "",
                    loading: _vm.loading,
                    disabled: !_vm.descripcion,
                    tabindex: "2"
                  },
                  on: { click: _vm.enviarComentario }
                },
                [_vm._v("Enviar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }