<template>
    <v-stepper v-model="stepper" :class="flat ? 'elevation-0' : ''" vertical>
        <v-stepper-step :complete="stepper > 1" step="1">{{ messages.fiel_select_file_cer }}</v-stepper-step>

        <v-stepper-content step="1">
            <v-text-field
                :label="labels.file_cer"
                v-model="fileCerName"
                :error-messages="errors.file_cer"
                :rules="[rules.required('file_cer')]"
                @click="$refs.fileCer.click()"
                append-icon="attach_file"
                :loading="loading"
            ></v-text-field>
            <input
                type="file"
                accept=".cer"
                style="display: none"
                ref="fileCer"
                @change="selectFileCer"
            />
            <v-alert
                v-if="fileCerName && certificateInfo.curp && certificateInfo.curp !== responsable.curp"
                :value="true"
                color="warning"
                icon="priority_high"
                outline
            >{{ messages.fiel_cer_file_invalid }}</v-alert>
            <v-btn
                color="primary"
                flat
                :disabled="certificateInfo.curp !== responsable.curp"
                @click="stepper = 2"
            >{{ labels.next }}</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="stepper > 2" step="2"> {{ messages.fiel_select_file_key }} </v-stepper-step>

        <v-stepper-content step="2">
            <v-text-field
                :label="labels.file_key"
                v-model="fileKeyName"
                :error-messages="errors.file_key"
                :rules="[rules.required('file_key')]"
                @click="$refs.fileKey.click()"
                append-icon="attach_file"
            ></v-text-field>
            <input
                type="file"
                accept=".key"
                style="display: none"
                ref="fileKey"
                @change="selectFileKey"
            />
            <v-btn flat @click="stepper = 1">{{ labels.back }}</v-btn>
            <v-btn
                color="primary"
                flat
                :disabled="!fiel.fileKey || fiel.fileKey === undefined || !fileKeyName"
                @click="stepper = 3"
            >{{ labels.next }}</v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="stepper > 3" step="3"> {{ messages.fiel_input_password }}</v-stepper-step>

        <v-stepper-content step="3">
            <v-text-field
                :label="labels.password"
                v-model="fiel.password"
                :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
                @click:append="() => (passwordHidden = !passwordHidden)"
                :type="passwordHidden ? 'password' : 'text'"
                :error-messages="errors.password"
                :rules="[rules.required('password')]"
            ></v-text-field>
            <v-btn flat @click="stepper = 2">{{ labels.back }}</v-btn>
            <v-btn
                flat
                color="primary"
                :disabled="!fiel.password"
                @click="firmar"
              >{{ textButton ? textButton : labels.next }}</v-btn>
        </v-stepper-content>
    </v-stepper>
</template>

<script>
import axios from "axios"
import Form from '~/mixins/form'
import { api } from "~/config"

export default {
    mixins: [Form],

    props: {
        flat: {
            type: Boolean,
            default: false,
        },
        textButton: {
            type: String,
        },
        responsable: {
            type: Object,
            default: () => ({}),
        },
    },
    
    data: () => ({
        certificateInfo: {},
        fiel: {
            fileCer: null,
            fileKey: null,
            password: '',
        },
        fileCerName: '',
        fileKeyName: '',
        loading: false,
        passwordHidden: true,
        stepper: null,
    }),

    methods: {
        selectFileCer(e) {
            this.fiel.fileCer = e.target.files[0]
            this.fileCerName = ''
            this.certificateInfo = {}
        
            if (this.fiel.fileCer !== undefined) {
                this.fileCerName = this.fiel.fileCer.name
                this.showCertificateInfo()
            }
        },

        selectFileKey(e) {
            this.fiel.fileKey = e.target.files[0]
            this.fileKeyName = ''

            if (this.fiel.fileKey !== undefined) {
                this.fileKeyName = this.fiel.fileKey.name
                this.stepper = 3
            }
        },

        showCertificateInfo() {
            this.loading = true
            let data = new FormData()

            data.append('file_cer', this.fiel.fileCer)

            axios
                .post(api.path('fiel.info'), data)
                .then(res => {
                    this.certificateInfo = res.data
                    if (this.certificateInfo.curp === this.responsable.curp) {
                        this.stepper = 2
                    }
                })
                .catch(err => {
                })
                .then(() => {
                    this.loading = false
                })
        },

        firmar() {
            this.$emit('firmar', this.responsable.id , this.fiel)
        },
    },
}
</script>
