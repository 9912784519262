var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tabs": "", grow: "", "slider-color": "primary" },
          on: {
            change: function($event) {
              _vm.cambiarPestaña($event)
            }
          },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#titulo" } }, [
            _c("span", [_vm._v("Título")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#carrera" } }, [
            _c("span", [_vm._v("Carrera")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#profesionista" } }, [
            _c("span", [_vm._v("Profesionista")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#expedicion" } }, [
            _c("span", [_vm._v("Expedición")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#antecedente" } }, [
            _c("span", [_vm._v("Antecedente")])
          ]),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            [
              _c(
                "v-tab-item",
                { attrs: { value: "titulo" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.estado,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.estado_documento.nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo.estado_documento,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.estado_documento.nombre"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.folio_control,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.folio_control,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "folio_control",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.folio_control"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.resultado,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.resultado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "resultado",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.resultado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "carrera" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.campus,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.nombre_campus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "nombre_campus",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.nombre_campus"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.carrera,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.nombre_carrera,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "nombre_carrera",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.nombre_carrera"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.fecha_inicio_carrera,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.fecha_inicio_carrera,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_inicio_carrera",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_inicio_carrera"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .fecha_terminacion_carrera,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .fecha_terminacion_carrera,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_terminacion_carrera",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_terminacion_carrera"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .autorizacion_reconocimiento,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .autorizacion_reconocimiento,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "autorizacion_reconocimiento",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.autorizacion_reconocimiento"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.numero_rvoe,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.numero_rvoe,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "numero_rvoe",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.numero_rvoe"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "profesionista" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.curp_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.curp_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "curp_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.curp_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.matricula_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.matricula_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "matricula_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.matricula_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.nombre_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.nombre_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "nombre_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.nombre_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .primer_apellido_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .primer_apellido_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "primer_apellido_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.primer_apellido_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .segundo_apellido_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .segundo_apellido_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "segundo_apellido_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.segundo_apellido_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .correo_electronico_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .correo_electronico_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "correo_electronico_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.correo_electronico_profesionista"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .numero_telefono_profesionista,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .numero_telefono_profesionista,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "numero_telefono_profesionista",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.numero_telefono_profesionista"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "expedicion" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.fecha_de_expedicion,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.fecha_de_expedicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_de_expedicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_de_expedicion"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.modalidad_de_titulacion,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.modalidad_de_titulacion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "modalidad_de_titulacion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.modalidad_de_titulacion"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_examen_profesional_o_fecha_exencion_examen_profesional",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.cumplio_servicio_social,
                                          value:
                                            _vm.titulo
                                              .cumplio_servicio_social === "1"
                                              ? "SI"
                                              : "NO",
                                          outline: "",
                                          readonly: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .fundamento_legal_servicio_social,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .fundamento_legal_servicio_social,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fundamento_legal_servicio_social",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fundamento_legal_servicio_social"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .entidad_federativa_expedicion,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .entidad_federativa_expedicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "entidad_federativa_expedicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.entidad_federativa_expedicion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "antecedente" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.institucion_procedencia,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.institucion_procedencia,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "institucion_procedencia",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.institucion_procedencia"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.tipo_estudio_antecedente,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.tipo_estudio_antecedente,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "tipo_estudio_antecedente",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.tipo_estudio_antecedente"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .entidad_federativa_antecedente,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .entidad_federativa_antecedente,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "entidad_federativa_antecedente",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.entidad_federativa_antecedente"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.fecha_inicio_antecedente,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo.fecha_inicio_antecedente,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_inicio_antecedente",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_inicio_antecedente"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .fecha_terminacion_antecedente,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.titulo
                                              .fecha_terminacion_antecedente,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "fecha_terminacion_antecedente",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "titulo.fecha_terminacion_antecedente"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.numero_cedula,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.titulo.numero_cedula,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.titulo,
                                              "numero_cedula",
                                              $$v
                                            )
                                          },
                                          expression: "titulo.numero_cedula"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }