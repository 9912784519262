import { render, staticRenderFns } from "./Panel.vue?vue&type=template&id=2f6e601e&"
import script from "./Panel.vue?vue&type=script&lang=js&"
export * from "./Panel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VIcon,VLayout,VSelect,VSheet,VSpacer,VSparkline})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f6e601e')) {
      api.createRecord('2f6e601e', component.options)
    } else {
      api.reload('2f6e601e', component.options)
    }
    module.hot.accept("./Panel.vue?vue&type=template&id=2f6e601e&", function () {
      api.rerender('2f6e601e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/panel/Panel.vue"
export default component.exports