<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.uuid"
              v-model="carrera.uuid"
              @click:append="editarCarrera('uuid')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.nombre"
              v-model="carrera.nombre"
              @click:append="editarCarrera('nombre')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.nivel_de_estudio"
              v-model="carrera.nivel_de_estudio.nombre"
              @click:append="editarCarrera('nivel_de_estudio')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.modalidad"
              v-model="carrera.modalidad.nombre"
              @click:append="editarCarrera('modalidad')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.clave"
              v-model="carrera.clave"
              @click:append="editarCarrera('clave')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,

      carrera: {
        nivel_de_estudio: {}
      }
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'carrera' })
      }
    },

    mounted() {
      this.getCarrera(this.$route.params.id)
    },

    methods: {
      getCarrera(id) {
        this.loading = true
        axios
          .get(api.path('carreras.single', { id: id } ))
          .then(res => {
            this.carrera = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      editarCarrera(campo) {
        this.$router.push({ name: "carrera-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
