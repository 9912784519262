<template>
  <div>
    <v-tabs
      fixed-tabs
      grow
      v-model="selectedTab"
      slider-color="primary"
      @change="cambiarPestaña($event)"
    >
      <v-tab href="#titulo" >
        <span>Título</span>
      </v-tab>
      <v-tab href="#carrera">
        <span>Carrera</span>
      </v-tab>
      <v-tab href="#profesionista">
        <span>Profesionista</span>
      </v-tab>
      <v-tab href="#expedicion">
        <span>Expedición</span>
      </v-tab>
      <v-tab href="#antecedente">
        <span>Antecedente</span>
      </v-tab>

      <v-tabs-items>
        <v-tab-item value="titulo">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.estado"
                      v-model="titulo.estado_documento.nombre"
                      outline
                      readonly
                    ></v-text-field>
                    <v-text-field
                      :label="labels.folio_control"
                      v-model="titulo.folio_control"
                      outline
                      readonly
                    ></v-text-field>
                    <v-text-field
                      :label="labels.resultado"
                      v-model="titulo.resultado"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
               </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        
        <v-tab-item value="carrera">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.campus"
                      v-model="titulo.nombre_campus"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.carrera"
                      v-model="titulo.nombre_carrera"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_inicio_carrera"
                      v-model="titulo.fecha_inicio_carrera"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_terminacion_carrera"
                      v-model="titulo.fecha_terminacion_carrera"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.autorizacion_reconocimiento"
                      v-model="titulo.autorizacion_reconocimiento"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.numero_rvoe"
                      v-model="titulo.numero_rvoe"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="profesionista">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.curp_profesionista"
                      v-model="titulo.curp_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.matricula_profesionista"
                      v-model="titulo.matricula_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.nombre_profesionista"
                      v-model="titulo.nombre_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.primer_apellido_profesionista"
                      v-model="titulo.primer_apellido_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.segundo_apellido_profesionista"
                      v-model="titulo.segundo_apellido_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.correo_electronico_profesionista"
                      v-model="titulo.correo_electronico_profesionista"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.numero_telefono_profesionista"
                      v-model="titulo.numero_telefono_profesionista"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="expedicion">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.fecha_de_expedicion"
                      v-model="titulo.fecha_de_expedicion"
                      outline
                      readonly
                    ></v-text-field>
    
                    <v-text-field
                      :label="labels.modalidad_de_titulacion"
                      v-model="titulo.modalidad_de_titulacion"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                      v-model="titulo.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.cumplio_servicio_social"
                      :value="titulo.cumplio_servicio_social === '1' ? 'SI' : 'NO'"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fundamento_legal_servicio_social"
                      v-model="titulo.fundamento_legal_servicio_social"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.entidad_federativa_expedicion"
                      v-model="titulo.entidad_federativa_expedicion"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="antecedente">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.institucion_procedencia"
                      v-model="titulo.institucion_procedencia"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.tipo_estudio_antecedente"
                      v-model="titulo.tipo_estudio_antecedente"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.entidad_federativa_antecedente"
                      v-model="titulo.entidad_federativa_antecedente"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.fecha_inicio_antecedente"
                      v-model="titulo.fecha_inicio_antecedente"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.fecha_terminacion_antecedente"
                      v-model="titulo.fecha_terminacion_antecedente"
                      outline
                      readonly
                    ></v-text-field>
                                
                    <v-text-field
                      :label="labels.numero_cedula"
                      v-model="titulo.numero_cedula"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      selectedTab: 'titulo',
      loading: false,

      titulo: {
        estado_documento: {},
      },

      loteTitulos: {
        responsables: {},
      },
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-titulos' })
      }
    },

    mounted() {
      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
    
      if (tab[0] === '') {
        this.cambiarPestaña(this.selectedTab)
      } else if (tab[0] === '#titulo') {
        this.selectedTab = 'titulo'
      } else if (tab[0] === '#carrera') {
        this.selectedTab = 'carrera'
      } else if (tab[0] === '#profesionista') {
        this.selectedTab = 'profesionista'
      } else if (tab[0] === '#expedicion') {
        this.selectedTab = 'expedicion'
      } else if (tab[0] === '#antecedente') {
        this.selectedTab = 'antecedente'
      }

      this.getTitulo()
      this.getLoteTitulos()
    },

    methods: {
      getLoteTitulos() {
        axios
          .get(api.path("titulos.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteTitulos = res.data
            this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteTitulos.estado })
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      getTitulo() {
        axios
          .get(api.path('titulos.titulos.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ))
          .then(res => {
            this.titulo = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      cambiarPestaña(tab) {
        this.$router.replace('#' + tab)
      }
    }
  }
</script>
