<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < certificados.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === certificados.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getCertificados"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && (loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3 || loteCertificados.estado_lote_id === 6)"
        icon
        @click="eliminarCertificados"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && loteCertificados.estado_lote_id === 4"
        icon
        @click="cancelarCertificados"
      >
        <v-icon color="grey darken-1">cancel</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ certificados.count > 1 ? ((certificados.current_page - 1) * certificados.per_page + 1) : 0 }}-{{ certificados.count ? ((certificados.current_page - 1) * certificados.per_page) + certificados.count : 0 }} de {{ certificados.total ? certificados.total : 0 }}</span>

      <v-btn
        :disabled="certificados.current_page <= 1"
        icon
        @click="getCertificadosPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="certificados.current_page >= certificados.last_page"
        icon
        @click="getCertificadosNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="certificados.data && certificados.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="certificados.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
          <tr
            @click="mostrarCertificado(props.item)"
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 2 : 0}`"
          >
            <td @click.stop="props.selected = !props.selected">
              <v-checkbox
                :input-value="props.selected"
                primary
                hide-details
              ></v-checkbox>
            </td>

            <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-chip
                small
                :color="props.item.estado_documento.color"
                text-color="white"
              >
                <span>{{ props.item.estado_documento.nombre }}</span>
                <v-menu max-width="400" transition="slide-x-transition" open-on-hover offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" right>info_outline</v-icon>
                  </template>
                    <v-card>
                      <v-card-text>
                          <div v-if="props.item.estado_documento.id === 1">
                            <div v-for="(linea, index) in (props.item.resultado.split(','))" :key="index">
                              <span><strong>{{ index + 1 }}. </strong>{{ linea }}</span>
                            </div>
                          </div>
                          <span v-else-if="props.item.estado_documento.id === 3">Para continuar se requiere pagar el certificado, una vez pagado no se puede modificar.</span>
                          <span v-else-if="props.item.estado_documento.id === 4">Para continuar se requiere firmar el certificado, una vez firmado no se puede modificar.</span>
                          <span v-else-if="props.item.estado_documento.id === 5">Para continuar se requiere registrar el certificado en el MEC.</span>
                          <span v-else>{{ props.item.resultado }}</span>
                      </v-card-text>
                    </v-card>
                </v-menu>
              </v-chip>
            </td>

            <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.folio_control }}</td>
            <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.resultado }}</td>
            <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_campus }}</td>
            <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_nivel_estudio }}</td>
            <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_carrera }}</td>
            <td :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.matricula_alumno }}</td>
            <td :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_alumno }}</td>
            <td :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.primer_apellido_alumno }}</td>
            <td :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.segundo_apellido_alumno }}</td>

            <td :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-chip
                v-if="props.item.curp_alumno"
                small
                :color="props.item.estado_curp.color"
                text-color="white"
              >
                <span>{{ props.item.curp_alumno }}</span>
                <v-menu max-width="400" transition="slide-x-transition" open-on-hover offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" right>info_outline</v-icon>
                  </template>
                    <v-card>
                      <v-card-text>
                          <span v-if="props.item.estado_curp.id === 1">Es necesario dar clic en el botón de <strong>Validar CURP's</strong> para verificar la CURP con la base de datos de RENAPO.</span>
                          <span v-else-if="props.item.estado_curp.id === 2">La CURP no está registrada ante RENAPO o no coincide con los nombre y/o apellidos del alumno.</span>
                          <span v-else-if="props.item.estado_curp.id === 3">La CURP si está registrada ante RENAPO.</span>
                      </v-card-text>
                    </v-card>
                </v-menu>
              </v-chip>
            </td>

            <td :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-chip small color="indigo" text-color="white">
                <v-avatar class="indigo darken-4"><strong>{{ props.item.calificaciones_capturadas_count }}</strong></v-avatar>
                <span v-if="props.item.total_asignaturas === 1">
                  Calificación
                </span>
                <span v-else>
                  Calificaciones
                </span>
              </v-chip>
            </td>

            <td :class="[headers[12].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-chip small color="indigo" text-color="white">
                <v-avatar class="indigo darken-4"><strong>{{ props.item.calificaciones_ciclos_count }}</strong></v-avatar>
                <span v-if="props.item.total_asignaturas === 1">
                  Ciclo
                </span>
                <span v-else>
                  Ciclos
                </span>
              </v-chip>
            </td>

            <div v-if="hover" class="certificado-fix-1">
              <div class="justify-center layout grey lighten-3 certificado-fix-2">
                <v-tooltip
                  v-if="loteCertificados.estado_lote_id === 4"
                  bottom
                >
                  <v-btn slot="activator" icon ripple @click.stop="cancelarCertificado(props.item)">
                    <v-icon color="grey darken-1">cancel</v-icon>
                  </v-btn>
                  <span>Cancelar</span>
                </v-tooltip>
                <v-tooltip
                  v-else
                  bottom
                >
                  <v-btn slot="activator" icon ripple @click.stop="eliminarCertificado(props.item)">
                    <v-icon color="grey darken-1">delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </div>
            </div>
          </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="cancelarCertificadosDialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Cancelar certificados</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-text-field
                :label="labels.motivo_cancelacion"
                v-model="motivo_cancelacion"
                :error-messages="errors.motivo_cancelacion"
                :rules="[rules.required('motivo_cancelacion')]"
              ></v-text-field>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="cancelarCertificadosDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" type="submit" @click="cancelar">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from '~/mixins/form'

export default {
  mixins: [Form],

  data: () => ({
    motivo_cancelacion: '',

    loading: false,

    noDataText: "No hay certificados registrados",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Estado", value: "estado_documento", visibility: true },
      { text: "Resultado", value: "resultado", visibility: false },
      { text: "Folio de Control", value: "folio_control", visibility: false },
      { text: "Campus", value: "nombre_campus", visibility: false },
      { text: "Nivel de Estudio", value: "nombre_nivel_estudio", visibility: true },
      { text: "Carrera", value: "nombre_carrera", visibility: true },
      { text: "Matricula", value: "matricula_alumno", visibility: false },
      { text: "Nombre", value: "nombre_alumno", visibility: false },
      { text: "Primer Apellido", value: "primer_apellido_alumno", visibility: false },
      { text: "Segundo Apellido", value: "segundo_apellido_alumno", visibility: false },
      { text: "CURP", value: "curp_alumno", visibility: true },
      { text: "Calificaciones", value: "calificaciones_capturadas_count", visibility: true },
      { text: "Ciclos", value: "calificaciones_ciclos_count", visibility: false },
    ],

    settingsDialog: false,
    cancelarCertificadosDialog: false,
    loadingDialog: false,

    pagination: {
      rowsPerPage: 10,
      sortBy: 'uuid'
    },

    certificados: [],
    loteCertificados: [],
    motivosCancelacion: [],
  }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
        busqueda: "auth/busqueda"
      })
    },

    watch: {
      live_mode(val) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        this.$router.push({ name: 'lotes-certificados' })
      },

      busqueda(val) {
        if (val) {
          this.$router.replace('#buscar/' + val.trim())
        }
        this.getLoteCertificados()
      },
    },

  methods: {
    incluyeBusqueda(val) {
      if(val && this.busqueda) {
        let value = val.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let value_two = this.busqueda.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return value.includes(value_two)
      }
    },
    
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.certificados.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getLoteCertificados() {
      axios
        .get(api.path("lotes_certificados.single", { id: this.$route.params.id }))
        .then(res => {
          this.loteCertificados = res.data
          this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteCertificados.estado_lote })
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
        })
    },
    

    getCertificados(val) {
      this.loading = true
      axios
        .get(api.path("lotes_certificados.certificados", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
        .then(res => {
          this.certificados = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    getCertificadosPreviousPage() {
      this.loading = true
      axios
        .get(api.path("lotes_certificados.certificados", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.certificados.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.certificados = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getCertificadosNextPage() {
      this.loading = true
      axios
        .get(api.path("lotes_certificados.certificados", { id: this.$route.params.id }), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.certificados.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.certificados = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarCertificado(val) {
      this.$confirm("¿Desea eliminar este certificado?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("lotes_certificados.certificados", { id: this.$route.params.id }), {
                params: {
                  ids: val.id
                }
              })
              .then(res => {
                this.$toast.success("Certificado eliminado correctamente.")
                this.getCertificados(this.$route.params.id)
              })
              .catch(err => {
                this.$toast.error("No se puede eliminar el certificado.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarCertificados() {
      this.$confirm("¿Desea eliminar los certificados seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("lotes_certificados.certificados", { id: this.$route.params.id }), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Certificados eliminados correctamente.")
                this.selected = []
                this.getCertificados()
              })
              .catch(err => {
                this.$toast.error("No se pueden eliminar los certificados.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    cancelarCertificado(val) {
      this.cancelarCertificadosDialog = true
      this.motivo_cancelacion = ''
      this.getCatalogoMotivoCancelacion()
      this.selected.push(val)
    },

    cancelarCertificados() {
      this.cancelarCertificadosDialog = true
      this.motivo_cancelacion = ''
      this.getCatalogoMotivoCancelacion()
    },

    cancelar() {
      this.cancelarCertificadosDialog = false
      this.loadingDialog = true

      let certificadosACancelar = []
      let motivo_cancelacion = this.motivo_cancelacion

      this.selected.forEach(function(val) {
        certificadosACancelar.push({
          folio_control: val.folio_control,
          motivo_cancelacion: motivo_cancelacion
        })
      })

      axios
        .post(api.path("lotes_certificados.cancelar", { id: this.$route.params.id }), certificadosACancelar)
        .then(res => {
          this.$toast.success("Certificados cancelados correctamente.")
          this.selected = []
          this.getCertificados(this.$route.params.id)
        })
        .catch(err => {
          this.$toast.error("No se pueden cancelar los certificados.")
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    getCatalogoMotivoCancelacion() {
      axios
        .get(api.path('catalogos.motivo_cancelacion'))
        .then(res => {
          this.motivosCancelacion = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {})
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.estado_documento.nombre + '\t'
        data = data + val.folio_control + '\t'
        data = data + val.resultado + '\t'
        data = data + val.nombre_campus + '\t'
        data = data + val.nombre_nivel_estudio + '\t'
        data = data + val.nombre_carrera + '\t'
        data = data + val.matricula_alumno + '\t'
        data = data + val.nombre_alumno + '\t'
        data = data + val.primer_apellido_alumno + '\t'
        data = data + val.segundo_apellido_alumno + '\t'
        data = data + val.estado_curp.nombre + '\t'
        data = data + val.curp_alumno + '\t'
        data = data + val.calificaciones_capturadas_count + '\t'
        data = data + val.calificaciones_ciclos_count + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarCertificado(val) {
      this.$router.push({ name: 'lotes-certificados-certificados-mostrar', params: { id: this.$route.params.id, id2: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getCertificados()
      localStorage.setItem('certificado.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('certificado.headers', JSON.stringify(this.headers))
    },
  },

  mounted() {
    if (localStorage.hasOwnProperty('certificado.pagination')) {
      this.pagination = JSON.parse(localStorage.getItem('certificado.pagination'))
    }
    if (localStorage.hasOwnProperty('certificado.headers')) {
      this.headers = JSON.parse(localStorage.getItem('certificado.headers'))
    }

    let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

    if (tab[0] === '#buscar') {
      let busqueda = decodeURI( tab[1] )
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
    } else {
      if (this.busqueda) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      }
    }

    this.getCertificados()
    this.getLoteCertificados()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .certificado-fix-1 {
    position: relative !important;
	}

  .certificado-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>
