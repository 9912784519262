var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _vm.auth.role === "Administrador"
                      ? _c("v-switch", {
                          attrs: { label: _vm.labels.editable },
                          on: { change: _vm.changeEstado },
                          model: {
                            value: _vm.programa.editable,
                            callback: function($$v) {
                              _vm.$set(_vm.programa, "editable", $$v)
                            },
                            expression: "programa.editable"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.campus,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("campus")
                        }
                      },
                      model: {
                        value: _vm.programa.campus,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "campus", $$v)
                        },
                        expression: "programa.campus"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.carrera,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("carrera")
                        }
                      },
                      model: {
                        value: _vm.programa.carrera,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "carrera", $$v)
                        },
                        expression: "programa.carrera"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.modalidad,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("modalidad")
                        }
                      },
                      model: {
                        value: _vm.programa.modalidad,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "modalidad", $$v)
                        },
                        expression: "programa.modalidad"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.tipo_periodo,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("tipo_periodo")
                        }
                      },
                      model: {
                        value: _vm.programa.tipo_periodo,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "tipo_periodo", $$v)
                        },
                        expression: "programa.tipo_periodo"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.tipo_rvoe,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("tipo_rvoe")
                        }
                      },
                      model: {
                        value: _vm.programa.tipo_rvoe,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "tipo_rvoe", $$v)
                        },
                        expression: "programa.tipo_rvoe"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.numero_rvoe,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("numero_rvoe")
                        }
                      },
                      model: {
                        value: _vm.programa.numero_rvoe,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "numero_rvoe", $$v)
                        },
                        expression: "programa.numero_rvoe"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.fecha_rvoe,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("fecha_rvoe")
                        }
                      },
                      model: {
                        value: _vm.programa.fecha_rvoe,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "fecha_rvoe", $$v)
                        },
                        expression: "programa.fecha_rvoe"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.clave_plan,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("clave_plan")
                        }
                      },
                      model: {
                        value: _vm.programa.clave_plan,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "clave_plan", $$v)
                        },
                        expression: "programa.clave_plan"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.calificacion_maxima,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("calificacion_maxima")
                        }
                      },
                      model: {
                        value: _vm.programa.calificacion_maxima,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "calificacion_maxima", $$v)
                        },
                        expression: "programa.calificacion_maxima"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.calificacion_minima,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("calificacion_minima")
                        }
                      },
                      model: {
                        value: _vm.programa.calificacion_minima,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "calificacion_minima", $$v)
                        },
                        expression: "programa.calificacion_minima"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.calificacion_minima_aprobatoria,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma(
                            "calificacion_minima_aprobatoria"
                          )
                        }
                      },
                      model: {
                        value: _vm.programa.calificacion_minima_aprobatoria,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.programa,
                            "calificacion_minima_aprobatoria",
                            $$v
                          )
                        },
                        expression: "programa.calificacion_minima_aprobatoria"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.total_creditos,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("total_creditos")
                        }
                      },
                      model: {
                        value: _vm.programa.total_creditos,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "total_creditos", $$v)
                        },
                        expression: "programa.total_creditos"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.total_asignaturas,
                        "append-icon": _vm.programa.editable ? "edit" : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPrograma("total_asignaturas")
                        }
                      },
                      model: {
                        value: _vm.programa.total_asignaturas,
                        callback: function($$v) {
                          _vm.$set(_vm.programa, "total_asignaturas", $$v)
                        },
                        expression: "programa.total_asignaturas"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }