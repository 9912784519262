var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      attrs: { "fixed-tabs": "", grow: "", "slider-color": "primary" },
      on: {
        change: function($event) {
          _vm.cambiarPestaña($event)
        }
      },
      model: {
        value: _vm.selectedTab,
        callback: function($$v) {
          _vm.selectedTab = $$v
        },
        expression: "selectedTab"
      }
    },
    [
      _c("v-tab", { attrs: { href: "#responsable" } }, [
        _c("span", [_vm._v("Responsable")])
      ]),
      _vm._v(" "),
      _c("v-tab", { attrs: { href: "#firma" } }, [
        _c("span", [_vm._v("e.firma")])
      ]),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        [
          _c(
            "v-tab-item",
            { attrs: { value: "responsable" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "justify-center": "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { lg6: "", sm9: "", xs12: "" } },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      label:
                                        _vm.labels.responsable_plan_estudios
                                    },
                                    on: {
                                      change:
                                        _vm.changeEstadoResponsablePlanEstudios
                                    },
                                    model: {
                                      value:
                                        _vm.responsable
                                          .responsable_plan_estudios,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "responsable_plan_estudios",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "responsable.responsable_plan_estudios"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-card",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "v-card-title",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                "align-center": "",
                                                "justify-space-between": "",
                                                row: "",
                                                "fill-height": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ml-2 font-weight-light grey--text subheading"
                                                },
                                                [_vm._v("Firma autógrafa")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editarResponsable(
                                                        "firma_autografa"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "grey darken-2"
                                                      }
                                                    },
                                                    [_vm._v("edit")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.responsable.firma_autografa
                                        ? _c(
                                            "v-card-text",
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  src:
                                                    _vm.responsable
                                                      .firma_autografa,
                                                  "lazy-src":
                                                    _vm.responsable
                                                      .firma_autografa,
                                                  height: "80",
                                                  contain: "",
                                                  "aspect-ratio": "1"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.nombre,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable("nombre")
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.nombre,
                                      callback: function($$v) {
                                        _vm.$set(_vm.responsable, "nombre", $$v)
                                      },
                                      expression: "responsable.nombre"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.primer_apellido,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable(
                                          "primer_apellido"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.primer_apellido,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "primer_apellido",
                                          $$v
                                        )
                                      },
                                      expression: "responsable.primer_apellido"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.segundo_apellido,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable(
                                          "segundo_apellido"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.segundo_apellido,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "segundo_apellido",
                                          $$v
                                        )
                                      },
                                      expression: "responsable.segundo_apellido"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.cargo,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable("cargo")
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.cargo.nombre,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable.cargo,
                                          "nombre",
                                          $$v
                                        )
                                      },
                                      expression: "responsable.cargo.nombre"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.curp,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable("curp")
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.curp,
                                      callback: function($$v) {
                                        _vm.$set(_vm.responsable, "curp", $$v)
                                      },
                                      expression: "responsable.curp"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: _vm.labels.abreviatura_titulo,
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      "click:append": function($event) {
                                        return _vm.editarResponsable(
                                          "abreviatura_titulo"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.abreviatura_titulo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "abreviatura_titulo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "responsable.abreviatura_titulo"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label:
                                        _vm.labels.campuses_a_firmar_titulos,
                                      items: _vm.responsable.campuses_titulos,
                                      "item-text": "nombre",
                                      "item-value": "id",
                                      attach: "",
                                      chips: "",
                                      multiple: "",
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return $event.target.blur()
                                      },
                                      "click:append": function($event) {
                                        return _vm.editarResponsable(
                                          "campuses_titulos"
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.responsable.campuses_titulos,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "campuses_titulos",
                                          $$v
                                        )
                                      },
                                      expression: "responsable.campuses_titulos"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label:
                                        _vm.labels
                                          .campuses_a_firmar_certificados,
                                      items:
                                        _vm.responsable.campuses_certificados,
                                      "item-text": "nombre",
                                      "item-value": "id",
                                      attach: "",
                                      chips: "",
                                      multiple: "",
                                      "append-icon": "edit",
                                      outline: "",
                                      readonly: ""
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return $event.target.blur()
                                      },
                                      "click:append": function($event) {
                                        return _vm.editarResponsable(
                                          "campuses_certificados"
                                        )
                                      }
                                    },
                                    model: {
                                      value:
                                        _vm.responsable.campuses_certificados,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.responsable,
                                          "campuses_certificados",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "responsable.campuses_certificados"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            { attrs: { value: "firma" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-xl": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", "justify-center": "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { lg6: "", sm9: "", xs12: "" } },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-space-around": "",
                                        row: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outline: "",
                                            color: "primary"
                                          },
                                          on: { click: _vm.subirFirma }
                                        },
                                        [_vm._v("Subir e.firma")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            outline: "",
                                            color: "error",
                                            disabled: !_vm.responsable.firma,
                                            loading: _vm.loading
                                          },
                                          on: { click: _vm.deleteFirma }
                                        },
                                        [_vm._v("Eliminar e.firma")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.responsable.firma
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: _vm.labels.autofirma,
                                              disabled: !_vm.responsable.firma
                                            },
                                            on: {
                                              change: _vm.changeEstadoAutofirma
                                            },
                                            model: {
                                              value: _vm.responsable.autofirma,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable,
                                                  "autofirma",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.autofirma"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.labels.nombre,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.responsable.fiel.nombre,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "nombre",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.nombre"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.labels.curp,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.responsable.fiel.curp,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "curp",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.curp"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.labels.rfc,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.responsable.fiel.rfc,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "rfc",
                                                  $$v
                                                )
                                              },
                                              expression: "responsable.fiel.rfc"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.labels.email,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value: _vm.responsable.fiel.email,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "email",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.email"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                _vm.labels.numero_certificado,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.responsable.fiel
                                                  .numeroCertificado,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "numeroCertificado",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.numeroCertificado"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: _vm.labels.fecha_emision,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.responsable.fiel
                                                  .fechaEmision,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "fechaEmision",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.fechaEmision"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                _vm.labels.fecha_expiracion,
                                              outline: "",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.responsable.fiel
                                                  .fechaExpiracion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.responsable.fiel,
                                                  "fechaExpiracion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "responsable.fiel.fechaExpiracion"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }