import { render, staticRenderFns } from "./CarreraEditarForm.vue?vue&type=template&id=4653ebf6&"
import script from "./CarreraEditarForm.vue?vue&type=script&lang=js&"
export * from "./CarreraEditarForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VContainer,VForm,VLayout,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4653ebf6')) {
      api.createRecord('4653ebf6', component.options)
    } else {
      api.reload('4653ebf6', component.options)
    }
    module.hot.accept("./CarreraEditarForm.vue?vue&type=template&id=4653ebf6&", function () {
      api.rerender('4653ebf6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/carrera/editar/CarreraEditarForm.vue"
export default component.exports