var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      attrs: {
        color: "white",
        app: "",
        flat: "",
        height: _vm.$vuetify.breakpoint.xsOnly ? "78" : "98"
      }
    },
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          attrs: {
            src: "/img/metaescolar.png",
            alt: "Logotipo",
            height: _vm.$vuetify.breakpoint.xsOnly ? "48" : "68px"
          }
        })
      ]),
      _vm._v(" "),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }