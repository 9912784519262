var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { app: "" } },
    [
      _c(
        "v-card",
        {
          staticClass: "flex",
          attrs: { color: _vm.live_mode ? "" : "orange", flat: "" }
        },
        [
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { flat: "", small: "" },
                  on: { click: _vm.acerca }
                },
                [
                  _vm._v(
                    "©" +
                      _vm._s(new Date().getFullYear()) +
                      " " +
                      _vm._s(_vm.siteName) +
                      " v" +
                      _vm._s(_vm.version)
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", [
                !_vm.live_mode
                  ? _c("strong", [_vm._v("(Modo de prueba)")])
                  : _vm._e()
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }