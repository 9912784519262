var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.nombre,
              "error-messages": _vm.errors.nombre,
              rules: [_vm.rules.required("nombre")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.nombre,
              callback: function($$v) {
                _vm.$set(_vm.form, "nombre", $$v)
              },
              expression: "form.nombre"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.primer_apellido,
              "error-messages": _vm.errors.primer_apellido,
              rules: [_vm.rules.required("primer_apellido")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.primer_apellido,
              callback: function($$v) {
                _vm.$set(_vm.form, "primer_apellido", $$v)
              },
              expression: "form.primer_apellido"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.segundo_apellido,
              "error-messages": _vm.errors.segundo_apellido,
              rules: [_vm.rules.required("segundo_apellido")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.segundo_apellido,
              callback: function($$v) {
                _vm.$set(_vm.form, "segundo_apellido", $$v)
              },
              expression: "form.segundo_apellido"
            }
          }),
          _vm._v(" "),
          _c("v-autocomplete", {
            attrs: {
              label: _vm.labels.cargo,
              "error-messages": _vm.errors.cargo,
              rules: [_vm.rules.required("cargo")],
              disabled: _vm.loading,
              items: _vm.cargos,
              "item-text": "nombre",
              "item-value": "id",
              dense: "",
              "hide-no-data": ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return $event.target.blur()
              }
            },
            model: {
              value: _vm.form.cargo.id,
              callback: function($$v) {
                _vm.$set(_vm.form.cargo, "id", $$v)
              },
              expression: "form.cargo.id"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.curp,
              "error-messages": _vm.errors.curp,
              rules: [_vm.rules.required("curp")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.curp,
              callback: function($$v) {
                _vm.$set(_vm.form, "curp", $$v)
              },
              expression: "form.curp"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.abreviatura_titulo,
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.abreviatura_titulo,
              callback: function($$v) {
                _vm.$set(_vm.form, "abreviatura_titulo", $$v)
              },
              expression: "form.abreviatura_titulo"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }