import * as types from '../mutation-types'

/**
 * Initial state
 */
export const state = {
  comentarioDialog: null,
  mensajeDialog: null,
  funcionPremiumDialog: null,
  estadoLote: null,
  titulosIds: null,
  enviarCertificados: null,
}

/**
 * Mutations
 */
export const mutations = {
  [types.SET_COMENTARIO_DIALOG](state, { comentarioDialog }) {
    state.comentarioDialog = comentarioDialog
  },

  [types.SET_MENSAJE_DIALOG](state, { mensajeDialog }) {
    state.mensajeDialog = mensajeDialog
  },

  [types.SET_FUNCION_PREMIUM_DIALOG](state, { funcionPremiumDialog }) {
    state.funcionPremiumDialog = funcionPremiumDialog
  },

  [types.SET_ESTADO_LOTE](state, { estadoLote }) {
    state.estadoLote = estadoLote
  },

  [types.SET_TITULOS_IDS](state, { titulosIds }) {
    state.titulosIds = titulosIds
  },

  [types.SET_ENVIAR_CERTIFICADOS](state, { enviarCertificados }) {
    state.enviarCertificados = enviarCertificados
  },
}

/**
 * Actions
 */
export const actions = {
  guardarComentarioDialog({ commit }, payload) {
    commit(types.SET_COMENTARIO_DIALOG, payload)
  },

  guardarMensajeDialog({ commit }, payload) {
    commit(types.SET_MENSAJE_DIALOG, payload)
  },

  guardarFuncionPremiumDialog({ commit }, payload) {
    commit(types.SET_FUNCION_PREMIUM_DIALOG, payload)
  },

  guardarEstadoLote({ commit }, payload) {
    commit(types.SET_ESTADO_LOTE, payload)
  },

  guardarTitulosIds({ commit }, payload) {
    commit(types.SET_TITULOS_IDS, payload)
  },

  guardarEnviarCertificados({ commit }, payload) {
    commit(types.SET_ENVIAR_CERTIFICADOS, payload)
  },
}

/**
 * Getters
 */
export const getters = {
  comentarioDialog: state => state.comentarioDialog,
  mensajeDialog: state => state.mensajeDialog,
  funcionPremiumDialog: state => state.funcionPremiumDialog,
  estadoLote: state => state.estadoLote,
  titulosIds: state => state.titulosIds,
  enviarCertificados: state => state.enviarCertificados,
}
