var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.name,
              "error-messages": _vm.errors.name,
              rules: [_vm.rules.required("name")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.email,
              "error-messages": _vm.errors.email,
              rules: [_vm.rules.required("email")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: _vm.labels.role,
              items: _vm.roles,
              "error-messages": _vm.errors.role,
              rules: [_vm.rules.required("role")],
              disabled: _vm.loading,
              dense: ""
            },
            model: {
              value: _vm.form.role,
              callback: function($$v) {
                _vm.$set(_vm.form, "role", $$v)
              },
              expression: "form.role"
            }
          }),
          _vm._v(" "),
          _vm.form.role !== "Administrador"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.campuses_asignados,
                  disabled: _vm.loading,
                  items: _vm.campuses,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  attach: "",
                  chips: "",
                  multiple: "",
                  "deletable-chips": "",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campuses,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campuses", $$v)
                  },
                  expression: "form.campuses"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }