import { render, staticRenderFns } from "./PlantillaTituloMostrar.vue?vue&type=template&id=1d030db8&"
import script from "./PlantillaTituloMostrar.vue?vue&type=script&lang=js&"
export * from "./PlantillaTituloMostrar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VFlex,VLayout,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d030db8')) {
      api.createRecord('1d030db8', component.options)
    } else {
      api.reload('1d030db8', component.options)
    }
    module.hot.accept("./PlantillaTituloMostrar.vue?vue&type=template&id=1d030db8&", function () {
      api.rerender('1d030db8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/plantilla-titulo/mostrar/PlantillaTituloMostrar.vue"
export default component.exports