var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "id_asignatura"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.id_asignatura,
                  "error-messages": _vm.errors.id_asignatura,
                  rules: [_vm.rules.required("id_asignatura")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.id_asignatura,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "id_asignatura", $$v)
                  },
                  expression: "form.id_asignatura"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "nombre_asignatura"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.nombre_asignatura,
                  "error-messages": _vm.errors.nombre_asignatura,
                  rules: [_vm.rules.required("nombre_asignatura")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.nombre_asignatura,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nombre_asignatura", $$v)
                  },
                  expression: "form.nombre_asignatura"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "clave_asignatura"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.clave_asignatura,
                  "error-messages": _vm.errors.clave_asignatura,
                  rules: [_vm.rules.required("clave_asignatura")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.clave_asignatura,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "clave_asignatura", $$v)
                  },
                  expression: "form.clave_asignatura"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "id_tipo_asignatura"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.id_tipo_asignatura,
                  "error-messages": _vm.errors.id_tipo_asignatura,
                  rules: [_vm.rules.required("id_tipo_asignatura")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.id_tipo_asignatura,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "id_tipo_asignatura", $$v)
                  },
                  expression: "form.id_tipo_asignatura"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "tipo_asignatura"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.tipo_asignatura,
                  "error-messages": _vm.errors.tipo_asignatura,
                  rules: [_vm.rules.required("tipo_asignatura")],
                  disabled: _vm.loading,
                  items: _vm.tipoAsignatura,
                  "item-text": "nombre",
                  "item-value": "id",
                  dense: ""
                },
                model: {
                  value: _vm.form.tipo_asignatura_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tipo_asignatura_id", $$v)
                  },
                  expression: "form.tipo_asignatura_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "creditos_asignatura"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.creditos_asignatura,
                  "error-messages": _vm.errors.creditos_asignatura,
                  rules: [_vm.rules.required("creditos_asignatura")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.creditos_asignatura,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "creditos_asignatura", $$v)
                  },
                  expression: "form.creditos_asignatura"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo ||
          this.campo === "ciclo_escolar" ||
          this.campo === "calificacion" ||
          this.campo === "observacion"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.ciclo_escolar,
                  "error-messages": _vm.errors.ciclo_escolar,
                  rules: [_vm.rules.required("ciclo_escolar")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.ciclo_escolar,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "ciclo_escolar", $$v)
                  },
                  expression: "form.ciclo_escolar"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo ||
          this.campo === "ciclo_escolar" ||
          this.campo === "calificacion" ||
          this.campo === "observacion"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.calificacion,
                  "error-messages": _vm.errors.calificacion,
                  rules: [_vm.rules.required("calificacion")],
                  disabled: _vm.loading,
                  items: _vm.calificacion,
                  dense: ""
                },
                model: {
                  value: _vm.form.calificacion,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "calificacion", $$v)
                  },
                  expression: "form.calificacion"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "id_observacion"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.id_observacion,
                  "error-messages": _vm.errors.id_observacion,
                  rules: [_vm.rules.required("id_observacion")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.observacion_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "observacion_id", $$v)
                  },
                  expression: "form.observacion_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo ||
          this.campo === "ciclo_escolar" ||
          this.campo === "calificacion" ||
          this.campo === "observacion"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.observacion,
                  disabled: _vm.loading,
                  items: _vm.observacion,
                  "item-text": _vm.observacionText,
                  "item-value": "id",
                  dense: ""
                },
                model: {
                  value: _vm.form.observacion_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "observacion_id", $$v)
                  },
                  expression: "form.observacion_id"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }