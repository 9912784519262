var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.nombre,
              "error-messages": _vm.errors.nombre,
              rules: [_vm.rules.required("nombre")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.nombre,
              callback: function($$v) {
                _vm.$set(_vm.form, "nombre", $$v)
              },
              expression: "form.nombre"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.estilo,
              "error-messages": _vm.errors.estilo,
              rules: [_vm.rules.required("estilo")],
              disabled: _vm.loadingDialog,
              "append-icon": "attach_file"
            },
            on: { click: _vm.seleccionarArchivoEstilo },
            model: {
              value: _vm.archivo_estilo.name,
              callback: function($$v) {
                _vm.$set(_vm.archivo_estilo, "name", $$v)
              },
              expression: "archivo_estilo.name"
            }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "archivo_estilo",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: ".css" },
            on: { change: _vm.archivoEstiloSeleccionado }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.fondo,
              "error-messages": _vm.errors.fondo,
              rules: [_vm.rules.required("fondo")],
              disabled: _vm.loadingDialog,
              "append-icon": "attach_file"
            },
            on: { click: _vm.seleccionarArchivoFondo },
            model: {
              value: _vm.archivo_fondo.name,
              callback: function($$v) {
                _vm.$set(_vm.archivo_fondo, "name", $$v)
              },
              expression: "archivo_fondo.name"
            }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "archivo_fondo",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: ".jpg,.png" },
            on: { change: _vm.archivoFondoSeleccionado }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }