<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>Enviar comentarios</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-textarea
          solo
          flat
          ref="descripcion"
          label="Describe el problema o comparte tus ideas"
          v-model="descripcion"
          no-resize
          counter="500"
          tabindex="1"
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="dialog = false" tabindex="3">Cancelar</v-btn>
        <v-btn
          color="primary"
          flat
          :loading="loading"
          :disabled="!descripcion"
          @click="enviarComentario"
          tabindex="2"
          >Enviar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { api } from '~/config'

export default {
  data: () => ({
    dialog: false,
    descripcion: null,
    loading: false,
  }),

  computed: {
    ...mapGetters({
      comentarioDialog: 'admin/comentarioDialog'
    })
  },

  watch: {
    comentarioDialog(val) {
      if (val) {
        this.dialog = val
      }
    },

    dialog(val) {
      if (val === false) {
        this.$store.dispatch('admin/guardarComentarioDialog', {
          comentarioDialog: false
        })
      } else if (val === true) {
        this.descripcion = null
        this.$nextTick(() => this.$refs.descripcion.focus())
      }
    }
  },

  methods: {
    enviarComentario() {
      this.loading = true

      axios
        .post(api.path('comentarios'), {
          descripcion: this.descripcion
        })
        .then(res => {
          this.dialog = false
					this.$toast.success("Gracias por tus comentarios.")
        })
        .catch(err => {
          //this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },
  }
}
</script>
