<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.uuid"
              v-model="loteTitulos.id"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.nombre"
              v-model="loteTitulos.nombre"
              @click:append="editarLoteTitulos('nombre')"
              :append-icon="loteTitulos.responsables.length > 0 ? '' : 'edit'"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.campus"
              v-model="loteTitulos.campus.nombre"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_capturado"
              v-model="loteTitulos.fecha_capturado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_capturado"
              v-model="loteTitulos.usuario_capturado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_firmado"
              v-model="loteTitulos.fecha_firmado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_firmado"
              v-model="loteTitulos.usuario_firmado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_enviado"
              v-model="loteTitulos.fecha_enviado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_enviado"
              v-model="loteTitulos.usuario_enviado"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loteTitulos: {
        campus: {},
        responsables: {}
      },
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-titulos' })
      }
    },

    mounted() {
      this.getLoteTitulos()
    },

    methods: {
      getLoteTitulos() {
        axios
          .get(api.path("titulos.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteTitulos = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      editarLoteTitulos(campo) {
        this.$router.push({ name: "lotes-titulos-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
