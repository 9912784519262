var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "", wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs4: "" } },
        [
          _c("v-select", {
            attrs: {
              label: _vm.labels.tipo_reporte,
              items: _vm.tipos,
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.getReporte },
            model: {
              value: _vm.tipo,
              callback: function($$v) {
                _vm.tipo = $$v
              },
              expression: "tipo"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs4: "" } },
        [
          _c("v-select", {
            attrs: {
              label: _vm.labels.campus,
              items: _vm.auth ? _vm.auth.campuses : [],
              "item-text": "nombre",
              "item-value": "id",
              dense: "",
              "no-data-text": _vm.labels.no_datos,
              "hide-details": ""
            },
            on: { input: _vm.getReporte },
            model: {
              value: _vm.campus_id,
              callback: function($$v) {
                _vm.campus_id = $$v
              },
              expression: "campus_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs4: "" } },
        [
          _c("v-select", {
            attrs: {
              label: _vm.labels.anio,
              items: _vm.anios,
              dense: "",
              "hide-details": ""
            },
            on: { input: _vm.getReporte },
            model: {
              value: _vm.anio,
              callback: function($$v) {
                _vm.anio = $$v
              },
              expression: "anio"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: { color: "blue", size: "36" }
                    },
                    [_vm._v("\n          la-list\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "subheading text-uppercase" }, [
                    _vm._v("\n          Capturados\n        ")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("div", { staticClass: "headline" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.documentos.noFirmados) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: _vm.tipo === "Títulos" ? "purple" : "indigo",
                        size: "36"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.tipo === "Títulos" ? "la-list-ol" : "la-tasks"
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "subheading text-uppercase" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.tipo === "Títulos" ? "Firmados" : "Pagados"
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("div", { staticClass: "headline" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.documentos.firmados) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg4: "", sm4: "", xs12: "" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        color: _vm.tipo === "Títulos" ? "green" : "purple",
                        size: "36"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.tipo === "Títulos"
                              ? "la-list-alt"
                              : "la-list-ol"
                          ) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "subheading text-uppercase" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.tipo === "Títulos" ? "Enviados" : "Firmados"
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("div", { staticClass: "headline" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.documentos.enviados) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { attrs: { lg12: "", sm12: "", xs12: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto text-xs-center",
              attrs: { "max-width": "600" }
            },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-sheet",
                    { attrs: { color: "rgba(0, 0, 0, .02)" } },
                    [
                      _c("v-sparkline", {
                        attrs: {
                          value: _vm.documentos.porMes,
                          "stroke-linecap": "round",
                          smooth: "",
                          height: "100",
                          "line-width": "2",
                          padding: "24",
                          "auto-draw": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function(item) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.value) +
                                    "\n            "
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "subheading text-uppercase mt-3" }, [
                    _vm._v("Cantidad de " + _vm._s(_vm.tipo) + " por mes")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }