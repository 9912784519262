var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-stepper",
        {
          attrs: { "non-linear": "", "alt-labels": "" },
          model: {
            value: _vm.stepper,
            callback: function($$v) {
              _vm.stepper = $$v
            },
            expression: "stepper"
          }
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    step: "1",
                    rules: [
                      function() {
                        return _vm.carreraEstado
                      }
                    ]
                  }
                },
                [
                  _vm._v("\n        Carrera\n        "),
                  _c("small", [_vm._v("Complete los datos")])
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    step: "2",
                    rules: [
                      function() {
                        return _vm.profesionistaEstado
                      }
                    ]
                  }
                },
                [
                  _vm._v("\n        Profesionista\n        "),
                  _c("small", [_vm._v("Complete los datos")])
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    step: "3",
                    rules: [
                      function() {
                        return _vm.expedicionEstado
                      }
                    ]
                  }
                },
                [
                  _vm._v("\n        Expedición\n        "),
                  _c("small", [_vm._v("Complete los datos")])
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    step: "4",
                    rules: [
                      function() {
                        return _vm.antecedenteEstado
                      }
                    ]
                  }
                },
                [
                  _vm._v("\n        Antecedente\n        "),
                  _c("small", [_vm._v("Complete los datos")])
                ]
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    editable: "",
                    step: "5",
                    rules: [
                      function() {
                        return _vm.loteEstado
                      }
                    ]
                  }
                },
                [
                  _vm._v("\n        Lote\n        "),
                  _c("small", [_vm._v("Complete los datos")])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.campus,
                              items: _vm.campuses,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages": _vm.errors.nombre_campus,
                              rules: [_vm.rules.required("campus")],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.nombre_campus,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nombre_campus", $$v)
                              },
                              expression: "form.nombre_campus"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.carrera,
                              items: _vm.carreras,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages": _vm.errors.nombre_carrera,
                              rules: [_vm.rules.required("carrera")],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.nombre_carrera,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nombre_carrera", $$v)
                              },
                              expression: "form.nombre_carrera"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels.fecha_inicio_carrera,
                                              "error-messages":
                                                _vm.errors.fecha_inicio_carrera,
                                              rules: [
                                                _vm.rules.required(
                                                  "fecha_inicio_carrera"
                                                )
                                              ],
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form.fecha_inicio_carrera,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_inicio_carrera",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_inicio_carrera"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaInicioCarrera,
                                callback: function($$v) {
                                  _vm.menuFechaInicioCarrera = $$v
                                },
                                expression: "menuFechaInicioCarrera"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaInicioCarrera = false
                                  }
                                },
                                model: {
                                  value: _vm.form.fecha_inicio_carrera,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_inicio_carrera",
                                      $$v
                                    )
                                  },
                                  expression: "form.fecha_inicio_carrera"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels
                                                  .fecha_terminacion_carrera,
                                              "error-messages":
                                                _vm.errors
                                                  .fecha_terminacion_carrera,
                                              rules: [
                                                _vm.rules.required(
                                                  "fecha_terminacion_carrera"
                                                )
                                              ],
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .fecha_terminacion_carrera,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_terminacion_carrera",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_terminacion_carrera"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaTerminacionCarrera,
                                callback: function($$v) {
                                  _vm.menuFechaTerminacionCarrera = $$v
                                },
                                expression: "menuFechaTerminacionCarrera"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaTerminacionCarrera = false
                                  }
                                },
                                model: {
                                  value: _vm.form.fecha_terminacion_carrera,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_terminacion_carrera",
                                      $$v
                                    )
                                  },
                                  expression: "form.fecha_terminacion_carrera"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.autorizacion_reconocimiento,
                              items: _vm.autorizacionesReconocimientos,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages":
                                _vm.errors.autorizacion_reconocimiento,
                              rules: [
                                _vm.rules.required(
                                  "autorizacion_reconocimiento"
                                )
                              ],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.autorizacion_reconocimiento,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "autorizacion_reconocimiento",
                                  $$v
                                )
                              },
                              expression: "form.autorizacion_reconocimiento"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.numero_rvoe,
                              "error-messages": _vm.errors.numero_rvoe,
                              rules: [_vm.rules.required("numero_rvoe")],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.numero_rvoe,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "numero_rvoe", $$v)
                              },
                              expression: "form.numero_rvoe"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        disabled: _vm.loading,
                        color: "grey darken-2"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$router.go(-1)
                        }
                      }
                    },
                    [_vm._v("\n            Cancelar\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("\n          Continuar\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.curp_profesionista,
                              "error-messages": _vm.errors.curp_profesionista,
                              rules: [_vm.rules.required("curp_profesionista")],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.curp_profesionista,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "curp_profesionista", $$v)
                              },
                              expression: "form.curp_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.matricula_profesionista,
                              "error-messages":
                                _vm.errors.matricula_profesionista,
                              rules: [
                                _vm.rules.required("matricula_profesionista")
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.matricula_profesionista,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "matricula_profesionista",
                                  $$v
                                )
                              },
                              expression: "form.matricula_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.nombre_profesionista,
                              "error-messages": _vm.errors.nombre_profesionista,
                              rules: [
                                _vm.rules.required("nombre_profesionista")
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.nombre_profesionista,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "nombre_profesionista", $$v)
                              },
                              expression: "form.nombre_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.primer_apellido_profesionista,
                              "error-messages":
                                _vm.errors.primer_apellido_profesionista,
                              rules: [
                                _vm.rules.required(
                                  "primer_apellido_profesionista"
                                )
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.primer_apellido_profesionista,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "primer_apellido_profesionista",
                                  $$v
                                )
                              },
                              expression: "form.primer_apellido_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.segundo_apellido_profesionista,
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.segundo_apellido_profesionista,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "segundo_apellido_profesionista",
                                  $$v
                                )
                              },
                              expression: "form.segundo_apellido_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label:
                                _vm.labels.correo_electronico_profesionista,
                              "error-messages":
                                _vm.errors.correo_electronico_profesionista,
                              rules: [
                                _vm.rules.required(
                                  "correo_electronico_profesionista"
                                )
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.correo_electronico_profesionista,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "correo_electronico_profesionista",
                                  $$v
                                )
                              },
                              expression:
                                "form.correo_electronico_profesionista"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.numero_telefono_profesionista,
                              "error-messages":
                                _vm.errors.numero_telefono_profesionista,
                              rules: [
                                _vm.rules.required(
                                  "numero_telefono_profesionista"
                                )
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.numero_telefono_profesionista,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "numero_telefono_profesionista",
                                  $$v
                                )
                              },
                              expression: "form.numero_telefono_profesionista"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 1
                        }
                      }
                    },
                    [_vm._v("\n          Regresar\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 3
                        }
                      }
                    },
                    [_vm._v("\n          Continuar\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels.fecha_de_expedicion,
                                              "error-messages":
                                                _vm.errors.fecha_de_expedicion,
                                              rules: [
                                                _vm.rules.required(
                                                  "fecha_de_expedicion"
                                                )
                                              ],
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form.fecha_de_expedicion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_de_expedicion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_de_expedicion"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaExpedicion,
                                callback: function($$v) {
                                  _vm.menuFechaExpedicion = $$v
                                },
                                expression: "menuFechaExpedicion"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaExpedicion = false
                                  }
                                },
                                model: {
                                  value: _vm.form.fecha_de_expedicion,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_de_expedicion",
                                      $$v
                                    )
                                  },
                                  expression: "form.fecha_de_expedicion"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.modalidad_de_titulacion,
                              items: _vm.modalidadesTitulacion,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages":
                                _vm.errors.modalidad_de_titulacion,
                              rules: [
                                _vm.rules.required("modalidad_de_titulacion")
                              ],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.modalidad_de_titulacion,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "modalidad_de_titulacion",
                                  $$v
                                )
                              },
                              expression: "form.modalidad_de_titulacion"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels
                                                  .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                              "error-messages":
                                                _vm.errors
                                                  .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                              rules: [
                                                _vm.rules.required(
                                                  "fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                                                )
                                              ],
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_examen_profesional_o_fecha_exencion_examen_profesional",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaExamenProfesional,
                                callback: function($$v) {
                                  _vm.menuFechaExamenProfesional = $$v
                                },
                                expression: "menuFechaExamenProfesional"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaExamenProfesional = false
                                  }
                                },
                                model: {
                                  value:
                                    _vm.form
                                      .fecha_examen_profesional_o_fecha_exencion_examen_profesional,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_examen_profesional_o_fecha_exencion_examen_profesional",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-select", {
                            attrs: {
                              label: _vm.labels.cumplio_servicio_social,
                              items: _vm.cumplioServicioSocial,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages":
                                _vm.errors.cumplio_servicio_social,
                              rules: [
                                _vm.rules.required("cumplio_servicio_social")
                              ],
                              disabled: _vm.loading,
                              dense: ""
                            },
                            model: {
                              value: _vm.form.cumplio_servicio_social,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "cumplio_servicio_social",
                                  $$v
                                )
                              },
                              expression: "form.cumplio_servicio_social"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label:
                                _vm.labels.fundamento_legal_servicio_social,
                              items: _vm.fundamentoLegalServicioSocial,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages":
                                _vm.errors.fundamento_legal_servicio_social,
                              rules: [
                                _vm.rules.required(
                                  "fundamento_legal_servicio_social"
                                )
                              ],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.fundamento_legal_servicio_social,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "fundamento_legal_servicio_social",
                                  $$v
                                )
                              },
                              expression:
                                "form.fundamento_legal_servicio_social"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.entidad_federativa_expedicion,
                              "error-messages":
                                _vm.errors.entidad_federativa_expedicion,
                              rules: [
                                _vm.rules.required(
                                  "entidad_federativa_expedicion"
                                )
                              ],
                              disabled: _vm.loading,
                              items: _vm.entidadesFederativas,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.entidad_federativa_expedicion,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "entidad_federativa_expedicion",
                                  $$v
                                )
                              },
                              expression: "form.entidad_federativa_expedicion"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 2
                        }
                      }
                    },
                    [_vm._v("\n          Regresar\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 4
                        }
                      }
                    },
                    [_vm._v("\n          Continuar\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "4" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.institucion_procedencia,
                              "error-messages":
                                _vm.errors.institucion_procedencia,
                              rules: [
                                _vm.rules.required("institucion_procedencia")
                              ],
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.institucion_procedencia,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "institucion_procedencia",
                                  $$v
                                )
                              },
                              expression: "form.institucion_procedencia"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.tipo_estudio_antecedente,
                              items: _vm.tipoEstudioAntecedente,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              "error-messages":
                                _vm.errors.tipo_estudio_antecedente,
                              rules: [
                                _vm.rules.required("tipo_estudio_antecedente")
                              ],
                              disabled: _vm.loading,
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.tipo_estudio_antecedente,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "tipo_estudio_antecedente",
                                  $$v
                                )
                              },
                              expression: "form.tipo_estudio_antecedente"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-autocomplete", {
                            attrs: {
                              label: _vm.labels.entidad_federativa_antecedente,
                              "error-messages":
                                _vm.errors.entidad_federativa_antecedente,
                              rules: [
                                _vm.rules.required(
                                  "entidad_federativa_antecedente"
                                )
                              ],
                              disabled: _vm.loading,
                              items: _vm.entidadesFederativas,
                              "item-text": "nombre",
                              "item-value": "nombre",
                              dense: "",
                              "hide-no-data": ""
                            },
                            on: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return $event.target.blur()
                              }
                            },
                            model: {
                              value: _vm.form.entidad_federativa_antecedente,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "entidad_federativa_antecedente",
                                  $$v
                                )
                              },
                              expression: "form.entidad_federativa_antecedente"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels
                                                  .fecha_inicio_antecedente,
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .fecha_inicio_antecedente,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_inicio_antecedente",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_inicio_antecedente"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaInicioAntecedente,
                                callback: function($$v) {
                                  _vm.menuFechaInicioAntecedente = $$v
                                },
                                expression: "menuFechaInicioAntecedente"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaInicioAntecedente = false
                                  }
                                },
                                model: {
                                  value: _vm.form.fecha_inicio_antecedente,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_inicio_antecedente",
                                      $$v
                                    )
                                  },
                                  expression: "form.fecha_inicio_antecedente"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                lazy: "",
                                transition: "scale-transition",
                                "offset-y": "",
                                "full-width": "",
                                "max-width": "290px",
                                "min-width": "290px"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label:
                                                _vm.labels
                                                  .fecha_terminacion_antecedente,
                                              "error-messages":
                                                _vm.errors
                                                  .fecha_terminacion_antecedente,
                                              rules: [
                                                _vm.rules.required(
                                                  "fecha_terminacion_antecedente"
                                                )
                                              ],
                                              disabled: _vm.loading,
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .fecha_terminacion_antecedente,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "fecha_terminacion_antecedente",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.fecha_terminacion_antecedente"
                                            }
                                          },
                                          on
                                        )
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.menuFechaTerminacionAntecedente,
                                callback: function($$v) {
                                  _vm.menuFechaTerminacionAntecedente = $$v
                                },
                                expression: "menuFechaTerminacionAntecedente"
                              }
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: { locale: "es-es" },
                                on: {
                                  input: function($event) {
                                    _vm.menuFechaTerminacionAntecedente = false
                                  }
                                },
                                model: {
                                  value: _vm.form.fecha_terminacion_antecedente,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "fecha_terminacion_antecedente",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.fecha_terminacion_antecedente"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.labels.numero_cedula,
                              disabled: _vm.loading
                            },
                            model: {
                              value: _vm.form.numero_cedula,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "numero_cedula", $$v)
                              },
                              expression: "form.numero_cedula"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 3
                        }
                      }
                    },
                    [_vm._v("\n          Regresar\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 5
                        }
                      }
                    },
                    [_vm._v("\n          Continuar\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-stepper-content",
                { attrs: { step: "5" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-radio-group",
                            {
                              model: {
                                value: _vm.form.tipo_guardado,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "tipo_guardado", $$v)
                                },
                                expression: "form.tipo_guardado"
                              }
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.labels.crear_nuevo_lote,
                                  value: "nuevo"
                                }
                              }),
                              _vm._v(" "),
                              _c("v-radio", {
                                attrs: {
                                  label: _vm.labels.agregar_lote_capturado,
                                  value: "agregar"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.form.tipo_guardado === "nuevo"
                            ? _c("v-text-field", {
                                attrs: {
                                  label: _vm.labels.nombre_lote,
                                  "error-messages": _vm.errors.nombre_lote,
                                  rules: [_vm.rules.required("nombre_lote")]
                                },
                                model: {
                                  value: _vm.form.nombre_lote,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "nombre_lote", $$v)
                                  },
                                  expression: "form.nombre_lote"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.tipo_guardado === "agregar"
                            ? _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.lotesTitulosCapturado,
                                  loading: _vm.loadingBusqueda,
                                  "search-input": _vm.busquedaTitulos,
                                  "hide-no-data": "",
                                  "item-text": "nombre",
                                  "item-value": "id",
                                  label: _vm.labels.nombre_lote,
                                  "return-object": "",
                                  chips: "",
                                  dense: "",
                                  "error-messages": _vm.errors.nombre_lote,
                                  rules: [_vm.rules.required("nombre_lote")]
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    _vm.busquedaTitulos = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.busquedaTitulos = $event
                                  }
                                },
                                model: {
                                  value: _vm.form.lote_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "lote_id", $$v)
                                  },
                                  expression: "form.lote_id"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.stepper = 4
                        }
                      }
                    },
                    [_vm._v("\n          Regresar\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        type: "submit",
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        color: "primary"
                      }
                    },
                    [_vm._v("\n          Guardar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }