<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.fecha"
              v-model="facturacion.fecha"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.descripcion"
              v-model="facturacion.descripcion"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.periodo_servicio"
              v-model="facturacion.periodo_servicio"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.tipo_plan"
              v-model="facturacion.plan"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.modalidad_pago"
              v-model="facturacion.modalidad"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.cantidad_alumnos"
              v-model="facturacion.cantidad_alumnos"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.total"
              v-model="facturacion.total"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,

      facturacion: {}
    }),
  
    mounted() {
      this.getFacturacion(this.$route.params.id)
    },

    methods: {
      getFacturacion(id) {
        this.loading = true
        axios
          .get(api.path('facturaciones.single', { id: id } ))
          .then(res => {
            this.facturacion = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },
    }
  }
</script>
