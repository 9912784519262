<template>
  <div>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
      <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
        <v-autocomplete
          @keyup.enter="$event.target.blur()"
          v-model="form.campus_id"
          :items="auth.campuses"
          item-text="nombre"
          item-value="id"
          :label="labels.campus"
          dense
          hide-no-data
          :error-messages="errors.campus"
          :rules="[rules.required('campus')]"
        ></v-autocomplete>

        <v-text-field
          :label="labels.nombre_lote"
          v-model="form.name"
          :error-messages="errors.nombre_lote"
          :rules="[rules.required('nombre_lote')]"
          :disabled="loadingDialog"
        ></v-text-field>

        <v-text-field
          :label="labels.archivo_xlsx"
          v-model="form.file.name"
          :error-messages="errors.archivo_xlsx"
          :rules="[rules.required('archivo_xlsx')]"
          :disabled="loadingDialog"
          @click="seleccionarArchivoXLSX"
          append-icon="attach_file"
        ></v-text-field>
        <input
          type="file"
          accept=".xlsx"
          style="display: none"
          ref="archivo_xlsx"
          @change="archivoXLSXSeleccionado"
        >
      </v-container>

      <v-layout justify-end mt-2>
        <v-btn
          flat
          :disabled="loadingDialog"
          @click="$router.go(-1)"
          color="grey darken-2"
        >
          Cancelar
        </v-btn>

        <v-btn
          type="submit"
          :disabled="loadingDialog"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-layout>
    </v-form>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        name: null,
        file: {
          data: '',
          name: '',
          url: '',
        },
        campus_id: ''
      },

      loadingDialog: false,
    }),

    computed: {
      ...mapGetters({
        auth: 'auth/user',
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-titulos' })
      }
    },

    mounted() {
      if (this.auth.campuses.length === 1) {
        this.form.campus_id = this.auth.campuses[0].id
      }
    },

    methods: {
      seleccionarArchivoXLSX () {
        this.$refs.archivo_xlsx.click ()
      },

      archivoXLSXSeleccionado (e) {
  			const files = e.target.files
  			if(files[0] !== undefined) {
  				this.form.file.name = files[0].name
  				if(this.form.file.name.lastIndexOf('.') <= 0) {
  					return
  				}
  				const fr = new FileReader ()
  				fr.readAsDataURL(files[0])
  				fr.addEventListener('load', () => {
  					this.form.file.url = fr.result
  					this.form.file.data = files[0]
  				})
  			} else {
  				this.form.file.name = ''
  				this.form.file.data = ''
  				this.form.file.url = ''
  			}
      },
      
      submit() {
        if (this.$refs.form.validate()) {
          this.loadingDialog = true

          axios.put(api.path('titulos'), this.form)
            .then(res => {
              this.$toast.success('Títulos guardados correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.loadingDialog = false
              this.$toast.error('No se pueden enviar los títulos.')
              this.handleErrors(err.response.data.errors)
            })
            .finally(() => {
              this.loadingDialog = false
            })
        }
      }
    }
  }
</script>
