var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tabs": "", grow: "", "slider-color": "primary" },
          on: {
            change: function($event) {
              _vm.cambiarPestaña($event)
            }
          },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#perfil" } }, [
            _c("span", [_vm._v("Perfil")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#notificaciones" } }, [
            _c("span", [_vm._v("Notificaciones")])
          ]),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            [
              _c(
                "v-tab-item",
                { attrs: { value: "perfil" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.name,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarPerfil("nombre")
                                          }
                                        },
                                        model: {
                                          value: _vm.user.name,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "name", $$v)
                                          },
                                          expression: "user.name"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.cambiar_password,
                                          value: "••••••••",
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarPerfil(
                                              "contraseña"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.email,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.user.email,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "email", $$v)
                                          },
                                          expression: "user.email"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-autocomplete", {
                                        attrs: {
                                          label: _vm.labels.campuses_asignados,
                                          items: _vm.user.campuses,
                                          "item-text": "nombre",
                                          "item-value": "id",
                                          attach: "",
                                          chips: "",
                                          multiple: "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.user.campuses,
                                          callback: function($$v) {
                                            _vm.$set(_vm.user, "campuses", $$v)
                                          },
                                          expression: "user.campuses"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.user.role === "Firmante"
                                        ? _c("v-autocomplete", {
                                            attrs: {
                                              label:
                                                _vm.labels
                                                  .responsables_asignados,
                                              items: _vm.user.responsables,
                                              "item-text": "nombre",
                                              "item-value": "id",
                                              attach: "",
                                              chips: "",
                                              multiple: "",
                                              outline: "",
                                              readonly: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "selection",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "grey--text"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.nombre
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " \n                        "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.primer_apellido
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            " \n                        "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.segundo_apellido
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              4080581354
                                            ),
                                            model: {
                                              value: _vm.user.responsables,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "responsables",
                                                  $$v
                                                )
                                              },
                                              expression: "user.responsables"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "notificaciones" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _vm.user.notificaciones &&
                                  _vm.user.notificaciones.length > 0
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                _vm._l(
                                                  _vm.user.notificaciones,
                                                  function(notificacion, i) {
                                                    return _c("v-switch", {
                                                      key: i,
                                                      attrs: {
                                                        label:
                                                          notificacion.nombre,
                                                        "input-value": "true"
                                                      }
                                                    })
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }