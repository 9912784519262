<template>
  <div>
    <v-container v-if="this.auth.membresia.tipo_plan.id != 3">
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          lazy-src="/img/clip/clip-upgrade.png"
          src="/img/clip/clip-upgrade.png"
          alt="Esta es una función Premium"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          Esta es una función Premium.
        </span>
        <v-alert
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Actualiza al plan Premium
          <v-btn
            small
            color="primary"
            flat
            :to="{ name: 'configuracion-plan' }"
          >Ver planes</v-btn
        >
        </v-alert>
      </div>
    </v-container>
    
    <div v-else>
      <v-toolbar
        class="elevation-1 toolbar-fix"
        flat
        dense
        color="white"
      >
        <v-btn
          v-if="selected.length === 0"
          icon
          @click="toggleAll"
        >
          <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
        </v-btn>

        <v-btn
          v-if="selected.length > 0 && selected.length < plantillas.data.length"
          icon
          @click="toggleAll"
        >
          <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
        </v-btn>

        <v-btn
          v-if="selected.length > 0 && selected.length === plantillas.data.length"
          icon
          @click="toggleAll"
        >
          <v-icon color="grey darken-1">check_box</v-icon>
        </v-btn>

        <v-btn
          v-if="selected.length === 0"
          icon
          @click="getPlantillasTitulos"
        >
          <v-icon color="grey darken-1">refresh</v-icon>
        </v-btn>

        <v-btn
          v-if="selected.length > 0"
          icon
          @click="eliminarPlantillasTitulos"
        >
          <v-icon color="grey darken-1">delete</v-icon>
        </v-btn>

        <v-btn
          v-if="selected.length > 0"
          icon
          @click="copiarTexto"
        >
          <v-icon color="grey darken-1">content_copy</v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <span color="grey darken-1">{{ plantillas.total > 1 ? 1 : 0 }}-{{ plantillas.total ? plantillas.total : 0 }} de {{ plantillas.total ? plantillas.total : 0 }}</span>

        <v-btn
          icon
          @click="settingsDialog = true"
        >
          <v-icon color="grey darken-1">settings</v-icon>
        </v-btn>

      </v-toolbar>

      <v-container v-if="loading">
        <v-layout justify-center row>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
      </v-container>

      <v-data-table
        v-else-if="plantillas.data && plantillas.data.length > 0"
        v-model="selected"
        :headers="headers"
        :items="plantillas.data"
        :pagination.sync="pagination"
        :no-data-text="noDataText"
        :no-results-text="noResultsText"
        :loading="loading"
        item-key="id"
        hide-actions
        >
        <template slot="headers" slot-scope="props">
          <tr>
            <th></th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
                'text-xs-left',
                header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
              ]"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon small>arrow_upward</v-icon>
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <v-hover>
          <tr
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 2 : 0}`"
          >
            <td @click.stop="props.selected = !props.selected">
              <v-checkbox
                :input-value="props.selected"
                primary
                hide-details
              ></v-checkbox>
            </td>
            <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre }}</td>
            <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-btn
                color="primary"
                outline
                small
                @click.stop="descargarEstiloPlantillaTitulo(props.item)"
              >
                Descargar Estilo
              </v-btn>
            </td>
            <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
              <v-btn
                color="primary"
                outline
                small
                @click.stop="descargarFondoPlantillaTitulo(props.item)"
              >
                Descargar Fondo
              </v-btn>
            </td>
            <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.autorizacion_reconocimiento_nombre }}</td>
            <div v-if="hover" class="plantilla-titulos-fix-1">
              <div class="justify-center layout grey lighten-3 plantilla-titulos-fix-2">
                <v-tooltip bottom>
                  <v-btn slot="activator" icon ripple @click.stop="eliminarPlantillaTitulos(props.item)">
                    <v-icon color="grey darken-1">delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </div>
            </div>
          </tr>
          </v-hover>
        </template>
      </v-data-table>

      <v-container v-else>
        <div class="layout column align-center text-center">
          <v-img
            class="mb-3"
            :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
            :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
            :alt="busqueda ? noResultsText : noDataText"
            width="380"
          ></v-img>
          <span class="mb-3 font-weight-light grey--text text--darken-4 title">
            {{ busqueda ? noResultsText : noDataText }}
          </span>
          <v-alert
            v-if="busqueda"
            :value="true"
            color="primary"
            icon="info"
            outline
          >
            Intenta usar otras palabras clave o quitar los filtros de búsqueda
          </v-alert>
        </div>
      </v-container>
    </div>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay plantillas de títulos asignadas",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Nombre", value: "nombre", visibility: true },
      { text: "Estilo", value: "estilo", visibility: true },
      { text: "Fondo", value: "fondo" , visibility: true },
      { text: "Autorización o reconocimiento del RVOE", value: "autorizacion_reconocimiento_nombre" , visibility: true },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: null,
      sortBy: 'name'
    },

    plantillas: [],
  }),

  computed: {
    ...mapGetters({
			auth: 'auth/user',
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      this.$router.push({ name: 'campus' })
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val)
      } else {
        this.$router.replace('')
      }
      this.getPlantillasTitulos()
    }
  },

  methods: {
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.plantillas.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getPlantillasTitulos() {
      this.loading = true
      axios
        .get(api.path('campus.plantillas', { id: this.$route.params.id } ))
        .then(res => {
            this.plantillas = res.data
            this.pagination.rowsPerPage = res.data.total
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    eliminarPlantillaTitulos(val) {
      this.$confirm("¿Desea eliminar esta plantilla de títulos?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("campus.plantillas", { id: this.$route.params.id }), {
                params: {
                  ids: val.id
                }
              })
              .then(res => {
                this.$toast.success("Plantilla de títulos eliminada correctamente.")
                this.getPlantillasTitulos(this.$route.params.id)
              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarPlantillasTitulos() {
      this.$confirm("¿Desea eliminar las plantillas de títulos seleccionadas?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("campus.plantillas", { id: this.$route.params.id }), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("PlantillasTitulos eliminadas correctamente.")
                this.selected = []
                this.getPlantillasTitulos()
              })
              .catch(err => {
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.nombre + '\t'
        data = data + val.estilo + '\t'
        data = data + val.fondo + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    descargarEstiloPlantillaTitulo(val) {
      this.loadingDialog = true
      axios
        .get(api.path('plantillas_titulos.estilo', { id: val.id }), { responseType: 'blob' })
        .then(res => {
          if (res.data.type === 'text/css') {
            let blob = new Blob([res.data], { type: 'text/css' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)

            const contentDisposition = res.headers['content-disposition'];
            let fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/["']/g, "");

            link.download = fileName
            link.click()
          } else if (res.data.error) {
            this.$toast.error(res.data.error)
          } else {
            this.$toast.error('Vuelva a intentarlo mas tarde.')
          }
        })
        .catch(err => {
          this.loadingDialog = false
          this.$toast.error('Vuelva a intentarlo mas tarde.')
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    descargarFondoPlantillaTitulo(val) {
      this.loadingDialog = true
      axios
        .get(api.path('plantillas_titulos.fondo', { id: val.id }), { responseType: 'blob' })
        .then(res => {
          if (res.data.type === 'image/jpeg') {
            let blob = new Blob([res.data], { type: 'image/jpeg' })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)

            const contentDisposition = res.headers['content-disposition'];
            let fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/["']/g, "");

            link.download = fileName
            link.click()
          } else if (res.data.error) {
            this.$toast.error(res.data.error)
          } else {
            this.$toast.error('Vuelva a intentarlo mas tarde.')
          }
        })
        .catch(err => {
          this.loadingDialog = false
          this.$toast.error('Vuelva a intentarlo mas tarde.')
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loadingDialog = false
        })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getPlantillasTitulos()
      localStorage.setItem('plantilla-titulos.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('plantilla-titulos.headers', JSON.stringify(this.headers))
    },
  },

  mounted() {
    if (localStorage.hasOwnProperty('plantilla-titulos.pagination')) {
      this.pagination = JSON.parse(localStorage.getItem('plantilla-titulos.pagination'))
    }

    if (localStorage.hasOwnProperty('plantilla-titulos.headers')) {
      this.headers = JSON.parse(localStorage.getItem('plantilla-titulos.headers'))
    }

    let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

    if (tab[0] === '#buscar') {
      let busqueda = decodeURI( tab[1] )
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
    } else {
      if (this.busqueda) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      }
    }

    this.getPlantillasTitulos()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .plantilla-titulos-fix-1 {
    position: relative !important;
	}

  .plantilla-titulos-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>

