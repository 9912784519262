var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-footer",
    { attrs: { app: "", color: "white", height: "auto" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", row: "", wrap: "" } },
        [
          _c(
            "v-btn",
            {
              attrs: { flat: "", round: "", small: "", to: { name: "ayuda" } }
            },
            [_vm._v("Ayuda")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                round: "",
                small: "",
                to: { name: "politicas" }
              }
            },
            [_vm._v("Privacidad")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", round: "", small: "", to: { name: "aliados" } }
            },
            [_vm._v("Aliados")]
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            { attrs: { "py-2": "", "text-xs-center": "", xs12: "" } },
            [
              _c(
                "v-btn",
                { staticClass: "text-none", attrs: { flat: "", small: "" } },
                [
                  _vm._v(
                    "©" +
                      _vm._s(new Date().getFullYear()) +
                      " " +
                      _vm._s(_vm.siteName) +
                      " v" +
                      _vm._s(_vm.version)
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }