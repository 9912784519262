var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit($event)
            }
          },
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-container",
            { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.auth.campuses,
                  "item-text": "nombre",
                  "item-value": "id",
                  label: _vm.labels.campus,
                  dense: "",
                  "hide-no-data": "",
                  "error-messages": _vm.errors.campus,
                  rules: [_vm.rules.required("campus")]
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campus_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campus_id", $$v)
                  },
                  expression: "form.campus_id"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  label: _vm.labels.nombre_lote,
                  "error-messages": _vm.errors.nombre_lote,
                  rules: [_vm.rules.required("nombre_lote")],
                  disabled: _vm.loadingDialog
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              }),
              _vm._v(" "),
              _c("v-text-field", {
                attrs: {
                  label: _vm.labels.archivo_xlsx,
                  "error-messages": _vm.errors.archivo_xlsx,
                  rules: [_vm.rules.required("archivo_xlsx")],
                  disabled: _vm.loadingDialog,
                  "append-icon": "attach_file"
                },
                on: { click: _vm.seleccionarArchivoXLSX },
                model: {
                  value: _vm.form.file.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form.file, "name", $$v)
                  },
                  expression: "form.file.name"
                }
              }),
              _vm._v(" "),
              _c("input", {
                ref: "archivo_xlsx",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: ".xlsx" },
                on: { change: _vm.archivoXLSXSeleccionado }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { "justify-end": "", "mt-2": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    flat: "",
                    disabled: _vm.loadingDialog,
                    color: "grey darken-2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("\n        Cancelar\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    type: "submit",
                    disabled: _vm.loadingDialog,
                    color: "primary"
                  }
                },
                [_vm._v("\n        Guardar\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("Espera un momento\n        "),
                  _c("v-progress-linear", {
                    staticClass: "mb-0",
                    attrs: { indeterminate: "", color: "white" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }