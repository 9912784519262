<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-stepper non-linear alt-labels v-model="stepper">
      <v-stepper-header>
        <v-stepper-step
          editable
          step="1"
        >
          Título
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          editable
          step="2"
          :rules="[() => carreraEstado]"
        >
          Carrera
          <small>Complete los datos</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          editable
          step="3"
          :rules="[() => profesionistaEstado]"
        >
          Profesionista
          <small>Complete los datos</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          editable
          step="4"
          :rules="[() => expedicionEstado]"
        >
          Expedición
          <small>Complete los datos</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          editable
          step="5"
          :rules="[() => antecedenteEstado]"
        >
          Antecedente
          <small>Complete los datos</small>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card flat>
            <v-card-text>
              <v-text-field
                :label="labels.estado"
                v-model="titulo.estado_documento.nombre"
                :disabled="loading"
                readonly
              ></v-text-field>

              <v-textarea
                :label="labels.resultado"
                v-model="titulo.resultado"
                :disabled="loading"
                readonly
                :rows="resultadoRows"
              ></v-textarea>
            </v-card-text>
          </v-card>
          
            <v-btn
              color="primary"
              @click="stepper = 2"
            >
              Continuar
            </v-btn>

        </v-stepper-content>
        
        <v-stepper-content step="2">
          <v-card flat>
            <v-card-text>
              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.campus"
                v-model="titulo.nombre_campus"
                :items="campuses"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.nombre_campus"
                :rules="[rules.required('campus')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.carrera"
                v-model="titulo.nombre_carrera"
                :items="carreras"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.nombre_carrera"
                :rules="[rules.required('carrera')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-menu
                v-model="menuFechaInicioCarrera"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_inicio_carrera"
                    :label="labels.fecha_inicio_carrera"
                    :error-messages="errors.fecha_inicio_carrera"
                    :rules="[rules.required('fecha_inicio_carrera')]"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_inicio_carrera"
                  @input="menuFechaInicioCarrera = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="menuFechaTerminacionCarrera"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_terminacion_carrera"
                    :label="labels.fecha_terminacion_carrera"
                    :error-messages="errors.fecha_terminacion_carrera"
                    :rules="[rules.required('fecha_terminacion_carrera')]"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_terminacion_carrera"
                  @input="menuFechaTerminacionCarrera = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.autorizacion_reconocimiento"
                v-model="titulo.autorizacion_reconocimiento"
                :items="autorizacionesReconocimientos"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.autorizacion_reconocimiento"
                :rules="[rules.required('autorizacion_reconocimiento')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-text-field
                :label="labels.numero_rvoe"
                v-model="titulo.numero_rvoe"
                :error-messages="errors.numero_rvoe"
                :rules="[rules.required('numero_rvoe')]"
                :disabled="loading"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-btn
            flat
            @click="stepper = 1"
          >
            Regresar
          </v-btn>

          <v-btn
            color="primary"
            @click="stepper = 3"
          >
            Continuar
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card flat>
            <v-card-text>
              <v-text-field
                :label="labels.curp_profesionista"
                v-model="titulo.curp_profesionista"
                :error-messages="errors.curp_profesionista"
                :rules="[rules.required('curp_profesionista')]"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                :label="labels.matricula_profesionista"
                v-model="titulo.matricula_profesionista"
                :error-messages="errors.matricula_profesionista"
                :rules="[rules.required('matricula_profesionista')]"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                :label="labels.nombre_profesionista"
                v-model="titulo.nombre_profesionista"
                :error-messages="errors.nombre_profesionista"
                :rules="[rules.required('nombre_profesionista')]"
                :disabled="loading"
              ></v-text-field>
              
              <v-text-field
                :label="labels.primer_apellido_profesionista"
                v-model="titulo.primer_apellido_profesionista"
                :error-messages="errors.primer_apellido_profesionista"
                :rules="[rules.required('primer_apellido_profesionista')]"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                :label="labels.segundo_apellido_profesionista"
                v-model="titulo.segundo_apellido_profesionista"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                :label="labels.correo_electronico_profesionista"
                v-model="titulo.correo_electronico_profesionista"
                :error-messages="errors.correo_electronico_profesionista"
                :rules="[rules.required('correo_electronico_profesionista')]"
                :disabled="loading"
              ></v-text-field>

              <v-text-field
                :label="labels.numero_telefono_profesionista"
                v-model="titulo.numero_telefono_profesionista"
                :error-messages="errors.numero_telefono_profesionista"
                :rules="[rules.required('numero_telefono_profesionista')]"
                :disabled="loading"
              ></v-text-field>
            </v-card-text>
          </v-card>

          <v-btn
            flat
            @click="stepper = 2"
          >
            Regresar
          </v-btn>

          <v-btn
            color="primary"
            @click="stepper = 4"
          >
            Continuar
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card flat>
            <v-card-text>
              <v-menu
                v-model="menuFechaExpedicion"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_de_expedicion"
                    :label="labels.fecha_de_expedicion"
                    :error-messages="errors.fecha_de_expedicion"
                    :rules="[rules.required('fecha_de_expedicion')]"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_de_expedicion"
                  @input="menuFechaExpedicion = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.modalidad_de_titulacion"
                v-model="titulo.modalidad_de_titulacion"
                :items="modalidadesTitulacion"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.modalidad_de_titulacion"
                :rules="[rules.required('modalidad_de_titulacion')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-menu
                v-model="menuFechaExamenProfesional"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                    :label="labels.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                    :error-messages="errors.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                    :rules="[rules.required('fecha_examen_profesional_o_fecha_exencion_examen_profesional')]"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_examen_profesional_o_fecha_exencion_examen_profesional"
                  @input="menuFechaExamenProfesional = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-select
                :label="labels.cumplio_servicio_social"
                v-model="titulo.cumplio_servicio_social"
                :items="cumplioServicioSocial"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.cumplio_servicio_social"
                :rules="[rules.required('cumplio_servicio_social')]"
                :disabled="loading"
                dense
              ></v-select>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.fundamento_legal_servicio_social"
                v-model="titulo.fundamento_legal_servicio_social"
                :items="fundamentoLegalServicioSocial"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.fundamento_legal_servicio_social"
                :rules="[rules.required('fundamento_legal_servicio_social')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.entidad_federativa_expedicion"
                v-model="titulo.entidad_federativa_expedicion"
                :error-messages="errors.entidad_federativa_expedicion"
                :rules="[rules.required('entidad_federativa_expedicion')]"
                :disabled="loading"
                :items="entidadesFederativas"
                item-text="nombre"
                item-value="nombre"
                dense
                hide-no-data
              ></v-autocomplete>
            </v-card-text>
          </v-card>

          <v-btn
            flat
            @click="stepper = 3"
          >
            Regresar
          </v-btn>

          <v-btn
            color="primary"
            @click="stepper = 5"
          >
            Continuar
          </v-btn>

        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card flat>
            <v-card-text>
              <v-text-field
                :label="labels.institucion_procedencia"
                v-model="titulo.institucion_procedencia"
                :error-messages="errors.institucion_procedencia"
                :rules="[rules.required('institucion_procedencia')]"
                :disabled="loading"
              ></v-text-field>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.tipo_estudio_antecedente"
                v-model="titulo.tipo_estudio_antecedente"
                :items="tipoEstudioAntecedente"
                item-text="nombre"
                item-value="nombre"
                :error-messages="errors.tipo_estudio_antecedente"
                :rules="[rules.required('tipo_estudio_antecedente')]"
                :disabled="loading"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-autocomplete
                @keyup.enter="$event.target.blur()"
                :label="labels.entidad_federativa_antecedente"
                v-model="titulo.entidad_federativa_antecedente"
                :error-messages="errors.entidad_federativa_antecedente"
                :rules="[rules.required('entidad_federativa_antecedente')]"
                :disabled="loading"
                :items="entidadesFederativas"
                item-text="nombre"
                item-value="nombre"
                dense
                hide-no-data
              ></v-autocomplete>

              <v-menu
                v-model="menuFechaInicioAntecedente"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_inicio_antecedente"
                    :label="labels.fecha_inicio_antecedente"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_inicio_antecedente"
                  @input="menuFechaInicioAntecedente = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-menu
                v-model="menuFechaTerminacionAntecedente"
                :close-on-content-click="false"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="titulo.fecha_terminacion_antecedente"
                    :label="labels.fecha_terminacion_antecedente"
                    :error-messages="errors.fecha_terminacion_antecedente"
                    :rules="[rules.required('fecha_terminacion_antecedente')]"
                    :disabled="loading"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="titulo.fecha_terminacion_antecedente"
                  @input="menuFechaTerminacionAntecedente = false"
                  locale="es-es"
                ></v-date-picker>
              </v-menu>

              <v-text-field
                :label="labels.numero_cedula"
                v-model="titulo.numero_cedula"
                :disabled="loading"
              ></v-text-field>
            </v-card-text>
          </v-card>
          
          <v-btn
            flat
            @click="stepper = 4"
          >
            Regresar
          </v-btn>

          <v-btn
            type="submit"
            :loading="loading"
            :disabled="loading"
            color="primary"
          >
            Guardar
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,

      estados: [
        'Por titular',
        'Registrado',
        'Rechazado',
        'Cancelado',
        'Autenticado',
      ],

      titulo: {
        estado_documento: {},
      },

      campuses: [],
      carreras: [],
      autorizacionesReconocimientos: [],
      modalidadesTitulacion: [],
      cumplioServicioSocial: [
        { id: '0', nombre: 'NO' },
        { id: '1', nombre: 'SI' }
      ],
      fundamentoLegalServicioSocial: [],
      entidadesFederativas: [],
      tipoEstudioAntecedente: [],

      menuFechaInicioCarrera: false,
      menuFechaTerminacionCarrera: false,
      menuFechaExpedicion: false,
      menuFechaExamenProfesional: false,
      menuFechaExencionExamenProfesional: false,
      menuFechaInicioAntecedente: false,
      menuFechaTerminacionAntecedente: false,

      stepper: 0,
      carreraEstado: true,
      profesionistaEstado: true,
      expedicionEstado: true,
      antecedenteEstado: true,

      loteTitulos: {
        responsables: {},
      },

      resultadoRows: 1,
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-titulos' })
      }
    },

    mounted() {
      this.getTitulo()
      this.getLoteTitulos()
      this.getCampuses()
      this.getCarreras()
      this.getCatalogoAutorizacionReconocimiento()
      this.getCatalogoModalidadTitulacion()
      this.getCatalogoFundamentoLegalServicioSocial()
      this.getCatalogoEntidadFederativa()
      this.getCatalogoTipoEstudioAntecedente()
    },

    methods: {
      getLoteTitulos() {
        axios
          .get(api.path("titulos.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteTitulos = res.data
            this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteTitulos.estado_lote })
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      getTitulo() {
        axios
          .get(api.path('titulos.titulos.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ))
          .then(res => {
            this.titulo = res.data
            this.titulo.resultado = this.titulo.resultado.split(',').join('\n')
            this.resultadoRows = this.titulo.resultado.split('\n').length
            if (this.titulo.cumplio_servicio_social === '0') {
              this.titulo.cumplio_servicio_social = 'NO'
            } else if (this.titulo.cumplio_servicio_social === '1') {
              this.titulo.cumplio_servicio_social = 'SI'
            }
            this.validateInputs()
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCarreras() {
        axios
          .get(api.path('carreras'))
          .then(res => {
            this.carreras = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoAutorizacionReconocimiento() {
        axios
          .get(api.path('catalogos.autorizacion_reconocimiento'))
          .then(res => {
            this.autorizacionesReconocimientos = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoModalidadTitulacion() {
        axios
          .get(api.path('catalogos.modalidad_titulacion'))
          .then(res => {
            this.modalidadesTitulacion = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoFundamentoLegalServicioSocial() {
        axios
          .get(api.path('catalogos.fundamento_legal_servicio_social'))
          .then(res => {
            this.fundamentoLegalServicioSocial = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoEntidadFederativa() {
        axios
          .get(api.path('catalogos.entidad_federativa'))
          .then(res => {
            this.entidadesFederativas = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoTipoEstudioAntecedente() {
        axios
          .get(api.path('catalogos.tipo_estudio_antecedente'))
          .then(res => {
            this.tipoEstudioAntecedente = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      validateInputs() {
        if (
          this.titulo.nombre_campus &&
          this.titulo.nombre_carrera &&
          this.titulo.fecha_inicio_carrera &&
          this.titulo.fecha_terminacion_carrera &&
          this.titulo.autorizacion_reconocimiento &&
          this.titulo.numero_rvoe
        ) {
          this.carreraEstado = true
        } else {
          this.carreraEstado = false
        }
        if (
          this.titulo.curp_profesionista &&
          this.titulo.matricula_profesionista &&
          this.titulo.nombre_profesionista &&
          this.titulo.primer_apellido_profesionista &&
          this.titulo.correo_electronico_profesionista &&
          this.titulo.numero_telefono_profesionista
        ) {
          this.profesionistaEstado = true
        } else {
          this.profesionistaEstado = false
        }
        if (
          this.titulo.fecha_de_expedicion &&
          this.titulo.modalidad_de_titulacion &&
          this.titulo.cumplio_servicio_social &&
          this.titulo.fundamento_legal_servicio_social &&
          this.titulo.entidad_federativa_expedicion 
        ) {
          this.expedicionEstado = true
        } else {
          this.expedicionEstado = false
        }
        if (
          this.titulo.institucion_procedencia &&
          this.titulo.tipo_estudio_antecedente &&
          this.titulo.entidad_federativa_antecedente &&
          this.titulo.fecha_terminacion_antecedente
        ) {
          this.antecedenteEstado = true
        } else {
          this.antecedenteEstado = false
        }
      },

      submit() {
        this.validateInputs()
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('titulos.titulos.single', { id: this.$route.params.id, id2: this.$route.params.id2 }), this.titulo)
            .then(res => {
              this.$toast.success('Título actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        } else {
            this.$toast.warning('Completa los datos faltantes.')
        }
      },
    }
  }
</script>
