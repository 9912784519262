<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'campus'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campus"
        v-model="form.campus.id"
        :items="campuses"
        item-text="nombre"
        item-value="id"
        :error-messages="errors.campus"
        :rules="[rules.required('campus')]"
        :disabled="loading"
        dense
        hide-no-data
      ></v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      
      form: {},
      
      campuses: [],
      
      campo: null
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-titulos' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'nombre' ||
          param === 'campus'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'campus') {
        this.getCampuses()
      }

      this.getLoteTitulos(this.$route.params.id)
    },

    methods: {
      getLoteTitulos(id) {
        axios
          .get(api.path("titulos.single", { id: id }), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('titulos.single', { id: this.$route.params.id }), this.form)
            .then(res => {
              this.$toast.success('Lote de títulos de actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
