<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-img
        v-if="form.firma_autografa"
        class="mb-4"
        :src="form.firma_autografa"
        :lazy-src="form.firma_autografa"
        height="80"
        contain
        aspect-ratio="1"
      ></v-img>

      <v-text-field
        v-if="!this.campo || this.campo === 'firma_autografa'"
        :label="labels.archivo_firma_autografa"
        v-model="file.name"
        :error-messages="errors.archivo_firma_autografa"
        :rules="[rules.required('archivo_firma_autografa')]"
        :disabled="loadingDialog"
        @click="seleccionarImagenFirmaAutografa"
        append-icon="attach_file"
      ></v-text-field>
      <input
        type="file"
        accept=".png"
        style="display: none"
        ref="archivo_firma_autografa"
        @change="imagenFirmaAutografaSeleccionada"
      />

      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'primer_apellido'"
        :label="labels.primer_apellido"
        v-model="form.primer_apellido"
        :error-messages="errors.primer_apellido"
        :rules="[rules.required('primer_apellido')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'segundo_apellido'"
        :label="labels.segundo_apellido"
        v-model="form.segundo_apellido"
        :error-messages="errors.segundo_apellido"
        :rules="[rules.required('segundo_apellido')]"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'cargo'"
        @keyup.enter="$event.target.blur()"
        :label="labels.cargo"
        v-model="form.cargo"
        :error-messages="errors.cargo"
        :rules="[rules.required('cargo')]"
        :disabled="loading"
        :items="cargos"
        item-text="nombre"
        item-value="id"
        return-object
        dense
        hide-no-data
      ></v-autocomplete>

      <v-text-field
        v-if="!this.campo || this.campo === 'curp'"
        :label="labels.curp"
        v-model="form.curp"
        :error-messages="errors.curp"
        :rules="[rules.required('curp')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'abreviatura_titulo'"
        :label="labels.abreviatura_titulo"
        v-model="form.abreviatura_titulo"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'campuses_titulos'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campuses_a_firmar_titulos"
        v-model="form.campuses_titulos"
        :disabled="loading"
        :items="campuses"
        item-text="nombre"
        item-value="id"
        return-object
        attach
        chips
        multiple
        deletable-chips
        dense
        hide-no-data
      ></v-autocomplete>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'campuses_certificados'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campuses_a_firmar_certificados"
        v-model="form.campuses_certificados"
        :disabled="loading"
        :items="campuses"
        item-text="nombre"
        item-value="id"
        return-object
        attach
        chips
        multiple
        deletable-chips
        dense
        hide-no-data
      ></v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
        >Cancelar</v-btn
      >

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading && !this.form.firma_autografa"
        color="primary"
        >Guardar</v-btn
      >
    </v-layout>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
  mixins: [Form],

  data: () => ({
    form: {
      cargo: {}
    },
    file: {
      data: "",
      name: "",
      url: ""
    },
    campuses: [],
    cargos: [],
    campo: null,
    loadingDialog: false
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode"
    })
  },

  watch: {
    live_mode(val) {
      this.$router.push({ name: "responsables" });
    }
  },

  mounted() {
    if (this.$route.params.campo) {
      this.campo = this.$route.params.campo;
    } else {
      let tab = this.$route.fullPath.replace(this.$route.path, "").split("/");
      let param = decodeURI(tab[0].substr(1));
      if (
        param === "firma_autografa" ||
        param === "nombre" ||
        param === "primer_apellido" ||
        param === "segundo_apellido" ||
        param === "cargo" ||
        param === "curp" ||
        param === "abreviatura_titulo" ||
        param === "campuses_titulos" ||
        param === "campuses_certificados"
      ) {
        this.campo = param;
      }
    }

    if (this.campo) {
      this.$router.replace("#" + this.campo);
    }

    if (!this.campo || this.campo === "cargo") {
      this.getCatalogoCargo();
    }

    if (!this.campo || this.campo === "campuses_titulos" || this.campo === "campuses_certificados") {
      this.getCampuses();
    }

    this.getResponsable(this.$route.params.id);
  },

  methods: {
    getResponsable(id) {
      this.loading = true;
      axios
        .get(api.path("responsables.single", { id: id }), {
          params: {
            campo: this.campo
          }
        })
        .then(res => {
          this.form = res.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false;
        });
    },

    getCatalogoCargo() {
      axios
        .get(api.path("catalogos.cargo"))
        .then(res => {
          this.cargos = res.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {});
    },

    getCampuses() {
      axios
        .get(api.path("campus"))
        .then(res => {
          this.campuses = res.data.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {});
    },

    seleccionarImagenFirmaAutografa() {
      this.$refs.archivo_firma_autografa.click();
    },

    imagenFirmaAutografaSeleccionada(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.file.name = files[0].name;
        if (this.file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.file.url = fr.result;
          this.file.data = files[0];
          if (this.file.data.type === "image/png") {
            this.form.firma_autografa = this.file.url;
          } else {
            this.form.firma_autografa = null;
          }
        });
      } else {
        this.file.name = "";
        this.file.data = "";
        this.file.url = "";
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let pathResponsable = "responsables.single"

        if (this.campo === "campuses_titulos" || this.campo === "campuses_certificados") {
          pathResponsable = "responsables.campus"
        }

        if (this.campo === "cargo") {
          this.form.cargo = {
            'id': this.form.cargo.id
          }
        }

        if (this.campo === "campuses_titulos") {
          let data = []
          this.form.campuses_titulos.forEach(function(val) {
            data.push({
              'id': val.id
            })
          })
          this.form.campuses_titulos = data
        }

        if (this.campo === "campuses_certificados") {
          let data = []
          this.form.campuses_certificados.forEach(function(val) {
            data.push({
              'id': val.id
            })
          })
          this.form.campuses_certificados = data
        }

        axios
          .put(api.path(pathResponsable, { id: this.$route.params.id }), this.form)
          .then(res => {
            this.$toast.success("Responsable actualizado correctamente.");
            this.$emit("success", res.data);
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
