var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$vuetify.breakpoint.xsOnly
    ? _c(
        "div",
        [_c("configuracion-editar-form", { on: { success: _vm.success } })],
        1
      )
    : _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-xl": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", "justify-center": "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { lg6: "", sm9: "", xs12: "" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade", mode: "out-in" } },
                    [
                      _c(
                        "div",
                        [
                          _c("configuracion-editar-form", {
                            on: { success: _vm.success }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }