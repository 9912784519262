var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline",
    _vm._l(_vm.actualizaciones, function(actualizacion, i) {
      return _c(
        "v-timeline-item",
        {
          key: i,
          attrs: { color: actualizacion.color, small: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "opposite",
                fn: function() {
                  return [
                    _c("span", {
                      class: "subheading " + actualizacion.color + "--text",
                      domProps: { textContent: _vm._s(actualizacion.date) }
                    })
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _vm._v(" "),
          _c("div", { staticClass: "py-3" }, [
            _c(
              "h2",
              {
                class:
                  "headline font-weight-light mb-3 " +
                  actualizacion.color +
                  "--text"
              },
              [
                _vm._v(
                  "\n        v" + _vm._s(actualizacion.version) + "\n        "
                ),
                _vm.version === actualizacion.version
                  ? _c(
                      "v-chip",
                      {
                        attrs: {
                          label: "",
                          small: "",
                          color: "indigo",
                          "text-color": "white"
                        }
                      },
                      [_vm._v("Actual")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", [
              _vm._v("\n        " + _vm._s(actualizacion.message) + "\n      ")
            ])
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }