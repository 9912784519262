var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "drawer-fix",
          attrs: {
            fixed: "",
            app: "",
            permanent: _vm.$vuetify.breakpoint.mdAndUp,
            light: "",
            "mini-variant": _vm.$vuetify.breakpoint.mdAndUp && _vm.mini,
            clipped: _vm.$vuetify.breakpoint.mdAndUp,
            value: _vm.mini
          },
          on: {
            "update:miniVariant": function($event) {
              return _vm.$set(
                _vm.$vuetify.breakpoint,
                "mdAndUp && mini",
                $event
              )
            },
            "update:mini-variant": function($event) {
              return _vm.$set(
                _vm.$vuetify.breakpoint,
                "mdAndUp && mini",
                $event
              )
            }
          }
        },
        [
          _c(
            "v-toolbar",
            { staticClass: "transparent", attrs: { flat: "" } },
            [
              _c(
                "v-list",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-list-tile",
                    { attrs: { avatar: "" } },
                    [
                      _vm.$vuetify.breakpoint.mdAndUp && _vm.mini
                        ? _c(
                            "v-list-tile-avatar",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0",
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.navToggle($event)
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("chevron_right")])],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "v-list-tile-avatar",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "mx-0 headline font-weight-light white--text",
                                  attrs: {
                                    icon: "",
                                    color: "blue lighten-4",
                                    to: { name: "perfil" }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "blue accent-4" } },
                                    [_vm._v("person")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [_c("v-list-tile-title", [_vm._v(_vm._s(_vm.name))])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-action",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              nativeOn: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.navToggle($event)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("chevron_left")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.items, function(group, index) {
            return _c(
              "v-list",
              { key: index, staticClass: "py-0", attrs: { dense: "" } },
              _vm._l(group, function(item) {
                return _c("div", { key: item.title }, [
                  _vm.auth && item.roles.includes(_vm.auth.role)
                    ? _c(
                        "div",
                        [
                          !!item.items
                            ? _c(
                                "v-list-group",
                                {
                                  attrs: {
                                    "prepend-icon": item.icon,
                                    "no-action": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile",
                                    {
                                      attrs: { slot: "activator" },
                                      slot: "activator"
                                    },
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v(_vm._s(item.title))
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._l(item.items, function(subItem) {
                                    return _c(
                                      "v-list-tile",
                                      {
                                        key: subItem.title,
                                        attrs: {
                                          to: subItem.to,
                                          ripple: "",
                                          exact:
                                            subItem.exact !== undefined
                                              ? subItem.exact
                                              : true
                                        },
                                        on: {
                                          click: function($event) {
                                            subItem.action
                                              ? subItem.action()
                                              : false
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(_vm._s(subItem.title))
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-list-tile-action",
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { medium: "" } },
                                              [_vm._v(_vm._s(subItem.icon))]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _c(
                                "v-list-tile",
                                {
                                  attrs: {
                                    href: "javascript:void(0)",
                                    to: item.to,
                                    ripple: "",
                                    exact:
                                      item.exact !== undefined
                                        ? item.exact
                                        : true
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      item.action ? item.action() : false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c("v-icon", { attrs: { medium: "" } }, [
                                        _vm._v(_vm._s(item.icon))
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c("v-list-tile-title", [
                                        _vm._v(_vm._s(item.title))
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.mini &&
                                  item.premium &&
                                  _vm.auth.membresia.tipo_plan.id !== 3
                                    ? _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                outline: "",
                                                small: "",
                                                color: "primary"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [_vm._v("Premium")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                          _vm._v(" "),
                          item.divider ? _c("v-divider") : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              }),
              0
            )
          }),
          _vm._v(" "),
          _c(
            "v-list",
            [
              _c(
                "v-list-tile",
                { attrs: { avatar: "" } },
                [
                  _vm.auth
                    ? _c(
                        "v-list-tile-avatar",
                        [
                          _c("v-switch", {
                            attrs: { color: "green" },
                            on: { change: _vm.changeLiveMode },
                            model: {
                              value: _vm.auth.live_mode,
                              callback: function($$v) {
                                _vm.$set(_vm.auth, "live_mode", $$v)
                              },
                              expression: "auth.live_mode"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-content",
                    [_c("v-list-tile-title", [_vm._v("Modo de producción")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("comentario")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }