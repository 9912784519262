var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c("app-nav", {
        attrs: { mini: _vm.mini },
        on: { navToggle: _vm.navToggle }
      }),
      _vm._v(" "),
      _c("top-menu", { on: { navToggle: _vm.navToggle } }),
      _vm._v(" "),
      _c(
        "v-content",
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("app-footer"),
      _vm._v(" "),
      _c("mensaje")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }