var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _c("v-autocomplete", {
            attrs: {
              label: _vm.labels.plantilla_titulos,
              items: _vm.plantillasTitulos,
              "item-text": "nombre",
              "item-value": "id",
              "error-messages": _vm.errors.plantilla_titulos,
              rules: [_vm.rules.required("plantilla_titulos")],
              disabled: _vm.loading,
              dense: "",
              "hide-no-data": ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return $event.target.blur()
              }
            },
            model: {
              value: _vm.form.plantilla_id,
              callback: function($$v) {
                _vm.$set(_vm.form, "plantilla_id", $$v)
              },
              expression: "form.plantilla_id"
            }
          }),
          _vm._v(" "),
          _c("v-autocomplete", {
            attrs: {
              label: _vm.labels.autorizacion_reconocimiento,
              items: _vm.autorizacionesReconocimientos,
              "item-text": "nombre",
              "item-value": "id",
              "error-messages": _vm.errors.autorizacion_reconocimiento,
              rules: [_vm.rules.required("autorizacion_reconocimiento")],
              disabled: _vm.loading,
              dense: "",
              "hide-no-data": ""
            },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return $event.target.blur()
              }
            },
            model: {
              value: _vm.form.autorizacion_reconocimiento_id,
              callback: function($$v) {
                _vm.$set(_vm.form, "autorizacion_reconocimiento_id", $$v)
              },
              expression: "form.autorizacion_reconocimiento_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { type: "submit", disabled: _vm.loading, color: "primary" }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }