var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tabs": "", grow: "", "slider-color": "primary" },
          on: {
            change: function($event) {
              _vm.cambiarPestaña($event)
            }
          },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#institucion" } }, [
            _c("span", [_vm._v("Institución")])
          ]),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            [
              _c(
                "v-tab-item",
                { attrs: { value: "institucion" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "mb-4" },
                                        [
                                          _c(
                                            "v-card-title",
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-space-between": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ml-2 font-weight-light grey--text subheading"
                                                    },
                                                    [_vm._v("Logotipo")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.editarInstitucion(
                                                            "logo"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey darken-2"
                                                          }
                                                        },
                                                        [_vm._v("edit")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.institucion.logo
                                            ? _c(
                                                "v-card-text",
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: _vm.institucion.logo,
                                                      "lazy-src":
                                                        _vm.institucion.logo,
                                                      height: "80",
                                                      contain: "",
                                                      "aspect-ratio": "1"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.nombre,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.institucion.nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression: "institucion.nombre"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.clave,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarInstitucion(
                                              "clave"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.institucion.clave,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion,
                                              "clave",
                                              $$v
                                            )
                                          },
                                          expression: "institucion.clave"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.clave_concentradora,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarInstitucion(
                                              "clave_concentradora"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.institucion.clave_concentradora,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion,
                                              "clave_concentradora",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.clave_concentradora"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.rfc,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarInstitucion("rfc")
                                          }
                                        },
                                        model: {
                                          value: _vm.institucion.rfc,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion,
                                              "rfc",
                                              $$v
                                            )
                                          },
                                          expression: "institucion.rfc"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.razon_social,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarInstitucion(
                                              "razon_social"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.institucion.razon_social,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion,
                                              "razon_social",
                                              $$v
                                            )
                                          },
                                          expression: "institucion.razon_social"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.wsdl_usuario_titulos,
                                          "append-icon": "edit",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarInstitucion(
                                              "wsdl_usuario_titulos"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.institucion.configuracion
                                              .wsdl_usuario_titulos,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion.configuracion,
                                              "wsdl_usuario_titulos",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.configuracion.wsdl_usuario_titulos"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.wsdl_constrasena_titulos,
                                          type: _vm.passwordHidden
                                            ? "password"
                                            : "text",
                                          outline: "",
                                          readonly: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-3",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.passwordHidden = !_vm.passwordHidden
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.passwordHidden
                                                          ? "visibility_off"
                                                          : "visibility"
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editarInstitucion(
                                                          "wsdl_constrasena_titulos"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("edit")]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value:
                                            _vm.institucion.configuracion
                                              .wsdl_constrasena_titulos,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion.configuracion,
                                              "wsdl_constrasena_titulos",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.configuracion.wsdl_constrasena_titulos"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "membresia" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.tipo_plan,
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.mostrarPlanes()
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.institucion.membresia.tipo_plan
                                              .nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion.membresia
                                                .tipo_plan,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.membresia.tipo_plan.nombre"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.modalidad_pago,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.institucion.membresia
                                              .modalidad_pago.nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion.membresia
                                                .modalidad_pago,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.membresia.modalidad_pago.nombre"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .correo_electronico_contacto,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.institucion.membresia
                                              .correo_electronico_contacto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.institucion.membresia,
                                              "correo_electronico_contacto",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "institucion.membresia.correo_electronico_contacto"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }