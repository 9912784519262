 <template>
    <div>
        <hot-table
            :settings="settings"
            ref="tablaCertificados"
            @afterOnCellMouseDown="afterOnCellMouseDown"
            @afterChange="afterChange"
        ></hot-table>

        <v-dialog v-model="loadingDialog" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>Espera un momento
                    <v-progress-linear indeterminate color="white" mb-0></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import axios from 'axios'
    import { mapGetters } from 'vuex'
    import { api } from '~/config'
    import { HotTable } from '@handsontable/vue'
    import Handsontable from 'handsontable'
    import 'handsontable/languages/es-MX'

    export default {
        components: {
            HotTable
        },

        data: () => ({
            loadingDialog: false,

            entidadesFederativas: [],
            programasDeEstudio: {},

            certificados: [],
            calificaciones: [],

            settings: {
                language: 'es-MX',

                data: [
                    [],[],[],[],[],
                    [],[],[],[],[],
                    [],[],[],[],[],
                    [],[],[],[],[],
                ],

                colHeaders: ['Fecha de Expedición', 'Entidad Federativa de Expedición', 'Nombre de Carrera', 'Modalidad', 'Clave del Plan', 'Tipo de Periodo', 'Número de RVOE', 'Matricula del Alumno', 'CURP del Alumno', 'Nombre del Alumno', 'Primer Apellido del Alumno', 'Segundo Apellido del Alumno', 'Calificaciones Capturadas', 'Capturar Calificaciones'],

                comments: true,
                rowHeaders: false,
                preventOverflow: 'horizontal',
                manualColumnResize: true,
                renderAllRows: true,

                contextMenu:    {
                    items: {
                        'row_above': {},
                        'row_below': {},
                        'separator': Handsontable.plugins.ContextMenu.SEPARATOR,
                        'copy': {},
                        'cut': {},
                        'separator2': Handsontable.plugins.ContextMenu.SEPARATOR,
                        'remove_row': {},
                    }
                },

                afterChange: function (changes, source) {
                    this.rootElement.__vue__.$emit('afterChange', this, changes, source)
                },

                afterOnCellMouseDown: function (event, coords, TD) {
                    this.rootElement.__vue__.$emit('afterOnCellMouseDown', event, coords, TD)
                },

                columns: [
                    {
                        type: 'date',
                        dateFormat: 'YYYY-MM-DD',
                        datePickerConfig: {
                            i18n: {
                                previousMonth: 'Mes Anterior',
                                nextMonth: 'Mes Siguiente',
                                months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                                weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
                                weekdaysShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']
                            },
                            maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
                            minDate: new Date(new Date().setDate(new Date().getDate() - 90))
                        }
                    },
                    {
                        type: 'dropdown',
                    },
                    {
                        type: 'dropdown',
                    },
                    {
                        type: 'dropdown',
                    },
                    {
                        type: 'dropdown',
                    },
                    {
                        type: 'dropdown',
                    },
                    {
                        type: 'dropdown',
                    },
                    {},
                    {
                        validator: /^[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]$/,
                        allowInvalid: true
                    },
                    {},
                    {},
                    {},
                    {
                        readOnly: true
                    },
                    {
                        readOnly: true
                    }
                ]
            }
        }),

        computed: {
            ...mapGetters({
                live_mode: "auth/live_mode",
                auth: 'auth/user',
                enviarCertificados: "admin/enviarCertificados",
            })
        },

        watch: {
            live_mode(val) {
                this.$router.push({ name: 'lotes-certificados' })
            },

            enviarCertificados(val) {
                if (val) {
                    this.guardarCertificados(this.$route.params.id)
                }
            },
        },

        mounted() {
            window.addEventListener('resize', this.updateTableSize)
            this.getCatalogoEntidadFederativa()
            this.getProgramasDeEstudio()
            this.updateTableSettings(this.$route.params.id)
            this.updateTableSize()
        },

        methods: {
            getCatalogoEntidadFederativa() {
                return axios
                    .get(api.path('catalogos.entidad_federativa'), {
                        params: {
                            pluck: 'nombre'
                        }
                    })
                    .then(res => {
                        this.entidadesFederativas = res.data
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors)
                    })
            },

            getProgramasDeEstudio() {
                return axios
                    .get(api.path('lotes_certificados.programas', {
                        id: this.$route.params.id
                    }))
                    .then(res => {
                        this.programasDeEstudio = res.data
                    })
                    .catch(err => {
                        this.handleErrors(err.response.data.errors)
                    })
            },

            updateTableSize() {
                if (this.$refs.tablaCertificados && typeof this.$refs.tablaCertificados.hotInstance !== 'undefined') {
                    this.$refs.tablaCertificados.hotInstance.updateSettings({
                        height: window.innerHeight - 64 - 36
                    })
                }
            },

            updateTableSettings(id) {
                if (this.$refs.tablaCertificados && typeof this.$refs.tablaCertificados.hotInstance !== 'undefined') {
                    let me = this

                    this.$refs.tablaCertificados.hotInstance.updateSettings({
                        cells: function (row, col) {
                            const cellProp = {}

                            if (col === 1) {
                                cellProp.type = 'dropdown'
                                cellProp.source = me.entidadesFederativas
                            } else if (col === 2) {
                                cellProp.type = 'dropdown'
                                cellProp.source = me.programasDeEstudio.carreras
                            } else if (col === 12) {
                                let numberOfSubjects = 0

                                if (localStorage['loteCertificados[' + id + ']']) {
                                    let certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])
                                    let calificaciones = certificados[row].calificaciones

                                    if (calificaciones && calificaciones.length > 0) {
                                        calificaciones.forEach(function(calificacion) {
                                            if (calificacion.ciclo_escolar && calificacion.calificacion) {
                                                numberOfSubjects++
                                            }
                                        })
                                    }
                                }
         
                                cellProp.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                                    td.innerHTML = numberOfSubjects
                                }
                            } else if (col === 13) {
                                cellProp.renderer = function (instance, td, row, col, prop, value, cellProperties) {
                                    td.innerHTML = '<button type="button" class="v-btn v-btn--small v-btn--flat theme--light primary--text" style="margin-top: 0px; margin-bottom: 0px; height: 22px;"><div class="v-btn__content">Capturar</div></button>'
                                }
                            }

                            return cellProp
                        },
                    })
                }
            },

            getCertificadosLocalStorage(id) {
                let data = []

                if (localStorage['loteCertificados[' + id + ']']) {
                    let certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])

                    if (certificados.length > 0) {
                        
                        certificados.forEach(function(certificado) {
                            data.push([
                                certificado.fecha_expedicion,
                                certificado.nombre_entidad_federativa_expedicion,
                                certificado.nombre_carrera,
                                certificado.nombre_modalidad,
                                certificado.clave_plan,
                                certificado.nombre_tipo_periodo,
                                certificado.numero_rvoe,
                                certificado.matricula_alumno,
                                certificado.curp_alumno,
                                certificado.nombre_alumno,
                                certificado.primer_apellido_alumno,
                                certificado.segundo_apellido_alumno,
                            ])
                        })
                    }
                }

                return data
            },

            setCertificadosLocalStorage(data, id) {
                if (data.length > 0) {
                    let certificados = []

                    if (localStorage['loteCertificados[' + id + ']']) {
                        certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])
                    }

                    data.forEach(function(certificado, i) {
                        certificados[i].fecha_expedicion = certificado[0]
                        certificados[i].nombre_entidad_federativa_expedicion = certificado[1]
                        certificados[i].nombre_carrera = certificado[2]
                        certificados[i].nombre_modalidad = certificado[3]
                        certificados[i].clave_plan = certificado[4]
                        certificados[i].nombre_tipo_periodo = certificado[5]
                        certificados[i].numero_rvoe = certificado[6]
                        certificados[i].matricula_alumno = certificado[7]
                        certificados[i].curp_alumno = certificado[8]
                        certificados[i].nombre_alumno = certificado[9]
                        certificados[i].primer_apellido_alumno = certificado[10]
                        certificados[i].segundo_apellido_alumno = certificado[11]
                    })

                    localStorage['loteCertificados[' + id + ']'] = JSON.stringify(certificados)
                }
            },

            afterChange(thisObject, changes, source) {
                if (source === 'loadData') {
                    let data = this.getCertificadosLocalStorage(this.$route.params.id) 
                    thisObject.loadData(data)
                    thisObject.render()
                } else {
                    this.setCertificadosLocalStorage(thisObject.getData(), this.$route.params.id)

                    let row = changes[0][0]
                    let col = changes[0][1]
                    let oldValue = changes[0][2]
                    let newValue = changes[0][3]

                    if (col === 2) {
                        let index_carrera = this.programasDeEstudio.carreras.indexOf(newValue)

                        if (index_carrera >= 0) {
                            thisObject.setCellMeta(row, 3, 'source', this.programasDeEstudio.modalidades[index_carrera])
                            thisObject.setDataAtCell(row, 3, this.programasDeEstudio.modalidades[index_carrera][0])
                            thisObject.render()
                        }
                    }

                    if (col === 3) {
                        let index_carrera = this.programasDeEstudio.carreras.indexOf(thisObject.getDataAtCell(row, 2))

                        if (index_carrera >= 0) {
                            let index_modalidad = this.programasDeEstudio.modalidades[index_carrera].indexOf(newValue)

                            if (index_modalidad => 0) {
                                thisObject.setCellMeta(row, 4, 'source', this.programasDeEstudio.claves_plan[index_carrera][index_modalidad])
                                thisObject.setDataAtCell(row, 4, this.programasDeEstudio.claves_plan[index_carrera][index_modalidad][0])
                                thisObject.render()
                            }
                        }
                    }

                    if (col === 4) {
                        let index_carrera = this.programasDeEstudio.carreras.indexOf(thisObject.getDataAtCell(row, 2))

                        if (index_carrera >= 0) {
                            let index_modalidad = this.programasDeEstudio.modalidades[index_carrera].indexOf(thisObject.getDataAtCell(row, 3))

                            if (index_modalidad => 0) {
                                let index_clave_plan = this.programasDeEstudio.claves_plan[index_carrera][index_modalidad].indexOf(newValue)

                                if (index_clave_plan => 0) {
                                    thisObject.setCellMeta(row, 5, 'source', this.programasDeEstudio.tipos_periodo[index_carrera][index_modalidad][index_clave_plan])
                                    thisObject.setDataAtCell(row, 5, this.programasDeEstudio.tipos_periodo[index_carrera][index_modalidad][index_clave_plan][0])
                                    thisObject.render()
                                }
                            }
                        }
                    }

                    if (col === 5) {
                        let index_carrera = this.programasDeEstudio.carreras.indexOf(thisObject.getDataAtCell(row, 2))

                        if (index_carrera >= 0) {
                            let index_modalidad = this.programasDeEstudio.modalidades[index_carrera].indexOf(thisObject.getDataAtCell(row, 3))

                            if (index_modalidad => 0) {
                                let index_clave_plan = this.programasDeEstudio.claves_plan[index_carrera][index_modalidad].indexOf(thisObject.getDataAtCell(row, 4))

                                if (index_clave_plan => 0) {
                                    let index_tipo_periodo = this.programasDeEstudio.tipos_periodo[index_carrera][index_modalidad][index_clave_plan].indexOf(newValue)

                                    if (index_tipo_periodo => 0) {
                                        thisObject.setCellMeta(row, 6, 'source', this.programasDeEstudio.numeros_rvoe[index_carrera][index_modalidad][index_clave_plan][index_tipo_periodo])
                                        thisObject.setDataAtCell(row, 6, this.programasDeEstudio.numeros_rvoe[index_carrera][index_modalidad][index_clave_plan][index_tipo_periodo][0])
                                        thisObject.render()
                                    }
                                }
                            }
                        }
                    }
                }
            },

            afterOnCellMouseDown(event, coords, td) {
                if (coords.col === 13 && coords.row >= 0) {
                    let dataRow = this.$refs.tablaCertificados.hotInstance.getDataAtRow(coords.row)
                    this.buttonCaptureQualifications(coords.row, dataRow[2], dataRow[3], dataRow[4], dataRow[5], dataRow[6])
                }
            },

            buttonCaptureQualifications(row, carrera, modalidad, clave_plan, tipo_periodo, numero_rvoe) {
                if (carrera && modalidad && clave_plan && tipo_periodo && numero_rvoe) {
                    if (this.programasDeEstudio.carreras) {
                        let programa_id = null

                        let index_carrera = this.programasDeEstudio.carreras.indexOf(carrera)

                        if (index_carrera >= 0) {
                            let index_modalidad = this.programasDeEstudio.modalidades[index_carrera].indexOf(modalidad)

                            if (index_modalidad => 0) {
                                let index_clave_plan = this.programasDeEstudio.claves_plan[index_carrera][index_modalidad].indexOf(clave_plan)

                                if (index_clave_plan => 0) {
                                    let index_tipo_periodo = this.programasDeEstudio.tipos_periodo[index_carrera][index_modalidad][index_clave_plan].indexOf(tipo_periodo)

                                    if (index_tipo_periodo => 0) {
                                        let index_numero_rvoe = this.programasDeEstudio.numeros_rvoe[index_carrera][index_modalidad][index_clave_plan][index_tipo_periodo].indexOf(numero_rvoe)

                                        if (index_numero_rvoe => 0) {
                                            programa_id = this.programasDeEstudio.programa_ids[index_carrera][index_modalidad][index_clave_plan][index_tipo_periodo][index_numero_rvoe]
                                        }
                                    }
                                }
                            }
                        }

                        if (programa_id) {
                            this.getSubjects(row, programa_id, this.$route.params.id)
                        } else {
                            this.$toast.warning('No existe un programa de estudios registrado con estos datos.')
                        }
                    } else {
                        this.$toast.warning('No hay programas de estudios registrados.')
                    }
                } else {
                    this.$toast.warning('Capture los datos del certificado.')
                }
            },

            getSubjects(row, programa_id, id) {
                this.loadingDialog = true

                axios
                    .get(api.path('programas.asignaturas', { id: programa_id }))
                    .then(res => {
                        let asignaturas = res.data.data
                        
                        if (asignaturas.length > 0) {
                            let dataAsignaturas = []
                            let certificados = []
                            let calificaciones = []

                            if (localStorage['loteCertificados[' + id + ']']) {
                                certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])
                                if (certificados[row].calificaciones) {
                                    calificaciones = certificados[row].calificaciones
                                }
                            }

                            console.log(dataAsignaturas)
                            console.log(certificados)
                            console.log(calificaciones)

                            asignaturas.forEach(function(asignatura) {
                                let result = calificaciones.find(calificacion => calificacion.clave_asignatura === asignatura.clave);
                                if (result && result.nombre_asignatura === asignatura.nombre && result.tipo_asignatura === asignatura.tipo) {
                                    dataAsignaturas.push({
                                        nombre_asignatura: result.nombre_asignatura,
                                        clave_asignatura: result.clave_asignatura,
                                        tipo_asignatura: result.tipo_asignatura,
                                        ciclo_escolar: result.ciclo_escolar,
                                        calificacion: result.calificacion,
                                        observacion: result.observacion,
                                    })
                                } else {
                                    dataAsignaturas.push({
                                        nombre_asignatura: asignatura.nombre,
                                        clave_asignatura: asignatura.clave,
                                        tipo_asignatura: asignatura.tipo,
                                        ciclo_escolar: '',
                                        calificacion: '',
                                        observacion: '',
                                    })
                                }
                            })

                            certificados[row].calificaciones = dataAsignaturas
                            localStorage['loteCertificados[' + id + ']'] = JSON.stringify(certificados)

                            this.$router.push({ name: 'lotes-certificados-certificados-calificaciones-capturar', params: {id: this.$route.params.id, id2: row + 1 } })
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.handleErrors(err.response.data.errors)
                    })
                    .then(() => {
                        this.loadingDialog = false
                    })
            },

            guardarCertificados(id) {
                this.loadingDialog = true
                let data = this.$refs.tablaCertificados.hotInstance.getData()
                this.setCertificadosLocalStorage(data, id)
                let certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])

                axios
                    .put(api.path('lotes_certificados.certificados', { id: id }), {
                        certificados: certificados
                    })
                    .then(res => {
                        this.$toast.success('Certificados guardados correctamente.')
                        localStorage.removeItem('loteCertificados[' + id + ']')
                        this.$router.push({ name: 'lotes-certificados', query: { saveCertificados: true } })
                    })
                    .catch(err => {
                        this.loadingDialog = false
                        this.$toast.error('No se pueden enviar los certificados.')
                        this.handleErrors(err.response.data.errors)
                    })
                    .then(() => {
                        this.loadingDialog = false
                        this.$store.dispatch('admin/guardarEnviarCertificados', { enviarCertificados: false })
                    })
            },
        }
    }
</script>

<style src="~/../../node_modules/handsontable/dist/handsontable.full.css"></style>

<style>
th {
    font-family: 'Roboto' !important;
    font-weight: bold !important;
    font-size: 12px !important;
    background-color: white !important;
}
</style>
