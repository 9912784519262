var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "uuid"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.uuid,
                  "error-messages": _vm.errors.uuid,
                  rules: [_vm.rules.required("uuid")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.uuid,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "uuid", $$v)
                  },
                  expression: "form.uuid"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "nombre"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.nombre,
                  "error-messages": _vm.errors.nombre,
                  rules: [_vm.rules.required("nombre")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.nombre,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nombre", $$v)
                  },
                  expression: "form.nombre"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "nivel_de_estudio"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.nivel_de_estudio,
                  "error-messages": _vm.errors.nivel_de_estudio,
                  rules: [_vm.rules.required("nivel_de_estudio")],
                  disabled: _vm.loading,
                  items: _vm.nivel_de_estudio,
                  "item-text": "nombre",
                  "item-value": "id",
                  dense: ""
                },
                model: {
                  value: _vm.form.nivel_de_estudio.id,
                  callback: function($$v) {
                    _vm.$set(_vm.form.nivel_de_estudio, "id", $$v)
                  },
                  expression: "form.nivel_de_estudio.id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "modalidad"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.modalidad,
                  "error-messages": _vm.errors.modalidad,
                  rules: [_vm.rules.required("modalidad")],
                  disabled: _vm.loading,
                  items: _vm.modalidad,
                  "item-text": "nombre",
                  "item-value": "id",
                  dense: ""
                },
                model: {
                  value: _vm.form.modalidad.id,
                  callback: function($$v) {
                    _vm.$set(_vm.form.modalidad, "id", $$v)
                  },
                  expression: "form.modalidad.id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "clave"
            ? _c("v-text-field", {
                attrs: { label: _vm.labels.clave, disabled: _vm.loading },
                model: {
                  value: _vm.form.clave,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "clave", $$v)
                  },
                  expression: "form.clave"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }