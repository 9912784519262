var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { raised: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { dark: "", color: "primary", flat: "" } },
        [_c("v-toolbar-title", [_vm._v("Password reset")])],
        1
      ),
      _vm._v(" "),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "fullwidth" },
          [
            _c("p", [
              _vm._v(
                "Please enter your email address. You will receive a link to create a new password via email."
              )
            ]),
            _vm._v(" "),
            _c("password-forgot-form", { on: { success: _vm.success } })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }