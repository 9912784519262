<template>
  <div>
    <hot-table
      :settings="settings"
      ref="tablaCalificaciones"
      @afterChange="afterChange"
    ></hot-table>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import { HotTable } from '@handsontable/vue'
  import Handsontable from 'handsontable'

  export default {

    components: {
      HotTable
    },

    data: () => ({

      observacion: [],

      settings: {
        data: [
          [],
        ],

        colHeaders: ['Nombre de Asignatura', 'Clave de Asignatura', 'Tipo de Asignatura', 'Ciclo Escolar', 'Calificación', 'Observación'],

        comments: true,
        rowHeaders: true,
        preventOverflow: 'horizontal',
        manualColumnResize: true,
        renderAllRows: true,

        contextMenu: {
          items: {
            'copy': {
              name: 'Copiar'
            },
            'cut': {
              name: 'Cortar'
            },
            'separator': Handsontable.plugins.ContextMenu.SEPARATOR,
            'clear_custom': {
              name: 'Borrar todas las celdas',
              callback: function () {
                this.clear();
              }
            },
          }
        },

        stretchH: 'all',

        afterChange: function (changes, source) {
            this.rootElement.__vue__.$emit('afterChange', this, changes, source)
        },

        columns: [{
            readOnly: true
          },
          {
            readOnly: true
          },
          {
            readOnly: true
          },
          {
            validator: /^((19|2[0-1])\d{2}-[1-9]\d?|)$/,
            allowInvalid: true
          },
          {
            type: 'dropdown',
            source: ['5.00', '6.00', '7.00', '8.00', '9.00', '10']
          },
          {
            type: 'dropdown',
          }
        ]
      }
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      window.addEventListener('resize', this.updateTableSize)
      this.getCatalogoObservacion()
      this.updateTableSize()
    },

    methods: {
      getCalificacionesLocalStorage(id, id2) {
          let data = []

          if (['loteCertificados[' + id + ']']) {
              let certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])
              let calificaciones = certificados[(id2 - 1)].calificaciones

              if (calificaciones.length > 0) {
                  calificaciones.forEach(function(calificacion, i) {
                      data.push([
                          calificacion.nombre_asignatura,
                          calificacion.clave_asignatura,
                          calificacion.tipo_asignatura,
                          calificacion.ciclo_escolar,
                          calificacion.calificacion,
                          calificacion.observacion,
                      ])
                  })
              }
          }

          return data
      },

      setCalificacionesLocalStorage(id, id2, data) {
        if (data.length > 0) {
            let certificados = []
            let calificaciones = []

            if (localStorage['loteCertificados[' + id + ']']) {
                certificados = JSON.parse(localStorage['loteCertificados[' + id + ']'])
                calificaciones = certificados[(id2 - 1)].calificaciones
            }

            data.forEach(function(calificacion, i) {
                calificaciones[i].nombre_asignatura = calificacion[0]
                calificaciones[i].clave_asignatura = calificacion[1]
                calificaciones[i].tipo_asignatura = calificacion[2]
                calificaciones[i].ciclo_escolar = calificacion[3]
                calificaciones[i].calificacion = calificacion[4]
                calificaciones[i].observacion = calificacion[5]
            })

            certificados[(id2 - 1)].calificaciones = calificaciones

            localStorage['loteCertificados[' + id + ']'] = JSON.stringify(certificados)
        }
      },

      afterChange(thisObject, changes, source) {
          if (source === 'loadData') {
              let data = this.getCalificacionesLocalStorage(this.$route.params.id, this.$route.params.id2)
              thisObject.loadData(data)
              thisObject.render()
          } else {
            this.setCalificacionesLocalStorage(this.$route.params.id, this.$route.params.id2, thisObject.getData())
          }
      },

      updateTableSize() {
        if (this.$refs.tablaCalificaciones && typeof this.$refs.tablaCalificaciones.hotInstance !== 'undefined') {
          this.$refs.tablaCalificaciones.hotInstance.updateSettings({
            height: window.innerHeight - 64 - 36
          })
        }
      },

      getCatalogoObservacion() {
        axios
          .get(api.path('catalogos.observacion'))
          .then(res => {
            let observaciones = []

            res.data.forEach(function(observacion) {
              if (observacion.descripcion_corta) {
                observaciones.push(observacion.descripcion_corta + ' (' + observacion.descripcion + ')')
              } else {
                observaciones.push(observacion.descripcion)
              }
            });

            this.$refs.tablaCalificaciones.hotInstance.updateSettings({
              cells: function (row, col) {
                const cellProp = {}

                if (col === 5) {
                  cellProp.type = 'dropdown';
                  cellProp.source = observaciones
                }

                return cellProp
              },
            })
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },
    }
  }
</script>

<style src="~/../../node_modules/handsontable/dist/handsontable.full.css"></style>
