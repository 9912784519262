<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        :label="labels.uuid"
        v-model="form.uuid"
        :error-messages="errors.uuid"
        :rules="[rules.required('uuid')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        @keyup.enter="$event.target.blur()"
        :label="labels.entidad_federativa"
        v-model="form.entidad_federativa.id"
        :error-messages="errors.entidad_federativa"
        :rules="[rules.required('entidad_federativa')]"
        :disabled="loading"
        :items="entidadesFederativas"
        item-text="nombre"
        item-value="id"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-text-field
        :label="labels.clave_estatal"
        v-model="form.clave_estatal"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.clave_federal"
        v-model="form.clave_federal"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.clave_pago"
        v-model="form.clave_pago"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        entidad_federativa: {}

      },
      entidadesFederativas: []
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      }),

    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'campus' })
      },   
    },

    mounted() {
      this.getCatalogoEntidadFederativa()
    },

    methods: {
      getCatalogoEntidadFederativa() {
        axios
          .get(api.path('catalogos.entidad_federativa'))
          .then(res => {
            this.entidadesFederativas = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.post(api.path('campus'), this.form)
            .then(res => {
              this.$toast.success('Campus agregado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
