<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'uuid'"
        :label="labels.uuid"
        v-model="form.uuid"
        :error-messages="errors.uuid"
        :rules="[rules.required('uuid')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'entidad_federativa'"
        @keyup.enter="$event.target.blur()"
        :label="labels.entidad_federativa"
        v-model="form.entidad_federativa.id"
        :error-messages="errors.entidad_federativa"
        :rules="[rules.required('entidad_federativa')]"
        :disabled="loading"
        :items="entidadesFederativas"
        item-text="nombre"
        item-value="id"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_estatal'"
        :label="labels.clave_estatal"
        v-model="form.clave_estatal"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_federal'"
        :label="labels.clave_federal"
        v-model="form.clave_federal"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_pago'"
        :label="labels.clave_pago"
        v-model="form.clave_pago"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        entidad_federativa: [],
      },
      entidadesFederativas: [],
      campo: null
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      }),

    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'campus' })
      },   
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'uuid' ||
          param === 'nombre' ||
          param === 'entidad_federativa' ||
          param === 'clave_estatal' ||
          param === 'clave_federal' ||
          param === 'clave_pago'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'entidad_federativa') {
        this.getCatalogoEntidadFederativa()
      }

      this.getCampus(this.$route.params.id)
      
    },

    methods: {
      getCampus(id) {
        this.loading = true
        axios
          .get(api.path('campus.single', { id: id } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      getCatalogoEntidadFederativa() {
        axios
          .get(api.path('catalogos.entidad_federativa'))
          .then(res => {
            this.entidadesFederativas = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.put(api.path('campus.single', { id: this.$route.params.id }), this.form)
            .then(res => {
              this.$toast.success('Campus actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
