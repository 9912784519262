<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'numero'"
        :label="labels.numero_asignatura"
        v-model="form.numero"
        :error-messages="errors.numero"
        :rules="[rules.required('numero_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave'"
        :label="labels.clave_asignatura"
        v-model="form.clave"
        :error-messages="errors.clave"
        :rules="[rules.required('clave_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.nombre_asignatura"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'tipo'"
        :label="labels.tipo_asignatura"
        v-model="form.tipo_asignatura_id"
        :error-messages="errors.tipo"
        :rules="[rules.required('tipo_asignatura')]"
        :disabled="loading"
        :items="tipoAsignatura"
        item-text="nombre"
        item-value="id"
        dense
      ></v-select>

      <v-text-field
        v-if="!this.campo || this.campo === 'creditos'"
        :label="labels.creditos_asignatura"
        v-model="form.creditos"
        :error-messages="errors.creditos"
        :rules="[rules.required('creditos_asignatura')]"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {},
      campo: null,
      tipoAsignatura: []
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'programa' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'numero' ||
          param === 'clave' ||
          param === 'nombre' ||
          param === 'tipo' ||
          param === 'creditos'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'tipo') {
        this.getCatalogoTipoAsignatura()
      }

      this.getAsignatura()
    },

    methods: {
      getCatalogoTipoAsignatura() {
        axios
          .get(api.path('catalogos.tipo_asignatura'))
          .then(res => {
            this.tipoAsignatura = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getAsignatura() {
        axios
          .get(api.path('programas.asignaturas.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('programas.asignaturas.single', { id: this.$route.params.id, id2: this.$route.params.id2 }), this.form)
            .then(res => {
              this.$toast.success('Asignatura actualizada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
