<template>
  <div>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
      <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
        <v-text-field
            :label="labels.archivo_xlsx"
            v-model="form.file.name"
            :error-messages="errors.name"
            :rules="[rules.required('archivo_xlsx')]"
            :disabled="loadingDialog"
            @click="seleccionarArchivoXLSX"
            append-icon="attach_file"
        ></v-text-field>
        <input
          type="file"
          accept=".xlsx"
          style="display: none"
          ref="archivo_xlsx"
          @change="archivoXLSXSeleccionado"
        >
      </v-container>

      <v-layout justify-end mt-2>

        <v-btn
          flat
          :disabled="loadingDialog"
          @click="$router.go(-1)"
          color="grey darken-2"
        >
          Cancelar
        </v-btn>

        <v-btn
          type="submit"
          :disabled="loadingDialog"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-layout>
    </v-form>

    <v-dialog v-model="errrorsDialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Resultados del archivo de programas de estudio</span>
        </v-card-title>
        <v-card-text>
          <v-data-table
              v-if="erroresProgramasDeEstudio.length"
              :headers="headers"
              :items="erroresProgramasDeEstudio"
              :rows-per-page-items="rowsPerPageItems"
              :rows-per-page-text="rowsPerPageText"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.linea }}</td>
                <td>
                  <div
                    v-for="(mensaje, index) in props.item.mensajes"
                    :key="index"
                  >
                    {{ mensaje }}
                  </div>
                </td>
              </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat="flat" @click="errrorsDialog = false">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loadingDialog" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>Espera un momento
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        file: {
          data: '',
          name: '',
          url: '',
        },
      },
      
      errrorsDialog: false,
      loadingDialog: false,

      rowsPerPageItems: [5, 10, 50, 100],
      rowsPerPageText: "Filas por página",

      pagination: {
        rowsPerPage: 10
      },

      headers: [
        { text: "Número de Línea", value: "linea" },
        { text: "Mensaje", value: "mensajes" },
      ],

      erroresProgramasDeEstudio: [],
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'programa' })
      }
    },

    methods: {
      seleccionarArchivoXLSX () {
        this.$refs.archivo_xlsx.click ()
      },

      archivoXLSXSeleccionado (e) {
  			const files = e.target.files
  			if(files[0] !== undefined) {
  				this.form.file.name = files[0].name
  				if(this.form.file.name.lastIndexOf('.') <= 0) {
  					return
  				}
  				const fr = new FileReader ()
  				fr.readAsDataURL(files[0])
  				fr.addEventListener('load', () => {
  					this.form.file.url = fr.result
  					this.form.file.data = files[0] // this is an image file that can be sent to server...
  				})
  			} else {
  				this.form.file.name = ''
  				this.form.file.data = ''
  				this.form.file.url = ''
  			}
      },
      
      submit() {
        if (this.$refs.form.validate()) {
          this.loadingDialog = true

          axios.put(api.path('programas'), this.form)
            .then(res => {
              if(res.data.errores) {
                this.erroresProgramasDeEstudio = res.data.errores
                this.errrorsDialog = true;
              } else {
                this.$toast.success('Archivo subido correctamente.')
                this.$emit('success', res.data)
              }
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
              this.loadingDialog = false
            })
            .finally(() => {
              this.loadingDialog = false
            })
        }
      }
    }
  }
</script>
