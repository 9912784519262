var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v(_vm._s(_vm.title))])
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _vm.type === "certificados" && _vm.lote && !_vm.lote.folio_pago
              ? _c(
                  "div",
                  [
                    _c(
                      "v-alert",
                      {
                        attrs: {
                          value: true,
                          color: "info",
                          icon: "info",
                          outline: ""
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.messages.lote_certificado_sin_folio_pago)
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _vm.loadingResponsables
                      ? _c(
                          "v-layout",
                          { attrs: { "justify-center": "", row: "" } },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" }
                            })
                          ],
                          1
                        )
                      : _vm.responsables.length > 0
                      ? _c(
                          "v-expansion-panel",
                          _vm._l(_vm.responsables, function(
                            responsable,
                            index
                          ) {
                            return _c(
                              "v-expansion-panel-content",
                              {
                                key: index,
                                attrs: { readonly: responsable.firmado },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "actions",
                                      fn: function() {
                                        return [
                                          responsable.firmado
                                            ? _c(
                                                "v-icon",
                                                { attrs: { color: "teal" } },
                                                [_vm._v("done")]
                                              )
                                            : _c(
                                                "div",
                                                [
                                                  responsable.autofirma
                                                    ? _c(
                                                        "div",
                                                        [
                                                          responsable.firma
                                                            ? _c("v-icon", [
                                                                _vm._v(
                                                                  "expand_more"
                                                                )
                                                              ])
                                                            : _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "error"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "error"
                                                                  )
                                                                ]
                                                              )
                                                        ],
                                                        1
                                                      )
                                                    : _c("v-icon", [
                                                        _vm._v("expand_more")
                                                      ])
                                                ],
                                                1
                                              )
                                        ]
                                      },
                                      proxy: true
                                    },
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(
                                                responsable.nombre +
                                                  " " +
                                                  responsable.primer_apellido +
                                                  " " +
                                                  responsable.segundo_apellido
                                              )
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  [
                                    _c("v-card-text", [
                                      !responsable.firmado
                                        ? _c(
                                            "div",
                                            [
                                              responsable.permitido
                                                ? _c("div", [
                                                    responsable.autofirma
                                                      ? _c(
                                                          "div",
                                                          [
                                                            responsable.firma
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      loading:
                                                                        _vm.loadingFirmar,
                                                                      color:
                                                                        "primary",
                                                                      flat: ""
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.firmar(
                                                                          responsable.id,
                                                                          null
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .labels
                                                                          .sign
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-alert",
                                                                  {
                                                                    attrs: {
                                                                      value: true,
                                                                      color:
                                                                        "warning",
                                                                      icon:
                                                                        "priority_high",
                                                                      outline:
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .messages
                                                                          .fiel_not_configured
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          [
                                                            _c("fiel", {
                                                              attrs: {
                                                                responsable: responsable,
                                                                textButton:
                                                                  _vm.labels
                                                                    .sign,
                                                                flat: ""
                                                              },
                                                              on: {
                                                                firmar:
                                                                  _vm.firmar
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                  ])
                                                : _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        value: true,
                                                        color: "info",
                                                        icon: "info",
                                                        outline: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.messages
                                                            .fiel_pending_sign
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      : _c(
                          "v-alert",
                          {
                            attrs: {
                              value: true,
                              color: "info",
                              icon: "info",
                              outline: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.messages
                                    .no_responsables_registrados_campus
                                ) +
                                "\n                    "
                            ),
                            _vm.lote
                              ? _c("span", [
                                  _vm._v(
                                    " (" + _vm._s(_vm.lote.campus.nombre) + ")"
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-btn", { attrs: { flat: "" }, on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.labels.cancel))
              ]),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    flat: "",
                    loading: _vm.loadingFirmarLote,
                    disabled:
                      !_vm.todosFirmaron || _vm.responsables.length === 0
                  },
                  on: { click: _vm.firmarLote }
                },
                [_vm._v(_vm._s(_vm.labels.next))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }