<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.name"
        v-model="form.name"
        :error-messages="errors.name"
        :rules="[rules.required('name')]"
        :disabled="loading"
      ></v-text-field>
    
      <v-text-field
        v-if="!this.campo || this.campo === 'contraseña'"
        :label="labels.new_password"
        v-model="form.password"
        :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
        @click:append="() => (passwordHidden = !passwordHidden)"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.new_password"
        :rules="[rules.required('new_password')]"
        :disabled="loading"
        hint="Al menos 6 caracteres"
        autocomplete="new-password"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'contraseña'"
        :label="labels.new_password_confirmation"
        v-model="form.password_confirmation"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.new_password_confirmation"
        :rules="[rules.required('new_password_confirmation')]"
        :disabled="loading"
        autocomplete="new-password"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      passwordHidden: true,

      form: {
        name: null,
        password: null,
        password_confirmation: null,
      },

      campo: null
    }),

    computed: mapGetters({
      auth: 'auth/user'
    }),

    mounted() {
      this.form = Object.assign(this.form, this.auth)
      
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (param === 'nombre' || param === 'contraseña') {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }
    },

    methods: {
      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.put(api.path('perfil'), this.form)
            .then(res => {
              this.$store.dispatch('auth/fetchUser')
              this.$toast.success('Perfil actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
