<template>
  <div class="fill-height">
    <app-nav :mini="mini" @navToggle="navToggle"></app-nav>
    <top-menu @navToggle="navToggle"></top-menu>
    <v-content>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
    </v-content>
    <app-footer></app-footer>
    <mensaje></mensaje>
  </div>
</template>

<script>
  import { settings } from '~/config.js'
  import { mapGetters } from "vuex";

  export default {
    data: () => ({
      mini: false
    }),

    components: {
      'app-nav': require('./shared/AppNav').default,
      'top-menu': require('./shared/TopMenu').default,
      'app-footer': require('./shared/AppFooter').default,
      'mensaje': require('./shared/Mensaje').default,
    },

    mounted() {
      if (this.auth.actualizacion) {
        this.$store.dispatch('admin/guardarMensajeDialog', { mensajeDialog: true })
      }
    },

    computed: mapGetters({
			auth: 'auth/user',
		}),

    methods: {
      navToggle() {
        this.mini = !this.mini
      }
    }
  }
</script>
