<template>
  <fiel :responsable="responsable" @firmar="storeFirma"></fiel>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'

  export default {
    components: {
      'fiel': require('$comp/admin/shared/Fiel').default,
    },

    data: () => ({
      responsable: {}
    }),

    computed: mapGetters({
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'responsables' })
      }
    },
    
    mounted() {
      this.getResponsable(this.$route.params.id)
    },

    methods: {
      getResponsable() {
        axios
          .get(api.path("responsables.single", { id: this.$route.params.id }))
          .then(res => {
            this.responsable = res.data
          })
          .catch(err => {
          })
          .then(() => {
          })
      },

      storeFirma(responsableId, fiel) {
        this.loading = true
        let data = new FormData()

        data.append('file_cer', fiel.fileCer)
        data.append('file_key', fiel.fileKey)
        data.append('password', fiel.password)

        axios
            .post(api.path('responsables.firma', { id: responsableId}), data)
            .then(res => {
              this.$toast.success(res.data.message)
              this.$emit('success')
            })
            .catch(err => {
            })
            .then(() => {
                this.loading = false
            })
        },
    }
  }
</script>
