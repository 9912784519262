var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "title layout column align-center" },
    [
      _c("span", { staticClass: "headline mb-5" }, [
        _vm._v("Acuerdo de términos de servicio")
      ]),
      _vm._v(" "),
      _c("v-img", {
        attrs: {
          "lazy-src": "/img/clip/welcome.png",
          src: "/img/clip/done.png",
          alt: "Acuerdo de términos de servicio",
          width: "380"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }