var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      attrs: {
        "align-center": "",
        "justify-center": "",
        column: "",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                row: "",
                "fill-height": ""
              }
            },
            [
              _c("span", { staticClass: "subheading mr-2" }, [
                _vm._v("Mensual")
              ]),
              _vm._v(" "),
              _c("v-switch", {
                attrs: { color: "primary" },
                model: {
                  value: _vm.tarifa,
                  callback: function($$v) {
                    _vm.tarifa = $$v
                  },
                  expression: "tarifa"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "subheading" }, [_vm._v("Anual")])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _c(
            "v-alert",
            { attrs: { value: true, color: "black", outline: "" } },
            [
              _vm.tarifa
                ? _c("span", [
                    _vm._v(
                      "\n        El pago de la tarifa anual es en una sola exhibición\n      "
                    )
                  ])
                : _c("span", [
                    _vm._v(
                      "\n        El pago de la tarifa mensual solo es para contratos de mínimo 6 meses\n      "
                    )
                  ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        {
          attrs: {
            "align-center": "",
            "justify-center": "",
            row: "",
            "fill-height": ""
          }
        },
        [
          _c(
            "v-flex",
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            class: { "on-hover": hover },
                            attrs: {
                              "min-width": "270",
                              elevation: hover ? 12 : 2
                            }
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "justify-center layout" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("Free")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": "",
                                      column: "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "display-3 font-weight-bold"
                                        },
                                        [_vm._v("$0")]
                                      ),
                                      _vm._v(" "),
                                      _vm.tarifa
                                        ? _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ año")]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ mes")]
                                          )
                                    ]),
                                    _vm._v(" "),
                                    _c("v-flex", [
                                      _c("span", { staticClass: "body-1" }, [
                                        _vm._v("Registro de 5 alumnos máximo")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outline: "",
                                              color: "orange",
                                              disabled:
                                                _vm.auth.membresia.tipo_plan
                                                  .id === 1
                                            }
                                          },
                                          [_vm._v("Empieza ya")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            class: { "on-hover": hover },
                            attrs: {
                              "min-width": "270",
                              elevation: hover ? 12 : 2
                            }
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "justify-center layout" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("Premium")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": "",
                                      column: "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", [
                                      _vm.tarifa
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "display-3 font-weight-bold green--text"
                                            },
                                            [_vm._v("$24,000")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "display-3 font-weight-bold green--text"
                                            },
                                            [_vm._v("$2,000")]
                                          ),
                                      _vm._v(" "),
                                      _vm.tarifa
                                        ? _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ año")]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ mes")]
                                          )
                                    ]),
                                    _vm._v(" "),
                                    _c("v-flex", [
                                      _c("span", { staticClass: "body-1" }, [
                                        _vm._v("+ $100 por alumno registrado")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { flat: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "green"
                                                }
                                              },
                                              [_vm._v("la-check")]
                                            ),
                                            _vm._v(
                                              "Validación de CURP con RENAPO\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          { attrs: { flat: "" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  left: "",
                                                  color: "green"
                                                }
                                              },
                                              [_vm._v("la-check")]
                                            ),
                                            _vm._v(
                                              "Perzonaliza los PDF de títulos\n                  "
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outline: "",
                                              color: "orange",
                                              disabled:
                                                _vm.auth.membresia.tipo_plan
                                                  .id === 3
                                            }
                                          },
                                          [_vm._v("Empieza ya")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c("v-hover", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var hover = ref.hover
                      return [
                        _c(
                          "v-card",
                          {
                            class: { "on-hover": hover },
                            attrs: {
                              "min-width": "270",
                              elevation: hover ? 12 : 2
                            }
                          },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "justify-center layout" },
                              [
                                _c("span", { staticClass: "headline" }, [
                                  _vm._v("Esencial")
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-center": "",
                                      column: "",
                                      "fill-height": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", [
                                      _vm.tarifa
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "display-3 font-weight-bold"
                                            },
                                            [_vm._v("$18,000")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "display-3 font-weight-boldt"
                                            },
                                            [_vm._v("$1,500")]
                                          ),
                                      _vm._v(" "),
                                      _vm.tarifa
                                        ? _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ año")]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [_vm._v("/ mes")]
                                          )
                                    ]),
                                    _vm._v(" "),
                                    _c("v-flex", [
                                      _c("span", { staticClass: "body-1" }, [
                                        _vm._v("+ $100 por alumno registrado")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              outline: "",
                                              color: "orange",
                                              disabled:
                                                _vm.auth.membresia.tipo_plan
                                                  .id === 2
                                            }
                                          },
                                          [_vm._v("Empieza ya")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }