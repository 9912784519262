var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.numero_asignatura,
              "error-messages": _vm.errors.numero,
              rules: [_vm.rules.required("numero_asignatura")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.numero,
              callback: function($$v) {
                _vm.$set(_vm.form, "numero", $$v)
              },
              expression: "form.numero"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.clave_asignatura,
              "error-messages": _vm.errors.clave,
              rules: [_vm.rules.required("clave_asignatura")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.clave,
              callback: function($$v) {
                _vm.$set(_vm.form, "clave", $$v)
              },
              expression: "form.clave"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.nombre_asignatura,
              "error-messages": _vm.errors.nombre,
              rules: [_vm.rules.required("nombre_asignatura")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.nombre,
              callback: function($$v) {
                _vm.$set(_vm.form, "nombre", $$v)
              },
              expression: "form.nombre"
            }
          }),
          _vm._v(" "),
          _c("v-select", {
            attrs: {
              label: _vm.labels.tipo_asignatura,
              "error-messages": _vm.errors.tipo,
              rules: [_vm.rules.required("tipo_asignatura")],
              disabled: _vm.loading,
              items: _vm.tipoAsignatura,
              "item-text": "nombre",
              "item-value": "id",
              dense: ""
            },
            model: {
              value: _vm.form.tipo_asignatura_id,
              callback: function($$v) {
                _vm.$set(_vm.form, "tipo_asignatura_id", $$v)
              },
              expression: "form.tipo_asignatura_id"
            }
          }),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.creditos_asignatura,
              "error-messages": _vm.errors.creditos,
              rules: [_vm.rules.required("creditos_asignatura")],
              disabled: _vm.loading
            },
            model: {
              value: _vm.form.creditos,
              callback: function($$v) {
                _vm.$set(_vm.form, "creditos", $$v)
              },
              expression: "form.creditos"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { type: "submit", disabled: _vm.loading, color: "primary" }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }