<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>¡Descubriste una función Premium!</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <span>Actualiza al plan Premium y obten increibles beneficios.</span>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="auth.role === 'Administrador'" flat @click="dialog = false" tabindex="3">Cancelar</v-btn>
        <v-btn v-else flat @click="dialog = false" tabindex="2">Continuar</v-btn>

        <v-btn
          v-if="auth.role === 'Administrador'"
          color="primary"
          flat
          :loading="loading"
          @click="mostrarPlanes"
          tabindex="2"
          >Ver planes</v-btn
        >


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { api } from '~/config'

export default {
  data: () => ({
    dialog: false,
    descripcion: null,
    loading: false,
  }),

  computed: {
    ...mapGetters({
      auth: 'auth/user',
      funcionPremiumDialog: 'admin/funcionPremiumDialog'
    })
  },

  watch: {
    funcionPremiumDialog(val) {
      if (val) {
        this.dialog = val
      }
    },

    dialog(val) {
      if (val === false) {
				this.$store.dispatch('admin/guardarFuncionPremiumDialog', { funcionPremiumDialog: false })
      }
    }
  },

  methods: {
    mostrarPlanes() {
      this.dialog = false
      this.$router.push({ name: "configuracion-plan" })
    },
  }
}
</script>
