var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "nombre"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.name,
                  "error-messages": _vm.errors.name,
                  rules: [_vm.rules.required("name")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "correo_electronico"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.email,
                  "error-messages": _vm.errors.email,
                  rules: [_vm.rules.required("email")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "contraseña"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.new_password,
                  "append-icon": _vm.passwordHidden
                    ? "visibility_off"
                    : "visibility",
                  type: _vm.passwordHidden ? "password" : "text",
                  "error-messages": _vm.errors.password,
                  disabled: _vm.loading,
                  hint: "Al menos 6 caracteres",
                  autocomplete: "new-password"
                },
                on: {
                  "click:append": function() {
                    return (_vm.passwordHidden = !_vm.passwordHidden)
                  }
                },
                model: {
                  value: _vm.form.password,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "contraseña"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.new_password_confirmation,
                  type: _vm.passwordHidden ? "password" : "text",
                  "error-messages": _vm.errors.password_confirmation,
                  disabled: _vm.loading,
                  autocomplete: "new-password"
                },
                model: {
                  value: _vm.form.password_confirmation,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "password_confirmation", $$v)
                  },
                  expression: "form.password_confirmation"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "rol"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.role,
                  items: _vm.roles,
                  "error-messages": _vm.errors.role,
                  rules: [_vm.rules.required("role")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.role,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "role", $$v)
                  },
                  expression: "form.role"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "campuses"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.campuses_asignados,
                  disabled: _vm.loading,
                  items: _vm.campuses,
                  "item-text": "nombre",
                  "item-value": "id",
                  "return-object": "",
                  attach: "",
                  chips: "",
                  multiple: "",
                  "deletable-chips": "",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campuses,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campuses", $$v)
                  },
                  expression: "form.campuses"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "responsables"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.responsables_asignados,
                  disabled: _vm.loading,
                  items: _vm.responsables,
                  "item-text": "nombre",
                  "item-value": "id",
                  "return-object": "",
                  chips: "",
                  multiple: "",
                  "deletable-chips": "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "selection",
                      fn: function(ref) {
                        var item = ref.item
                        var selected = ref.selected
                        return [
                          _c(
                            "v-chip",
                            {
                              attrs: { selected: selected },
                              on: {
                                input: function($event) {
                                  return _vm.remove(item)
                                }
                              }
                            },
                            [
                              _c("span", [_vm._v(_vm._s(item.nombre))]),
                              _vm._v(" \n          "),
                              _c("span", [
                                _vm._v(_vm._s(item.primer_apellido))
                              ]),
                              _vm._v(" \n          "),
                              _c("span", [
                                _vm._v(_vm._s(item.segundo_apellido))
                              ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-list-tile-avatar",
                            {
                              staticClass:
                                "headline font-weight-light white--text",
                              attrs: { color: "indigo" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.nombre.charAt(0)) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", {
                                domProps: {
                                  textContent: _vm._s(_vm.nombreCompleto(item))
                                }
                              }),
                              _vm._v(" "),
                              _c("v-list-tile-sub-title", {
                                domProps: { textContent: _vm._s(item.curp) }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  553574197
                ),
                model: {
                  value: _vm.form.responsables,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "responsables", $$v)
                  },
                  expression: "form.responsables"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }