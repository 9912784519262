var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "layout column align-center" }, [
    _c("span", { staticClass: "headline mb-5" }, [_vm._v("Nuestras alianzas")]),
    _vm._v(" "),
    _vm.aliados.length > 0
      ? _c(
          "div",
          _vm._l(_vm.aliados, function(aliado) {
            return _c(
              "div",
              { key: aliado.nombre, staticClass: "mb-4" },
              [
                _c("v-img", {
                  attrs: {
                    "lazy-src": aliado.logo,
                    src: aliado.logo,
                    alt: aliado.nombre,
                    width: "200"
                  }
                })
              ],
              1
            )
          }),
          0
        )
      : _c(
          "div",
          [
            _c("v-img", {
              attrs: {
                "lazy-src": "/img/clip/welcome.png",
                src: "/img/clip/welcome.png",
                alt: "Acuerdo de términos de servicio",
                width: "380"
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }