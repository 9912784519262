<template>
  <v-content>
    <v-container fluid fill-height justify-center align-center>
      <top-menu></top-menu>
      <v-layout align-center justify-center>
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-layout>
      <app-footer></app-footer>
    </v-container>
  </v-content>
</template>

<script>
export default {
  components: {
    "top-menu": require("./shared/TopMenu").default,
    "app-footer": require("./shared/AppFooter").default
  }
};
</script>
