<template>
  <v-flex sm6 md4 lg4>
    <v-card>
      <v-card-title class="title layout column align-center" primary-title>
        <v-img class="mb-4" lazy-src="/img/logo.png" src="/img/logo.png" alt="Logotipo" width="180"></v-img>
        <span class="font-weight-light">Titulación y Certificación Electrónica</span>
      </v-card-title>
      <v-card-text>
        <login-form @success="success"></login-form>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
  import { settings } from "~/config"

  export default {
    data: () => ({
      siteName: settings.siteName
    }),

    components: {
      "login-form": require("./LoginForm").default
    },

    methods: {
      success(data) {
        this.$store.dispatch("auth/saveToken", data)
        this.$store.dispatch("auth/setUser", data)
        let urlPath = this.$route.query.redirigir
        if (urlPath) {
          this.$router.replace(urlPath)
        } else {
          this.$router.push({ name: "index" })
        }
      }
    }
  }
</script>
