var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.nombre_asignatura,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.calificacion.nombre_asignatura,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "nombre_asignatura", $$v)
                        },
                        expression: "calificacion.nombre_asignatura"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.clave_asignatura,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.calificacion.clave_asignatura,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "clave_asignatura", $$v)
                        },
                        expression: "calificacion.clave_asignatura"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.tipo_asignatura,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.calificacion.tipo_asignatura,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "tipo_asignatura", $$v)
                        },
                        expression: "calificacion.tipo_asignatura"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.creditos_asignatura,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.calificacion.creditos_asignatura,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "creditos_asignatura", $$v)
                        },
                        expression: "calificacion.creditos_asignatura"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.ciclo_escolar,
                        "append-icon":
                          _vm.loteCertificados.estado_lote_id === 2 ||
                          _vm.loteCertificados.estado_lote_id === 3
                            ? "edit"
                            : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCalificacion("ciclo_escolar")
                        }
                      },
                      model: {
                        value: _vm.calificacion.ciclo_escolar,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "ciclo_escolar", $$v)
                        },
                        expression: "calificacion.ciclo_escolar"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.calificacion,
                        "append-icon":
                          _vm.loteCertificados.estado_lote_id === 2 ||
                          _vm.loteCertificados.estado_lote_id === 3
                            ? "edit"
                            : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCalificacion("calificacion")
                        }
                      },
                      model: {
                        value: _vm.calificacion.calificacion,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "calificacion", $$v)
                        },
                        expression: "calificacion.calificacion"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.observacion,
                        "append-icon":
                          _vm.loteCertificados.estado_lote_id === 2 ||
                          _vm.loteCertificados.estado_lote_id === 3
                            ? "edit"
                            : "",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarCalificacion("observacion")
                        }
                      },
                      model: {
                        value: _vm.calificacion.observacion,
                        callback: function($$v) {
                          _vm.$set(_vm.calificacion, "observacion", $$v)
                        },
                        expression: "calificacion.observacion"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }