<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-switch
              :label="labels.habilitado"
              v-model="usuario.active"
              @change="changeEstado"
            ></v-switch>

            <v-text-field
              :label="labels.name"
              v-model="usuario.name"
              @click:append="editarUsuario('nombre')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.email"
              v-model="usuario.email"
              @click:append="editarUsuario('correo_electronico')"
              append-icon="edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.password"
              value="••••••••"
              append-icon="edit"
              @click:append="editarUsuario('contraseña')"
              outline
              readonly
            ></v-text-field>

            <v-select
              :label="labels.role"
              v-model="usuario.role"
              :items="roles"
              @click:append="editarUsuario('rol')"
              append-icon="edit"
              outline
              readonly
            ></v-select>

            <v-autocomplete
              v-if="usuario.role === 'Administrador' || usuario.role === 'Escolares'"
              :label="labels.campuses_asignados"
              v-model="usuario.campuses"
              :items="usuario.campuses"
              item-text="nombre"
              item-value="uuid"
              attach
              chips
              multiple
              @click:append="editarUsuario('campuses')"
              append-icon="edit"
              outline
              readonly
            ></v-autocomplete>

            <v-autocomplete
              v-if="usuario.role === 'Firmante'"
              :label="labels.responsables_asignados"
              v-model="usuario.responsables"
              :items="usuario.responsables"
              item-text="nombre"
              item-value="id"
              attach
              chips
              multiple
              @click:append="editarUsuario('responsables')"
              append-icon="edit"
              outline
              readonly
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  class="grey--text"
                >
                  <span>{{ item.nombre }}</span>&nbsp;
                  <span>{{ item.primer_apellido }}</span>&nbsp;
                  <span>{{ item.segundo_apellido }}</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      usuario: {},
      campuses: [],
      responsables: [],
      roles: ['Administrador', 'Firmante', 'Escolares'],
    }),

    computed: mapGetters({
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'usuario' })
      }
    },

    mounted() {
      this.getUsuario(this.$route.params.id)
    },

    methods: {
      getUsuario(id) {
        axios.get(api.path('usuarios.single', { id: id } ))
          .then(res => {
            this.usuario = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      editarUsuario(campo) {
        this.$router.push({ name: "usuario-editar", params: { 'campo': campo } })
      },

      changeEstado() {
        axios
          .put(api.path('usuarios.single', { id: this.$route.params.id }), {
            active: this.usuario.active
          })
          .then(res => {
            this.getUsuario(this.$route.params.id)
            this.$toast.success('Se actualizó el estado del usuario.')
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },
    }
  }
</script>
