<template>
    <v-dialog v-model="dialog" max-width="500" persistent>
        <v-card>
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>

            <v-card-text>
                <div v-if="type === 'certificados' && lote && !lote.folio_pago">
                    <v-alert
                        :value="true"
                        color="info"
                        icon="info"
                        outline
                    >{{ messages.lote_certificado_sin_folio_pago }}</v-alert>
                </div>

                <div v-else>
                    <v-layout v-if="loadingResponsables" justify-center row>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-layout>

                    <v-expansion-panel v-else-if="responsables.length > 0">
                        <v-expansion-panel-content
                            v-for="(responsable, index) in responsables"
                            :key="index"
                            :readonly="responsable.firmado"
                        >
                            <template v-slot:actions>
                                <v-icon v-if="responsable.firmado" color="teal">done</v-icon>
                                <div v-else>
                                    <div v-if="responsable.autofirma">
                                        <v-icon v-if="responsable.firma">expand_more</v-icon>
                                        <v-icon v-else color="error">error</v-icon>
                                    </div>
                                    <v-icon v-else>expand_more</v-icon>
                                </div>
                            </template>

                            <template v-slot:header>
                                <div>{{ responsable.nombre + " " + responsable.primer_apellido + " " + responsable.segundo_apellido }}</div>
                            </template>

                            <v-card>
                                <v-card-text>
                                    <div v-if="!responsable.firmado">
                                        <div v-if="responsable.permitido">
                                            <div v-if="responsable.autofirma">
                                                <v-btn
                                                    v-if="responsable.firma"
                                                    :loading="loadingFirmar"
                                                    color="primary"
                                                    flat
                                                    @click="firmar(responsable.id, null)"
                                                >{{ labels.sign }}</v-btn>
                                                <v-alert
                                                    v-else
                                                    :value="true"
                                                    color="warning"
                                                    icon="priority_high"
                                                    outline
                                                >{{ messages.fiel_not_configured }}</v-alert>
                                            </div>
                                            <div v-else>
                                                <fiel :responsable="responsable" :textButton="labels.sign" flat @firmar="firmar"></fiel>
                                            </div>
                                        </div>
                                        <v-alert
                                            v-else
                                            :value="true"
                                            color="info"
                                            icon="info"
                                            outline
                                        >{{ messages.fiel_pending_sign }}</v-alert>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-alert
                        v-else
                        :value="true"
                        color="info"
                        icon="info"
                        outline
                    >
                        {{ messages.no_responsables_registrados_campus }}
                        <span v-if="lote"> ({{ lote.campus.nombre }})</span>
                    </v-alert>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat @click="close">{{ labels.cancel }}</v-btn>
                <v-btn
                    color="primary"
                    flat
                    @click="firmarLote"
                    :loading="loadingFirmarLote"
                    :disabled="!todosFirmaron || responsables.length === 0"
        
                >{{ labels.next }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios"
import Form from '~/mixins/form'
import { api } from "~/config"

export default {
    mixins: [Form],

    components: {
        'fiel': require('./Fiel').default,
    },

    data: () => ({
        dialog: false,
        loadingFirmar: false,
        loadingFirmarLote: false,
        loadingResponsables: false,
        lote: null,
        loteId: null,
        path: {
            responsablesLote: null,
            firmarResponsable: null,
            firmarLote: null,
        },
        responsables: {},
        title: null,
        todosFirmaron: false,
        type: null,
    }),

    methods: {
        close() {
            this.dialog = false
        },

        open(type, loteId) {
            this.dialog = true
            this.loteId = loteId
            this.type = type
            this.title = 'Firmado del lote de ' + this.type

            if (this.type === 'titulos') {
                this.path = {
                    responsablesLote: 'titulos.firmantes',
                    firmarResponsable: 'titulos.responsables.sellar',
                    firmarLote: 'titulos.firmar',
                }
            } else if (type === 'certificados') {
                this.path = {
                    responsablesLote: 'lotes_certificados.firmantes',
                    firmarResponsable: 'lotes_certificados.responsables.sellar',
                    firmarLote: 'lotes_certificados.firmar',
                    lote: 'lotes_certificados.single',
                }
                this.getLote()
            }

            this.getResponsablesLote()
        },

        getResponsablesLote() {
            this.loadingResponsables = true
            axios
                .get(api.path(this.path.responsablesLote, { id: this.loteId } ))
                .then(res => {
                    this.responsables = res.data
                    let firmaron = true
                    this.responsables.forEach(firmante => {
                        if (!firmante.firmado) {
                            firmaron = false
                        }
                    })
                    this.todosFirmaron = firmaron
                })
                .catch(err => {
                })
                .then(() => {
                    this.loadingResponsables = false
                })
        },

        firmar(responsableId, fiel) {
            this.loadingFirmar = true
            let data = null

            if (fiel) {
                data = new FormData()

                data.append('file_cer', fiel.fileCer)
                data.append('file_key', fiel.fileKey)
                data.append('password', fiel.password)
            }

            axios
                .post(api.path(this.path.firmarResponsable, { id: this.loteId, id2: responsableId }), data)
                .then(res => {
                    this.$toast.success(res.data.message)
                    this.getResponsablesLote()
                })
                .catch(err => {
                })
                .then(() => {
                    this.loadingFirmar = false
                })
        },

        firmarLote() {
            this.loadingFirmarLote = true
            axios
                .post(api.path(this.path.firmarLote, { id: this.loteId }))
                .then(res => {
                    this.$toast.success(res.data.message)
                    this.$emit('success')
                    this.close()
                })
                .catch(err => {
                })
                .then(() => {
                    this.loadingFirmarLote = false
                })
        },

        getLote() {
            axios
                .get(api.path(this.path.lote, { id: this.loteId }))
                .then(res => {
                    this.lote = res.data
                })
                .catch(err => {
                })
                .then(() => {
                })
      },
    },
}
</script>
