<template>
  <v-tabs
    fixed-tabs
    grow
    v-model="selectedTab"
    slider-color="primary"
    @change="cambiarPestaña($event)"
  >
    <v-tab href="#responsable">
      <span>Responsable</span>
    </v-tab>
    <v-tab href="#firma">
      <span>e.firma</span>
    </v-tab>

    <v-tabs-items>
      <v-tab-item value="responsable">
        <v-container fluid grid-list-xl>
          <v-layout row justify-center wrap>
            <v-flex lg6 sm9 xs12>
              <transition name="fade" mode="out-in">
                <div>
                  <v-switch
                    :label="labels.responsable_plan_estudios"
                    v-model="responsable.responsable_plan_estudios"
                    @change="changeEstadoResponsablePlanEstudios"
                  ></v-switch>

                  <v-card class="mb-4">
                    <v-card-title>
                      <v-layout align-center justify-space-between row fill-height>
                        <div class="ml-2 font-weight-light grey--text subheading">Firma autógrafa</div>
                        <v-btn icon @click="editarResponsable('firma_autografa')">
                          <v-icon color="grey darken-2">edit</v-icon>
                        </v-btn>
                      </v-layout>
                    </v-card-title>

                    <v-card-text v-if="responsable.firma_autografa">
                      <v-img
                        :src="responsable.firma_autografa"
                        :lazy-src="responsable.firma_autografa"
                        height="80"
                        contain
                        aspect-ratio="1"
                      ></v-img>
                    </v-card-text>
                  </v-card>

                  <v-text-field
                    :label="labels.nombre"
                    v-model="responsable.nombre"
                    @click:append="editarResponsable('nombre')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-text-field
                    :label="labels.primer_apellido"
                    v-model="responsable.primer_apellido"
                    @click:append="editarResponsable('primer_apellido')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-text-field
                    :label="labels.segundo_apellido"
                    v-model="responsable.segundo_apellido"
                    @click:append="editarResponsable('segundo_apellido')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-text-field
                    :label="labels.cargo"
                    v-model="responsable.cargo.nombre"
                    @click:append="editarResponsable('cargo')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-text-field
                    :label="labels.curp"
                    v-model="responsable.curp"
                    @click:append="editarResponsable('curp')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-text-field
                    :label="labels.abreviatura_titulo"
                    v-model="responsable.abreviatura_titulo"
                    @click:append="editarResponsable('abreviatura_titulo')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-text-field>

                  <v-autocomplete
                    @keyup.enter="$event.target.blur()"
                    :label="labels.campuses_a_firmar_titulos"
                    v-model="responsable.campuses_titulos"
                    :items="responsable.campuses_titulos"
                    item-text="nombre"
                    item-value="id"
                    attach
                    chips
                    multiple
                    @click:append="editarResponsable('campuses_titulos')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-autocomplete>

                  <v-autocomplete
                    @keyup.enter="$event.target.blur()"
                    :label="labels.campuses_a_firmar_certificados"
                    v-model="responsable.campuses_certificados"
                    :items="responsable.campuses_certificados"
                    item-text="nombre"
                    item-value="id"
                    attach
                    chips
                    multiple
                    @click:append="editarResponsable('campuses_certificados')"
                    append-icon="edit"
                    outline
                    readonly
                  ></v-autocomplete>
                </div>
              </transition>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>

      <v-tab-item value="firma">
        <v-container fluid grid-list-xl>
          <v-layout row justify-center wrap>
            <v-flex lg6 sm9 xs12>
              <transition name="fade" mode="out-in">
                <div>
                  <v-layout align-center justify-space-around row>
                    <v-btn
                      outline
                      color="primary"
                      @click="subirFirma"
                    >Subir e.firma</v-btn>
                    <v-btn
                      outline
                      color="error"
                      @click="deleteFirma"
                      :disabled="!responsable.firma"
                      :loading="loading"
                    >Eliminar e.firma</v-btn>
                  </v-layout>

                  <div v-if="responsable.firma">
                    <v-switch
                      :label="labels.autofirma"
                      v-model="responsable.autofirma"
                      @change="changeEstadoAutofirma"
                      :disabled="!responsable.firma"
                    ></v-switch>

                    <v-text-field
                      :label="labels.nombre"
                      v-model="responsable.fiel.nombre"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.curp"
                      v-model="responsable.fiel.curp"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.rfc"
                      v-model="responsable.fiel.rfc"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.email"
                      v-model="responsable.fiel.email"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.numero_certificado"
                      v-model="responsable.fiel.numeroCertificado"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_emision"
                      v-model="responsable.fiel.fechaEmision"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.fecha_expiracion"
                      v-model="responsable.fiel.fechaExpiracion"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </div>
              </transition>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
  mixins: [Form],

  data: () => ({
    selectedTab: "responsable",
    loading: false,

    responsable: {
      cargo: {},
      fiel: {}
    }
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode"
    })
  },

  watch: {
    live_mode(val) {
      this.$router.push({ name: "responsables" });
    }
  },

  mounted() {
    let tab = this.$route.fullPath.replace(this.$route.path, "").split("/");

    if (tab[0] === "") {
      this.cambiarPestaña(this.selectedTab);
    } else if (tab[0] === "#responsable") {
      this.selectedTab = "responsable";
    } else if (tab[0] === "#firma") {
      this.selectedTab = "firma";
    }

    this.getResponsable();
  },

  methods: {
    getResponsable() {
      axios
        .get(api.path("responsables.single", { id: this.$route.params.id }))
        .then(res => {
          this.responsable = res.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {});
    },

    editarResponsable(campo) {
      this.$router.push({
        name: "responsables-editar",
        params: { campo: campo }
      });
    },

    changeEstadoResponsablePlanEstudios() {
      axios
        .put(api.path("responsables.single", { id: this.$route.params.id }), {
          responsable_plan_estudios: this.responsable.responsable_plan_estudios
        })
        .then(res => {
          this.$toast.success("Se actualizó el responsable.");
          this.getResponsable();
        })
        .catch(err => {
        })
    },

    changeEstadoAutofirma() {
      axios
        .put(api.path("responsables.single", { id: this.$route.params.id }), {
          autofirma: this.responsable.autofirma
        })
        .then(res => {
          this.$toast.success("Se actualizó el responsable.");
          this.getResponsable();
        })
        .catch(err => {
        })
    },

    cambiarPestaña(tab) {
      this.$router.replace("#" + tab);
    },
    
    subirFirma() {
      this.$router.push({ name: 'responsables-firma', params: { id: this.$route.params.id } })
    },

    deleteFirma() {
        this.loading = true
        axios
            .delete(api.path('responsables.firma', { id: this.$route.params.id}))
            .then(res => {
              this.$toast.success(res.data.message)
              this.getResponsable();
            })
            .catch(err => {
            })
            .then(() => {
                this.loading = false
            })
        },
  }
};
</script>
