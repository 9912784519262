<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < solicitudes.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === solicitudes.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getSolicitudes"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="eliminarSolicitudes"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ solicitudes.total > 1 ? 1 : 0 }}-{{ solicitudes.total ? solicitudes.total : 0 }} de {{ solicitudes.total ? solicitudes.total : 0 }}</span>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="solicitudes.data && solicitudes.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="solicitudes.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="registrarSolicitud(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre }}</td>
          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.correo_electronico }}</td>
          <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.created_at }}</td>
          <div v-if="hover" class="solicitud-fix-1">
            <div class="justify-center layout grey lighten-3 solicitud-fix-2">
              <v-tooltip bottom>
                <v-btn slot="activator" icon ripple @click.stop="eliminarSolicitud(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay solicitudes de usuarios",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Nombre", value: "nombre" , visibility: true },
      { text: "Correo Electrónico", value: "correo_electronico", visibility: true },
      { text: "Fecha", value: "created_at", visibility: true },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: null,
      sortBy: 'uuid'
    },

    solicitudes: [],
  }),

  computed: {
    ...mapGetters({
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val)
      } else {
        this.$router.replace('')
      }
      this.getSolicitudes()
    }
  },

  methods: {
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.solicitudes.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getSolicitudes() {
      this.loading = true
      axios
        .get(api.path('usuarios.solicitudes'))
        .then(res => {
            this.solicitudes = res.data
            this.pagination.rowsPerPage = res.data.total
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    eliminarSolicitud(val) {
      this.$confirm("¿Desea eliminar esta solicitud?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("usuarios.solicitudes"), {
                params: {
                  ids: val.id
                }
              })
              .then(res => {
                this.$toast.success("Solicitud eliminada correctamente.")
                this.getSolicitudes(this.$route.params.id)
              })
              .catch(err => {
                this.$toast.error("No se puede eliminar la solicitud.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarSolicitudes() {
      this.$confirm("¿Desea eliminar las solicitudes seleccionadas?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("usuarios.solicitudes"), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Solicitudes eliminadas correctamente.")
                this.selected = []
                this.getSolicitudes()
              })
              .catch(err => {
                this.$toast.error("No se pueden eliminar las solicitudes.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    registrarSolicitud(val) {
      this.$router.push({ name: 'usuario-solicitud-registrar', params: { id: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getSolicitudes()
      localStorage.setItem('solicitud.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('solicitud.headers', JSON.stringify(this.headers))
    },
  },

  mounted() {
    if (localStorage.hasOwnProperty('solicitud.pagination')) {
      this.pagination = JSON.parse(localStorage.getItem('solicitud.pagination'))
    }

    if (localStorage.hasOwnProperty('solicitud.headers')) {
      this.headers = JSON.parse(localStorage.getItem('solicitud.headers'))
    }

    let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

    if (tab[0] === '#buscar') {
      let busqueda = decodeURI( tab[1] )
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
    } else {
      if (this.busqueda) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      }
    }

    this.getSolicitudes()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .solicitud-fix-1 {
    position: relative !important;
	}

  .solicitud-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>

