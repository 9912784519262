<template>
  <div>
    <v-navigation-drawer class="drawer-fix" fixed app :permanent="$vuetify.breakpoint.mdAndUp" light :mini-variant.sync="$vuetify.breakpoint.mdAndUp && mini" :clipped="$vuetify.breakpoint.mdAndUp" :value="mini">
      <v-toolbar flat class="transparent">
        <v-list class="pa-0">
          <v-list-tile avatar>
            <v-list-tile-avatar v-if="$vuetify.breakpoint.mdAndUp && mini">
              <v-btn icon @click.native.stop="navToggle" class="mx-0">
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-list-tile-avatar>
            <v-list-tile-avatar v-else>
              <v-btn icon color="blue lighten-4" class="mx-0 headline font-weight-light white--text" :to="{ name: 'perfil' }">
                <v-icon color="blue accent-4">person</v-icon>
              </v-btn>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ name }}</v-list-tile-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-btn icon @click.native.stop="navToggle">
                <v-icon>chevron_left</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-toolbar>

      <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
        <!--<v-divider class="mb-2" :class="{ 'mt-0': !index, 'mt-2': !!index }" v-if="auth && group[0].roles.includes(auth.role)"></v-divider>-->
        <div v-for="item in group" :key="item.title">
          <div v-if="auth && item.roles.includes(auth.role)">
            <v-list-group v-if="!!item.items" :prepend-icon="item.icon" no-action>
              <v-list-tile slot="activator">
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-for="subItem in item.items" :key="subItem.title" @click="subItem.action ? subItem.action() : false" :to="subItem.to" ripple :exact="subItem.exact !== undefined ? subItem.exact : true">
                <v-list-tile-content>
                  <v-list-tile-title>{{ subItem.title }}</v-list-tile-title>
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-icon medium>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
              </v-list-tile>
            </v-list-group>
            <v-list-tile v-else @click.native="item.action ? item.action() : false" href="javascript:void(0)" :to="item.to" ripple :exact="item.exact !== undefined ? item.exact : true">
              <v-list-tile-action>
                <v-icon medium>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action v-if="!mini && item.premium && auth.membresia.tipo_plan.id !== 3">
                  <v-chip outline small color="primary">
                    <span class="caption">Premium</span>
                  </v-chip>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider v-if="item.divider"></v-divider>
          </div>
        </div>
      </v-list>

      <v-list>
        <v-list-tile avatar>
          <v-list-tile-avatar v-if="auth">
            <v-switch
              v-model="auth.live_mode"
              color="green"
              @change="changeLiveMode"
            ></v-switch>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Modo de producción</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>  
    </v-navigation-drawer>
    
    <comentario></comentario>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { api } from '~/config'

export default {
  data: () => ( {
    items: [],
    name: null,
  } ),

  components: {
    'comentario': require('./Comentario').default,
  },

  props: [ 'mini' ],

  computed: {
    ...mapGetters({
      auth: "auth/user"
    }),
  },

  watch: {
    authName( val ) {
      if ( val ) {
        this.name = val
      }
    },
  },

  mounted() {
    this.name = this.auth.name
    if(this.name.length > 16) {
      this.name = this.name.substring(0,16) + '...'
    }
    this.navigation()
  },

  methods: {
    navToggle() {
      this.$emit( 'navToggle' )
    },

    async logout() {
      await this.$store.dispatch( 'auth/logout' )

	  	this.$toast.info("Hasta la próxima.")

      this.$router.push( { name: 'login' } )
    },

    comentarios() {
			this.$store.dispatch('admin/guardarComentarioDialog', { comentarioDialog: true })
    },

    openWiki() {
      window.open('https://wiki.metaescolar.com', '_blank');
    },

    changeLiveMode() {
      axios
        .put(api.path('perfil.live_mode'), {
          live_mode: this.auth.live_mode
        })
        .then(res => {
          this.$store.dispatch('auth/fetchUser')
          this.$toast.success('Se actualizó el estado del entorno.')
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
        })
    },

    navigation() {
      this.items = [
        [ {
          title: 'Panel',
          icon: 'la-home',
          to: {
            name: 'panel'
          },
          exact: false,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ]
        } ],
        [ {
          title: 'Lotes de Títulos',
          icon: 'la-graduation-cap',
          to: {
            name: 'lotes-titulos'
          },
          exact: false,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ]
        } ],
        [ {
          title: 'Lotes de Certificados',
          icon: 'la-certificate',
          to: {
            name: 'lotes-certificados'
          },
          exact: false,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ]
        } ],
        [ {
          title: 'Programas de Estudio',
          icon: 'la-school',
          to: {
            name: 'programa'
          },
          exact: false,
          roles: [ 'Administrador', 'Escolares' ]
        } ],
        [ {
          title: 'Campus',
          icon: 'la-university',
          to: {
            name: 'campus'
          },
          exact: false,
          roles: [ 'Administrador']
        } ],
        [ {
          title: 'Carreras',
          icon: 'la-chalkboard',
          to: {
            name: 'carrera'
          },
          exact: false,
          roles: [ 'Administrador']
        } ],
        [ {
          title: 'Responsables',
          icon: 'la-user-ninja',
          to: {
            name: 'responsables'
          },
          exact: false,
          roles: [ 'Administrador']
        } ],
        [ {
          title: 'Usuarios',
          icon: 'la-user',
          to: {
            name: 'usuario'
          },
          exact: false,
          roles: [ 'Administrador']
        } ],
        [ {
          title: 'Reglas de Folios de Control',
          icon: 'la-list-ol',
          to: {
            name: 'regla-folio-control'
          },
          exact: false,
          roles: [ 'Administrador']
        } ],
        [ {
          title: 'Plantillas de Títulos',
          icon: 'la-file-signature',
          to: {
            name: 'plantilla-titulo'
          },
          exact: false,
          roles: [ 'Administrador'],
          premium: true
        } ],
        [ {
          title: 'Configuración',
          icon: 'la-cog',
          to: {
            name: 'configuracion'
          },
          exact: false,
          roles: [ 'Administrador'],
          divider: true
        } ],
        [ {
          title: 'Cerrar Sesión',
          icon: 'la-sign-out-alt',
          action: this.logout,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ],
          divider: true
        } ],
        /*[ {
          title: 'Enviar Comentarios',
          icon: 'announcement',
          action: this.comentarios,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ]
        } ],*/
         [ {
          title: 'Ayuda',
          icon: 'help',
          action: this.openWiki,
          roles: [ 'Administrador', 'Firmante', 'Escolares' ],
          divider: true
        } ]
      ]
    }
  }
}
</script>

<style>
	/*.drawer-fix {
    z-index: 202 !important;
	}*/
</style>