var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "nombre"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.nombre,
                  "error-messages": _vm.errors.nombre,
                  rules: [_vm.rules.required("nombre")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.nombre,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nombre", $$v)
                  },
                  expression: "form.nombre"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "nombre_campus"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.campus,
                  items: _vm.campuses,
                  "item-text": "nombre",
                  "item-value": "id",
                  "error-messages": _vm.errors.nombre_campus,
                  rules: [_vm.rules.required("campus")],
                  disabled: _vm.loading,
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campus_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campus_id", $$v)
                  },
                  expression: "form.campus_id"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }