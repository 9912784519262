<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < responsables.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === responsables.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getResponsables"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="eliminarResponsables"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ responsables.count > 1 ? ((responsables.current_page - 1) * responsables.per_page + 1) : 0 }}-{{ responsables.count ? ((responsables.current_page - 1) * responsables.per_page) + responsables.count : 0 }} de {{ responsables.total ? responsables.total : 0 }}</span>

      <v-btn
        :disabled="responsables.current_page <= 1"
        icon
        @click="getResponsablesPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="responsables.current_page >= responsables.last_page"
        icon
        @click="getResponsablesNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="responsables.data && responsables.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="responsables.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarResponsable(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre }}</td>
          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.primer_apellido }}</td>
          <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.segundo_apellido }}</td>
          <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.cargo.nombre }}</td>
          <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.curp }}</td>
          <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.abreviatura_titulo }}</td>

          <td :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-menu open-on-hover offset-y nudge-bottom="5" :disabled="props.item.campuses_titulos.length === 0">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small color="indigo" text-color="white">
                  <v-avatar class="indigo darken-4"><strong>{{ props.item.campuses_titulos.length }}</strong></v-avatar>
                  Campus
              </v-chip>
              </template>
              <v-list dense>
                <v-list-tile
                  v-for="(item, index) in props.item.campuses_titulos"
                  :key="index"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.nombre"></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>

          <td :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-menu open-on-hover offset-y nudge-bottom="5" :disabled="props.item.campuses_certificados.length === 0">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" small color="indigo" text-color="white">
                  <v-avatar class="indigo darken-4"><strong>{{ props.item.campuses_certificados.length }}</strong></v-avatar>
                  Campus
              </v-chip>
              </template>
              <v-list dense subheader>
                <v-list-tile
                  v-for="(item, index) in props.item.campuses_certificados"
                  :key="index"
                >
                  <v-list-tile-content>
                    <v-list-tile-title v-text="item.nombre"></v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>
            </v-menu>
          </td>

          <td :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.firma" small color="green" text-color="white">
              <v-avatar>
                <v-icon>done</v-icon>
              </v-avatar>
              Si
            </v-chip>
            <v-chip v-else small color="red" text-color="white">
              <v-avatar>
                <v-icon>error</v-icon>
              </v-avatar>
              No
            </v-chip>
          </td>

          <td :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.autofirma" small color="green" text-color="white">
              <v-avatar>
                <v-icon>done</v-icon>
              </v-avatar>
              Si
            </v-chip>
            <v-chip v-else small color="red" text-color="white">
              <v-avatar>
                <v-icon>error</v-icon>
              </v-avatar>
              No
            </v-chip>
          </td>

          <td :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.responsable_plan_estudios" small color="green" text-color="white">
              <v-avatar>
                <v-icon>done</v-icon>
              </v-avatar>
              Si
            </v-chip>
            <v-chip v-else small color="red" text-color="white">
              <v-avatar>
                <v-icon>error</v-icon>
              </v-avatar>
              No
            </v-chip>
          </td>

          <div v-if="hover" class="responsable-fix-1">
            <div class="justify-center layout grey lighten-3 responsable-fix-2">
              <v-tooltip bottom>
                <v-btn slot="activator" icon ripple @click.stop="eliminarResponsable(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay responsables registrados",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Nombre", value: "nombre" , visibility: true },
      { text: "Primer Apellido", value: "primer_apellido", visibility: true },
      { text: "Segundo Apellido", value: "segundo_apellido", visibility: true },
      { text: "Cargo", value: "cargo.nombre", visibility: false },
      { text: "CURP", value: "curp", visibility: false },
      { text: "Abreviatura de Grado Académico", value: "abreviatura_titulo", visibility: false },
      { text: "Firma de Títulos", value: "campuses_titulos", visibility: true },
      { text: "Firma de Certificados", value: "campuses_certificados", visibility: true },
      { text: "e.firma", value: "firma", visibility: true },
      { text: "Autofirma", value: "autofirma", visibility: true },
      { text: "Responsable de Plan de Estudios", value: "responsable_plan_estudios", visibility: false },

    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: 10,
      sortBy: 'nombre'
    },

    responsables: {},
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        this.initial()
      }
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val)
      } else {
        this.$router.replace('')
      }
      this.getResponsables()
    }
  },

  methods: {
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.responsables.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getResponsables() {
      this.loading = true
      axios
        .get(api.path("responsables"),  {
          params: {
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
        .then(res => {
          this.responsables = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getResponsablesPreviousPage() {
      this.loading = true
      axios
        .get(api.path("responsables"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.responsables.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.responsables = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getResponsablesNextPage() {
      this.loading = true
      axios
        .get(api.path("responsables"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.responsables.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.responsables = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarResponsable(val) {
      this.$confirm("¿Desea eliminar este responsable?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("responsables.single", { id: val.id }))
              .then(res => {
                this.$toast.success("Responsable eliminado correctamente.")
                this.selected = []
                this.getResponsables()
              })
              .catch(err => {
                this.$toast.error("No se puede eliminar al responsable.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarResponsables() {
      this.$confirm("¿Desea eliminar los responsables seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("responsables"), {
                params: {
                  ids: ids
                }
              })
              .then(res => {
                this.$toast.success("Responsables eliminados correctamente.")
                this.selected = []
                this.getResponsables()
              })
              .catch(err => {
                this.$toast.error("No se pueden eliminar los responsables.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.nombre + '\t'
        data = data + val.primer_apellido + '\t'
        data = data + val.segundo_apellido + '\t'
        data = data + val.cargo.nombre + '\t'
        data = data + val.curp + '\t'
        data = data + val.abreviatura_titulo + '\t'
        data = data + val.campuses_titulos.length  + '\t'
        data = data + val.campuses_certificados.length + '\t'
        data = data + val.firma + '\t'
        data = data + val.responsable_plan_estudios + '\n'

      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarResponsable(val) {
      this.$router.push({ name: 'responsables-mostrar', params: { id: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getResponsables()
      localStorage.setItem('responsables.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('responsables.headers', JSON.stringify(this.headers))
    },

    initial() {
      if (localStorage.hasOwnProperty('responsables.pagination')) {
        this.pagination = JSON.parse(localStorage.getItem('responsables.pagination'))
      }

      if (localStorage.hasOwnProperty('responsables.headers')) {
        this.headers = JSON.parse(localStorage.getItem('responsables.headers'))
      }

      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

      if (tab[0] === '#buscar') {
        let busqueda = decodeURI( tab[1] )
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
      } else {
        if (this.busqueda) {
          this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        }
      }

      this.getResponsables()
      }
  },

  mounted() {
    this.initial()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .responsable-fix-1 {
    position: relative !important;
	}

  .responsable-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 1px !important;
    height: 47px !important;
	}
</style>

