<template>
  <v-timeline>
    <v-timeline-item
      v-for="(actualizacion, i) in actualizaciones"
      :key="i"
      :color="actualizacion.color"
      small
    >
      <template v-slot:opposite>
        <span
          :class="`subheading ${actualizacion.color}--text`"
          v-text="actualizacion.date"
        ></span>
      </template>
      <div class="py-3">
        <h2 :class="`headline font-weight-light mb-3 ${actualizacion.color}--text`">
          v{{ actualizacion.version }}
          <v-chip
            v-if="version === actualizacion.version"
            label
            small
            color="indigo"
            text-color="white"
          >Actual</v-chip>
        </h2>
        <div>
          {{ actualizacion.message }}
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import { settings } from '~/config'

export default {
  data: () => ({
    actualizaciones: [],
    version: settings.version
  }),

  mounted() {
    this.getActualizaciones()
  },

  methods: {
   getActualizaciones() {
      this.dialog = false
      axios
        .get(api.path('actualizacion'))
        .then(res => {
          this.actualizaciones = res.data
        })
        .catch(err => {
        })
        .then(() => {
        })
    }
  }
}
</script>
