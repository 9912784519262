var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "curp_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.curp_alumno,
                  "error-messages": _vm.errors.curp_alumno,
                  rules: [_vm.rules.required("curp_alumno")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.curp_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "curp_alumno", $$v)
                  },
                  expression: "form.curp_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "matricula_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.matricula_alumno,
                  "error-messages": _vm.errors.matricula_alumno,
                  rules: [_vm.rules.required("matricula_alumno")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.matricula_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "matricula_alumno", $$v)
                  },
                  expression: "form.matricula_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "nombre_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.nombre_alumno,
                  "error-messages": _vm.errors.nombre_alumno,
                  rules: [_vm.rules.required("nombre_alumno")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.nombre_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nombre_alumno", $$v)
                  },
                  expression: "form.nombre_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "primer_apellido_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.primer_apellido_alumno,
                  "error-messages": _vm.errors.primer_apellido_alumno,
                  rules: [_vm.rules.required("primer_apellido_alumno")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.primer_apellido_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "primer_apellido_alumno", $$v)
                  },
                  expression: "form.primer_apellido_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "segundo_apellido_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.segundo_apellido_alumno,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.segundo_apellido_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "segundo_apellido_alumno", $$v)
                  },
                  expression: "form.segundo_apellido_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "genero_alumno"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.genero_alumno,
                  items: _vm.genero,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  "error-messages": _vm.errors.genero_alumno,
                  rules: [_vm.rules.required("genero_alumno")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.id_genero,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "id_genero", $$v)
                  },
                  expression: "form.id_genero"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "fecha_nacimiento_alumno"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.fecha_nacimiento_alumno,
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.fecha_nacimiento_alumno,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fecha_nacimiento_alumno", $$v)
                  },
                  expression: "form.fecha_nacimiento_alumno"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "tipo_certificacion"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.tipo_certificacion,
                  items: _vm.tipoCertificacion,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  "error-messages": _vm.errors.tipo_certificacion,
                  rules: [_vm.rules.required("tipo_certificacion")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.id_tipo_certificacion,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "id_tipo_certificacion", $$v)
                  },
                  expression: "form.id_tipo_certificacion"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "fecha_expedicion"
            ? _c(
                "div",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        lazy: "",
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "max-width": "290px",
                        "min-width": "290px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    {
                                      attrs: {
                                        label: _vm.labels.fecha_de_expedicion,
                                        disabled: _vm.loading,
                                        readonly: ""
                                      },
                                      model: {
                                        value: _vm.computedFechaExpedicion,
                                        callback: function($$v) {
                                          _vm.computedFechaExpedicion = $$v
                                        },
                                        expression: "computedFechaExpedicion"
                                      }
                                    },
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        662145314
                      ),
                      model: {
                        value: _vm.menuFechaExpedicion,
                        callback: function($$v) {
                          _vm.menuFechaExpedicion = $$v
                        },
                        expression: "menuFechaExpedicion"
                      }
                    },
                    [
                      _vm._v(" "),
                      _c("v-date-picker", {
                        attrs: { locale: "es-es" },
                        on: {
                          input: function($event) {
                            _vm.menuFechaExpedicion = false
                          }
                        },
                        model: {
                          value: _vm.fecha_expedicion,
                          callback: function($$v) {
                            _vm.fecha_expedicion = $$v
                          },
                          expression: "fecha_expedicion"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "entidad_federativa_expedicion"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.entidad_federativa_expedicion,
                  "error-messages": _vm.errors.entidad_federativa_expedicion,
                  rules: [_vm.rules.required("entidad_federativa_expedicion")],
                  disabled: _vm.loading,
                  items: _vm.entidadesFederativas,
                  "item-text": "nombre",
                  "item-value": "nombre",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.nombre_entidad_federativa_expedicion,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.form,
                      "nombre_entidad_federativa_expedicion",
                      $$v
                    )
                  },
                  expression: "form.nombre_entidad_federativa_expedicion"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }