<template>
  <div class="layout column align-center">
    <span class="headline mb-5">Nuestras alianzas</span>
    <div v-if="aliados.length > 0">
      <div
        v-for="aliado in aliados"
        :key="aliado.nombre"
        class="mb-4"
      >
        <v-img :lazy-src="aliado.logo" :src="aliado.logo" :alt="aliado.nombre" width="200"></v-img>
      </div>
    </div>
    <div v-else>
      <v-img lazy-src="/img/clip/welcome.png" src="/img/clip/welcome.png" alt="Acuerdo de términos de servicio" width="380"></v-img>
    </div>
  </div>
</template>

<script>
  import axios from "axios"
  import { api } from "~/config"

  export default {
    data: () => ({
      aliados: null,
    }),

    methods: {
      getAliados() {
        axios
          .get(api.path('aliados'))
          .then(res => {
            this.aliados = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      }
    },

    mounted() {
      this.getAliados()
    }
  }
</script>
