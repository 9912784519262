<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.numero_asignatura"
              v-model="asignatura.numero"
              @click:append="editarAsignatura('numero')"
              :append-icon="programa.estado === 'capturado' ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.clave_asignatura"
              v-model="asignatura.clave"
              @click:append="editarAsignatura('clave')"
              :append-icon="programa.estado === 'capturado' ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
      
            <v-text-field
              :label="labels.nombre_asignatura"
              v-model="asignatura.nombre"
              @click:append="editarAsignatura('nombre')"
              :append-icon="programa.estado === 'capturado' ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.tipo_asignatura"
              v-model="asignatura.tipo"
              @click:append="editarAsignatura('tipo')"
              :append-icon="programa.estado === 'capturado' ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.creditos_asignatura"
              v-model="asignatura.creditos"
              @click:append="editarAsignatura('creditos')"
              :append-icon="programa.estado === 'capturado' ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,

      asignatura: {},
      programa: {}
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'programa' })
      }
    },

    mounted() {
      this.getAsignatura()
      this.getPrograma()
    },

    methods: {
      getAsignatura() {
        axios.get(api.path('programas.asignaturas.single', { id: this.$route.params.id, id2: this.$route.params.id2 } ))
          .then(res => {
            this.asignatura = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getPrograma(id) {
        axios.get(api.path('programas.single', { id: this.$route.params.id } ))
          .then(res => {
            this.programa = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      editarAsignatura(campo) {
        this.$router.push({ name: "programa-asignatura-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
