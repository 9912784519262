<template>
	<div>
		<v-toolbar
			flat
			dark
			:clipped-left="$vuetify.breakpoint.mdAndUp"
			fixed
			app
			color="primary"
			ref="toolbar"
		>
		<v-toolbar-side-icon
			v-if="! barraDeBusqueda && (
				this.$route.name === 'panel' ||
				this.$route.name === 'perfil' ||
				this.$route.name === 'lotes-titulos' ||
				this.$route.name === 'lotes-certificados' ||
				this.$route.name === 'certificados' ||
				this.$route.name === 'programa' ||
				this.$route.name === 'campus' ||
				this.$route.name === 'carrera' ||
				this.$route.name === 'responsables' ||
				this.$route.name === 'usuario' ||
				this.$route.name === 'regla-folio-control' ||
				this.$route.name === 'plantilla-titulo' ||
				this.$route.name === 'configuracion'
				
			)"
			@click.stop="navToggle"
		>
		</v-toolbar-side-icon>
		
		<v-btn
			v-if="barraDeBusqueda && (
				this.$route.name === 'panel' ||
				this.$route.name === 'perfil' ||
				this.$route.name === 'lotes-titulos' ||
				this.$route.name === 'lotes-certificados' ||
				this.$route.name === 'certificados' ||
				this.$route.name === 'programa' ||
				this.$route.name === 'campus' ||
				this.$route.name === 'carrera' ||
				this.$route.name === 'responsables' ||
				this.$route.name === 'usuario' ||
				this.$route.name === 'regla-folio-control' ||
				this.$route.name === 'plantilla-titulo' ||
				this.$route.name === 'configuracion'
			)"
			icon
			@click="barraDeBusqueda = false"
		>
			<v-icon>arrow_back</v-icon>
		</v-btn>

		<v-btn v-if="
				this.$route.name === 'perfil-editar' ||
				this.$route.name === 'lotes-titulos-subir' ||
				this.$route.name === 'lotes-titulos-mostrar' ||
				this.$route.name === 'lotes-titulos-editar' ||
				this.$route.name === 'lotes-titulos-titulos' ||
				this.$route.name === 'lotes-titulos-titulos-agregar' ||
				this.$route.name === 'lotes-titulos-titulos-mostrar' ||
				this.$route.name === 'lotes-titulos-titulos-editar' ||
				this.$route.name === 'lotes-certificados-mostrar' ||
				this.$route.name === 'lotes-certificados-editar' ||
				this.$route.name === 'lotes-certificados-capturar' ||
				this.$route.name === 'lotes-certificados-certificados' ||
				this.$route.name === 'lotes-certificados-certificados-capturar' ||
				this.$route.name === 'lotes-certificados-certificados-mostrar' ||
				this.$route.name === 'lotes-certificados-certificados-editar' ||
				this.$route.name === 'lotes-certificados-certificados-calificaciones' ||
				this.$route.name === 'lotes-certificados-certificados-calificaciones-capturar' ||
				this.$route.name === 'lotes-certificados-certificados-calificaciones-mostrar' ||
				this.$route.name === 'lotes-certificados-certificados-calificaciones-editar' ||
				this.$route.name === 'programa-subir' ||
				this.$route.name === 'programa-mostrar' ||
				this.$route.name === 'programa-editar' ||
				this.$route.name === 'programa-asignatura' ||
				this.$route.name === 'programa-asignatura-agregar' ||
				this.$route.name === 'programa-asignatura-mostrar' ||
				this.$route.name === 'programa-asignatura-editar' ||
				this.$route.name === 'campus-agregar' ||
				this.$route.name === 'campus-mostrar' ||
				this.$route.name === 'campus-editar' ||
				this.$route.name === 'campus-plantilla-titulo' ||
				this.$route.name === 'campus-plantilla-titulo-asignar' ||
				this.$route.name === 'carrera-agregar' ||
				this.$route.name === 'carrera-mostrar' ||
				this.$route.name === 'carrera-editar' ||
				this.$route.name === 'responsables-agregar' ||
				this.$route.name === 'responsables-mostrar' ||
				this.$route.name === 'responsables-firma' ||
				this.$route.name === 'responsables-editar' ||
				this.$route.name === 'usuario-agregar' ||
				this.$route.name === 'usuario-solicitud' ||
				this.$route.name === 'usuario-solicitud-registrar' ||
				this.$route.name === 'usuario-mostrar' ||
				this.$route.name === 'usuario-editar' ||
				this.$route.name === 'regla-folio-control-agregar' ||
				this.$route.name === 'regla-folio-control-editar' ||
				this.$route.name === 'regla-folio-control-mostrar' ||
				this.$route.name === 'plantilla-titulo-agregar' ||
				this.$route.name === 'plantilla-titulo-editar' ||
				this.$route.name === 'plantilla-titulo-mostrar' ||
				this.$route.name === 'configuracion-editar' ||
				this.$route.name === 'configuracion-facturacion' ||
				this.$route.name === 'configuracion-facturacion-mostrar' ||
				this.$route.name === 'configuracion-plan' ||
				this.$route.name === 'acerca'
			"
			icon
			@click="$router.go(-1)"
		>
			<v-icon>arrow_back</v-icon>
		</v-btn>

		<router-link
			class="navbar-brand"
			:to="{ name: this.$route.name }"
		>
    		<v-toolbar-title class="white--text">
				<div v-if="this.$route.name === 'perfil'">
					<span class="title ml-3 mr-3">Perfil</span>
				</div>
				<div v-if="this.$route.name === 'perfil-editar'">
					<span class="title ml-3 mr-3">Editar Perfil</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-titulos'">
					<span class="title ml-3 mr-3">Lotes de Títulos</span>
				</div>
				<div v-if="this.$route.name === 'lotes-titulos-subir'">
					<span class="title ml-3 mr-3">Subir Lote de Títulos</span>
				</div>
				<div v-if="this.$route.name === 'lotes-titulos-mostrar'">
					<span class="title ml-3 mr-3">Lote de Títulos</span>
				</div>
				<div v-if="this.$route.name === 'lotes-titulos-editar'">
					<span class="title ml-3 mr-3">Editar Lote de Títulos</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-titulos-titulos'">
					<span class="title ml-3 mr-3">Lote de Títulos</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-titulos-titulos-agregar'">
					<span class="title ml-3 mr-3">Agregar Título</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-titulos-titulos-mostrar'">
					<span class="title ml-3 mr-3">Título</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-titulos-titulos-editar'">
					<span class="title ml-3 mr-3">Editar Título</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados'">
					<span class="title ml-3 mr-3">Lotes de Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-mostrar'">
					<span class="title ml-3 mr-3">Lote de Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-editar'">
					<span class="title ml-3 mr-3">Editar Lote de Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-capturar'">
					<span class="title ml-3 mr-3">Capturar Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados'">
					<span class="title ml-3 mr-3">Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-capturar'">
					<span class="title ml-3 mr-3">Capturar Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-mostrar'">
					<span class="title ml-3 mr-3">Certificado</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-editar'">
					<span class="title ml-3 mr-3">Editar Certificado</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-calificaciones'">
					<span class="title ml-3 mr-3">Calificaciones</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-calificaciones-capturar'">
					<span class="title ml-3 mr-3">Capturar Calificaciones</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-calificaciones-mostrar'">
					<span class="title ml-3 mr-3">Calificación</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'lotes-certificados-certificados-calificaciones-editar'">
					<span class="title ml-3 mr-3">Editar Calificación</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'certificados'">
					<span class="title ml-3 mr-3">Certificados</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'programa'">
					<span class="title ml-3 mr-3">Programas de Estudio</span>
				</div>
				<div v-if="this.$route.name === 'programa-mostrar'">
					<span class="title ml-3 mr-3">Programa de Estudio</span>
				</div>
				<div v-if="this.$route.name === 'programa-editar'">
					<span class="title ml-3 mr-3">Editar Programa de Estudio</span>
				</div>
				<div v-if="this.$route.name === 'programa-subir'">
					<span class="title ml-3 mr-3">Subir Programas de Estudio</span>
				</div>
				<div v-if="this.$route.name === 'programa-asignatura'">
					<span class="title ml-3 mr-3">Asignaturas</span>
				</div>
				<div v-if="this.$route.name === 'programa-asignatura-agregar'">
					<span class="title ml-3 mr-3">Agregar Asignatura</span>
				</div>
				<div v-if="this.$route.name === 'programa-asignatura-mostrar'">
					<span class="title ml-3 mr-3">Asignatura</span>
				</div>
				<div v-if="this.$route.name === 'programa-asignatura-editar'">
					<span class="title ml-3 mr-3">Editar Asignatura</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'campus'">
					<span class="title ml-3 mr-3">Campus</span>
				</div>
				<div v-if="this.$route.name === 'campus-agregar'">
					<span class="title ml-3 mr-3">Agregar Campus</span>
				</div>
				<div v-if="this.$route.name === 'campus-mostrar'">
					<span class="title ml-3 mr-3">Campus</span>
				</div>
				<div v-if="this.$route.name === 'campus-editar'">
					<span class="title ml-3 mr-3">Editar Campus</span>
				</div>
				<div v-if="this.$route.name === 'campus-plantilla-titulo'">
					<span class="title ml-3 mr-3">Plantillas Títulos</span>
				</div>
				<div v-if="this.$route.name === 'campus-plantilla-titulo-asignar'">
					<span class="title ml-3 mr-3">Agregar Plantilla Títulos</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'carrera'">
					<span class="title ml-3 mr-3">Carreras</span>
				</div>
				<div v-if="this.$route.name === 'carrera-agregar'">
					<span class="title ml-3 mr-3">Agregar Carrera</span>
				</div>
				<div v-if="this.$route.name === 'carrera-mostrar'">
					<span class="title ml-3 mr-3">Carrera</span>
				</div>
				<div v-if="this.$route.name === 'carrera-editar'">
					<span class="title ml-3 mr-3">Editar Carrera</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'responsables'">
					<span class="title ml-3 mr-3">Responsables</span>
				</div>
				<div v-if="this.$route.name === 'responsables-agregar'">
					<span class="title ml-3 mr-3">Agregar Responsable</span>
				</div>
				<div v-if="this.$route.name === 'responsables-mostrar'">
					<span class="title ml-3 mr-3">Responsable</span>
				</div>
				<div v-if="this.$route.name === 'responsables-editar'">
					<span class="title ml-3 mr-3">Editar Responsable</span>
				</div>
				<div v-if="this.$route.name === 'responsables-firma'">
					<span class="title ml-3 mr-3">Firma de Responsable</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'usuario'">
					<span class="title ml-3 mr-3">Usuarios</span>
				</div>
				<div v-if="this.$route.name === 'usuario-solicitud'">
					<span class="title ml-3 mr-3">Solicitudes de usuarios</span>
				</div>
				<div v-if="this.$route.name === 'usuario-solicitud-registrar'">
					<span class="title ml-3 mr-3">Registrar Usuario</span>
				</div>
				<div v-if="this.$route.name === 'usuario-agregar'">
					<span class="title ml-3 mr-3">Agregar Usuario</span>
				</div>
				<div v-if="this.$route.name === 'usuario-mostrar'">
					<span class="title ml-3 mr-3">Usuario</span>
				</div>
				<div v-if="this.$route.name === 'usuario-editar'">
					<span class="title ml-3 mr-3">Editar Usuario</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'panel'">
					<span class="title ml-3 mr-3">Panel</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'regla-folio-control'">
					<span class="title ml-3 mr-3">Reglas de Folios</span>
				</div>
				<div v-if="this.$route.name === 'regla-folio-control-mostrar'">
					<span class="title ml-3 mr-3">Regla de Folio</span>
				</div>
				<div v-if="this.$route.name === 'regla-folio-control-agregar'">
					<span class="title ml-3 mr-3">Agregar Regla de Folio</span>
				</div>
				<div v-if="this.$route.name === 'regla-folio-control-editar'">
					<span class="title ml-3 mr-3">Editar Regla de Folio</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'plantilla-titulo'">
					<span class="title ml-3 mr-3">Plantillas de Títulos</span>
				</div>
				<div v-if="this.$route.name === 'plantilla-titulo-mostrar'">
					<span class="title ml-3 mr-3">Plantilla de Título</span>
				</div>
				<div v-if="this.$route.name === 'plantilla-titulo-agregar'">
					<span class="title ml-3 mr-3">Agregar plantilla de Título</span>
				</div>
				<div v-if="this.$route.name === 'plantilla-titulo-editar'">
					<span class="title ml-3 mr-3">Editar plantilla de Título</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'configuracion'">
					<span class="title ml-3 mr-3">Configuración</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'configuracion-editar'">
					<span class="title ml-3 mr-3">Editar Configuración</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'configuracion-facturacion'">
					<span class="title ml-3 mr-3">Facturaciones</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'configuracion-facturacion-mostrar'">
					<span class="title ml-3 mr-3">Mostrar Facturación</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'configuracion-plan'">
					<span class="title ml-3 mr-3">Cambiar de plan</span>
				</div>
				<div v-if="! barraDeBusqueda && this.$route.name === 'acerca'">
					<span class="title ml-3 mr-3">Acerca de</span>
				</div>
			</v-toolbar-title>
    </router-link>

	 <v-layout row justify-center wrap>

    <v-flex lg8 sm11 xs12>

			<v-text-field
				v-if="(!$vuetify.breakpoint.xsOnly || barraDeBusqueda) && (
					this.$route.name === 'lotes-titulos' ||
					this.$route.name === 'lotes-certificados' ||
					this.$route.name === 'programa' ||
					this.$route.name === 'campus' ||
					this.$route.name === 'carrera' ||
					this.$route.name === 'responsables' ||
					this.$route.name === 'usuario' ||
					this.$route.name === 'regla-folio-control' ||
					this.$route.name === 'plantilla-titulo'

				)"
				@keyup.enter="guardarBusqueda() & $event.target.blur()"
				@click:clear="clearBusqueda"
				v-model="modelBusqueda"
				label="Buscar..."
				:background-color="barraDeBusqueda ? 'primary' : 'primary darken-1'"
				color="white"
				type="search"
				autocomplete="off"
				autocorrect="off"
				spellcheck="false"
				hide-details
				single-line
				clearable
				flat
				solo
			>

				<v-btn
					v-if="!$vuetify.breakpoint.xsOnly"
					slot="prepend-inner"
					icon
					@click="guardarBusqueda()"
				>
					<v-icon>search</v-icon>
				</v-btn>

				<v-btn
					v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa'"
					slot="append"
					icon
					@click="openFilterDialog()"
				>
					<v-icon>la-caret-down</v-icon>
				</v-btn>

			</v-text-field>

    </v-flex>

		</v-layout>

		<v-tooltip bottom
			v-if="($vuetify.breakpoint.xsOnly && !barraDeBusqueda) && (
				this.$route.name === 'lotes-titulos' ||
				this.$route.name === 'lotes-certificados' ||
				this.$route.name === 'programa' ||
				this.$route.name === 'campus' ||
				this.$route.name === 'carrera' ||
				this.$route.name === 'responsables' ||
				this.$route.name === 'usuario' ||
				this.$route.name === 'regla-folio-control' ||
				this.$route.name === 'plantilla-titulo'
			)"
		>
			<v-btn slot="activator" icon @click="barraDeBusqueda = true">
				<v-icon>search</v-icon>
			</v-btn>
			<span>Buscar</span>
		</v-tooltip>

		<v-btn
			v-if="$vuetify.breakpoint.xsOnly && barraDeBusqueda && this.$route.name === 'programa'"
			icon
			@click="openFilterDialog()"
		>
			<v-icon>la-caret-down</v-icon>
		</v-btn>

		<!--<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="descargarLayoutRVOESFederales">
				<v-icon>cloud_download</v-icon>
			</v-btn>
			<span>Descargar layout de RVOES federales</span>
		</v-tooltip>-->

		<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos' && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon @click="descargarFormatoTitulo">
				<v-icon>la-file-download</v-icon>
			</v-btn>
			<span>Descargar formato</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos' && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon :to="{ name: 'lotes-titulos-titulos-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar título</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos' && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon :to="{ name: 'lotes-titulos-subir' }">
				<v-icon>la-upload</v-icon>
			</v-btn>
			<span>Subir lote de títulos</span>
		</v-tooltip>

		<v-menu
			v-if="$vuetify.breakpoint.xsOnly && !barraDeBusqueda && this.$route.name === 'lotes-titulos' && auth.role !== 'Firmante'"
			bottom
			left
		>

			<v-btn
				slot="activator"
				icon
			>
				<v-icon>more_vert</v-icon>
			</v-btn>

			<v-list>
				<!--<v-list-tile
					v-if="auth.role === 'Administrador'"
					@click="descargarLayoutRVOESFederales"
				>
					<v-list-tile-title>Descargar layout de RVOES federales</v-list-tile-title>
				</v-list-tile>-->

				<v-list-tile
					@click="descargarFormatoTitulo"
				>
					<v-list-tile-title>Descargar formato de títulos</v-list-tile-title>
				</v-list-tile>
				
				<v-list-tile
					:to="{ name: 'lotes-titulos-titulos-agregar' }"
				>
					<v-list-tile-title>Agregar título</v-list-tile-title>
				</v-list-tile>

				<v-list-tile
					:to="{ name: 'lotes-titulos-subir' }"
				>
					<v-list-tile-title>Subir lote de títulos</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>
		
		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && (estadoLote.id === 2 || estadoLote.id === 3) && (auth.role === 'Administrador' || auth.role === 'Escolares')">
			<v-btn slot="activator" icon @click="validarCurpsLoteTitulos($route.params.id)">
				<v-icon>la-id-card</v-icon>
			</v-btn>
			<span>Validar CURP's con RENAPO</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="validarCurpsLoteTitulos($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && (estadoLote.id === 2 || estadoLote.id === 3) && (auth.role === 'Administrador' || auth.role === 'Escolares')"
		>
			Validar CURP's
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && (estadoLote.id === 3 || estadoLote.id === 7) && (auth.role === 'Administrador' || auth.role === 'Firmante')">
			<v-btn slot="activator" icon @click="firmadoLoteTitulos">
				<v-icon>la-stamp</v-icon>
			</v-btn>
			<span>Firmar lote de títulos</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="firmadoLoteTitulos"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && (estadoLote.id === 3 || estadoLote.id === 7) && (auth.role === 'Administrador' || auth.role === 'Firmante')"
		>
			Firmar lote
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 5">
			<v-btn slot="activator" icon @click="descargarPDFLote($route.params.id)">
				<v-icon>la-file-pdf</v-icon>
			</v-btn>
			<span>Descargar PDF de títulos</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="descargarPDFLote($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 5"
		>
			Descargar PDF
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && (estadoLote && (estadoLote.id === 4 || estadoLote.id === 5))">
			<v-btn slot="activator" icon @click="descargarXMLTitulos($route.params.id)">
				<v-icon>la-file-code</v-icon>
			</v-btn>
			<span>Descargar XML de títulos</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="descargarXMLTitulos($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && (estadoLote && (estadoLote.id === 4 || estadoLote.id === 5))"
		>
			Descargar XML
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 4 && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon @click="enviarALaSEP($route.params.id)">
				<v-icon>la-paper-plane</v-icon>
			</v-btn>
			<span>Enviar a la SEP</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="enviarALaSEP($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 4 && auth.role !== 'Firmante'"
		>
			Enviar a la SEP
		</v-btn>

		<v-tooltip bottom v-if="auth.live_mode && $vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 5 && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon @click="enviarAAutenticar($route.params.id)">
				<v-icon>la-external-link-alt</v-icon>
			</v-btn>
			<span>Integración MET</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="enviarAAutenticar($route.params.id)"
			v-if="auth.live_mode && !$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos' && estadoLote && estadoLote.id === 5 && auth.role !== 'Firmante'"
		>
			Integración MET
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos-mostrar' && estadoLote && estadoLote.id === 5">
			<v-btn slot="activator" icon @click="descargarPDFTitulo($route.params.id, $route.params.id2)">
				<v-icon>la-file-pdf</v-icon>
			</v-btn>
			<span>Descargar título en PDF</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="descargarPDFTitulo($route.params.id, $route.params.id2)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos-mostrar' && estadoLote && estadoLote.id === 5"
		>
			Descargar PDF
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos-mostrar' && estadoLote && estadoLote.id === 5">
			<v-btn slot="activator" icon @click="descargarXMLTitulo($route.params.id, $route.params.id2)">
				<v-icon>la-file-code</v-icon>
			</v-btn>
			<span>Descargar título en XML</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="descargarXMLTitulo($route.params.id, $route.params.id2)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-titulos-titulos-mostrar' && (estadoLote && (estadoLote.id === 4 || estadoLote.id === 5))"
		>
			Descargar XML
		</v-btn>

		<v-tooltip bottom v-if="this.$route.name === 'lotes-certificados' && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon @click="capturarCertificados">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Capturar certificados</span>
		</v-tooltip>

		<!--<v-tooltip bottom v-if="this.$route.name === 'lotes-certificados-certificados' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="descargarLoteCertificados($route.params.id)">
				<v-icon>cloud_download</v-icon>
			</v-btn>
			<span>Descargar lote de certificados</span>
		</v-tooltip>-->

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id === 2 || estadoLote.id === 3) && (auth.role === 'Administrador' || auth.role === 'Escolares')">
			<v-btn slot="activator" icon @click="validarCurpsLoteCertificados($route.params.id)">
				<v-icon>la-id-card</v-icon>
			</v-btn>
			<span>Validar CURP's con RENAPO</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="validarCurpsLoteCertificados($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id === 2 || estadoLote.id === 3) && (auth.role === 'Administrador' || auth.role === 'Escolares')"
		>
			Validar CURP's
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && estadoLote.id === 3 && (auth.role === 'Administrador' || auth.role === 'Firmante')">
			<v-btn slot="activator" icon @click="pagarLoteCertificados($route.params.id)">
				<v-icon>thumb_up</v-icon>
			</v-btn>
			<span>Marcar lote de certificados como pagado</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="pagarLoteCertificados($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && estadoLote.id === 3 && (auth.role === 'Administrador' || auth.role === 'Firmante')"
		>
			Marcar como pagado
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id === 6 || estadoLote.id === 7) && (auth.role === 'Administrador' || auth.role === 'Firmante')">
			<v-btn slot="activator" icon @click="firmarLoteCertificados">
				<v-icon>gavel</v-icon>
			</v-btn>
			<span>Firmar lote de certificados</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="firmarLoteCertificados"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id === 6 || estadoLote.id === 7) && (auth.role === 'Administrador' || auth.role === 'Firmante')"
		>
			Firmar lote
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id >= 3 && estadoLote.id <= 7) && auth.role !== 'Firmante'">
			<v-btn slot="activator" icon @click="enviarARegistrar($route.params.id)">
				<v-icon>la-flag-checkered</v-icon>
			</v-btn>
			<span>Integración MEC</span>
		</v-tooltip>

		<v-btn
			outline
			small
			@click="enviarARegistrar($route.params.id)"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados' && estadoLote && (estadoLote.id >= 3 && estadoLote.id <= 7) && auth.role !== 'Firmante'"
		>
			Integración MEC
		</v-btn>

		<v-btn icon @click="enviarCertificados" v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados-capturar'">
			<v-icon>la-save</v-icon>
		</v-btn>

		<v-btn
			outline
			small
			@click="enviarCertificados"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados-capturar'"
		>
			Guardar certificados
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados-mostrar'">
			<v-btn slot="activator" icon :to="{ name: 'lotes-certificados-certificados-calificaciones', params: { id: this.$route.params.id, id2: this.$route.params.id2 } }">
				<v-icon>la-clipboard-list</v-icon>
			</v-btn>
			<span>Calificaciones</span>
		</v-tooltip>

		<v-btn
			outline
			small
			:to="{ name: 'lotes-certificados-certificados-calificaciones', params: { id: this.$route.params.id, id2: this.$route.params.id2 } }"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'lotes-certificados-certificados-mostrar'"
		>
			Calificaciones
		</v-btn>

		<!--<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="descargarXLSXProgramasDeEstudio">
				<v-icon>file_download</v-icon>
			</v-btn>
			<span>Descargar programas de estudio</span>
		</v-tooltip>-->

		<!--<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="refactorizarAsignaturas">
				<v-icon>assignment_turned_in</v-icon>
			</v-btn>
			<span>Refactorizar asignaturas</span>
		</v-tooltip>-->

		<!--<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="descargarLayoutProgramasDeEstudio">
				<v-icon>cloud_download</v-icon>
			</v-btn>
			<span>Descargar layout de programas de estudio</span>
		</v-tooltip>-->

		<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa'">
			<v-btn slot="activator" icon @click="descargarFormatoPrograma">
				<v-icon>la-file-download</v-icon>
			</v-btn>
			<span>Descargar formato</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa'">
			<v-btn slot="activator" icon :to="{ name: 'programa-subir' }">
				<v-icon>la-upload</v-icon>
			</v-btn>
			<span>Subir programas de estudio</span>
		</v-tooltip>

		<v-menu
			v-if="$vuetify.breakpoint.xsOnly && !barraDeBusqueda && this.$route.name === 'programa'"
			bottom
			left
		>

			<v-btn
				slot="activator"
				icon
			>
				<v-icon>more_vert</v-icon>
			</v-btn>

		
			<v-list>
				<!--<v-list-tile
					v-if="auth.role === 'Administrador'"
					@click="descargarXLSXProgramasDeEstudio"
				>
					<v-list-tile-title>Descargar programas de estudio</v-list-tile-title>
				</v-list-tile>-->	

				<!--<v-list-tile
					v-if="auth.role === 'Administrador'"
					@click="refactorizarAsignaturas"
				>
					<v-list-tile-title>Refactorizar asignaturas</v-list-tile-title>
				</v-list-tile>-->

				<!--<v-list-tile
					v-if="auth.role === 'Administrador'"
					@click="descargarLayoutProgramasDeEstudio"
				>
					<v-list-tile-title>Descargar layout de programas de estudio</v-list-tile-title>
				</v-list-tile>-->

				<v-list-tile
					@click="descargarFormatoPrograma"
				>
					<v-list-tile-title>Descargar formato de programas de estudio</v-list-tile-title>
				</v-list-tile>

				<v-list-tile
					:to="{ name: 'programa-subir' }"
				>
					<v-list-tile-title>Subir programas de estudio</v-list-tile-title>
				</v-list-tile>
			</v-list>
		</v-menu>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'programa-mostrar'">
			<v-btn slot="activator" icon :to="{ name: 'programa-asignatura' }">
				<v-icon>la-clipboard-list</v-icon>
			</v-btn>
			<span>Asignaturas</span>
		</v-tooltip>

		<v-btn
		    outline
			small
		    :to="{ name: 'programa-asignatura' }"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa-mostrar'"
		>
			Asignaturas
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'programa-asignatura'">
			<v-btn slot="activator" icon :to="{ name: 'programa-asignatura-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar asignatura</span>
		</v-tooltip>

		<v-btn
		    outline
			small
		    :to="{ name: 'programa-asignatura-agregar' }"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'programa-asignatura'"
		>
			Agregar asignatura
		</v-btn>

		<!--<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'responsables-mostrar'">
			<v-btn slot="activator" icon @click="subirFirma()">
				<v-icon>la-signature</v-icon>
			</v-btn>
			<span>Subir e.firma</span>
		</v-tooltip>-->

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'responsables'">
			<v-btn slot="activator" icon :to="{ name: 'responsables-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar responsable</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'campus'">
			<v-btn slot="activator" icon :to="{ name: 'campus-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar campus</span>
		</v-tooltip>

		<v-btn
		    outline
			small
		    @click="plantillasTitulosCampus"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'campus-mostrar'"
		>
			Plantillas Títulos
		</v-btn>

		<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'campus-plantilla-titulo' && this.auth.membresia.tipo_plan.id === 3">
			<v-btn slot="activator" icon :to="{ name: 'campus-plantilla-titulo-asignar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Asignar plantilla</span>
		</v-tooltip>

		<v-btn
		    outline
			small
		    :to="{ name: 'campus-plantilla-titulo-asignar' }"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'campus-plantilla-titulo'"
		>
			Asignar plantilla
		</v-btn>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'carrera'">
			<v-btn slot="activator" icon :to="{ name: 'carrera-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar carrera</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'usuario'">
			<v-btn slot="activator" icon :to="{ name: 'usuario-solicitud' }">
				<v-icon>la-bell</v-icon>
			</v-btn>
			<span>Solicitudes de registro</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'usuario'">
			<v-btn slot="activator" icon :to="{ name: 'usuario-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar usuario</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'regla-folio-control'">
			<v-btn slot="activator" icon :to="{ name: 'regla-folio-control-agregar' }">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar regla de folio</span>
		</v-tooltip>

		<v-tooltip bottom v-if="!barraDeBusqueda && this.$route.name === 'plantilla-titulo'">
			<v-btn slot="activator" icon @click="agregarPlantillaTitulo">
				<v-icon>la-plus</v-icon>
			</v-btn>
			<span>Agregar plantilla de título</span>
		</v-tooltip>

		<!--<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'configuracion' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="mostrarPlanes()">
				<v-icon>la-store-alt</v-icon>
			</v-btn>
			<span>Cambiar de plan</span>
		</v-tooltip>-->

		<!--<v-btn
			outline
			small
			@click="mostrarPlanes()"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'configuracion' && auth.role === 'Administrador'"
		>
			Cambiar de plan
		</v-btn>-->
		
		<!--<v-tooltip bottom v-if="$vuetify.breakpoint.xsOnly && this.$route.name === 'configuracion' && auth.role === 'Administrador'">
			<v-btn slot="activator" icon @click="mostrarFacturaciones()">
				<v-icon>la-receipt</v-icon>
			</v-btn>
			<span>Ver facturaciones</span>
		</v-tooltip>-->

		<!--<v-btn
			outline
			small
			@click="mostrarFacturaciones()"
			v-if="!$vuetify.breakpoint.xsOnly && this.$route.name === 'configuracion' && auth.role === 'Administrador'"
		>
			Ver facturaciones
		</v-btn>-->
	</v-toolbar>

	<v-dialog v-model="loadingDialog" persistent width="300">
		<v-card color="primary" dark>
			<v-card-text>Espera un momento
				<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
			</v-card-text>
		</v-card>
	</v-dialog>

	<firmado-lote ref="firmadoLote" @success="successRouterBack"></firmado-lote>

	<v-dialog
		v-model="firmarCertificadosDialog"
		persistent
		max-width="500px"
	>
		<v-card>
			<v-card-title>
				<span class="headline">Firmar lote de certificados</span>
			</v-card-title>

			<v-card-text>
				<v-list two-line subheader>
					<v-list-tile
						v-for="(firmante, index) in firmantes"
						:key="index"
						avatar
					>
						<v-list-tile-avatar>
							<v-icon ripple>person</v-icon>
						</v-list-tile-avatar>

						<v-list-tile-content>
							<v-list-tile-title>{{ firmante.nombre + ' ' + firmante.primer_apellido + ' ' + firmante.segundo_apellido}}</v-list-tile-title>
							<v-list-tile-sub-title>{{ firmante.curp }}</v-list-tile-sub-title>
						</v-list-tile-content>

						<v-list-tile-action>
							<div v-if="firmante.firma">
								<v-btn flat disabled v-if="firmante.firmado">
									Firmado
								</v-btn>
								<div v-else>
									<v-btn v-if="firmante.permitido" flat color="primary" :disabled="loadingDialog" @click="firmanteLote(firmante.id)">
										Firmar
									</v-btn>
									<v-btn v-else flat disabled>
										Pendiente
									</v-btn>
								</div>
							</div>
							<v-tooltip bottom v-else>
								<v-btn slot="activator" icon ripple>
									<v-icon color="red lighten-1">error</v-icon>
								</v-btn>
								<span>e.firma de firmante no configurada</span>
							</v-tooltip>							
						</v-list-tile-action>
					</v-list-tile>
        		</v-list>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="darken-1" flat @click="firmarCertificadosDialog = false">Volver</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<v-dialog
		v-model="folioSolicitudAutenticacionDialog"
		max-width="400px"
	>
		<v-stepper v-model="stepper" vertical>
			<v-stepper-step :complete="stepper > 1" step="1">Descarga los folios de títulos</v-stepper-step>
			<v-stepper-content step="1">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline @click="descargarFoliosTitulos($route.params.id)">
							<v-icon left>la-file-download</v-icon>Descargar folios
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="folioSolicitudAutenticacionDialog = false">Cancelar</v-btn>
				<v-btn flat color="primary" @click="stepper = 2">Continuar</v-btn>
			</v-stepper-content>

			<v-stepper-step :complete="stepper > 2" step="2">Autentica los títulos en el MET</v-stepper-step>
			<v-stepper-content step="2">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline href="https://met.sep.gob.mx/met/" target="_blank">
							<v-icon left>la-external-link-square-alt</v-icon>Ir al MET
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="stepper = 1">Regresar</v-btn>
				<v-btn flat color="primary" @click="stepper = 3">Continuar</v-btn>
			</v-stepper-content>

			<v-stepper-step step="3">Sube los XML de los títulos autenticados</v-stepper-step>
			<v-stepper-content step="3">
				<v-text-field
					:label="labels.archivo_titulos_autenticados"
					append-icon="attach_file"
				></v-text-field>
				<v-btn flat @click="stepper = 2">Regresar</v-btn>
				<v-btn flat color="primary" @click="marcarAutenticado">Subir títulos</v-btn>
			</v-stepper-content>

		</v-stepper>
	</v-dialog>

	<v-dialog
		v-model="folioDialog"
		max-width="500px"
	>
		<v-stepper v-model="stepper" vertical>
			<v-stepper-step :complete="stepper > 1" step="1">Descarga los formatos de pago</v-stepper-step>
			<v-stepper-content step="1">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline @click="descargarFormatoPagosCertificados($route.params.id)">
							<v-icon left>la-file-download</v-icon>Descargar formatos de pago
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="folioDialog = false">Cancelar</v-btn>
				<v-btn
					flat
					color="primary"
					@click="stepper = 2"
					:disabled="estadoLote && estadoLote.id === 3"
				>Continuar</v-btn>
			</v-stepper-content>

			<v-stepper-step :complete="stepper > 2" step="2">Captura el folio de pago</v-stepper-step>
			<v-stepper-content step="2">
				<v-text-field
					:label="labels.folio_pago"
					v-model="folioPago"
					:error-messages="errors.folio_pago"
					:rules="[rules.required('folio_pago')]"
					:disabled="loteCertificados && loteCertificados.estado_lote_id !== 6 || auth.role === 'Firmante'"
				></v-text-field>
				<v-alert
					v-if="loteCertificados && loteCertificados.estado_lote_id === 7"
					:value="true"
					color="info"
					icon="info"
					outline
                >{{ "El lote de certificados está en proceso de firma" }}</v-alert>
				<v-btn flat @click="stepper = 1">Regresar</v-btn>
				<v-btn
					v-if="loteCertificados && loteCertificados.estado_lote_id === 4"
					flat
					color="primary"
					@click="stepper = 3"
					:disabled="loteCertificados && loteCertificados.estado_lote_id !== 4"
				>Continuar</v-btn>
				<v-btn
					v-else-if="auth.role !== 'Firmante'"
					flat
					color="primary"
					@click="saveFolioPago"
					:disabled="loteCertificados && loteCertificados.estado_lote_id !== 6 || auth.role === 'Firmante'"
				>Guardar Folio</v-btn>
			</v-stepper-content>

			<v-stepper-step :complete="stepper > 3" step="3">Descarga los XML de los certificados y copia el folio de pago</v-stepper-step>
			<v-stepper-content step="3">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline @click="descargarXMLCertificados($route.params.id)">
							<v-icon left>la-file-download</v-icon>
							<span>Descargar certificados</span>
						</v-btn>
						<v-btn flat @click="copiarFolio">
							{{ folioPago }}
							<v-icon right>la-copy</v-icon>
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="stepper = 2" :disabled="auth.role !== 'Administrador'">Regresar</v-btn>
				<v-btn flat color="primary" @click="stepper = 4">Continuar</v-btn>
			</v-stepper-content>

			<v-stepper-step :complete="stepper > 4" step="4">Registra los certificados en el MEC</v-stepper-step>
			<v-stepper-content step="4">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline :href="auth.live_mode ? 'https://mec.sep.gob.mx/mec/' : 'https://mecqa.siged.sep.gob.mx/mec/'" target="_blank">
							<v-icon left>la-external-link-square-alt</v-icon>Ir al MEC
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="stepper = 3">Regresar</v-btn>
				<v-btn flat color="primary" @click="stepper = 5">Continuar</v-btn>
			</v-stepper-content>

			<v-stepper-step step="5">Marca los certificados como registrados (Opcional)</v-stepper-step>
			<v-stepper-content step="5">
				<v-card flat>
					<v-card-text class="layout column align-center">
						<v-btn outline @click="marcarRegistrado">
							<v-icon left>la-check</v-icon>Marcar certificados
						</v-btn>
					</v-card-text>
				</v-card>
				<v-btn flat @click="stepper = 4">Regresar</v-btn>
				<v-btn flat color="primary" @click="folioDialog = false">Continuar</v-btn>
			</v-stepper-content>
		</v-stepper>
	</v-dialog>

	<v-dialog
		v-model="filterDialog"
		persistent
		max-width="500px"
		:fullscreen="$vuetify.breakpoint.xsOnly"
	>
	
		<v-toolbar 
			v-if="$vuetify.breakpoint.xsOnly"
			dark
			fixed
			dense
			color="primary"
		>
			<v-btn icon>
				<v-icon
					@click="filterDialog = false"
				>
					arrow_back
				</v-icon>
			</v-btn>

			<router-link
				class="navbar-brand"
				:to="{ name: this.$route.name }"
			>
				<v-toolbar-title class="white--text">
					Filtro de búsqueda
				</v-toolbar-title>
			</router-link>

			<v-spacer></v-spacer>

			<v-toolbar-items>
				<v-btn flat @click="aplicarFiltro">Aplicar</v-btn>
			</v-toolbar-items>
		</v-toolbar>

		<v-card>
			<v-card-title>
				<span class="headline">Filtro de búsqueda</span>
			</v-card-title>
			<v-card-text>

				<v-autocomplete
					v-if="this.$route.name === 'programa'"
					@keyup.enter="$event.target.blur()"
					:label="labels.campus"
					v-model="form.campus"
					:items="campuses"
					item-text="nombre"
					item-value="nombre"
					attach
					chips
					multiple
					deletable-chips
					dense
					hide-no-data
				></v-autocomplete>

				<v-autocomplete
					v-if="this.$route.name === 'programa'"
					@keyup.enter="$event.target.blur()"
					:label="labels.carrera"
					v-model="form.carrera"
					:items="carreras"
					item-text="nombre"
					item-value="nombre"
					attach
					chips
					multiple
					deletable-chips
					dense
					hide-no-data
				></v-autocomplete>

				<v-select
					v-if="this.$route.name === 'programa'"
					:label="labels.modalidad"
					v-model="form.modalidad"
					:items="modalidades"
					item-text="nombre"
					item-value="nombre"
					attach
					chips
					multiple
					deletable-chips
					dense
					hide-no-data
				></v-select>

				<v-select
					v-if="this.$route.name === 'programa'"
					:label="labels.tipo_periodo"
					v-model="form.tipo_periodo"
					:items="tipos_periodo"
					item-text="nombre"
					item-value="nombre"
					attach
					chips
					multiple
					deletable-chips
					dense
					hide-no-data
				></v-select>

				<v-select
					v-if="this.$route.name === 'programa'"
					:label="labels.tipo_rvoe"
					v-model="form.tipo_rvoe"
					:items="['ESTATAL', 'FEDERAL']"
					attach
					chips
					multiple
					deletable-chips
					dense
				></v-select>

				<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.numero_rvoe"
					v-model="form.numero_rvoe"
				></v-text-field>

				<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.fecha_rvoe"
					v-model="form.fecha_rvoe"
				></v-text-field>

				<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.clave_plan"
					v-model="form.clave_plan"
				></v-text-field>

				<!--<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.calificacion_maxima"
					v-model="form.calificacion_maxima"
				></v-text-field>

				<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.calificacion_minima"
					v-model="form.calificacion_minima"
				></v-text-field>

				<v-text-field
					v-if="this.$route.name === 'programa'"
					:label="labels.calificacion_minima_aprobatoria"
					v-model="form.calificacion_minima_aprobatoria"
				></v-text-field>-->

			</v-card-text>
			<v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
				<v-spacer></v-spacer>
				<v-btn color="primary darken-1" flat @click="filterDialog = false">Cancelar</v-btn>
				<v-btn color="primary darken-1" flat @click="aplicarFiltro">Aplicar</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

	<funcion-premium></funcion-premium>
	</div>
</template>

<script>
	import axios from "axios"
	import { mapGetters } from "vuex"
	import { api } from "~/config"
	import { settings } from '~/config'
	import Form from '~/mixins/form'

	export default {
		mixins: [Form],

		data: () => ({
	      	passwordHidden: true,
			loadingDialog: false,
			filterDialog: false,
			firmarCertificadosDialog: false,
			folioSolicitudAutenticacionDialog: false,
			folioDialog: false,

			loteTitulosSeleccionado: null,
			loteCertificados: {},
			loteCertificadosSeleccionado: null,
			documentoAFirmar: null,

			modelBusqueda: null,

			barraDeBusqueda: false,

			form: {
				campus: null,
				carrera: null,
				modalidad: null,
				tipo_periodo: null,
				tipo_rvoe: null,
				clave_plan: null,
    		    numero_rvoe: null,
				fecha_rvoe: null,
				calificacion_maxima: null,
				calificacion_minima: null,
				calificacion_minima_aprobatoria: null,
			},
			
			campuses: [],
     		carreras: [],
			modalidades: [],
			tipos_periodo: [],
			firmantes: [],
			todosFirmaron: false,

			stepper: null,
			folioPago: '',
		}),

		components: {
			'firmado-lote': require('./FirmadoLote').default,
			'funcion-premium': require('./FuncionPremium').default,
		},

		computed: mapGetters({
			auth: 'auth/user',
			busqueda: 'auth/busqueda',
			estadoLote: 'admin/estadoLote',
			titulosIds: 'admin/titulosIds',
		}),

		watch: {
			busqueda(val) {
				this.modelBusqueda = val
			},
		},

		mounted() {
			window.addEventListener( 'resize', this.updateToolBarSize )
		},

		methods: {			
			successRouterBack() {
				this.$router.go(-1)
			},

			updateToolBarSize() {
				if (this.$refs.toolbar) {
					console.log('updateToolBarSize:' + this.$refs.toolbar.computedHeight)
				}
			},

			navToggle() {
				this.$emit('navToggle')
			},

			clearBusqueda() {
				this.modelBusqueda = null
				this.guardarBusqueda()
			},

			guardarBusqueda() {
				this.$store.dispatch('auth/guardarBusqueda', { busqueda: this.modelBusqueda })
			},

			enviarCertificados() {
				this.$store.dispatch('admin/guardarEnviarCertificados', { enviarCertificados: true })
			},

			mostrarPlanes() {
				this.$router.push({ name: "configuracion-plan" })
			},

			mostrarFacturaciones() {
				this.$router.push({ name: "configuracion-facturacion" })
			},

			copiarFolio() {
				let me = this
				this.$copyText(this.folioPago, this.$refs.folio).then(function (e) {
					me.$toast.success("Folio de pago copiado al portapapeles.")
				}, function (e) {
					me.$toast.success("No se puede copiar el folio de pago al portapapeles.")
				})
			},

			pagarLoteCertificados(id) {
				this.loteCertificadosSeleccionado = id
				
				this.$confirm('Esta acción marcara como pagado el lote de certificados.', {
					buttonTrueText: 'Continuar',
					color: 'primary',
					icon: '',
					title: 'Marcar como pagado',
				}).then(
					res => {
						if (res) {
							this.loadingDialog = true
							axios
								.post(api.path('lotes_certificados.pagar', { id: this.loteCertificadosSeleccionado }))
								.then(res => {
									this.$toast.success('El lote de certificados se ha marcados como pagado.')
									this.$router.go(-1)
								})
								.catch(err => {
									this.$toast.error('Vuelva a intentarlo mas tarde.')
									this.handleErrors(err.response.data.errors)
								})
								.then(() => {
									this.loadingDialog = false
								})
						}
					}
				)
			},

			descargarLoteCertificados(id) {
				this.loadingDialog = true
				axios
					.get(api.path("lotes_certificados.xlsx", { id: id }), { responseType: "blob" })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'certificados_' + id + '.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error("Vuelva a intentarlo mas tarde.")
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			firmadoLoteTitulos() {
				this.$refs.firmadoLote.open('titulos', this.$route.params.id)
			},

			plantillasTitulosCampus() {
				this.$router.push({ name: 'campus-plantilla-titulo' })
			},

			agregarPlantillaTitulo() {
				if (this.auth.membresia.tipo_plan.id === 3) {
					this.$router.push({ name: 'plantilla-titulo-agregar' })
				} else {
					this.funcionPremium()
				}
			},

			validarCurpsLoteTitulos(id) {
				if (this.auth.membresia.tipo_plan.id === 3) {
					this.loadingDialog = true
					axios
						.post(api.path("titulos.validar_curps", { id: id }))
						.then(res => {
							this.$toast.success(res.data.message)
							this.$router.go(-1)
						})
						.catch(err => {
							this.handleErrors(err.response.data.errors)
						})
						.then(() => {
							this.loadingDialog = false
						})
				} else {
					this.funcionPremium()
				}
			},

			validarCurpsLoteCertificados(id) {
				if (this.auth.membresia.tipo_plan.id === 3) {
					this.loadingDialog = true
					axios
						.post(api.path("lotes_certificados.validar_curps", { id: id }))
						.then(res => {
							this.$toast.success(res.data.message)
							this.$router.go(-1)
						})
						.catch(err => {
							this.handleErrors(err.response.data.errors)
						})
						.then(() => {
							this.loadingDialog = false
						})
				} else {
					this.funcionPremium()
				}
			},

			funcionPremium() {
				this.$store.dispatch('admin/guardarFuncionPremiumDialog', { funcionPremiumDialog: true })
			},

			firmarLoteCertificados() {
				this.$refs.firmadoLote.open('certificados', this.$route.params.id)
				/*this.folioPago = ''
				this.firmarCertificadosDialog = true
				this.documentoAFirmar = 'Certificados'
				this.loteCertificadosSeleccionado = this.$route.params.id
				this.getFirmantesCertificados()*/
			},

			firmanteLote(responsable_id) {
				if(this.documentoAFirmar === 'Certificados') {
					this.loadingDialog = true
					axios
						.post(api.path('lotes_certificados.firmantes.firmar', { id: this.loteCertificadosSeleccionado, id2: responsable_id }))
						.then(res => {
							this.$toast.success('Firmado correctamente.')
						})
						.catch(err => {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
							this.handleErrors(err.response.data.errors)
						})
						.then(() => {
							this.getFirmantesCertificados()
							this.loadingDialog = false
						})
				}
			},

			saveFolioPago() {
				axios
					.put(api.path('lotes_certificados.folio_pago', { id: this.loteCertificadosSeleccionado }), {
						folio_pago: this.folioPago
					})
					.then(res => {
						this.$toast.success(res.data.message)
					})
					.catch(err => {
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						//this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						//this.loadingDialog = false
					})
			},

			marcarAutenticado() {
				this.folioSolicitudAutenticacionDialog = false
				this.loadingDialog = true
				axios
					.post(api.path('titulos.autenticar', { id: this.loteTitulosSeleccionado }))
					.then(res => {
						this.$toast.success('Lote de títulos marcado como autenticado.')
						this.$router.go(-1)
					})
					.catch(err => {
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			marcarRegistrado() {
				this.folioDialog = false
				this.loadingDialog = true
				axios
					.post(api.path('lotes_certificados.registrar', { id: this.loteCertificadosSeleccionado }))
					.then(res => {
						this.$toast.success('Lote de certificados marcado como registrado.')
						this.$router.go(-1)
					})
					.catch(err => {
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			enviarALaSEP(id) {
				this.loadingDialog = true
				axios
					.post(api.path("titulos.sep", { id: id }))
					.then(res => {
						this.$toast.success(res.data.message)
						this.$router.go(-1)
					})
					.catch(err => {
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarXMLTitulos(id) {
				this.loadingDialog = true
				axios
					.get(api.path("titulos.zip", { id: id }), { responseType: "blob" })
					.then(res => {
						if (res.data.type === "application/zip") {
							let blob = new Blob([res.data], { type: "application/zip" })
							let link = document.createElement("a")
							link.href = window.URL.createObjectURL(blob)
							link.download = "titulos_xml_" + id + ".zip"
							link.click()
						} else {
							this.$toast.error("Vuelva a intentarlo mas tarde.")
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error("Vuelva a intentarlo mas tarde.")
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarPDFLote(id) {
				this.loadingDialog = true

				let paramsIds = this.titulosIds ? (this.titulosIds.length > 0 ? { ids: this.titulosIds.join() } : null) : null

				axios
					.get(api.path("titulos.lotepdf", { id: id }), {
						responseType: "blob",
						params: paramsIds
					})
					.then(res => {
						if (res.data.type === "application/zip") {
							let blob = new Blob([res.data], { type: "application/zip" })
							let link = document.createElement("a")
							link.href = window.URL.createObjectURL(blob)
							link.download = "titulos_pdf_" + id + ".zip"
							link.click()
						} else {
							this.$toast.error("Vuelva a intentarlo mas tarde.")
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error("Vuelva a intentarlo mas tarde.")
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarFoliosTitulos(id) {
				this.loadingDialog = true
				axios
					.get(api.path('titulos.folios', { id: id }), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'formato_folios.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			enviarAAutenticar(id) {
				this.stepper = 1
				this.folioSolicitudAutenticacionDialog = true
				this.loteTitulosSeleccionado = id
			},

			enviarARegistrar(id) {
				this.folioDialog = true
				this.folioPago = ''
				this.loteCertificadosSeleccionado = id

				if (this.estadoLote) {
					switch (this.estadoLote.id) {
						case 3:
							this.stepper = 1
							break;
					
						case 6:
							this.stepper = 2
							this.getLoteCertificados()
							break;

						case 7:
							this.stepper = 2
							this.getLoteCertificados()
							break;

						case 4:
							this.getLoteCertificados()
							this.stepper = 3
							break;
					}
				}
			},

			getLoteCertificados() {
				axios
					.get(api.path('lotes_certificados.single', { id: this.$route.params.id }))
					.then(res => {
						this.loteCertificados = res.data
						if (this.loteCertificados.folio_pago !== null) {
							this.folioPago = this.loteCertificados.folio_pago
						}
					})
					.catch(err => {
					})
					.then(() => {
					})
      		},

			descargarXMLTitulo(id, id2) {
				this.loadingDialog = true
				axios
					.get(api.path('titulos.titulos.xml', { id: this.$route.params.id, id2: this.$route.params.id2 }), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/xml') {
							let blob = new Blob([res.data], { type: 'application/xml' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)

							const contentDisposition = res.headers['content-disposition'];
							let fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/["']/g, "");

							link.download = fileName
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarPDFTitulo(id, id2) {
				this.loadingDialog = true
				axios
					.get(api.path('titulos.titulos.pdf', { id: this.$route.params.id, id2: this.$route.params.id2 }), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/pdf') {
							let blob = new Blob([res.data], { type: 'application/pdf' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)

							const contentDisposition = res.headers['content-disposition'];
							let fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/["']/g, "");

							link.download = fileName
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarXMLCertificados(id) {
				this.loadingDialog = true
				axios
					.get(api.path("lotes_certificados.zip", { id: id }), { responseType: "blob" })
					.then(res => {
						if (res.data.type === "application/zip") {
							let blob = new Blob([res.data], { type: "application/zip" })
							let link = document.createElement("a")
							link.href = window.URL.createObjectURL(blob)
							link.download = "certificados_xml_" + id + ".zip"
							link.click()
						} else {
							this.$toast.error("Vuelva a intentarlo mas tarde.")
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error("Vuelva a intentarlo mas tarde.")
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			openFilterDialog() {
				this.filterDialog = true
				if(this.$route.name === 'programa') {
					this.getCampuses()
					this.getCarreras()
					this.getCatalogoModalidad()
					this.getCatalogoTipoPeriodo()
				}
			},

			aplicarFiltro() {
				this.filterDialog = false
				this.modelBusqueda = ''
				if(this.$route.name === 'programa') {
					if(this.form.campus && this.form.campus.length > 0) {
						this.modelBusqueda += " campuses:(" + this.form.campus.join(',') + ")"
					}
					if(this.form.carrera && this.form.carrera.length > 0) {
						this.modelBusqueda += " carreras:(" + this.form.carrera.join(',') + ")"
					}
					if(this.form.modalidad && this.form.modalidad.length > 0) {
						this.modelBusqueda += " modalidades:(" + this.form.modalidad.join(',') + ")"
					}
					if(this.form.tipo_periodo && this.form.tipo_periodo.length > 0) {
						this.modelBusqueda += " tipos_periodo:(" + this.form.tipo_periodo.join(',') + ")"
					}
					if(this.form.tipo_rvoe) {
						this.modelBusqueda += " tipos_rvoe:(" + this.form.tipo_rvoe + ")"
					}
					if(this.form.clave_plan) {
						this.modelBusqueda += " clave_plan:(" + this.form.clave_plan + ")"
					}
					if(this.form.numero_rvoe) {
						this.modelBusqueda += " numero_rvoe:(" + this.form.numero_rvoe + ")"
					}
					if(this.form.fecha_rvoe) {
						this.modelBusqueda += " fecha_rvoe:(" + this.form.fecha_rvoe + ")"
					}
					if(this.form.calificacion_maxima) {
						this.modelBusqueda += " calificacion_maxima:(" + this.form.calificacion_maxima + ")"
					}
					if(this.form.calificacion_minima) {
						this.modelBusqueda += " calificacion_minima:(" + this.form.calificacion_minima + ")"
					}
					if(this.form.calificacion_minima_aprobatoria) {
						this.modelBusqueda += " calificacion_minima_aprobatoria:(" + this.form.calificacion_minima_aprobatoria + ")"
					}
				}
				this.guardarBusqueda()
			},

			getFirmantesCertificados() {
				axios
					.get(api.path('lotes_certificados.firmantes', { id: this.$route.params.id } ))
					.then(res => {
						this.firmantes = res.data
						let firmaron = true
						this.firmantes.forEach(firmante => {
							if (!firmante.firmado) {
								firmaron = false
							}
						});
						this.todosFirmaron = firmaron
					})
					.catch(err => {
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {})
			},

	getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCarreras() {
        axios
        .get(api.path('carreras'))
        .then(res => {
            this.carreras = res.data.data
        })
        .catch(err => {
        	this.handleErrors(err.response.data.errors)
        })
        .then(() => {})
      },

      getCatalogoModalidad() {
        axios
        .get(api.path('catalogos.modalidad'))
        .then(res => {
            this.modalidades = res.data
        })
        .catch(err => {
        	this.handleErrors(err.response.data.errors)
        })
          .then(() => {})
		},
			
		getCatalogoTipoPeriodo() {
			axios
			.get(api.path('catalogos.tipo_periodo'))
			.then(res => {
				this.tipos_periodo = res.data
			})
			.catch(err => {
				this.handleErrors(err.response.data.errors)
			})
			.then(() => {})
		},
			
		descargarFormatoPagosCertificados() {
			this.loadingDialog = true
			axios
				.get(api.path('lotes_certificados.formato_pagos', { id: this.$route.params.id }), { responseType: 'blob' })
				.then(res => {
					if (res.data.type === 'application/zip') {
						let blob = new Blob([res.data], { type: 'application/zip' })
						let link = document.createElement('a')
						link.href = window.URL.createObjectURL(blob)
						link.download = 'formatos_pago_certificados.zip'
						link.click()
					} else if (res.data.error) {
						this.$toast.error(res.data.error)
					} else {
						this.$toast.error('Vuelva a intentarlo mas tarde.')
					}
				})
				.catch(err => {
					this.loadingDialog = false
					this.$toast.error('Vuelva a intentarlo mas tarde.')
					this.handleErrors(err.response.data.errors)
				})
				.then(() => {
					this.loadingDialog = false
				})
			},

			capturarCertificados() {
				this.$router.push({ name: "lotes-certificados-capturar" })
			},

			descargarFormatoTitulo() {
				this.loadingDialog = true
				axios
					.get(api.path('titulos.formato'), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'formato_titulos.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarFormatoPrograma() {
				this.loadingDialog = true
				axios
					.get(api.path('programas.formato'), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'formato_programas_de_estudio.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarLayoutRVOESFederales() {
				this.loadingDialog = true
				axios
					.get(api.path('titulos.layout'), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'layout_rvoes_federales.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarLayoutProgramasDeEstudio() {
				this.loadingDialog = true
				axios
					.get(api.path('programas.layout'), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'layout_programas_de_estudio.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			descargarXLSXProgramasDeEstudio() {
				this.loadingDialog = true
				axios
					.get(api.path('programas.xlsx'), { responseType: 'blob' })
					.then(res => {
						if (res.data.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
							let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
							let link = document.createElement('a')
							link.href = window.URL.createObjectURL(blob)
							link.download = 'programas_de_estudio.xlsx'
							link.click()
						} else if (res.data.error) {
							this.$toast.error(res.data.error)
						} else {
							this.$toast.error('Vuelva a intentarlo mas tarde.')
						}
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},

			/*refactorizarAsignaturas() {
				this.loadingDialog = true
				axios
					.get(api.path('programas.refactorizar'), { responseType: 'blob' })
					.then(res => {
						this.$toast.success("Asignaturas recfactorizadas correctamente.")
					})
					.catch(err => {
						this.loadingDialog = false
						this.$toast.error('Vuelva a intentarlo mas tarde.')
						this.handleErrors(err.response.data.errors)
					})
					.then(() => {
						this.loadingDialog = false
					})
			},*/
		}
	}
</script>
