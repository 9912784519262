<template>
  <v-flex sm6 md4 lg4>
    <v-card raised>
      <div class="layout column align-center">
        <v-toolbar dark color="primary" flat dense>
          <div class="layout column align-center">
            <v-toolbar-title>Crea tu cuenta de usuario</v-toolbar-title>
          </div>
        </v-toolbar>
        <v-img class="mt-4" lazy-src="/img/logo.png" src="/img/logo.png" alt="Logotipo" width="180"></v-img>
      </div>
      <v-card-text>
        <registro-form @success="success"></registro-form>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  components: {
    "registro-form": require("./RegistroForm").default
  },

  methods: {
    success(data) {
      this.$router.push({ name: "login" });
    }
  }
};
</script>
