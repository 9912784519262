<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        :label="labels.name"
        v-model="form.name"
        :error-messages="errors.name"
        :rules="[rules.required('name')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.email"
        v-model="form.email"
        :error-messages="errors.email"
        :rules="[rules.required('email')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        :label="labels.role"
        v-model="form.role"
        :items="roles"
        :error-messages="errors.role"
        :rules="[rules.required('role')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-autocomplete
        v-if="form.role !== 'Administrador'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campuses_asignados"
        v-model="form.campuses"
        :disabled="loading"
        :items="campuses"
        item-text="nombre"
        item-value="uuid"
        attach
        chips
        multiple
        deletable-chips
        dense
        hide-no-data
      ></v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        name: null,
        email: null,
        role: null,
        campuses: [],
      },

      campuses: [],
      roles: ['Administrador', 'Firmante', 'Escolares']
    }),

    mounted() {
      this.getSolicitud()
      this.getCampuses()
    },

    methods: {
      getSolicitud() {
        axios
          .get(api.path('usuarios.solicitudes.single', { id: this.$route.params.id } ))
          .then(res => {
            this.form.name = res.data.nombre
            this.form.email = res.data.correo_electronico
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('usuarios.solicitudes.single', { id: this.$route.params.id } ), this.form)
            .then(res => {
              this.$toast.success('Usuario agregado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
