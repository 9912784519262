var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { sm6: "", md4: "", lg4: "" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "title layout column align-center",
              attrs: { "primary-title": "" }
            },
            [
              _c("v-img", {
                staticClass: "mb-4",
                attrs: {
                  "lazy-src": "/img/logo.png",
                  src: "/img/logo.png",
                  alt: "Logotipo",
                  width: "180"
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "font-weight-light" }, [
                _vm._v("Titulación y Certificación Electrónica")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [_c("login-form", { on: { success: _vm.success } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }