import { render, staticRenderFns } from "./TituloEditar.vue?vue&type=template&id=20fcbb8c&"
import script from "./TituloEditar.vue?vue&type=script&lang=js&"
export * from "./TituloEditar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20fcbb8c')) {
      api.createRecord('20fcbb8c', component.options)
    } else {
      api.reload('20fcbb8c', component.options)
    }
    module.hot.accept("./TituloEditar.vue?vue&type=template&id=20fcbb8c&", function () {
      api.rerender('20fcbb8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/lote-titulo/titulo/editar/TituloEditar.vue"
export default component.exports