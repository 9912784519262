var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [
                _vm._v("¡Descubriste una función Premium!")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c("span", [
              _vm._v("Actualiza al plan Premium y obten increibles beneficios.")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _vm.auth.role === "Administrador"
                ? _c(
                    "v-btn",
                    {
                      attrs: { flat: "", tabindex: "3" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { flat: "", tabindex: "2" },
                      on: {
                        click: function($event) {
                          _vm.dialog = false
                        }
                      }
                    },
                    [_vm._v("Continuar")]
                  ),
              _vm._v(" "),
              _vm.auth.role === "Administrador"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        flat: "",
                        loading: _vm.loading,
                        tabindex: "2"
                      },
                      on: { click: _vm.mostrarPlanes }
                    },
                    [_vm._v("Ver planes")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }