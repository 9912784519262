var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: {
            "fixed-tabs": "",
            grow: "",
            "slider-color": _vm.sliderColor
          },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c(
            "v-tab",
            {
              attrs: { href: "#capturado" },
              on: { click: _vm.cambiarANoFirmados }
            },
            [
              _vm.selectedTab === "capturado"
                ? _c("span", { staticClass: "blue--text text--darken-2" }, [
                    _vm._v("Capturados")
                  ])
                : _c("span", { staticClass: "grey--text text--darken-1" }, [
                    _vm._v("Capturados")
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            {
              attrs: { href: "#firmado" },
              on: { click: _vm.cambiarAFirmados }
            },
            [
              _vm.selectedTab === "firmado"
                ? _c("span", { staticClass: "purple--text text--darken-2" }, [
                    _vm._v("Firmados")
                  ])
                : _c("span", { staticClass: "grey--text text--darken-1" }, [
                    _vm._v("Firmados")
                  ])
            ]
          ),
          _vm._v(" "),
          _c(
            "v-tab",
            { attrs: { href: "#enviado" }, on: { click: _vm.cambiarASubidos } },
            [
              _vm.selectedTab === "enviado"
                ? _c("span", { staticClass: "green--text text--darken-2" }, [
                    _vm._v("Enviados")
                  ])
                : _c("span", { staticClass: "grey--text text--darken-1" }, [
                    _vm._v("Enviados")
                  ])
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-toolbar",
        {
          staticClass: "elevation-1 toolbar-fix",
          attrs: { flat: "", dense: "", color: "white" }
        },
        [
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box_outline_blank")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length < _vm.lotesTitulos.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("indeterminate_check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 &&
          _vm.selected.length === _vm.lotesTitulos.data.length
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.toggleAll } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("check_box")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length === 0
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.getLotesTitulos(_vm.selectedTab)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("refresh")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0 && _vm.selectedTab !== "enviado"
            ? _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: { click: _vm.eliminarLotesTitulos }
                },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("delete")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selected.length > 0
            ? _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.copiarTexto } },
                [
                  _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                    _vm._v("content_copy")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("span", { attrs: { color: "grey darken-1" } }, [
            _vm._v(
              _vm._s(
                _vm.lotesTitulos.count > 1
                  ? (_vm.lotesTitulos.current_page - 1) *
                      _vm.lotesTitulos.per_page +
                      1
                  : 0
              ) +
                "-" +
                _vm._s(
                  _vm.lotesTitulos.count
                    ? (_vm.lotesTitulos.current_page - 1) *
                        _vm.lotesTitulos.per_page +
                        _vm.lotesTitulos.count
                    : 0
                ) +
                " de " +
                _vm._s(_vm.lotesTitulos.total ? _vm.lotesTitulos.total : 0)
            )
          ]),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { disabled: _vm.lotesTitulos.current_page <= 1, icon: "" },
              on: { click: _vm.getLotesTitulosPreviousPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_before")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                disabled:
                  _vm.lotesTitulos.current_page >= _vm.lotesTitulos.last_page,
                icon: ""
              },
              on: { click: _vm.getLotesTitulosNextPage }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("navigate_next")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  _vm.settingsDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { color: "grey darken-1" } }, [
                _vm._v("settings")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.loading
        ? _c(
            "v-container",
            [
              _c(
                "v-layout",
                { attrs: { "justify-center": "", row: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", color: "primary" }
                  })
                ],
                1
              )
            ],
            1
          )
        : !_vm.$vuetify.breakpoint.xsOnly &&
          _vm.lotesTitulos.data &&
          _vm.lotesTitulos.data.length > 0
        ? _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.lotesTitulos.data,
              pagination: _vm.pagination,
              "no-data-text": _vm.noDataText,
              "no-results-text": _vm.noResultsText,
              loading: _vm.loading,
              "item-key": "id",
              "hide-actions": ""
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headers",
                fn: function(props) {
                  return [
                    _c(
                      "tr",
                      [
                        _c("th"),
                        _vm._v(" "),
                        _vm._l(props.headers, function(header) {
                          return _c(
                            "th",
                            {
                              key: header.text,
                              class: [
                                "column sortable",
                                _vm.pagination.descending ? "desc" : "asc",
                                header.value === _vm.pagination.sortBy
                                  ? "active"
                                  : "",
                                "text-xs-left",
                                header.visibility
                                  ? "hidden-print-only"
                                  : "hidden-screen-only"
                              ],
                              on: {
                                click: function($event) {
                                  return _vm.changeSort(header.value)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(header.text) +
                                  "\n          "
                              ),
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("arrow_upward")
                              ])
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ]
                }
              },
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("v-hover", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return _c(
                                "tr",
                                {
                                  class: "elevation-" + (hover ? 2 : 0),
                                  on: {
                                    click: function($event) {
                                      return _vm.mostrarLoteTitulos(props.item)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          props.selected = !props.selected
                                        }
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          "input-value": props.selected,
                                          primary: "",
                                          "hide-details": ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(props.item.id)
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[0].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(props.item.id))
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[0].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [_vm._v(_vm._s(props.item.id))]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[1].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      props.item.estado_lote.id === 1
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color:
                                                props.item.estado_lote.color
                                            }
                                          })
                                        : _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color:
                                                  props.item.estado_lote.color,
                                                "text-color": "white"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item.estado_lote
                                                      .nombre
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(props.item.nombre)
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[2].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(_vm._s(props.item.nombre))
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[2].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [_vm._v(_vm._s(props.item.nombre))]
                                      ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(props.item.nombre_campus)
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[3].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(props.item.nombre_campus)
                                            )
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[3].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.item.campus.nombre)
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[4].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_con_errores_count > 0
                                                ? "red"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_con_errores_count > 0
                                                  ? "red darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_con_errores_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[5].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_capturados_count > 0
                                                ? "blue"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_capturados_count > 0
                                                  ? "blue darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_capturados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[6].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_firmados_count > 0
                                                ? "purple"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_firmados_count > 0
                                                  ? "purple darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_firmados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[7].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_registrados_count > 0
                                                ? "green"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_registrados_count > 0
                                                  ? "green darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_registrados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[8].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_rechazados_count > 0
                                                ? "orange"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_rechazados_count > 0
                                                  ? "orange darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_rechazados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[9].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_cancelados_count > 0
                                                ? "red"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_cancelados_count > 0
                                                  ? "red darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_cancelados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      class: [
                                        _vm.headers[10].visibility
                                          ? "hidden-print-only"
                                          : "hidden-screen-only"
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color:
                                              props.item
                                                .titulos_autenticados_count > 0
                                                ? "cyan"
                                                : "blue-grey",
                                            "text-color": "white"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              class:
                                                props.item
                                                  .titulos_autenticados_count >
                                                0
                                                  ? "cyan darken-4"
                                                  : "blue-grey darken-4"
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item
                                                      .titulos_autenticados_count
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _vm._v(
                                            "\n            Títulos\n          "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(
                                    props.item.fecha_capturado
                                  )
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[11].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(props.item.fecha_capturado)
                                            )
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[11].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.item.fecha_capturado)
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(props.item.fecha_firmado)
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[12].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(props.item.fecha_firmado)
                                            )
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[12].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.item.fecha_firmado)
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _vm.incluyeBusqueda(props.item.fecha_enviado)
                                    ? _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[13].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(props.item.fecha_enviado)
                                            )
                                          ])
                                        ]
                                      )
                                    : _c(
                                        "td",
                                        {
                                          class: [
                                            _vm.headers[13].visibility
                                              ? "hidden-print-only"
                                              : "hidden-screen-only"
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.item.fecha_enviado)
                                          )
                                        ]
                                      ),
                                  _vm._v(" "),
                                  hover
                                    ? _c(
                                        "div",
                                        { staticClass: "lotes-titulos-fix-1" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "justify-center layout grey lighten-3 lotes-titulos-fix-2",
                                              class:
                                                _vm.selectedTab !== "enviado"
                                                  ? "lotes-titulos-fix-left-1"
                                                  : "lotes-titulos-fix-left-2"
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        ripple: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.mostrarInformacionLoteTitulos(
                                                            props.item
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey darken-1"
                                                          }
                                                        },
                                                        [_vm._v("visibility")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Mostrar lote de títulos"
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.selectedTab !== "enviado"
                                                ? _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            icon: "",
                                                            ripple: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.eliminarLoteTitulos(
                                                                props.item
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "grey darken-1"
                                                              }
                                                            },
                                                            [_vm._v("delete")]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Eliminar")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        : _c("v-container", [
            _c(
              "div",
              { staticClass: "layout column align-center text-center" },
              [
                _c("v-img", {
                  staticClass: "mb-3",
                  attrs: {
                    "lazy-src": _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    src: _vm.busqueda
                      ? "/img/clip/clip-page-not-found.png"
                      : "/img/clip/list-is-empty.png",
                    alt: _vm.busqueda ? _vm.noResultsText : _vm.noDataText,
                    width: "380"
                  }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "mb-3 font-weight-light grey--text text--darken-4 title"
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.busqueda ? _vm.noResultsText : _vm.noDataText
                        ) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.busqueda
                  ? _c(
                      "v-alert",
                      {
                        attrs: {
                          value: true,
                          color: "primary",
                          icon: "info",
                          outline: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n        Intenta usar otras palabras clave o quitar los filtros de búsqueda\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "400px",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly
          },
          model: {
            value: _vm.settingsDialog,
            callback: function($$v) {
              _vm.settingsDialog = $$v
            },
            expression: "settingsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Configuración")
                ])
              ]),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-list",
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Número de filas por página\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-slider", {
                            attrs: {
                              "thumb-label": "always",
                              step: "10",
                              "thumb-size": 24,
                              min: 10,
                              max: 100,
                              ticks: "",
                              "tick-size": "2"
                            },
                            model: {
                              value: _vm.pagination.rowsPerPage,
                              callback: function($$v) {
                                _vm.$set(_vm.pagination, "rowsPerPage", $$v)
                              },
                              expression: "pagination.rowsPerPage"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-subheader", [
                            _vm._v(
                              "\n              Columnas a mostrar\n            "
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.headers, function(header, index) {
                            return _c(
                              "v-list-tile",
                              { key: index },
                              [
                                _c(
                                  "v-list-tile-action",
                                  [
                                    _c("v-switch", {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: header.visibility,
                                        callback: function($$v) {
                                          _vm.$set(header, "visibility", $$v)
                                        },
                                        expression: "header.visibility"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("v-list-tile-title", [
                                  _vm._v(_vm._s(header.text))
                                ])
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: {
                        click: function($event) {
                          _vm.settingsDialog = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary darken-1", flat: "" },
                      on: { click: _vm.aplicarConfiguracion }
                    },
                    [_vm._v("Aplicar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }