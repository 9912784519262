var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "campus"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.campus,
                  items: _vm.campuses,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  "error-messages": _vm.errors.campus,
                  rules: [_vm.rules.required("campus")],
                  disabled: _vm.loading,
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campus_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campus_id", $$v)
                  },
                  expression: "form.campus_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "carrera"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.carrera,
                  items: _vm.carreras,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  "error-messages": _vm.errors.carrera,
                  rules: [_vm.rules.required("carrera")],
                  disabled: _vm.loading,
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.carrera_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "carrera_id", $$v)
                  },
                  expression: "form.carrera_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "modalidad"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.modalidad,
                  items: _vm.modalidades,
                  "item-text": "nombre",
                  "item-value": "id",
                  "error-messages": _vm.errors.modalidad,
                  rules: [_vm.rules.required("modalidad")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.modalidad_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "modalidad_id", $$v)
                  },
                  expression: "form.modalidad_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "tipo_periodo"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.tipo_periodo,
                  items: _vm.tipos_periodo,
                  "item-text": "nombre",
                  "item-value": "id",
                  "error-messages": _vm.errors.tipo_periodo,
                  rules: [_vm.rules.required("tipo_periodo")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.tipo_periodo_id,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tipo_periodo_id", $$v)
                  },
                  expression: "form.tipo_periodo_id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "tipo_rvoe"
            ? _c("v-select", {
                attrs: {
                  label: _vm.labels.tipo_rvoe,
                  items: ["ESTATAL", "FEDERAL"],
                  "error-messages": _vm.errors.tipo_rvoe,
                  rules: [_vm.rules.required("tipo_rvoe")],
                  disabled: _vm.loading,
                  dense: ""
                },
                model: {
                  value: _vm.form.tipo_rvoe,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "tipo_rvoe", $$v)
                  },
                  expression: "form.tipo_rvoe"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "numero_rvoe"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.numero_rvoe,
                  "error-messages": _vm.errors.numero_rvoe,
                  rules: [_vm.rules.required("numero_rvoe")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.numero_rvoe,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "numero_rvoe", $$v)
                  },
                  expression: "form.numero_rvoe"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "fecha_rvoe"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.fecha_rvoe,
                  "error-messages": _vm.errors.fecha_rvoe,
                  rules: [_vm.rules.required("fecha_rvoe")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.fecha_rvoe,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "fecha_rvoe", $$v)
                  },
                  expression: "form.fecha_rvoe"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "clave_plan"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.clave_plan,
                  "error-messages": _vm.errors.clave_plan,
                  rules: [_vm.rules.required("clave_plan")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.clave_plan,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "clave_plan", $$v)
                  },
                  expression: "form.clave_plan"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "calificacion_maxima"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.calificacion_maxima,
                  "error-messages": _vm.errors.calificacion_maxima,
                  rules: [_vm.rules.required("calificacion_maxima")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.calificacion_maxima,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "calificacion_maxima", $$v)
                  },
                  expression: "form.calificacion_maxima"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "calificacion_minima"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.calificacion_minima,
                  "error-messages": _vm.errors.calificacion_minima,
                  rules: [_vm.rules.required("calificacion_minima")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.calificacion_minima,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "calificacion_minima", $$v)
                  },
                  expression: "form.calificacion_minima"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "calificacion_minima_aprobatoria"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.calificacion_minima_aprobatoria,
                  "error-messages": _vm.errors.calificacion_minima_aprobatoria,
                  rules: [
                    _vm.rules.required("calificacion_minima_aprobatoria")
                  ],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.calificacion_minima_aprobatoria,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "calificacion_minima_aprobatoria", $$v)
                  },
                  expression: "form.calificacion_minima_aprobatoria"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "total_creditos"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.total_creditos,
                  "error-messages": _vm.errors.total_creditos,
                  rules: [_vm.rules.required("total_creditos")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.total_creditos,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "total_creditos", $$v)
                  },
                  expression: "form.total_creditos"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "total_asignaturas"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.total_asignaturas,
                  "error-messages": _vm.errors.total_asignaturas,
                  rules: [_vm.rules.required("total_asignaturas")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.total_asignaturas,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "total_asignaturas", $$v)
                  },
                  expression: "form.total_asignaturas"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }