import { render, staticRenderFns } from "./PasswordReset.vue?vue&type=template&id=56b3258c&"
import script from "./PasswordReset.vue?vue&type=script&lang=js&"
export * from "./PasswordReset.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56b3258c')) {
      api.createRecord('56b3258c', component.options)
    } else {
      api.reload('56b3258c', component.options)
    }
    module.hot.accept("./PasswordReset.vue?vue&type=template&id=56b3258c&", function () {
      api.rerender('56b3258c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/auth/password/password-reset/PasswordReset.vue"
export default component.exports