<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="headline">Actualización de la plataforma</v-card-title>

      <v-card-text>
        <span>Se ha actualizado la plataforma. Ahora es más segura y moderna.</span>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click="acerca" tabindex="2">Ver cambios</v-btn>

        <v-btn flat color="primary" @click="aceptarActualizacion" tabindex="1">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { api } from '~/config'

export default {
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapGetters({
      mensajeDialog: 'admin/mensajeDialog'
    })
  },

  watch: {
    mensajeDialog(val) {
      if (val) {
        this.dialog = val
      }
    },

    dialog(val) {
      if (val === false) {
        this.$store.dispatch('admin/guardarMensajeDialog', {
          mensajeDialog: false
        })
      }
    }
  },

  methods: {
    acerca() {
      this.aceptarActualizacion()
      this.$router.push({ name: 'acerca' }).catch(err => {})
    },

    aceptarActualizacion() {
      this.dialog = false
      axios
        .post(api.path('actualizacion'), {
          aceptar: true
        })
        .then(res => {
          this.$toast.success('Gracias a tus comentarios seguimos mejorando.')
        })
        .catch(err => {
        })
        .then(() => {
        })
    },
  }
}
</script>
