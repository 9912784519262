<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.estilo"
        v-model="archivo_estilo.name"
        :error-messages="errors.estilo"
        :rules="[rules.required('estilo')]"
        :disabled="loadingDialog"
        @click="seleccionarArchivoEstilo"
        append-icon="attach_file"
      ></v-text-field>
      <input
        type="file"
        accept=".css"
        style="display: none"
        ref="archivo_estilo"
        @change="archivoEstiloSeleccionado"
      />

      <v-text-field
        :label="labels.fondo"
        v-model="archivo_fondo.name"
        :error-messages="errors.fondo"
        :rules="[rules.required('fondo')]"
        :disabled="loadingDialog"
        @click="seleccionarArchivoFondo"
        append-icon="attach_file"
      ></v-text-field>
      <input
        type="file"
        accept=".jpg,.png"
        style="display: none"
        ref="archivo_fondo"
        @change="archivoFondoSeleccionado"
      />
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
      },
      archivo_estilo: {
        data: "",
        name: "",
        url: ""
      },
      archivo_fondo: {
        data: "",
        name: "",
        url: ""
      },
      loadingDialog: false
    }),

    computed: mapGetters({
      auth: 'auth/user',
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'plantilla-titulo' })
      }
    },

    mounted() {
    },

    methods: {
      seleccionarArchivoEstilo() {
        this.$refs.archivo_estilo.click();
      },

      seleccionarArchivoFondo() {
        this.$refs.archivo_fondo.click();
      },

      archivoEstiloSeleccionado(e) {
        const files = e.target.files;
        if (files[0] !== undefined) {
          this.archivo_estilo.name = files[0].name;
          if (this.archivo_estilo.name.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.archivo_estilo.url = fr.result;
            this.archivo_estilo.data = files[0];
            if (this.archivo_estilo.data.type === "text/css") {
              this.form.estilo = this.archivo_estilo.url;
            } else {
              this.form.estilo = null;
            }
          });
        } else {
          this.archivo_estilo.name = "";
          this.archivo_estilo.data = "";
          this.archivo_estilo.url = "";
        }
      },

      archivoFondoSeleccionado(e) {
        const files = e.target.files;
        if (files[0] !== undefined) {
          this.archivo_fondo.name = files[0].name;
          if (this.archivo_fondo.name.lastIndexOf(".") <= 0) {
            return;
          }
          const fr = new FileReader();
          fr.readAsDataURL(files[0]);
          fr.addEventListener("load", () => {
            this.archivo_fondo.url = fr.result;
            this.archivo_fondo.data = files[0];
            if (this.archivo_fondo.data.type === "image/png" || this.archivo_fondo.data.type === "image/jpeg") {
              this.form.fondo = this.archivo_fondo.url;
            } else {
              this.form.fondo = null;
            }
          });
        } else {
          this.archivo_fondo.name = "";
          this.archivo_fondo.data = "";
          this.archivo_fondo.url = "";
        }
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.post(api.path('plantillas_titulos'), this.form)
            .then(res => {
              this.$toast.success('Plantilla de título agregada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
