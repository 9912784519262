<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-switch
              v-if="auth.role === 'Administrador'"
              :label="labels.editable"
              v-model="programa.editable"
              @change="changeEstado"
            ></v-switch>

            <v-text-field
              :label="labels.campus"
              v-model="programa.campus"
              @click:append="editarPrograma('campus')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
      
            <v-text-field
              :label="labels.carrera"
              v-model="programa.carrera"
              @click:append="editarPrograma('carrera')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
        
            <v-text-field
              :label="labels.modalidad"
              v-model="programa.modalidad"
              @click:append="editarPrograma('modalidad')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.tipo_periodo"
              v-model="programa.tipo_periodo"
              @click:append="editarPrograma('tipo_periodo')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.tipo_rvoe"
              v-model="programa.tipo_rvoe"
              @click:append="editarPrograma('tipo_rvoe')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.numero_rvoe"
              v-model="programa.numero_rvoe"
              @click:append="editarPrograma('numero_rvoe')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.fecha_rvoe"
              v-model="programa.fecha_rvoe"
              @click:append="editarPrograma('fecha_rvoe')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
        
            <v-text-field
              :label="labels.clave_plan"
              v-model="programa.clave_plan"
              @click:append="editarPrograma('clave_plan')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.calificacion_maxima"
              v-model="programa.calificacion_maxima"
              @click:append="editarPrograma('calificacion_maxima')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.calificacion_minima"
              v-model="programa.calificacion_minima"
              @click:append="editarPrograma('calificacion_minima')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          
            <v-text-field
              :label="labels.calificacion_minima_aprobatoria"
              v-model="programa.calificacion_minima_aprobatoria"
              @click:append="editarPrograma('calificacion_minima_aprobatoria')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.total_creditos"
              v-model="programa.total_creditos"
              @click:append="editarPrograma('total_creditos')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.total_asignaturas"
              v-model="programa.total_asignaturas"
              @click:append="editarPrograma('total_asignaturas')"
              :append-icon="programa.editable ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      programa: {},
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
        auth: 'auth/user',
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'programa' })
      }
    },

    mounted() {
      this.getPrograma()
    },

    methods: {
      getPrograma(id) {
        axios.get(api.path('programas.single', { id: this.$route.params.id } ))
          .then(res => {
            this.programa = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      changeEstado() {
        axios
          .put(api.path('programas.single', { id: this.$route.params.id }), {
            editable: this.programa.editable
          })
          .then(res => {
            this.getPrograma()
            this.$toast.success('Se actualizó el estado del programa.')
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      editarPrograma(campo) {
        this.$router.push({ name: "programa-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
