import { render, staticRenderFns } from "./SolicitudRegistrarForm.vue?vue&type=template&id=5e7e6969&"
import script from "./SolicitudRegistrarForm.vue?vue&type=script&lang=js&"
export * from "./SolicitudRegistrarForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VContainer,VForm,VLayout,VSelect,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/recmusica/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e7e6969')) {
      api.createRecord('5e7e6969', component.options)
    } else {
      api.reload('5e7e6969', component.options)
    }
    module.hot.accept("./SolicitudRegistrarForm.vue?vue&type=template&id=5e7e6969&", function () {
      api.rerender('5e7e6969', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/usuarios/solicitud/registrar/SolicitudRegistrarForm.vue"
export default component.exports