<template>
  <div v-if="$vuetify.breakpoint.xsOnly">
    <responsable-firma-form @success="success"></responsable-firma-form>
  </div>
  <v-container fluid grid-list-xl v-else>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <responsable-firma-form @success="success"></responsable-firma-form>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    components: {
      'responsable-firma-form': require('./ResponsableFirmaForm').default
    },

    methods: {
      success() {
        this.$router.go(-1)
      }
    }
  }
</script>
