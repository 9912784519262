<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-img
        v-if="form.logo"
        class="mb-4"
        :src="form.logo"
        :lazy-src="form.logo"
        height="80"
        contain
        aspect-ratio="1"
      ></v-img>

      <v-text-field
        v-if="!this.campo || this.campo === 'logo'"
        :label="labels.archivo_logo"
        v-model="file.name"
        :error-messages="errors.archivo_logo"
        :rules="[rules.required('archivo_logo')]"
        :disabled="loadingDialog"
        @click="seleccionarImagenLogo"
        append-icon="attach_file"
      ></v-text-field>
      <input
        type="file"
        accept=".png"
        style="display: none"
        ref="archivo_logo"
        @change="imagenLogoSeleccionada"
      />

      <v-text-field
        v-if="!this.campo || this.campo === 'clave'"
        :label="labels.clave"
        v-model="form.clave"
        :error-messages="errors.clave"
        :rules="[rules.required('clave')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_concentradora'"
        :label="labels.clave_concentradora"
        v-model="form.clave_concentradora"
        :error-messages="errors.clave_concentradora"
        :rules="[rules.required('clave_concentradora')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'rfc'"
        :label="labels.rfc"
        v-model="form.rfc"
        :error-messages="errors.rfc"
        :rules="[rules.required('rfc')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'razon_social'"
        :label="labels.razon_social"
        v-model="form.razon_social"
        :error-messages="errors.razon_social"
        :rules="[rules.required('razon_social')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'wsdl_usuario_titulos'"
        :label="labels.wsdl_usuario_titulos"
        v-model="form.configuracion.wsdl_usuario_titulos"
        :error-messages="errors.wsdl_usuario_titulos"
        :rules="[rules.required('wsdl_usuario_titulos')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'wsdl_constrasena_titulos'"
        :label="labels.wsdl_constrasena_titulos"
        v-model="form.configuracion.wsdl_constrasena_titulos"
        :error-messages="errors.wsdl_constrasena_titulos"
        :rules="[rules.required('wsdl_constrasena_titulos')]"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >Cancelar</v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >Guardar</v-btn>
    </v-layout>
  </v-form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
  mixins: [Form],

  data: () => ({
    form: {
      configuracion: {}
    },
    file: {
      data: "",
      name: "",
      url: ""
    },
    campo: null,
    loadingDialog: false
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.$router.push({ name: 'configuracion' })
      }
    }
  },

  mounted() {
    if (this.$route.params.campo) {
      this.campo = this.$route.params.campo;
    } else {
      let tab = this.$route.fullPath.replace(this.$route.path, "").split("/");
      let param = decodeURI(tab[0].substr(1));
      if (
        param === "logo" ||
        param === "nombre" ||
        param === "clave" ||
        param === "clave_concentradora" ||
        param === "rfc" ||
        param === "razon_social" ||
        param === "version_xml_titulos" ||
        param === "wsdl_url_titulos" ||
        param === "wsdl_usuario_titulos" ||
        param === "wsdl_constrasena_titulos" ||
        param === "version_xml_certificados"
      ) {
        this.campo = param;
      }
    }

    if (this.campo) {
      this.$router.replace("#" + this.campo);
    }

    this.getInstitucion(1);
  },

  methods: {
    getInstitucion(id) {
      this.loading = true;
      axios
        .get(api.path("instituciones.single", { id: id }), {
          params: {
            campo: this.campo
          }
        })
        .then(res => {
          this.form = res.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false;
        });
    },

    seleccionarImagenLogo() {
      this.$refs.archivo_logo.click();
    },

    imagenLogoSeleccionada(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.file.name = files[0].name;
        if (this.file.name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.file.url = fr.result;
          this.file.data = files[0];
          if (this.file.data.type === "image/png") {
            this.form.logo = this.file.url;
          } else {
            this.form.logo = null;
          }
        });
      } else {
        this.file.name = "";
        this.file.data = "";
        this.file.url = "";
      }
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        axios
          .put(api.path("instituciones.single", { id: 1 }), this.form)
          .then(res => {
            this.$toast.success("Configuración actualizada correctamente.");
            this.$emit("success", res.data);
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors);
          })
          .then(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
