var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.tipo_documento,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarReglaFolioControl("tipo_documento")
                        }
                      },
                      model: {
                        value: _vm.reglaFolioControl.tipo_documento.nombre,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.reglaFolioControl.tipo_documento,
                            "nombre",
                            $$v
                          )
                        },
                        expression: "reglaFolioControl.tipo_documento.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.autorizacion_reconocimiento,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarReglaFolioControl(
                            "autorizacion_reconocimiento"
                          )
                        }
                      },
                      model: {
                        value:
                          _vm.reglaFolioControl.autorizacion_reconocimiento
                            .nombre,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.reglaFolioControl.autorizacion_reconocimiento,
                            "nombre",
                            $$v
                          )
                        },
                        expression:
                          "reglaFolioControl.autorizacion_reconocimiento.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.consecutivo_documento,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarReglaFolioControl(
                            "consecutivo_documento"
                          )
                        }
                      },
                      model: {
                        value: _vm.reglaFolioControl.consecutivo_documento,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.reglaFolioControl,
                            "consecutivo_documento",
                            $$v
                          )
                        },
                        expression: "reglaFolioControl.consecutivo_documento"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.regla,
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarReglaFolioControl("regla")
                        }
                      },
                      model: {
                        value: _vm.reglaFolioControl.regla,
                        callback: function($$v) {
                          _vm.$set(_vm.reglaFolioControl, "regla", $$v)
                        },
                        expression: "reglaFolioControl.regla"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-autocomplete", {
                      attrs: {
                        label: _vm.labels.campuses_asignados,
                        items: _vm.campuses,
                        "item-text": "nombre",
                        "item-value": "uuid",
                        attach: "",
                        chips: "",
                        multiple: "",
                        "append-icon": "edit",
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarReglaFolioControl("campuses")
                        }
                      },
                      model: {
                        value: _vm.reglaFolioControl.campuses,
                        callback: function($$v) {
                          _vm.$set(_vm.reglaFolioControl, "campuses", $$v)
                        },
                        expression: "reglaFolioControl.campuses"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }