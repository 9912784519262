var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "500px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v("Actualización de la plataforma")
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _c("span", [
              _vm._v(
                "Se ha actualizado la plataforma. Ahora es más segura y moderna."
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", tabindex: "2" },
                  on: { click: _vm.acerca }
                },
                [_vm._v("Ver cambios")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "primary", tabindex: "1" },
                  on: { click: _vm.aceptarActualizacion }
                },
                [_vm._v("Aceptar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }