var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { lg6: "", sm9: "", xs12: "" } },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.nombre,
                        "append-icon":
                          _vm.plantillaTitulo.nombre === "default"
                            ? ""
                            : _vm.edit,
                        outline: "",
                        readonly: ""
                      },
                      on: {
                        "click:append": function($event) {
                          return _vm.editarPlantillaTitulo("nombre")
                        }
                      },
                      model: {
                        value: _vm.plantillaTitulo.nombre,
                        callback: function($$v) {
                          _vm.$set(_vm.plantillaTitulo, "nombre", $$v)
                        },
                        expression: "plantillaTitulo.nombre"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.estilo,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.plantillaTitulo.estilo,
                        callback: function($$v) {
                          _vm.$set(_vm.plantillaTitulo, "estilo", $$v)
                        },
                        expression: "plantillaTitulo.estilo"
                      }
                    }),
                    _vm._v(" "),
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.labels.fondo,
                        outline: "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.plantillaTitulo.fondo,
                        callback: function($$v) {
                          _vm.$set(_vm.plantillaTitulo, "fondo", $$v)
                        },
                        expression: "plantillaTitulo.fondo"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }