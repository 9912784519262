const siteUrl = config.siteUrl,
  apiUrl = config.apiUrl

export const settings = {
  siteName: config.siteName,
  version: config.version
}

class URL {
  constructor(base) {
    this.base = base
  }

  path(path, args) {
    path = path.split('.')
    let obj = this,
      url = this.base

    for (let i = 0; i < path.length && obj; i++) {
      if (obj.url) {
        url += '/' + obj.url
      }

      obj = obj[path[i]]
    }
    if (obj) {
      url = url + '/' + (typeof obj === 'string' ? obj : obj.url)
    }

    if (args) {
      for (let key in args) {
        url = url.replace(':' + key, args[key])
      }
    }

    return url
  }
}

export const api = Object.assign(new URL(apiUrl), {
  url: '',

  login: {
    url:     'login',
    refresh: 'refresh'
  },

  logout: 'logout',

  registro: 'registro',

  password: {
    url:    'password',
    forgot: 'email',
    reset:  'reset'
  },

  aliados: {
    url: 'aliados',
  },

  me: 'me',

  perfil: {
    url: 'perfil',
    live_mode: 'live-mode'
  },

  titulos: {
    url:           'lotes-titulos',
    cancelar:      'cancelar',
    formato:       'formato',
    layout:        'layout',
    reporte:       'reporte',
    single:        ':id',
    autenticar:    ':id/autenticar',
    firmar:        ':id/firmar',
    folios:        ':id/folios',
    resultado:     ':id/resultado',
    sep:           ':id/sep',
    validar_curps: ':id/validar-curps',
    zip:           ':id/zip',
    lotepdf:       ':id/pdf',

    firmantes: {
      url:    ':id/firmantes',
      single: ':id2',
      firmar: ':id2/firmar',
    },

    responsables: {
      url:    ':id/responsables',
      single: ':id2',
      sellar: ':id2/sellar',
    },
    
    titulos: {
      url:    ':id/titulos',
      single: ':id2',
      pdf: ':id2/pdf',
      xml: ':id2/xml',
    },
  },

  lotes_certificados: {
    url:           'lotes-certificados',
    cancelar:      'cancelar',
    reporte:       'reporte',
    single:        ':id',
    formato_pagos: ':id/formato-pagos',
    pagar:         ':id/pagar',
    firmar:        ':id/firmar',
    folio_pago:    ':id/folio-pago',
    registrar:     ':id/registrar',
    xlsx:          ':id/xlsx',
    validar_curps: ':id/validar-curps',
    zip:           ':id/zip',
    programas:     ':id/programas',

    responsables: {
      url:    ':id/responsables',
      single: ':id2',
      sellar: ':id2/sellar',
    },
    
    firmantes: {
      url:    ':id/firmantes',
      single: ':id2',
      firmar: ':id2/firmar',
    },
    
    certificados: {
      url:           ':id/certificados',
      captura:       'captura',
      single:        ':id2',

      calificaciones: {
        url:    ':id2/calificaciones',
        single: ':id3',
      },
    },
  },

  programas: {
    url:          'programas-estudio',
    formato:      'formato',
    layout:       'layout',
    //refactorizar: 'refactorizar',
    xlsx:         'xlsx',
    single:       ':id',

    asignaturas: {
      url:    ':id/asignaturas',
      single: ':id2',
    },
  },

  campus: {
    url:    'campus',
    single: ':id',

    plantillas: {
      url:    ':id/plantillas',
      single: ':id2',
    },
  },

  carreras: {
    url:    'carreras',
    single: ':id',
  },

  responsables: {
    url:    'responsables',
    single: ':id',
    campus:  ':id/campus',
    firma:  ':id/firma',
  },

  fiel: {
    url:  'fiel',
    info: 'info'
  },

  usuarios: {
    url:    'usuarios',
    single: ':id',

    solicitudes: {
      url:    'solicitudes',
      single: ':id',
    },
  },

  folios_control: {
    url:    'folios-control',
    single: ':id',
  },

  plantillas_titulos: {
    url:    'plantillas-titulos',

    single: ':id',
    estilo: ':id/estilo',
    fondo:  ':id/fondo',
  },

  instituciones: {
    url:    'instituciones',
    single: ':id',
  },

  catalogos: {
    url:                              'catalogos',
    autorizacion_reconocimiento:      'autorizacion-reconocimiento',
    cargo:                            'cargo',
    entidad_federativa:               'entidad-federativa',
    fundamento_legal_servicio_social: 'fundamento-legal-servicio-social',
    genero:                           'genero',
    modalidad:                        'modalidad',
    modalidad_titulacion:             'modalidad-titulacion',
    motivo_cancelacion:               'motivo-cancelacion',
    nivel_de_estudio:                 'nivel-de-estudio',
    observacion:                      'observacion',
    tipo_asignatura:                  'tipo-asignatura',
    tipo_certificacion:               'tipo-certificacion',
    tipo_estudio_antecedente:         'tipo-estudio-antecedente',
    tipo_periodo:                     'tipo-periodo',
    tipo_documento:                   'tipo-documento',
  },

  comentarios: {
    url:    'comentarios',
    single: ':id',
  },

  actualizacion: {
    url: 'actualizacion',
  },

  facturaciones: {
    url:    'facturaciones',
    single: ':id',
  },
})
