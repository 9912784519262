var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [_c("perfil-editar-form", { on: { success: _vm.success } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }