<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.nombre_asignatura"
              v-model="calificacion.nombre_asignatura"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.clave_asignatura"
              v-model="calificacion.clave_asignatura"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.tipo_asignatura"
              v-model="calificacion.tipo_asignatura"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.creditos_asignatura"
              v-model="calificacion.creditos_asignatura"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.ciclo_escolar"
              v-model="calificacion.ciclo_escolar"
              @click:append="editarCalificacion('ciclo_escolar')"
              :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.calificacion"
              v-model="calificacion.calificacion"
              @click:append="editarCalificacion('calificacion')"
              :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.observacion"
              v-model="calificacion.observacion"
              @click:append="editarCalificacion('observacion')"
              :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,

      calificacion: {
        nombre_asignatura: null,
        clave_asignatura: null,
        tipo_asignatura: null,
        creditos_asignatura: null,
        ciclo_escolar: null,
        calificacion: null,
        observacion: null,
      },

      loteCertificados: [],
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      this.getCalificacion()
      this.getLoteCertificados()
    },

    methods: {
      getCalificacion() {
        axios.get(api.path('lotes_certificados.certificados.calificaciones.single', { id: this.$route.params.id, id2: this.$route.params.id2, id3: this.$route.params.id3 } ))
          .then(res => {
            this.calificacion = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getLoteCertificados() {
        axios
          .get(api.path("lotes_certificados.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteCertificados = res.data
            this.$store.dispatch('admin/guardarEstadoLote', { estadoLote: this.loteCertificados.estado_lote })
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      editarCalificacion(campo) {
        this.$router.push({ name: "lotes-certificados-certificados-calificaciones-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
