var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fiel", {
    attrs: { responsable: _vm.responsable },
    on: { firmar: _vm.storeFirma }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }