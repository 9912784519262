var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-container",
        { class: _vm.$vuetify.breakpoint.xsOnly ? "" : "elevation-2" },
        [
          !this.campo || this.campo === "tipo_documento"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.tipo_documento,
                  "error-messages": _vm.errors.tipo_documento,
                  rules: [_vm.rules.required("tipo_documento")],
                  disabled: _vm.loading,
                  items: _vm.tipoDocumento,
                  "item-text": "nombre",
                  "item-value": "id",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.tipo_documento.id,
                  callback: function($$v) {
                    _vm.$set(_vm.form.tipo_documento, "id", $$v)
                  },
                  expression: "form.tipo_documento.id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "autorizacion_reconocimiento"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.autorizacion_reconocimiento,
                  "error-messages": _vm.errors.autorizacion_reconocimiento,
                  rules: [_vm.rules.required("autorizacion_reconocimiento")],
                  disabled: _vm.loading,
                  items: _vm.autorizacionesReconocimientos,
                  "item-text": "nombre",
                  "item-value": "id",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.autorizacion_reconocimiento.id,
                  callback: function($$v) {
                    _vm.$set(_vm.form.autorizacion_reconocimiento, "id", $$v)
                  },
                  expression: "form.autorizacion_reconocimiento.id"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "consecutivo_documento"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.consecutivo_documento,
                  "error-messages": _vm.errors.consecutivo_documento,
                  rules: [_vm.rules.required("consecutivo_documento")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.consecutivo_documento,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "consecutivo_documento", $$v)
                  },
                  expression: "form.consecutivo_documento"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "regla"
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.labels.regla,
                  "error-messages": _vm.errors.regla,
                  rules: [_vm.rules.required("regla")],
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.form.regla,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "regla", $$v)
                  },
                  expression: "form.regla"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !this.campo || this.campo === "campuses"
            ? _c("v-autocomplete", {
                attrs: {
                  label: _vm.labels.campuses_asignados,
                  disabled: _vm.loading,
                  items: _vm.campuses,
                  "item-text": "nombre",
                  "item-value": "uuid",
                  attach: "",
                  chips: "",
                  multiple: "",
                  "deletable-chips": "",
                  dense: "",
                  "hide-no-data": ""
                },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return $event.target.blur()
                  }
                },
                model: {
                  value: _vm.form.campuses,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "campuses", $$v)
                  },
                  expression: "form.campuses"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "justify-end": "", "mt-2": "" } },
        [
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                disabled: _vm.loading,
                color: "grey darken-2"
              },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("\n      Cancelar\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "primary"
              }
            },
            [_vm._v("\n      Guardar\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }