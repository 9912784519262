<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'id_asignatura'"
        :label="labels.id_asignatura"
        v-model="form.id_asignatura"
        :error-messages="errors.id_asignatura"
        :rules="[rules.required('id_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'nombre_asignatura'"
        :label="labels.nombre_asignatura"
        v-model="form.nombre_asignatura"
        :error-messages="errors.nombre_asignatura"
        :rules="[rules.required('nombre_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_asignatura'"
        :label="labels.clave_asignatura"
        v-model="form.clave_asignatura"
        :error-messages="errors.clave_asignatura"
        :rules="[rules.required('clave_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'id_tipo_asignatura'"
        :label="labels.id_tipo_asignatura"
        v-model="form.id_tipo_asignatura"
        :error-messages="errors.id_tipo_asignatura"
        :rules="[rules.required('id_tipo_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'tipo_asignatura'"
        :label="labels.tipo_asignatura"
        v-model="form.tipo_asignatura_id"
        :error-messages="errors.tipo_asignatura"
        :rules="[rules.required('tipo_asignatura')]"
        :disabled="loading"
        :items="tipoAsignatura"
        item-text="nombre"
        item-value="id"
        dense
      ></v-select>

      <v-text-field
        v-if="!this.campo || this.campo === 'creditos_asignatura'"
        :label="labels.creditos_asignatura"
        v-model="form.creditos_asignatura"
        :error-messages="errors.creditos_asignatura"
        :rules="[rules.required('creditos_asignatura')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'ciclo_escolar' || this.campo === 'calificacion' || this.campo === 'observacion'"
        :label="labels.ciclo_escolar"
        v-model="form.ciclo_escolar"
        :error-messages="errors.ciclo_escolar"
        :rules="[rules.required('ciclo_escolar')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'ciclo_escolar' || this.campo === 'calificacion' || this.campo === 'observacion'"
        :label="labels.calificacion"
        v-model="form.calificacion"
        :error-messages="errors.calificacion"
        :rules="[rules.required('calificacion')]"
        :disabled="loading"
        :items="calificacion"
        dense
      ></v-select>

      <v-text-field
        v-if="!this.campo || this.campo === 'id_observacion'"
        :label="labels.id_observacion"
        v-model="form.observacion_id"
        :error-messages="errors.id_observacion"
        :rules="[rules.required('id_observacion')]"
        :disabled="loading"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'ciclo_escolar' || this.campo === 'calificacion' || this.campo === 'observacion'"
        :label="labels.observacion"
        v-model="form.observacion_id"
        :disabled="loading"
        :items="observacion"
        :item-text="observacionText"
        item-value="id"
        dense
      ></v-select>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {},
      campo: null,
      tipoAsignatura: [],
      observacion: [],
      calificacion: ['5.00', '6.00', '7.00', '8.00', '9.00', '10']
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },
    
    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'id_asignatura' ||
          param === 'nombre_asignatura' ||
          param === 'clave_asignatura' ||
          param === 'id_tipo_asignatura' ||
          param === 'tipo_asignatura' ||
          param === 'creditos_asignatura' ||
          param === 'ciclo_escolar' ||
          param === 'calificacion' ||
          param === 'id_observacion' ||
          param === 'observacion'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'tipo_asignatura') {
        this.getCatalogoTipoAsignatura()
      }

      if(!this.campo || this.campo === 'ciclo_escolar' || this.campo === 'calificacion' || this.campo === 'observacion') {
        this.getCatalogoObservacion()
      }

      this.getCalificacion()
    },

    methods: {
      observacionText: item => item.descripcion_corta ? item.descripcion_corta + ' (' + item.descripcion + ')' : item.descripcion,

      getCatalogoObservacion() {
        axios
          .get(api.path('catalogos.observacion'))
          .then(res => {
            this.observacion = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoTipoAsignatura() {
        axios
          .get(api.path('catalogos.tipo_asignatura'))
          .then(res => {
            this.tipoAsignatura = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCalificacion() {
        axios
          .get(api.path('lotes_certificados.certificados.calificaciones.single', { id: this.$route.params.id, id2: this.$route.params.id2, id3: this.$route.params.id3 } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('lotes_certificados.certificados.calificaciones.single', { id: this.$route.params.id, id2: this.$route.params.id2, id3: this.$route.params.id3 }), this.form)
            .then(res => {
              this.$toast.success('Calificación actualizada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
