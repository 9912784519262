<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.uuid"
              v-model="loteCertificados.id"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.nombre"
              v-model="loteCertificados.nombre"
              @click:append="editarLoteCertificados('nombre')"
              :append-icon="(loteCertificados.estado_lote_id === 2 || loteCertificados.estado_lote_id === 3) ? 'edit' : ''"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.campus"
              v-model="loteCertificados.nombre_campus"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.folio_pago"
              v-model="loteCertificados.folio_pago"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_capturado"
              v-model="loteCertificados.fecha_capturado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_capturado"
              v-model="loteCertificados.usuario_capturado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_pagado"
              v-model="loteCertificados.fecha_pagado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_pagado"
              v-model="loteCertificados.usuario_pagado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fecha_firmado"
              v-model="loteCertificados.fecha_firmado"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.usuario_firmado"
              v-model="loteCertificados.usuario_firmado"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loteCertificados: {},
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'lotes-certificados' })
      }
    },

    mounted() {
      this.getLoteCertificados()
    },

    methods: {
      getLoteCertificados() {
        axios
          .get(api.path("lotes_certificados.single", { id: this.$route.params.id }))
          .then(res => {
            this.loteCertificados = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
          })
      },

      editarLoteCertificados(campo) {
        this.$router.push({ name: "lotes-certificados-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
