<template>
  <v-container fluid grid-list-xl>
    <v-layout row justify-center wrap>
      <v-flex lg6 sm9 xs12>
        <transition name="fade" mode="out-in">
          <div>
            <v-text-field
              :label="labels.nombre"
              v-model="plantillaTitulo.nombre"
              @click:append="editarPlantillaTitulo('nombre')"
              :append-icon="plantillaTitulo.nombre === 'default' ? '' : edit"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.estilo"
              v-model="plantillaTitulo.estilo"
              outline
              readonly
            ></v-text-field>

            <v-text-field
              :label="labels.fondo"
              v-model="plantillaTitulo.fondo"
              outline
              readonly
            ></v-text-field>
          </div>
        </transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      plantillaTitulo: {}
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'plantilla-titulo' })
      }
    },

    mounted() {
      this.getPlantillaTitulo(this.$route.params.id)
    },

    methods: {
      getPlantillaTitulo(id) {
        this.loading = true
        axios
          .get(api.path('plantillas_titulos.single', { id: id } ))
          .then(res => {
            this.plantillaTitulo = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      editarPlantillaTitulo(campo) {
        this.$router.push({ name: "plantilla-titulo-editar", params: { 'campo': campo } })
      }
    }
  }
</script>
