<template>
  <div>
    <perfil-editar-form @success="success"></perfil-editar-form>
  </div>
</template>

<script>
  export default {
    components: {
      'perfil-editar-form': require('./PerfilEditarForm').default
    },

    methods: {
      success(data) {
        this.$router.push({ name: 'perfil' })
      }
    }
  }
</script>
