<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.name"
        v-model="form.name"
        :error-messages="errors.name"
        :rules="[rules.required('name')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'correo_electronico'"
        :label="labels.email"
        v-model="form.email"
        :error-messages="errors.email"
        :rules="[rules.required('email')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'contraseña'"
        :label="labels.new_password"
        v-model="form.password"
        :append-icon="passwordHidden ? 'visibility_off' : 'visibility'"
        @click:append="() => (passwordHidden = !passwordHidden)"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.password"
        :disabled="loading"
        hint="Al menos 6 caracteres"
        autocomplete="new-password"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'contraseña'"
        :label="labels.new_password_confirmation"
        v-model="form.password_confirmation"
        :type="passwordHidden ? 'password' : 'text'"
        :error-messages="errors.password_confirmation"
        :disabled="loading"
        autocomplete="new-password"
      ></v-text-field>

      <v-select
        v-if="!this.campo || this.campo === 'rol'"
        :label="labels.role"
        v-model="form.role"
        :items="roles"
        :error-messages="errors.role"
        :rules="[rules.required('role')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'campuses'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campuses_asignados"
        v-model="form.campuses"
        :disabled="loading"
        :items="campuses"
        item-text="nombre"
        item-value="id"
        return-object
        attach
        chips
        multiple
        deletable-chips
        dense
        hide-no-data
      ></v-autocomplete>
      
      <v-autocomplete
        v-if="!this.campo || this.campo === 'responsables'"
        @keyup.enter="$event.target.blur()"
        :label="labels.responsables_asignados"
        v-model="form.responsables"
        :disabled="loading"
        :items="responsables"
        item-text="nombre"
        item-value="id"
        return-object
        chips
        multiple
        deletable-chips
        hide-no-data
      >
        <template v-slot:selection="{ item, selected }">
          <v-chip
            :selected="selected"
            @input="remove(item)"
          >
            <span>{{ item.nombre }}</span>&nbsp;
            <span>{{ item.primer_apellido }}</span>&nbsp;
            <span>{{ item.segundo_apellido }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item }">
          <v-list-tile-avatar
            color="indigo"
            class="headline font-weight-light white--text"
          >
            {{ item.nombre.charAt(0) }}
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title v-text="nombreCompleto(item)"></v-list-tile-title>
            <v-list-tile-sub-title v-text="item.curp"></v-list-tile-sub-title>
          </v-list-tile-content>
        </template>
      </v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      passwordHidden: true,
      form: {},
      campuses: [],
      cargos: [],
      responsables: [],
      roles: ['Administrador', 'Firmante', 'Escolares'],
      campo: null
    }),

    computed: mapGetters({
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'usuario' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'nombre' ||
          param === 'correo_electronico' ||
          param === 'contraseña' ||
          param === 'rol' ||
          param === 'campuses' ||
          param === 'responsables'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'campuses') {
        this.getCampuses()
      }

      if(!this.campo || this.campo === 'responsables') {
        this.getResponsables()
      }

      if(this.campo !== 'contraseña') {
        this.getUsuario(this.$route.params.id)
      }
    },

    methods: {
      remove (item) {
        this.form.responsables.splice(this.form.responsables.indexOf(item), 1)
        this.form.responsables = [...this.form.responsables]
      },

      getUsuario(id) {
        this.loading = true
        axios
          .get(api.path('usuarios.single', { id: id } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getResponsables() {
        axios
          .get(api.path('responsables'))
          .then(res => {
            this.responsables = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      nombreCompleto(responsable) {
        return responsable.nombre + ' ' + responsable.primer_apellido + ' ' + responsable.segundo_apellido
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.put(api.path('usuarios.single', { id: this.$route.params.id }), this.form)
            .then(res => {
              this.$toast.success('Usuario actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
