<template>
  <v-footer app>
    <v-card :color="live_mode ? '' : 'orange'" class="flex" flat>
      <v-card-actions class="justify-center">
        <v-btn
          class="text-none"
          flat
          small
          @click="acerca"
        >&copy;{{ new Date().getFullYear() }} {{ siteName }} v{{ version }}</v-btn>
        <span> <strong v-if="!live_mode">(Modo de prueba)</strong></span>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";
import { settings } from "~/config";

export default {
  data: () => ({
    siteName: settings.siteName,
    version: settings.version
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
    })
  },

  methods: {
    acerca() {
      this.$router.push({ name: 'acerca' }).catch(err => {})
    },
  }
};
</script>
