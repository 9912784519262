var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      class: _vm.flat ? "elevation-0" : "",
      attrs: { vertical: "" },
      model: {
        value: _vm.stepper,
        callback: function($$v) {
          _vm.stepper = $$v
        },
        expression: "stepper"
      }
    },
    [
      _c(
        "v-stepper-step",
        { attrs: { complete: _vm.stepper > 1, step: "1" } },
        [_vm._v(_vm._s(_vm.messages.fiel_select_file_cer))]
      ),
      _vm._v(" "),
      _c(
        "v-stepper-content",
        { attrs: { step: "1" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.file_cer,
              "error-messages": _vm.errors.file_cer,
              rules: [_vm.rules.required("file_cer")],
              "append-icon": "attach_file",
              loading: _vm.loading
            },
            on: {
              click: function($event) {
                return _vm.$refs.fileCer.click()
              }
            },
            model: {
              value: _vm.fileCerName,
              callback: function($$v) {
                _vm.fileCerName = $$v
              },
              expression: "fileCerName"
            }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "fileCer",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: ".cer" },
            on: { change: _vm.selectFileCer }
          }),
          _vm._v(" "),
          _vm.fileCerName &&
          _vm.certificateInfo.curp &&
          _vm.certificateInfo.curp !== _vm.responsable.curp
            ? _c(
                "v-alert",
                {
                  attrs: {
                    value: true,
                    color: "warning",
                    icon: "priority_high",
                    outline: ""
                  }
                },
                [_vm._v(_vm._s(_vm.messages.fiel_cer_file_invalid))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                flat: "",
                disabled: _vm.certificateInfo.curp !== _vm.responsable.curp
              },
              on: {
                click: function($event) {
                  _vm.stepper = 2
                }
              }
            },
            [_vm._v(_vm._s(_vm.labels.next))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-stepper-step",
        { attrs: { complete: _vm.stepper > 2, step: "2" } },
        [_vm._v(" " + _vm._s(_vm.messages.fiel_select_file_key) + " ")]
      ),
      _vm._v(" "),
      _c(
        "v-stepper-content",
        { attrs: { step: "2" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.file_key,
              "error-messages": _vm.errors.file_key,
              rules: [_vm.rules.required("file_key")],
              "append-icon": "attach_file"
            },
            on: {
              click: function($event) {
                return _vm.$refs.fileKey.click()
              }
            },
            model: {
              value: _vm.fileKeyName,
              callback: function($$v) {
                _vm.fileKeyName = $$v
              },
              expression: "fileKeyName"
            }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "fileKey",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: ".key" },
            on: { change: _vm.selectFileKey }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  _vm.stepper = 1
                }
              }
            },
            [_vm._v(_vm._s(_vm.labels.back))]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                flat: "",
                disabled:
                  !_vm.fiel.fileKey ||
                  _vm.fiel.fileKey === undefined ||
                  !_vm.fileKeyName
              },
              on: {
                click: function($event) {
                  _vm.stepper = 3
                }
              }
            },
            [_vm._v(_vm._s(_vm.labels.next))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-stepper-step",
        { attrs: { complete: _vm.stepper > 3, step: "3" } },
        [_vm._v(" " + _vm._s(_vm.messages.fiel_input_password))]
      ),
      _vm._v(" "),
      _c(
        "v-stepper-content",
        { attrs: { step: "3" } },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.labels.password,
              "append-icon": _vm.passwordHidden
                ? "visibility_off"
                : "visibility",
              type: _vm.passwordHidden ? "password" : "text",
              "error-messages": _vm.errors.password,
              rules: [_vm.rules.required("password")]
            },
            on: {
              "click:append": function() {
                return (_vm.passwordHidden = !_vm.passwordHidden)
              }
            },
            model: {
              value: _vm.fiel.password,
              callback: function($$v) {
                _vm.$set(_vm.fiel, "password", $$v)
              },
              expression: "fiel.password"
            }
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { flat: "" },
              on: {
                click: function($event) {
                  _vm.stepper = 2
                }
              }
            },
            [_vm._v(_vm._s(_vm.labels.back))]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                flat: "",
                color: "primary",
                disabled: !_vm.fiel.password
              },
              on: { click: _vm.firmar }
            },
            [_vm._v(_vm._s(_vm.textButton ? _vm.textButton : _vm.labels.next))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }