<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-autocomplete
        v-if="!this.campo || this.campo === 'campus'"
        @keyup.enter="$event.target.blur()"
        :label="labels.campus"
        v-model="form.campus_id"
        :items="campuses"
        item-text="nombre"
        item-value="uuid"
        :error-messages="errors.campus"
        :rules="[rules.required('campus')]"
        :disabled="loading"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-autocomplete
        v-if="!this.campo || this.campo === 'carrera'"
        @keyup.enter="$event.target.blur()"
        :label="labels.carrera"
        v-model="form.carrera_id"
        :items="carreras"
        item-text="nombre"
        item-value="uuid"
        :error-messages="errors.carrera"
        :rules="[rules.required('carrera')]"
        :disabled="loading"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-select
        v-if="!this.campo || this.campo === 'modalidad'"
        :label="labels.modalidad"
        v-model="form.modalidad_id"
        :items="modalidades"
        item-text="nombre"
        item-value="id"
        :error-messages="errors.modalidad"
        :rules="[rules.required('modalidad')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-select
        v-if="!this.campo || this.campo === 'tipo_periodo'"
        :label="labels.tipo_periodo"
        v-model="form.tipo_periodo_id"
        :items="tipos_periodo"
        item-text="nombre"
        item-value="id"
        :error-messages="errors.tipo_periodo"
        :rules="[rules.required('tipo_periodo')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-select
        v-if="!this.campo || this.campo === 'tipo_rvoe'"
        :label="labels.tipo_rvoe"
        v-model="form.tipo_rvoe"
        :items="['ESTATAL', 'FEDERAL']"
        :error-messages="errors.tipo_rvoe"
        :rules="[rules.required('tipo_rvoe')]"
        :disabled="loading"
        dense
      ></v-select>

      <v-text-field
        v-if="!this.campo || this.campo === 'numero_rvoe'"
        :label="labels.numero_rvoe"
        v-model="form.numero_rvoe"
        :error-messages="errors.numero_rvoe"
        :rules="[rules.required('numero_rvoe')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'fecha_rvoe'"
        :label="labels.fecha_rvoe"
        v-model="form.fecha_rvoe"
        :error-messages="errors.fecha_rvoe"
        :rules="[rules.required('fecha_rvoe')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'clave_plan'"
        :label="labels.clave_plan"
        v-model="form.clave_plan"
        :error-messages="errors.clave_plan"
        :rules="[rules.required('clave_plan')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'calificacion_maxima'"
        :label="labels.calificacion_maxima"
        v-model="form.calificacion_maxima"
        :error-messages="errors.calificacion_maxima"
        :rules="[rules.required('calificacion_maxima')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'calificacion_minima'"
        :label="labels.calificacion_minima"
        v-model="form.calificacion_minima"
        :error-messages="errors.calificacion_minima"
        :rules="[rules.required('calificacion_minima')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'calificacion_minima_aprobatoria'"
        :label="labels.calificacion_minima_aprobatoria"
        v-model="form.calificacion_minima_aprobatoria"
        :error-messages="errors.calificacion_minima_aprobatoria"
        :rules="[rules.required('calificacion_minima_aprobatoria')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'total_creditos'"
        :label="labels.total_creditos"
        v-model="form.total_creditos"
        :error-messages="errors.total_creditos"
        :rules="[rules.required('total_creditos')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'total_asignaturas'"
        :label="labels.total_asignaturas"
        v-model="form.total_asignaturas"
        :error-messages="errors.total_asignaturas"
        :rules="[rules.required('total_asignaturas')]"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      loading: false,
      
      form: {
        campus_id: null,
        carrera_id: null,
        modalidad_id: null,
        tipo_periodo_id: null,
        tipo_rvoe: null,
        clave_plan: null,
        numero_rvoe: null,
        fecha_rvoe: null,
        calificacion_maxima: null,
        calificacion_minima: null,
        calificacion_minima_aprobatoria: null,
      },
      
      campuses: [],
      carreras: [],
      modalidades: [],
      tipos_periodo: [],

      campo: null
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'programa' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'campus' ||
          param === 'carrera' ||
          param === 'modalidad' ||
          param === 'tipo_periodo' ||
          param === 'tipo_rvoe' ||
          param === 'numero_rvoe' ||
          param === 'fecha_rvoe' ||
          param === 'clave_plan' ||
          param === 'calificacion_maxima' ||
          param === 'calificacion_minima' ||
          param === 'calificacion_minima_aprobatoria'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      if(!this.campo || this.campo === 'campus') {
        this.getCampuses()
      }

      if(!this.campo || this.campo === 'carrera') {
        this.getCarreras()
      }

      if(!this.campo || this.campo === 'modalidad') {
        this.getCatalogoModalidad()
      }

      if(!this.campo || this.campo === 'tipo_periodo') {
        this.getCatalogoTipoPeriodo()
      }

      this.getPrograma()
    },

    methods: {
      getPrograma(id) {
        axios
          .get(api.path('programas.single', { id: this.$route.params.id } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCarreras() {
        axios
          .get(api.path('carreras'))
          .then(res => {
            this.carreras = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoModalidad() {
        axios
          .get(api.path('catalogos.modalidad'))
          .then(res => {
            this.modalidades = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoTipoPeriodo() {
        axios
          .get(api.path('catalogos.tipo_periodo'))
          .then(res => {
            this.tipos_periodo = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios
            .put(api.path('programas.single', { id: this.$route.params.id }), this.form)
            .then(res => {
              this.$toast.success('Programa de actualizado correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
