var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        {
          attrs: { "fixed-tabs": "", grow: "", "slider-color": "primary" },
          on: {
            change: function($event) {
              _vm.cambiarPestaña($event)
            }
          },
          model: {
            value: _vm.selectedTab,
            callback: function($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab"
          }
        },
        [
          _c("v-tab", { attrs: { href: "#certificado" } }, [
            _c("span", [_vm._v("Certificado")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#carrera" } }, [
            _c("span", [_vm._v("Carrera")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#alumno" } }, [
            _c("span", [_vm._v("Alumno")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#expedicion" } }, [
            _c("span", [_vm._v("Expedición")])
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { href: "#asignaturas" } }, [
            _c("span", [_vm._v("Asignaturas")])
          ]),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            [
              _c(
                "v-tab-item",
                { attrs: { value: "certificado" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.estado,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.estado_documento
                                              .nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado.estado_documento,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.estado_documento.nombre"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.folio_control,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.folio_control,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "folio_control",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.folio_control"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-textarea", {
                                        attrs: {
                                          label: _vm.labels.resultado,
                                          outline: "",
                                          readonly: "",
                                          rows: _vm.resultadoRows
                                        },
                                        model: {
                                          value: _vm.certificado.resultado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "resultado",
                                              $$v
                                            )
                                          },
                                          expression: "certificado.resultado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "carrera" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.campus,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.nombre_campus,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_campus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_campus"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.entidad_federativa,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .nombre_entidad_federativa,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_entidad_federativa",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_entidad_federativa"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.nivel_de_estudio,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .nombre_nivel_estudio,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_nivel_estudio",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_nivel_estudio"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.carrera,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.nombre_carrera,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_carrera",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_carrera"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.numero_rvoe,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.numero_rvoe,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "numero_rvoe",
                                              $$v
                                            )
                                          },
                                          expression: "certificado.numero_rvoe"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.fecha_rvoe,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.fecha_rvoe,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "fecha_rvoe",
                                              $$v
                                            )
                                          },
                                          expression: "certificado.fecha_rvoe"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.tipo_periodo,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.nombre_tipo_periodo,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_tipo_periodo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_tipo_periodo"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.clave_plan,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.clave_plan,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "clave_plan",
                                              $$v
                                            )
                                          },
                                          expression: "certificado.clave_plan"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.calificacion_minima,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.calificacion_minima,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "calificacion_minima",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.calificacion_minima"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.calificacion_maxima,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.calificacion_maxima,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "calificacion_maxima",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.calificacion_maxima"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .calificacion_minima_aprobatoria,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .calificacion_minima_aprobatoria,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "calificacion_minima_aprobatoria",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.calificacion_minima_aprobatoria"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "alumno" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.curp_alumno,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "curp_alumno"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.certificado.curp_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "curp_alumno",
                                              $$v
                                            )
                                          },
                                          expression: "certificado.curp_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.matricula_alumno,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "matricula_alumno"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.matricula_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "matricula_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.matricula_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.nombre_alumno,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "nombre_alumno"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.certificado.nombre_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.primer_apellido_alumno,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "primer_apellido_alumno"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .primer_apellido_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "primer_apellido_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.primer_apellido_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.segundo_apellido_alumno,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "segundo_apellido_alumno"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .segundo_apellido_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "segundo_apellido_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.segundo_apellido_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.genero_alumno,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.genero_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "genero_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.genero_alumno"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.fecha_nacimiento_alumno,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .fecha_nacimiento_alumno,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "fecha_nacimiento_alumno",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.fecha_nacimiento_alumno"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "expedicion" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.tipo_certificacion,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.tipo_certificacion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "tipo_certificacion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.tipo_certificacion"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.fecha_de_expedicion,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "fecha_expedicion"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.fecha_expedicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "fecha_expedicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.fecha_expedicion"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels
                                              .entidad_federativa_expedicion,
                                          "append-icon":
                                            _vm.loteCertificados
                                              .estado_lote_id === 2 ||
                                            _vm.loteCertificados
                                              .estado_lote_id === 3
                                              ? "edit"
                                              : "",
                                          outline: "",
                                          readonly: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            return _vm.editarCertificado(
                                              "entidad_federativa_expedicion"
                                            )
                                          }
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .nombre_entidad_federativa_expedicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "nombre_entidad_federativa_expedicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.nombre_entidad_federativa_expedicion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab-item",
                { attrs: { value: "asignaturas" } },
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-xl": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", "justify-center": "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg6: "", sm9: "", xs12: "" } },
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade", mode: "out-in" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.total_asignaturas,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.total_asignaturas,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "total_asignaturas",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.total_asignaturas"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.asignaturas_asignadas,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .asignaturas_asignadas,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "asignaturas_asignadas",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.asignaturas_asignadas"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            _vm.labels.promedio_calificaciones,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado
                                              .promedio_calificaciones,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "promedio_calificaciones",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.promedio_calificaciones"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.total_creditos,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.total_creditos,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "total_creditos",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.total_creditos"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.creditos_obtenidos,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value:
                                            _vm.certificado.creditos_obtenidos,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "creditos_obtenidos",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.creditos_obtenidos"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.labels.numero_ciclos,
                                          outline: "",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.certificado.numero_ciclos,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.certificado,
                                              "numero_ciclos",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "certificado.numero_ciclos"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }