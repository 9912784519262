<template>
  <v-footer app color="white" height="auto">
    <v-layout justify-center row wrap>
      <v-btn flat round small :to="{ name: 'ayuda' }">Ayuda</v-btn>
      <v-btn flat round small :to="{ name: 'politicas' }">Privacidad</v-btn>
      <v-btn flat round small :to="{ name: 'aliados' }">Aliados</v-btn>
      <v-flex py-2 text-xs-center xs12>
        <v-btn
          class="text-none"
          flat
          small
        >&copy;{{ new Date().getFullYear() }} {{ siteName }} v{{ version }}</v-btn>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
  import { settings } from '~/config'

  export default {
    data: () => ({
      siteName: settings.siteName,
      version: settings.version
    }),
  }
</script>
