<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < calificaciones.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === calificaciones.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getCalificaciones"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ calificaciones.total > 1 ? 1 : 0 }}-{{ calificaciones.total ? calificaciones.total : 0 }} de {{ calificaciones.total ? calificaciones.total : 0 }}</span>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="calificaciones.data && calificaciones.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="calificaciones.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarCalificacion(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_asignatura }}</td>
          <td :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.clave_asignatura }}</td>
          <td :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.nombre_tipo_asignatura }}</td>
          <td :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.creditos_asignatura }}</td>
          <td :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.ciclo_escolar }}</td>
          <td :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.calificacion }}</td>
          <td :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.observacion }}</td>
        </tr>
        </v-hover>
      </template>
    </v-data-table>

    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay calificaciones registradas",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Nombre Asignatura", value: "nombre_asignatura", visibility: true },
      { text: "Clave Asignatura", value: "clave_asignatura", visibility: false },
      { text: "Tipo Asignatura", value: "nombre_tipo_asignatura" , visibility: true },
      { text: "Creditos Asignatura", value: "creditos_asignatura" , visibility: false },
      { text: "Ciclo", value: "ciclo_escolar", visibility: true },
      { text: "Calificación", value: "calificacion", visibility: true },
      { text: "Observación", value: "observacion" , visibility: true },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: null,
      sortBy: 'id'
    },

    calificaciones: [],
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      this.$router.push({ name: 'lotes-certificados' })
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val)
      } else {
        this.$router.replace('')
      }
      this.getCalificaciones()
    }
  },

  methods: {
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.calificaciones.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getCalificaciones() {
      this.loading = true
      axios
        .get(api.path('lotes_certificados.certificados.calificaciones', { id: this.$route.params.id, id2: this.$route.params.id2 } ))
        .then(res => {
            this.calificaciones = res.data
            this.pagination.rowsPerPage = res.data.total
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors)
        })
        .then(() => {
          this.loading = false
        })
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.nombre_asignatura + '\t'
        data = data + val.clave_asignatura + '\t'
        data = data + val.nombre_tipo_asignatura + '\t'
        data = data + val.creditos_asignatura + '\t'
        data = data + val.ciclo_escolar + '\t'
        data = data + val.calificacion + '\t'
        data = data + val.observacion + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarCalificacion(val) {
      this.$router.push({ name: 'lotes-certificados-certificados-calificaciones-mostrar', params: { id: this.$route.params.id, id2: this.$route.params.id2, id3: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getCalificaciones()
      localStorage.setItem('calificacion.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('calificacion.headers', JSON.stringify(this.headers))
    },
  },

  mounted() {
    if (localStorage.hasOwnProperty('calificacion.pagination')) {
      this.pagination = JSON.parse(localStorage.getItem('calificacion.pagination'))
    }

    if (localStorage.hasOwnProperty('calificacion.headers')) {
      this.headers = JSON.parse(localStorage.getItem('calificacion.headers'))
    }

    let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')

    if (tab[0] === '#buscar') {
      let busqueda = decodeURI( tab[1] )
      this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
    } else {
      if (this.busqueda) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
      }
    }

    this.getCalificaciones()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}
</style>

