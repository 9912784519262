var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.valid,
        callback: function($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("v-text-field", {
        ref: "campoCorreo",
        attrs: {
          label: _vm.labels.email,
          type: "email",
          "error-messages": _vm.errors.email,
          rules: [_vm.rules.required("email")],
          disabled: _vm.loading,
          "prepend-icon": "la-user"
        },
        model: {
          value: _vm.form.email,
          callback: function($$v) {
            _vm.$set(_vm.form, "email", $$v)
          },
          expression: "form.email"
        }
      }),
      _vm._v(" "),
      _c("v-text-field", {
        ref: "campoContraseña",
        attrs: {
          label: _vm.labels.password,
          "append-icon": _vm.passwordHidden ? "visibility_off" : "visibility",
          type: _vm.passwordHidden ? "password" : "text",
          "error-messages": _vm.errors.password,
          disabled: _vm.loading,
          rules: [_vm.rules.required("password")],
          "prepend-icon": "la-lock"
        },
        on: {
          "click:append": function() {
            return (_vm.passwordHidden = !_vm.passwordHidden)
          }
        },
        model: {
          value: _vm.form.password,
          callback: function($$v) {
            _vm.$set(_vm.form, "password", $$v)
          },
          expression: "form.password"
        }
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "mt-2": "" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading || !_vm.valid,
                color: "primary"
              }
            },
            [_vm._v("\n      Siguiente\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }