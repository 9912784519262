export default [
  ...applyRules(['guest'], [
    { path: '', component: require('$comp/auth/AuthWrapper').default, redirect: { name: 'login' }, children:
      [
        { path: '/acceso', name: 'login', component: require('$comp/auth/login/Login').default },
        { path: '/registro', name: 'registro', component: require('$comp/auth/registro/Registro').default },
        { path: '/password', component: require('$comp/auth/password/PasswordWrapper').default, children:
          [
            { path: '', name: 'forgot', component: require('$comp/auth/password/password-forgot/PasswordForgot').default },
            { path: 'reset/:token', name: 'reset', component: require('$comp/auth/password/password-reset/PasswordReset').default }
          ]
        },
        { path: '/aliados', name: 'aliados', component: require('$comp/auth/shared/Aliados').default },
        { path: '/ayuda', name: 'ayuda', component: require('$comp/auth/shared/Ayuda').default },
        { path: '/politicas', name: 'politicas', component: require('$comp/auth/shared/Terminos').default },
      ]
    },
  ]),
  ...applyRules(['auth'], [
    { path: '', component: require('$comp/admin/AdminWrapper').default, children:
      [
        { path: '', name: 'index', redirect: { name: 'panel' } },
        { path: 'perfil', component: require('$comp/admin/perfil/PerfilWrapper').default, children:
          [
            { path: '', name: 'perfil', component: require('$comp/admin/perfil/Perfil').default },
            { path: 'editar', name: 'perfil-editar', component: require('$comp/admin/perfil/editar/PerfilEditar').default }
          ]
        },
        { path: 'lotes-titulos', component: require('$comp/admin/lote-titulo/LoteTituloWrapper').default, children:
          [
            { path: '', name: 'lotes-titulos', component: require('$comp/admin/lote-titulo/LoteTitulo').default },
            { path: 'subir', name: 'lotes-titulos-subir', component: require('$comp/admin/lote-titulo/subir/LoteTituloSubir').default },
            { path: 'titulos/agregar', name: 'lotes-titulos-titulos-agregar', component: require('$comp/admin/lote-titulo/titulo/agregar/TituloAgregar').default },
            { path: ':id', name: 'lotes-titulos-mostrar', component: require('$comp/admin/lote-titulo/mostrar/LoteTituloMostrar').default },
            { path: ':id/editar', name: 'lotes-titulos-editar', component: require('$comp/admin/lote-titulo/editar/LoteTituloEditar').default },
            { path: ':id/titulos', component: require('$comp/admin/lote-titulo/titulo/TituloWrapper').default, children:
              [
                { path: '', name: 'lotes-titulos-titulos', component: require('$comp/admin/lote-titulo/titulo/Titulo').default },
                { path: ':id2', name: 'lotes-titulos-titulos-mostrar', component: require('$comp/admin/lote-titulo/titulo/mostrar/TituloMostrar').default },
                { path: ':id2/editar', name: 'lotes-titulos-titulos-editar', component: require('$comp/admin/lote-titulo/titulo/editar/TituloEditar').default },
              ]
            },
          ]
        },
        { path: 'lotes-certificados', component: require('$comp/admin/lote-certificado/LoteCertificadoWrapper').default, children:
          [
            { path: '', name: 'lotes-certificados', component: require('$comp/admin/lote-certificado/LoteCertificado').default },
            { path: 'capturar', name: 'lotes-certificados-capturar', component: require('$comp/admin/lote-certificado/capturar/LoteCertificadoCapturar').default },
            { path: ':id', name: 'lotes-certificados-mostrar', component: require('$comp/admin/lote-certificado/mostrar/LoteCertificadoMostrar').default },
            { path: ':id/editar', name: 'lotes-certificados-editar', component: require('$comp/admin/lote-certificado/editar/LoteCertificadoEditar').default },
            { path: ':id/certificados', component: require('$comp/admin/lote-certificado/certificado/CertificadoWrapper').default, children:
              [
                { path: '', name: 'lotes-certificados-certificados', component: require('$comp/admin/lote-certificado/certificado/Certificado').default },
                { path: 'capturar', name: 'lotes-certificados-certificados-capturar', component: require('$comp/admin/lote-certificado/certificado/capturar/CertificadoCapturar').default },
                { path: ':id2', name: 'lotes-certificados-certificados-mostrar', component: require('$comp/admin/lote-certificado/certificado/mostrar/CertificadoMostrar').default },
                { path: ':id2/editar', name: 'lotes-certificados-certificados-editar', component: require('$comp/admin/lote-certificado/certificado/editar/CertificadoEditar').default },
                { path: ':id2/calificaciones', component: require('$comp/admin/lote-certificado/certificado/calificacion/CalificacionWrapper').default, children:
                  [
                    { path: '', name: 'lotes-certificados-certificados-calificaciones', component: require('$comp/admin/lote-certificado/certificado/calificacion/Calificacion').default },
                    { path: 'capturar', name: 'lotes-certificados-certificados-calificaciones-capturar', component: require('$comp/admin/lote-certificado/certificado/calificacion/capturar/CalificacionCapturar').default },
                    { path: ':id3', name: 'lotes-certificados-certificados-calificaciones-mostrar', component: require('$comp/admin/lote-certificado/certificado/calificacion/mostrar/CalificacionMostrar').default },
                    { path: ':id3/editar', name: 'lotes-certificados-certificados-calificaciones-editar', component: require('$comp/admin/lote-certificado/certificado/calificacion/editar/CalificacionEditar').default },
                  ]
                },
              ]
            },
          ]
        },
        { path: 'programas-estudio', component: require('$comp/admin/programa/ProgramaWrapper').default, children:
          [
            { path: '', name: 'programa', component: require('$comp/admin/programa/Programa').default },
            { path: 'subir', name: 'programa-subir', component: require('$comp/admin/programa/subir/ProgramaSubir').default },
            { path: ':id', name: 'programa-mostrar', component: require('$comp/admin/programa/mostrar/ProgramaMostrar').default },
            { path: ':id/editar', name: 'programa-editar', component: require('$comp/admin/programa/editar/ProgramaEditar').default },
            { path: ':id/asignaturas', component: require('$comp/admin/programa/asignatura/AsignaturaWrapper').default, children:
              [
                { path: '', name: 'programa-asignatura', component: require('$comp/admin/programa/asignatura/Asignatura').default },
                { path: 'agregar', name: 'programa-asignatura-agregar', component: require('$comp/admin/programa/asignatura/agregar/AsignaturaAgregar').default },
                { path: ':id2', name: 'programa-asignatura-mostrar', component: require('$comp/admin/programa/asignatura/mostrar/AsignaturaMostrar').default },
                { path: ':id2/editar', name: 'programa-asignatura-editar', component: require('$comp/admin/programa/asignatura/editar/AsignaturaEditar').default },
              ]
            },
          ]
        },
        { path: 'responsables', component: require('$comp/admin/responsables/ResponsablesWrapper').default, children:
          [
            { path: '', name: 'responsables', component: require('$comp/admin/responsables/Responsables').default },
            { path: 'agregar', name: 'responsables-agregar', component: require('$comp/admin/responsables/agregar/ResponsableAgregar').default },
            { path: ':id/firma', name: 'responsables-firma', component: require('$comp/admin/responsables/firma/ResponsableFirma').default },
            { path: ':id', name: 'responsables-mostrar', component: require('$comp/admin/responsables/mostrar/ResponsableMostrar').default },
            { path: ':id/editar', name: 'responsables-editar', component: require('$comp/admin/responsables/editar/ResponsableEditar').default },
          ]
        },
        { path: 'campus', component: require('$comp/admin/campus/CampusWrapper').default, children:
          [
            { path: '', name: 'campus', component: require('$comp/admin/campus/Campus').default },
            { path: 'agregar', name: 'campus-agregar', component: require('$comp/admin/campus/agregar/CampusAgregar').default },
            { path: ':id', name: 'campus-mostrar', component: require('$comp/admin/campus/mostrar/CampusMostrar').default },
            { path: ':id/editar', name: 'campus-editar', component: require('$comp/admin/campus/editar/CampusEditar').default },
            { path: ':id/plantillas', component: require('$comp/admin/campus/plantilla-titulo/PlantillaTituloWrapper').default, children:
              [
                { path: '', name: 'campus-plantilla-titulo', component: require('$comp/admin/campus/plantilla-titulo/PlantillaTitulo').default },
                { path: 'asignar', name: 'campus-plantilla-titulo-asignar', component: require('$comp/admin/campus/plantilla-titulo/asignar/PlantillaTituloAsignar').default },
              ]
            },
          ]
        },
        { path: 'carreras', component: require('$comp/admin/carrera/CarreraWrapper').default, children:
          [
            { path: '', name: 'carrera', component: require('$comp/admin/carrera/Carrera').default },
            { path: 'agregar', name: 'carrera-agregar', component: require('$comp/admin/carrera/agregar/CarreraAgregar').default },
            { path: ':id', name: 'carrera-mostrar', component: require('$comp/admin/carrera/mostrar/CarreraMostrar').default },
            { path: ':id/editar', name: 'carrera-editar', component: require('$comp/admin/carrera/editar/CarreraEditar').default },
          ]
        },
        { path: 'usuarios', component: require('$comp/admin/usuarios/UsuarioWrapper').default, children:
          [
            { path: '', name: 'usuario', component: require('$comp/admin/usuarios/Usuario').default },
            { path: 'agregar', name: 'usuario-agregar', component: require('$comp/admin/usuarios/agregar/UsuarioAgregar').default },
            { path: 'solicitudes', component: require('$comp/admin/usuarios/solicitud/SolicitudWrapper').default, children:
              [
                { path: '', name: 'usuario-solicitud', component: require('$comp/admin/usuarios/solicitud/Solicitud').default },
                { path: ':id/registrar', name: 'usuario-solicitud-registrar', component: require('$comp/admin/usuarios/solicitud/registrar/SolicitudRegistrar').default },
              ]
            },
            { path: ':id', name: 'usuario-mostrar', component: require('$comp/admin/usuarios/mostrar/UsuarioMostrar').default },
            { path: ':id/editar', name: 'usuario-editar', component: require('$comp/admin/usuarios/editar/UsuarioEditar').default },
          ]
        },
        { path: 'folios-control', component: require('$comp/admin/regla-folio-control/ReglaFolioControlWrapper').default, children:
          [
            { path: '', name: 'regla-folio-control', component: require('$comp/admin/regla-folio-control/ReglaFolioControl').default },
            { path: 'agregar', name: 'regla-folio-control-agregar', component: require('$comp/admin/regla-folio-control/agregar/ReglaFolioControlAgregar').default },
            { path: ':id', name: 'regla-folio-control-mostrar', component: require('$comp/admin/regla-folio-control/mostrar/ReglaFolioControlMostrar').default },
            { path: ':id/editar', name: 'regla-folio-control-editar', component: require('$comp/admin/regla-folio-control/editar/ReglaFolioControlEditar').default },
          ]
        },
        { path: 'plantillas-titulos', component: require('$comp/admin/plantilla-titulo/PlantillaTituloWrapper').default, children:
        [
          { path: '', name: 'plantilla-titulo', component: require('$comp/admin/plantilla-titulo/PlantillaTitulo').default },
          { path: 'agregar', name: 'plantilla-titulo-agregar', component: require('$comp/admin/plantilla-titulo/agregar/PlantillaTituloAgregar').default },
          { path: ':id', name: 'plantilla-titulo-mostrar', component: require('$comp/admin/plantilla-titulo/mostrar/PlantillaTituloMostrar').default },
          { path: ':id/editar', name: 'plantilla-titulo-editar', component: require('$comp/admin/plantilla-titulo/editar/PlantillaTituloEditar').default },
        ]
      },
        { path: 'panel', component: require('$comp/admin/panel/PanelWrapper').default, children:
          [
            { path: '', name: 'panel', component: require('$comp/admin/panel/Panel').default },
          ]
        },
        { path: 'configuracion', component: require('$comp/admin/configuracion/ConfiguracionWrapper').default, children:
          [
            { path: '', name: 'configuracion', component: require('$comp/admin/configuracion/Configuracion').default },
            { path: 'editar', name: 'configuracion-editar', component: require('$comp/admin/configuracion/editar/ConfiguracionEditar').default },
            { path: 'facturaciones', component: require('$comp/admin/configuracion/facturacion/FacturacionWrapper').default, children:
              [
                { path: '', name: 'configuracion-facturacion', component: require('$comp/admin/configuracion/facturacion/Facturacion').default },
                { path: ':id', name: 'configuracion-facturacion-mostrar', component: require('$comp/admin/configuracion/facturacion/mostrar/FacturacionMostrar').default },
              ]
            },
            { path: 'planes', component: require('$comp/admin/configuracion/plan/PlanWrapper').default, children:
              [
                { path: '', name: 'configuracion-plan', component: require('$comp/admin/configuracion/plan/Plan').default },
              ]
            },
          ]
        },
        { path: 'acerca', component: require('$comp/admin/acerca/AcercaWrapper').default, children:
          [
            { path: '', name: 'acerca', component: require('$comp/admin/acerca/Acerca').default },
          ]
        },
      ]
    },
  ]),
  { path: '*', redirect: { name: 'index' } }
]

function applyRules(rules, routes) {
  for (let i in routes) {
    routes[i].meta = routes[i].meta || {}

    if (!routes[i].meta.rules) {
      routes[i].meta.rules = []
    }
    routes[i].meta.rules.unshift(...rules)

    if (routes[i].children) {
      routes[i].children = applyRules(rules, routes[i].children)
    }
  }

  return routes
}
