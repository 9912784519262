var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hot-table", {
        ref: "tablaCertificados",
        attrs: { settings: _vm.settings },
        on: {
          afterOnCellMouseDown: _vm.afterOnCellMouseDown,
          afterChange: _vm.afterChange
        }
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "300" },
          model: {
            value: _vm.loadingDialog,
            callback: function($$v) {
              _vm.loadingDialog = $$v
            },
            expression: "loadingDialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { color: "primary", dark: "" } },
            [
              _c(
                "v-card-text",
                [
                  _vm._v("Espera un momento\n                "),
                  _c("v-progress-linear", {
                    attrs: { indeterminate: "", color: "white", "mb-0": "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }