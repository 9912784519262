import 'babel-polyfill'
import Vue from 'vue'
import Vuetify from 'vuetify'
import router from '~/router/index'
import store from '~/store/index'
import VuetifyToast from 'vuetify-toast-snackbar'
import VuetifyConfirm from 'vuetify-confirm'
import VueClipboard from 'vue-clipboard2'
import '~/plugins/index'
import 'material-design-icons/iconfont/material-icons.css'
import 'typeface-roboto/index.css'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
  iconfont: 'las',
  theme: {
    primary: colors.teal.darken1,
    secondary: colors.teal.lighten4,
    accent: colors.indigo.base
  }
})

Vue.use(VuetifyToast, {
  timeout: 6000
})

Vue.use(VuetifyConfirm, {
  buttonTrueText: 'Eliminar',
  buttonFalseText: 'Cancelar',
  color: 'warning',
  icon: 'warning',
  title: 'Advertencia',
  width: 350,
  property: '$confirm'
})

Vue.use(VueClipboard)

export const app = new Vue({
  router,
  store,
  render: h => h(require('$comp/App').default)
}).$mount('#app')
