<template>
  <div>
    <v-tabs
      fixed-tabs
      grow
      v-model="selectedTab"
      slider-color="primary"
      @change="cambiarPestaña($event)"
    >
      <v-tab href="#institucion">
        <span>Institución</span>
      </v-tab>

      <!--<v-tab href="#membresia">
        <span>Membresia</span>
      </v-tab>-->

      <v-tabs-items>
        <v-tab-item value="institucion">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-card class="mb-4">
                      <v-card-title>
                        <v-layout align-center justify-space-between row fill-height>
                          <div
                            class="ml-2 font-weight-light grey--text subheading"
                          >Logotipo</div>
                          <v-btn icon @click="editarInstitucion('logo')">
                            <v-icon color="grey darken-2">edit</v-icon>
                          </v-btn>
                        </v-layout>
                      </v-card-title>

                      <v-card-text v-if="institucion.logo">
                        <v-img
                          :src="institucion.logo"
                          :lazy-src="institucion.logo"
                          height="80"
                          contain
                          aspect-ratio="1"
                        ></v-img>
                      </v-card-text>
                    </v-card>

                    <v-text-field
                      :label="labels.nombre"
                      v-model="institucion.nombre"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.clave"
                      v-model="institucion.clave"
                      @click:append="editarInstitucion('clave')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.clave_concentradora"
                      v-model="institucion.clave_concentradora"
                      @click:append="editarInstitucion('clave_concentradora')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.rfc"
                      v-model="institucion.rfc"
                      @click:append="editarInstitucion('rfc')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.razon_social"
                      v-model="institucion.razon_social"
                      @click:append="editarInstitucion('razon_social')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.wsdl_usuario_titulos"
                      v-model="institucion.configuracion.wsdl_usuario_titulos"
                      @click:append="editarInstitucion('wsdl_usuario_titulos')"
                      append-icon="edit"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.wsdl_constrasena_titulos"
                      v-model="institucion.configuracion.wsdl_constrasena_titulos"
                      :type="passwordHidden ? 'password' : 'text'"
                      outline
                      readonly
                    >
                      <template v-slot:append>
                        <v-icon
                          class="mr-3"
                          @click="passwordHidden = !passwordHidden"
                        >{{ passwordHidden ? 'visibility_off' : 'visibility' }}</v-icon>
                        <v-icon @click="editarInstitucion('wsdl_constrasena_titulos')">edit</v-icon>
                      </template>
                    </v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>

        <v-tab-item value="membresia">
          <v-container fluid grid-list-xl>
            <v-layout row justify-center wrap>
              <v-flex lg6 sm9 xs12>
                <transition name="fade" mode="out-in">
                  <div>
                    <v-text-field
                      :label="labels.tipo_plan"
                      v-model="institucion.membresia.tipo_plan.nombre"
                      @click:append="mostrarPlanes()"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.modalidad_pago"
                      v-model="institucion.membresia.modalidad_pago.nombre"
                      outline
                      readonly
                    ></v-text-field>

                    <v-text-field
                      :label="labels.correo_electronico_contacto"
                      v-model="institucion.membresia.correo_electronico_contacto"
                      outline
                      readonly
                    ></v-text-field>
                  </div>
                </transition>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";
import Form from "~/mixins/form";

export default {
  mixins: [Form],

  data: () => ({
    selectedTab: "institucion",

    institucion: {
      configuracion: {},
      membresia: {
        tipo_plan: {},
        modalidad_pago: {}
      }
    },

    passwordHidden: true
  }),

  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.getInstitucion(1);
      }
    }
  },

  methods: {
    getInstitucion(id) {
      axios
        .get(api.path("instituciones.single", { id: id }))
        .then(res => {
          this.institucion = res.data;
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {});
    },

    editarInstitucion(campo) {
      this.$router.push({
        name: "configuracion-editar",
        params: { campo: campo }
      });
    },

    cambiarPestaña(tab) {
      this.$router.replace("#" + tab);
    }
  },

  mounted() {
    let tab = this.$route.fullPath.replace(this.$route.path, "").split("/");

    if (tab[0] === "") {
      this.cambiarPestaña(this.selectedTab);
    } else if (tab[0] === "#institucion") {
      this.selectedTab = "institucion";
    } else if (tab[0] === "#membresia") {
      this.selectedTab = "membresia";
    }

    this.getInstitucion(1);
  }
};
</script>
