<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-autocomplete
        @keyup.enter="$event.target.blur()"
        :label="labels.tipo_documento"
        v-model="form.tipo_documento.id"
        :error-messages="errors.tipo_documento"
        :rules="[rules.required('tipo_documento')]"
        :disabled="loading"
        :items="tipoDocumento"
        item-text="nombre"
        item-value="id"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-autocomplete
        @keyup.enter="$event.target.blur()"
        :label="labels.autorizacion_reconocimiento"
        v-model="form.autorizacion_reconocimiento.id"
        :error-messages="errors.autorizacion_reconocimiento"
        :rules="[rules.required('autorizacion_reconocimiento')]"
        :disabled="loading"
        :items="autorizacionesReconocimientos"
        item-text="nombre"
        item-value="id"
        dense
        hide-no-data
      ></v-autocomplete>

      <v-text-field
        :label="labels.consecutivo_documento"
        v-model="form.consecutivo_documento"
        :error-messages="errors.consecutivo_documento"
        :rules="[rules.required('consecutivo_documento')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        :label="labels.regla"
        v-model="form.regla"
        :error-messages="errors.regla"
        :rules="[rules.required('regla')]"
        :disabled="loading"
      ></v-text-field>

      <v-autocomplete
        @keyup.enter="$event.target.blur()"
        :label="labels.campuses_asignados"
        v-model="form.campuses"
        :disabled="loading"
        :items="campuses"
        item-text="nombre"
        item-value="uuid"
        attach
        chips
        multiple
        deletable-chips
        dense
        hide-no-data
      ></v-autocomplete>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {
        autorizacion_reconocimiento: {},
        campuses: [],
        tipo_documento: {},
      },
      autorizacionesReconocimientos: [],
      campuses: [],
      tipoDocumento: [],
    }),

    computed: mapGetters({
      auth: 'auth/user',
      live_mode: "auth/live_mode",
    }),

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'regla-folio-control' })
      }
    },

    mounted() {
      this.getCampuses()
      this.getCatalogoAutorizacionesReconocimientos()
      this.getCatalogoTipoDocumento()
    },

    methods: {
      getCampuses() {
        axios
          .get(api.path('campus'))
          .then(res => {
            this.campuses = res.data.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoAutorizacionesReconocimientos() {
        axios
          .get(api.path('catalogos.autorizacion_reconocimiento'))
          .then(res => {
            this.autorizacionesReconocimientos = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      getCatalogoTipoDocumento() {
        axios
          .get(api.path('catalogos.tipo_documento'))
          .then(res => {
            this.tipoDocumento = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {})
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.post(api.path('folios_control'), this.form)
            .then(res => {
              this.$toast.success('Regla de folio agregada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
