<template>
  <div>
    <v-toolbar
      class="elevation-1 toolbar-fix"
      flat
      dense
      color="white"
    >
      <v-btn
        v-if="selected.length === 0"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box_outline_blank</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length < programas.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">indeterminate_check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0 && selected.length === programas.data.length"
        icon
        @click="toggleAll"
      >
        <v-icon color="grey darken-1">check_box</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length === 0"
        icon
        @click="getProgramas"
      >
        <v-icon color="grey darken-1">refresh</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="eliminarProgramas"
      >
        <v-icon color="grey darken-1">delete</v-icon>
      </v-btn>

      <v-btn
        v-if="selected.length > 0"
        icon
        @click="copiarTexto"
      >
        <v-icon color="grey darken-1">content_copy</v-icon>
      </v-btn>

      <v-spacer></v-spacer>

      <span color="grey darken-1">{{ programas.count > 1 ? ((programas.current_page - 1) * programas.per_page + 1) : 0 }}-{{ programas.count ? ((programas.current_page - 1) * programas.per_page) + programas.count : 0 }} de {{ programas.total ? programas.total : 0 }}</span>

      <v-btn
        :disabled="programas.current_page <= 1"
        icon
        @click="getProgramasPreviousPage"
      >
        <v-icon color="grey darken-1">navigate_before</v-icon>
      </v-btn>

      <v-btn
        :disabled="programas.current_page >= programas.last_page"
        icon
        @click="getProgramasNextPage"
      >
        <v-icon
          color="grey darken-1">navigate_next</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="settingsDialog = true"
      >
        <v-icon color="grey darken-1">settings</v-icon>
      </v-btn>

    </v-toolbar>

    <v-container v-if="loading">
      <v-layout justify-center row>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>

    <v-data-table
      v-else-if="programas.data && programas.data.length > 0"
      v-model="selected"
      :headers="headers"
      :items="programas.data"
      :pagination.sync="pagination"
      :no-data-text="noDataText"
      :no-results-text="noResultsText"
      :loading="loading"
      item-key="id"
      hide-actions
      >
      <template slot="headers" slot-scope="props">
        <tr>
          <th></th>
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column sortable',
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              'text-xs-left',
              header.visibility ? 'hidden-print-only' : 'hidden-screen-only'
            ]"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>arrow_upward</v-icon>
          </th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <v-hover>
        <tr
          @click="mostrarPrograma(props.item)"
          slot-scope="{ hover }"
          :class="`elevation-${hover ? 2 : 0}`"
        >
          <td @click.stop="props.selected = !props.selected">
            <v-checkbox
              :input-value="props.selected"
              primary
              hide-details
            ></v-checkbox>
          </td>
          <td :class="[headers[0].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip v-if="props.item.editable" small color="green" text-color="white">
              <v-avatar>
                <v-icon>done</v-icon>
              </v-avatar>
              Si
            </v-chip>
            <v-chip v-else small color="orange" text-color="white">
              <v-avatar>
                <v-icon>error</v-icon>
              </v-avatar>
              No
            </v-chip>
          </td>

          <td v-if="incluyeBusqueda(props.item.campus)" :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.campus }}</strong></td>
          <td v-else :class="[headers[1].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.id }}</td>

          <td v-if="incluyeBusqueda(props.item.campus)" :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.campus }}</strong></td>
          <td v-else :class="[headers[2].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.campus }}</td>

          <td v-if="incluyeBusqueda(props.item.carrera)" :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.carrera }}</strong></td>
          <td v-else :class="[headers[3].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.carrera }}</td>

          <td v-if="incluyeBusqueda(props.item.modalidad)" :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.modalidad }}</strong></td>
          <td v-else :class="[headers[4].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.modalidad }}</td>

          <td v-if="incluyeBusqueda(props.item.tipo_periodo)" :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.tipo_periodo }}</strong></td>
          <td v-else :class="[headers[5].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.tipo_periodo }}</td>

          <td v-if="incluyeBusqueda(props.item.tipo_rvoe)" :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.tipo_rvoe }}</strong></td>
          <td v-else :class="[headers[6].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.tipo_rvoe }}</td>

          <td v-if="incluyeBusqueda(props.item.numero_rvoe)" :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.numero_rvoe }}</strong></td>
          <td v-else :class="[headers[7].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.numero_rvoe }}</td>

          <td v-if="incluyeBusqueda(props.item.fecha_rvoe)" :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.fecha_rvoe }}</strong></td>
          <td v-else :class="[headers[8].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.fecha_rvoe }}</td>

          <td v-if="incluyeBusqueda(props.item.clave_plan)" :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.clave_plan }}</strong></td>
          <td v-else :class="[headers[9].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.clave_plan }}</td>

          <td v-if="incluyeBusqueda(props.item.calificacion_maxima)" :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.calificacion_maxima }}</strong></td>
          <td v-else :class="[headers[10].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.calificacion_maxima }}</td>

          <td v-if="incluyeBusqueda(props.item.calificacion_minima)" :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.calificacion_minima }}</strong></td>
          <td v-else :class="[headers[11].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.calificacion_minima }}</td>

          <td v-if="incluyeBusqueda(props.item.calificacion_minima_aprobatoria)" :class="[headers[12].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.calificacion_minima_aprobatoria }}</strong></td>
          <td v-else :class="[headers[12].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.calificacion_minima_aprobatoria }}</td>
          
          <td v-if="incluyeBusqueda(props.item.created_at)" :class="[headers[13].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.created_at }}</strong></td>
          <td v-else :class="[headers[13].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.created_at }}</td>

          <td v-if="incluyeBusqueda(props.item.updated_at)" :class="[headers[14].visibility ? 'hidden-print-only' : 'hidden-screen-only']"><strong>{{ props.item.updated_at }}</strong></td>
          <td v-else :class="[headers[14].visibility ? 'hidden-print-only' : 'hidden-screen-only']">{{ props.item.updated_at }}</td>

          <td :class="[headers[15].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small color="indigo" text-color="white">
              <v-avatar class="indigo darken-4"><strong>{{ props.item.total_asignaturas }}</strong></v-avatar>
              <span v-if="props.item.total_asignaturas === 1">
                Asignatura
              </span>
              <span v-else>
                Asignaturas
              </span>
            </v-chip>
          </td>

          <td :class="[headers[16].visibility ? 'hidden-print-only' : 'hidden-screen-only']">
            <v-chip small color="indigo" text-color="white">
              <div v-if="props.item.total_creditos_length === 1" class="indigo darken-4 avatar avatar-small-width">
                <strong>{{ props.item.total_creditos }}</strong>
              </div>
              <div v-if="props.item.total_creditos_length > 1 && props.item.total_creditos_length <= 4" class="indigo darken-4 avatar avatar-medium-width">
                <strong>{{ props.item.total_creditos }}</strong>
              </div>
              <div v-if="props.item.total_creditos_length > 4" class="indigo darken-4 avatar">
                <strong>{{ props.item.total_creditos }}</strong>
              </div>
              <span v-if="props.item.total_creditos === 1">
                Crédito
              </span>
              <span v-else>
                Créditos
              </span>
            </v-chip>
          </td>

          <div v-if="hover && props.item.editable" class="programa-fix-1">
            <div class="justify-center layout grey lighten-3 programa-fix-2">
              <v-tooltip bottom>
                <v-btn class="button-fix" slot="activator" icon ripple @click.stop="eliminarPrograma(props.item)">
                  <v-icon color="grey darken-1">delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
          </div>
        </tr>
        </v-hover>
      </template>
    </v-data-table>
    
    <v-container v-else>
      <div class="layout column align-center text-center">
        <v-img
          class="mb-3"
          :lazy-src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :src="busqueda ? '/img/clip/clip-page-not-found.png' : '/img/clip/list-is-empty.png'"
          :alt="busqueda ? noResultsText : noDataText"
          width="380"
        ></v-img>
        <span class="mb-3 font-weight-light grey--text text--darken-4 title">
          {{ busqueda ? noResultsText : noDataText }}
        </span>
        <v-alert
          v-if="busqueda"
          :value="true"
          color="primary"
          icon="info"
          outline
        >
          Intenta usar otras palabras clave o quitar los filtros de búsqueda
        </v-alert>
      </div>
    </v-container>

    <v-dialog
      v-model="settingsDialog"
      persistent
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Configuración</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list>
              <v-subheader>
                Número de filas por página
              </v-subheader>

              <v-slider
                v-model="pagination.rowsPerPage"
                thumb-label="always"
                step="10"
                :thumb-size="24"
                :min="10"
                :max="100"
                ticks
                tick-size="2"
              ></v-slider>

              <v-subheader>
                Columnas a mostrar
              </v-subheader>
              
              <v-list-tile
                v-for="(header, index) in headers"
                :key="index"
              >
                <v-list-tile-action>
                  <v-switch
                    v-model="header.visibility"
                    color="primary"
                  ></v-switch>
                </v-list-tile-action>
                <v-list-tile-title>{{ header.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" flat @click="settingsDialog = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" flat @click="aplicarConfiguracion">Aplicar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { api } from "~/config";

export default {
  data: () => ({
    loading: false,

    noDataText: "No hay programas de estudio",
    noResultsText: "No se econtraron resultados",

    selected: [],

    headers: [
      { text: "Editable", value: "editable" , visibility: true },
      { text: "ID", value: "id" , visibility: false },
      { text: "Campus", value: "campus" , visibility: true },
      { text: "Carrera", value: "carrera", visibility: true },
      { text: "Modalidad", value: "modalidad", visibility: false },
      { text: "Tipo de Periodo", value: "tipo_periodo", visibility: false },
      { text: "Tipo de RVOE", value: "tipo_rvoe", visibility: false },
      { text: "Número de RVOE", value: "numero_rvoe", visibility: true },
      { text: "Fecha de RVOE", value: "fecha_rvoe", visibility: false },
      { text: "Clave del Plan", value: "clave_plan", visibility: true },
      { text: "Calif. Máxima", value: "calificacion_maxima", visibility: false },
      { text: "Calif. Mínima", value: "calificacion_minima", visibility: false },
      { text: "Calif. Mínima Aprobatoria", value: "calificacion_minima_aprobatoria", visibility: false },
      { text: "Fecha de Captura", value: "created_at", visibility: false },
      { text: "Fecha de Modificación", value: "updated_at", visibility: false },
      { text: "Total de Asignaturas", value: "total_asignaturas", visibility: true },
      { text: "Total de Créditos", value: "total_creditos", visibility: true },
    ],

    settingsDialog: false,
    
    pagination: {
      rowsPerPage: 10,
      sortBy: 'nombre'
    },

    programas: {},
  }),


  computed: {
    ...mapGetters({
      live_mode: "auth/live_mode",
      auth: 'auth/user',
      busqueda: "auth/busqueda"
    })
  },

  watch: {
    live_mode(val) {
      if (val !== null) {
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        this.initial()
      }
    },

    busqueda(val) {
      if (val) {
        this.$router.replace('#buscar/' + val.trim())
      } else {
        this.$router.replace('')
      }
      this.getProgramas()
    }
  },

  methods: {
    incluyeBusqueda(val) {
      if(val && this.busqueda) {
        let value = val.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        let value_two = this.busqueda.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return value.includes(value_two)
      }
    },

    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.programas.data.slice()
    },
    
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    getProgramas() {
      this.loading = true
      axios
        .get(api.path("programas"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            q: this.busqueda
          }
        })
        .then(res => {
          this.programas = res.data
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getProgramasPreviousPage() {
      this.loading = true
      axios
        .get(api.path("programas"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.programas.current_page - 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.programas = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    getProgramasNextPage() {
      this.loading = true
      axios
        .get(api.path("programas"), {
          params: {
            page_size: this.pagination.rowsPerPage,
            page: this.programas.current_page + 1,
            q: this.busqueda
          }
        })
        .then(res => {
          this.programas = res.data
          this.selected = []
        })
        .catch(err => {
          this.handleErrors(err.response.data.errors);
        })
        .then(() => {
          this.loading = false
        });
    },

    eliminarPrograma(val) {
      this.$confirm("¿Desea eliminar este programa de estudio?").then(
        res => {
          if (res) {
            axios
              .delete(api.path("programas.single", { id: val.id }))
              .then(res => {
                this.$toast.success("Programa de estudio eliminado correctamente.")
                this.selected = []
                this.getProgramas()
              })
              .catch(err => {
                this.$toast.error("No se puede eliminar el programa de estudio.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    eliminarProgramas() {
      this.$confirm("¿Desea eliminar los programas de estudio seleccionados?").then(
        res => {
          if (res) {

            let ids = []

            this.selected.forEach(function(val) {
              ids.push(val.id)
            })

            axios
              .delete(api.path("programas"), {
                params: {
                  ids: ids.join()
                }
              })
              .then(res => {
                this.$toast.success("Programas de estudio eliminados correctamente.")
                this.selected = []
                this.getProgramas()
              })
              .catch(err => {
                this.$toast.error("No se pueden eliminar los programas de estudio.")
                this.handleErrors(err.response.data.errors)
              })
              .then(() => {})
          }
        }
      )
    },

    copiarTexto() {
      let data = ''

      this.selected.forEach(function(val) {
        data = data + val.id + '\t'
        data = data + val.campus + '\t'
        data = data + val.carrera + '\t'
        data = data + val.modalidad + '\t'
        data = data + val.tipo_periodo + '\t'
        data = data + val.tipo_rvoe + '\t'
        data = data + val.numero_rvoe + '\t'
        data = data + val.fecha_rvoe + '\t'
        data = data + val.clave_plan + '\t'
        data = data + val.calificacion_maxima + '\t'
        data = data + val.calificacion_minima + '\t'
        data = data + val.calificacion_minima_aprobatoria + '\t'
        data = data + val.created_at + '\t'
        data = data + val.updated_at + '\t'
        data = data + val.total_asignaturas + '\t'
        data = data + val.total_creditos + '\n'
      })

      let me = this
      this.$copyText(data).then(function (e) {
        me.$toast.success("Datos copiados al portapapeles.")
      }, function (e) {
        me.$toast.success("No se puede copiar los datos al portapapeles.")
      })
    },

    mostrarPrograma(val) {
      this.$router.push({ name: 'programa-mostrar', params: { id: val.id } })
    },

    aplicarConfiguracion() {
      this.settingsDialog = false
      this.getProgramas()
      localStorage.setItem('programas.pagination', JSON.stringify({
        descending: this.pagination.descending,
        rowsPerPage: this.pagination.rowsPerPage,
        sortBy: this.pagination.sortBy
      }))
      localStorage.setItem('programas.headers', JSON.stringify(this.headers))
    },

    initial() {
      if (localStorage.hasOwnProperty('programas.pagination')) {
        this.pagination = JSON.parse(localStorage.getItem('programas.pagination'))
      }

      if (localStorage.hasOwnProperty('programas.headers')) {
        this.headers = JSON.parse(localStorage.getItem('programas.headers'))
      }

      let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
      
      if (tab[0] === '#buscar') {
        let busqueda = decodeURI( tab[1] )
        this.$store.dispatch('auth/guardarBusqueda', { busqueda: busqueda })
      } else {
        if (this.busqueda) {
          this.$store.dispatch('auth/guardarBusqueda', { busqueda: null })
        }
      }

      this.getProgramas()
    }
  },

  mounted() {
    this.initial()
  }
}
</script>

<style>
	.toolbar-fix {
    position: sticky !important;
    z-index: 2 !important;
    top: 48px !important;
	}

  .table-fix {
    padding-top: 48px !important;
	}

  .programa-fix-1 {
    position: relative !important;
	}

  .programa-fix-2 {
    position: absolute !important;
    z-index: 1 !important;
    left: -52px !important;
    top: 6px !important;
    height: 36px !important;
	}

  .button-fix {
    margin-top: 0px !important;
	}

  .avatar {
    height: 24px;
    margin-left: -12px;
    margin-right: 8px;
    min-width: 24px;
    width: 48px;
    border-bottom-left-radius: 48px;
    border-top-left-radius: 48px;
    border-bottom-right-radius: 48px;
    border-top-right-radius: 48px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    text-align: center;
    vertical-align: middle;
  }

  .avatar-small-width {
    width: 24px !important;
  }

  .avatar-medium-width {
    width: 32px !important;
  }
</style>

