/**
 * auth.js
 */
export const SET_USER = 'SET_USER'
export const LOGOUT = 'LOGOUT'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_SEARCH = 'SET_SEARCH'
export const SET_COMENTARIO_DIALOG = 'SET_COMENTARIO_DIALOG'
export const SET_MENSAJE_DIALOG = 'SET_MENSAJE_DIALOG'
export const SET_FUNCION_PREMIUM_DIALOG = 'SET_FUNCION_PREMIUM_DIALOG'
export const SET_ENVIAR_CERTIFICADOS = 'SET_ENVIAR_CERTIFICADOS'
export const SET_ESTADO_LOTE = 'SET_ESTADO_LOTE'
export const SET_TITULOS_IDS = 'SET_TITULOS_IDS'