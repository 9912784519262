<template>
  <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="valid">
    <v-container :class="$vuetify.breakpoint.xsOnly ? '' : 'elevation-2'">
      <v-text-field
        v-if="!this.campo || this.campo === 'nombre'"
        :label="labels.nombre"
        v-model="form.nombre"
        :error-messages="errors.nombre"
        :rules="[rules.required('nombre')]"
        :disabled="loading"
      ></v-text-field>
      
      <v-text-field
        v-if="!this.campo || this.campo === 'estilo'"
        :label="labels.estilo"
        v-model="form.estilo"
        :error-messages="errors.estilo"
        :rules="[rules.required('estilo')]"
        :disabled="loading"
      ></v-text-field>

      <v-text-field
        v-if="!this.campo || this.campo === 'fondo'"
        :label="labels.fondo"
        v-model="form.fondo"
        :error-messages="errors.fondo"
        :rules="[rules.required('fondo')]"
        :disabled="loading"
      ></v-text-field>
    </v-container>

    <v-layout justify-end mt-2>
      <v-btn
        flat
        :disabled="loading"
        @click="$router.go(-1)"
        color="grey darken-2"
      >
        Cancelar
      </v-btn>

      <v-btn
        type="submit"
        :loading="loading"
        :disabled="loading"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-layout>
  </v-form>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { api } from '~/config'
  import Form from '~/mixins/form'

  export default {
    mixins: [Form],

    data: () => ({
      form: {},
      campo: null
    }),

    computed: {
      ...mapGetters({
        live_mode: "auth/live_mode",
      })
    },

    watch: {
      live_mode(val) {
        this.$router.push({ name: 'plantilla-titulo' })
      }
    },

    mounted() {
      if(this.$route.params.campo) {
        this.campo = this.$route.params.campo
      } else {
        let tab = this.$route.fullPath.replace(this.$route.path, '').split('/')
        let param = decodeURI(tab[0].substr(1))
        if (
          param === 'nombre' ||
          param === 'estilo' ||
          param === 'fondo'
        ) {
          this.campo = param
        }
      }

      if(this.campo) {
        this.$router.replace('#' + this.campo)
      }

      this.getPlantillaTitulo(this.$route.params.id)
    },

    methods: {
      getPlantillaTitulo(id) {
        this.loading = true
        axios
          .get(api.path('plantillas_titulos.single', { id: id } ), {
            params: {
              campo: this.campo
            }
          })
          .then(res => {
            this.form = res.data
          })
          .catch(err => {
            this.handleErrors(err.response.data.errors)
          })
          .then(() => {
            this.loading = false
          })
      },

      submit() {
        if (this.$refs.form.validate()) {
          this.loading = true

          axios.put(api.path('plantillas_titulos.single', { id: this.$route.params.id }), this.form)
            .then(res => {
              this.$toast.success('Plantilla de título actualizada correctamente.')
              this.$emit('success', res.data)
            })
            .catch(err => {
              this.handleErrors(err.response.data.errors)
            })
            .then(() => {
              this.loading = false
            })
        }
      }
    }
  }
</script>
